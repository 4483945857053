<template>
  <div>
    <v-toolbar class="background" >
      nice
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: "SecondaryNavbar",
  data() {
    return {
      userRoleId: 2,
    };
  },
  computed: {
    navItems() {
      if (this.userRoleId == 8) {
        return [
          { title: "Dashboard", icon: "dashboard", link: "home" },
          {
            title: "Notice & Information",
            icon: "mdi-download",
            link: "referencefiles",
          },
        ];
      } else {
        return [
          {
            title: "Dashboard",
            icon: "mdi-view-dashboard-variant",
            link: "home",
          },
          { title: "Report", icon: "mdi-chart-arc", link: "report" },
          {
            title: "Analyse",
            icon: "mdi-home-analytics",
            link: "resultanalysis",
          },
          {
            title: "Application Status",
            icon: "mdi-list-status",
            link: "applicationstatuspage",
          },
          { title: "Downloads", icon: "mdi-download", link: "referencefiles" },
          {
            title: "Municipality",
            icon: "mdi-domain",
            link: "municipalitydetail",
          },
          {
            title: "Consultancy",
            icon: "mdi-account-group",
            link: "consultancy",
          },
        ];
      }
    },
  },
};
</script>

<style scoped>
</style>