export default axios => ({
    floorInfoRevenue(regId) {
        return axios.get(`api/registration/${regId}/floor-info-revenue`);
    },
    saveFloorInfoRevenue(regId, payload) {
        return axios.post(`api/registration/${regId}/floor-info-revenue`, payload);
    },
    floorDetailsIndex(regId) {
        return axios.get(`api/registration/${regId}/floor-detail`);
    }
});
