<template>
  <v-card class="nibedanBibaran elevation-12 my-2">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>
        थप विवरणहरु
      </v-toolbar-title>
    </v-toolbar>
    <v-simple-table class="sober elevation-2">
      <thead>
        <tr class="">
          <th class="text-left ">Name</th>
          <th class="text-left ">Actions</th>
          <th class="text-left ">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in fifteenDaysBibarans" :key="item.name" class="">
          <td class="">{{ item.name }}</td>
          <td class="">
            <router-link
              :to="{ name: 'fifteentabbed', params: { id: item.id, regId: regId, sn: item.sn } }"
              >{{ actionButtonText }}</router-link
            >
          </td>
          <td>{{ item.status }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  name: "FifteenDaysBibaran",
  props: {
    regId: {
      type: Number
    },
    isAddEditAuthorized: {
      type: Boolean
    }
  },
  computed: {
    actionButtonText() {
      if (this.isAddEditAuthorized) {
        return "Add/Edit";
      }
      return "View";
    }
  },
  data() {
    return {
      user_role_id: localStorage.getItem("auth_user_role_id"),
      registrationStatus: [],
      fifteenDaysBibarans: [
        {
          id: 1,
          name: "निर्माणकर्मी / निर्माण व्यवसायी",
          nameEng: "contractor",
          action: "Add/Edit",
          status: "",
          sn: 1
        },
        {
          id: 2,
          name: "इलेक्ट्रीशियन",
          nameEng: "electrician",
          action: "Add/Edit",
          status: "",
          sn: 2
        },
        {
          id: 3,
          name: "प्लम्बर",
          nameEng: "plumber",
          action: "Add/Edit",
          status: "",
          sn: 3
        }
      ]
    };
  },
  mounted() {
    const statusUrl = `api/registration/${this.regId}/status`;
    const temp = this.fifteenDaysBibarans;

    this.$axios
      .get(statusUrl)
      .then(response => {
        this.registrationStatus = response.data.data;

        temp.forEach(item => {
          this.fifteenDaysBibarans[item.sn - 1].status = this.registrationStatus[item.nameEng];
        });
      })
      .catch(error => {
        console.log(`api error:${error}`);
        temp.forEach(item => {
          this.fifteenDaysBibarans[item.sn - 1].status = "Not Completed";
        });
      });
  }
};
</script>

<style scoped></style>
