<template>
  <div>
    <loading :active.sync="isDataLoading" :can-cancel="false" :is-full-page="false"></loading>
    <h1>List of Notices</h1>
    <div>
      <v-row>
        <v-col>
          <v-text-field label="Reg ID" type="number" v-model="input.regId"></v-text-field>
        </v-col>
        <v-col>
          <v-select v-model="input.phaseId" :items="phaseList" item-value="phase_id" item-text="phase_name"
            label="Phase"></v-select>
        </v-col>
        <v-col>
          <v-select v-model="input.constructionTypeId" :items="constructionTypeList" item-value="construction_type_id"
            item-text="construction_type_name" label="Construction Type"></v-select>
        </v-col>
        <v-col>
          <v-btn class="primary" @click="getNoticeMaster">Get Notices</v-btn>
        </v-col>
      </v-row>
      <template v-if="showNotice">
        <v-expansion-panels>
          <v-expansion-panel v-for="(item, i) in noticeList" :key="i">
            <!-- <template v-if="user_role_id == 8 && item.consultancy_view_only"> -->
            <v-expansion-panel-header>
              {{ item.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <inline-doc :src="item.apiUrl"></inline-doc>
            </v-expansion-panel-content>
            <!-- </template> -->
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "NoticeList",
  data() {
    return {
      phaseList: [],
      input: {
        regId: "",
        phaseId: "",
        constructionTypeId: "",
      },
      showNotice: false,
      noticeList: [],
      constructionTypeList: [],
      isDataLoading: false,
      
    };
  },
  created() {
    this.getPhaseList();
    this.getConstructionTypeList();
  },
  methods: {
    async getPhaseList() {
      let response = await api.dropdowns.phaseList();
      this.phaseList = response.data.data;
    },
    async getConstructionTypeList() {
      let response = await api.dropdowns.constructionType();
      this.constructionTypeList = response.data;
    },
    // async getConstructionTypeId() {
    //   let response = await api.registration.registration(this.input.regId);
    //   let constructionTypeId = response.data.data.construction_type_id;
    //   return constructionTypeId;
    // },
    async getNoticeMaster() {
      this.isDataLoading = true;
      this.noticeList = [];
      let response = await api.documents.phaseNoticeMaster(
        this.input.constructionTypeId,
        this.input.phaseId
      );
      let responseNoticeList = response.data;
      let basePath = process.env.VUE_APP_BASE_URL;
      // let basePath = "https://backend-demo.gharnakshanepal.com/";
      responseNoticeList.forEach((element) => {
        this.noticeList.push({
          title: element.notice_master_title,
          apiUrl:
            basePath +
            `api/registration/${this.input.regId}/${element.notice_master_api_keyword}`,
        });
      });
      this.showNotice = true;
      this.isDataLoading = false;
    },
  },
};
</script>

<style>

</style>