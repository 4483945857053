<template>
  <div
    class="container pa-4 text-center text-no-wrap rounded-xl"
    style="border-style: ridge; margin-bottom: 20px"
  >
    <v-form v-model="formValid">
      <div>
        <h3 class="blue--text font-weight-bold">निवेदन</h3>
        <!-- {{$i18n.locale}} -->
        <hr />
      </div>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <nepali-date-picker
            v-model="application.application_date_np"
            :label="$t('application.applicationDate')"
            hint="Required*"
            outlined
          ></nepali-date-picker>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-select
            v-model="application.land_use_area_id"
            :items="landUseAreaList"
            item-text="name"
            item-value="id"
            :label="$t('application.landUseArea')"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-select
            v-model="application.building_use_id"
            :label="$t('application.buildingUse')"
            :items="buildingUseList"
            item-value="id"
            item-text="building_use_type"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-select
            v-model="application.building_category_id"
            :label="$t('application.buildingCategory')"
            :items="buildingCategoryList"
            item-value="id"
            item-text="building_category_type"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-select
            v-model="application.building_structure_id"
            :label="$t('application.buildingStructure')"
            :items="buildingStructureList"
            item-value="id"
            item-text="building_structure_type"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4"> </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto">
          <h4>तलाको विवरण</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" sm="3" md="2">
          <v-text-field
            v-model="application.building_storey"
            :label="$t('application.buildingStorey')"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="3" md="2">
          <v-text-field
            v-model="application.building_storey_basement"
            :label="$t('application.buildingStoreyBasement')"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <div>
        <!-- {{ floorData[0].area }} -->
      </div>
      <v-row>
        <v-col cols="12">
          <FloorInfo
            :registrationId="registrationId"
            :surfaceLength="parseInt(application.building_storey)"
            :baseLength="parseInt(application.building_storey_basement)"
            @floorData="getFloorData($event)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          {{ $t("application.buildingLength") }}
          <v-row>
            <v-col>
              <v-text-field
                v-model="application.building_length_ft"
                :label="$t('unit.feet')"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="application.building_length_in"
                :label="$t('unit.inch')"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          {{ $t("application.buildingBreadth") }}
          <v-row>
            <v-col>
              <v-text-field
                v-model="application.building_breadth_ft"
                :label="$t('unit.feet')"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="application.building_breadth_in"
                :label="$t('unit.inch')"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          {{ $t("application.buildingStorey") }}
          <v-row>
            <v-col>
              <v-text-field
                v-model="application.building_breadth_ft"
                :label="$t('unit.feet')"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="application.building_breadth_in"
                :label="$t('unit.inch')"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-btn @click="saveFloorInfo" class="primary">Save</v-btn>
      </v-row> -->
    </v-form>
  </div>
</template>

<script>
import NepaliDatePicker from "@/custom-inputs/nepali-date-picker/NepaliDatePicker.vue";
import FloorInfo from "./subcomponent/FloorInfo.vue";
import api from "@/api/api";

export default {
  name: "ApplicationForm",
  components: {
    NepaliDatePicker,
    FloorInfo,
  },
  data() {
    return {
      formValid: false,
      application: {
        id: 0,
        // reg_id: 0,
        application_date: "2020-04-22",
        application_date_np: "",
        building_category_id: "",
        land_use_area_id: "",
        building_use_id: "",
        building_structure_id: "",
        building_length_in: "",
        building_breadth_in: "",
        building_height_in: "",
        building_length_ft: "",
        building_breadth_ft: "",
        building_height_ft: "",
        building_storey: "",
        building_storey_basement: "",
        building_max_area: "",
        building_plinth_area: "",
      },
      landUseAreaList: [],
      buildingUseList: [],
      buildingCategoryList: [],
      buildingStructureList: [],
      // Remove below data later
      registrationId: 32,
      floorData: [],
    };
  },
  created() {
    this.getDropdowns();
  },
  methods: {
    // async/await Function
    async getDropdowns() {
      try {
        let responseLandUseArea = await api.dropdowns.landUseArea();
        this.landUseAreaList = responseLandUseArea.data.data;

        let responseBuildingUse = await api.dropdowns.buildingUse();
        this.buildingUseList = responseBuildingUse.data;

        let responseBuildingCategory = await api.dropdowns.buildingCategory();
        this.buildingCategoryList = responseBuildingCategory.data;

        let responseBuildingStructure = await api.dropdowns.buildingStructure();
        this.buildingStructureList = responseBuildingStructure.data;
      } catch (error) {
        console.log(error);
      }
    },
    getFloorData(eventFloorData) {
      this.floorData = eventFloorData;
      console.log("nice" + eventFloorData[0].area);
    },

    // saveFloorInfo() {
    //   // console.log("k vairaxa yeha");
    //   api.houseownerInfo.saveFloorInfoRevenue(this.registrationId, this.floorData);
    // },
  },
};
</script>

<style></style>
