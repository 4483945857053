<template>
  <v-dialog v-model="dialog" max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :color="clickType === 'delete' || clickType === 'revert' ? 'error' : 'primary'
        " :small="clickType === 'delete' || 'renew' ? true : false" v-bind="attrs" v-on="on" :disabled="disabled" class="ma-1">
        <slot />
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5"> {{ title }} </v-card-title>
      <v-card-text>{{ subtitle }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed color="darken-1" text v-on:click="dialog = false">
          No
        </v-btn>
        <v-btn depressed color="error darken-1" text v-on:click="
          clickDialog(regId);
        dialog = false;
        ">
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    clickDialog: {
      type: Function,
    },
    regId: {
      type: [String, Number],
    },
    clickType: {
      default: "Click",
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: false,
      title: "Are you sure?",
      subtitle: "Are you really sure?",
      BtnColor: this.clickType == "delete" ? "red" : "primary",
    };
  },
  mounted() {
    this.changeTitle();
  },
  methods: {
    changeTitle() {
      let titles = {
        delete: {
          heading: "Are you sure you want to delete?",
          subheading: "After delete data will be lost permanently!",
          color: "primary",
        },
        renew: {
          heading: "Are you sure you want to renew?",
          subheading: "Consultancy will be renew for current fiscal year!",
          color: "success",
        },
        forward: {
          heading: "Are you sure you want to forward?",
          subheading: "Click yes to forward.",
          color: "primary",
        },
        revert: {
          heading: "Are you sure you want to revert?",
          subheading: "Click yes to revert.",
          color: "error",
        },
        transferAuthority: {
          heading: "Are you sure want to transfer?",
          subheading: "Click 'YES' to transfer user authority permission.",
          color: "primary",
        },
        revokedAuthority: {
          heading: "Are you sure want to revoke?",
          subheading: "Click 'YES' to revoke user authority.",
          color: "primary",
        },
        transferConsultant: {
          heading: "Are you sure you want to transfer Consultancy?",
          subheading: "Click 'YES' to transfer the consultancy of this file.",
          color: "primary"
        },
        save: {
          heading: "Are you sure you want to save?",
          subheading: "Click 'YES' to save.",
          color: "primary"
        },
      };
      this.title = titles[this.clickType].heading;
      this.subtitle = titles[this.clickType].subheading;
      this.BtnColor = titles[this.clickType].color;
    },
  },
};
</script>
