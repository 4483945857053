<template>
  <v-container>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <div id="map" class="mt-2">
      <Map :building-coordinates="responseCoordinates"></Map>
    </div>
    <div id="filterCard">
      <v-card elevation="2">
        <v-card-title>Filter Data</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="2"><v-select v-model="filters.ward" :items="wards" chips label="वडा" multiple></v-select></v-col>
            <v-col cols="2">
              <v-select v-model="filters.selectedStorey" :items="storeys" chips label="तला" multiple></v-select>
            </v-col>
            <v-col cols="3">
              <v-select v-model="filters.selectedConstructionType" :items="constructiontypes" item-text="construction_type_name" item-value="construction_type_id" chips
                label="निर्माणको विवरण" multiple></v-select>
            </v-col>
            <v-col cols="3">
              <v-select v-model="filters.selectedStructure" :items="buildingStructureList" item-text="building_structure_type" item-value="id" chips label="भवन संरचना"
                multiple></v-select>
            </v-col>
            <v-col cols="2">
              <v-select v-model="filters.selectedBuildingCategory" :items="buildingCategoryList" item-text="building_category_type" item-value="id" chips label="भवन वर्गिकरण"
                multiple></v-select>
            </v-col>
            <!-- <v-col cols="8">
              <v-range-slider v-model="filters.plinthAreaRange" :max="rangeMax" :min="rangeMin" hide-details class="align-center">
                <template v-slot:prepend>
                  <v-text-field :value="filters.plinthAreaRange[0]" class="mt-0 pt-0" hide-details single-line type="number" style="width: 60px"
                    @change="$set(filters.plinthAreaRange, 0, $event)"></v-text-field>
                </template>
                <template v-slot:append>
                  <v-text-field :value="filters.plinthAreaRange[1]" class="mt-0 pt-0" hide-details single-line type="number" style="width: 60px"
                    @change="$set(filters.plinthAreaRange, 1, $event)"></v-text-field>
                </template>
              </v-range-slider>
            </v-col> -->
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn class="primary" @click="filterData">Filter</v-btn>
        </v-card-actions>
      </v-card>
    </div>

  </v-container>
</template>

<script>
import { engNumConvert } from "@/modules/mixins/numericToUnicode.js";
import Map from "./ResultAnalysisMap.vue";

export default {
  name: "ResultAnalysis",
  mixins: [engNumConvert],
  components: {
    Map
  },
  data() {
    return {
      isLoading: false,
      filters: {
        selectedFiscalYear: [],
        ward: [],
        building_permit: "",
        selectedConstructionType: [],
        selectedYear: [],
        selectedStorey: [],
        selectedStructure: [],
        selectedIrregularity: [],
        selectedAreaOfBuilding: [],
        selectedTypeOfRoof: [],
        selectedBuildingCategory: [],
        plinthAreaRange: [0, 10000]
      },
      wards: [],
      noOfStoreys: 5,
      constructiontypes: [],
      buildingStructureList: [],
      buildingCategoryList: [],
      responseCoordinates: [],
      rangeMin: 0,
      rangeMax: 10000,
    };
  },
  mounted() {
    this.getMunicipality();
    this.constructionType();
    this.getBuildingStructure();
    this.getBuildingCategory();
  },
  computed: {
    storeys() {
      let tempStoreys = [];
      for (let i = 0; i < this.noOfStoreys; i++) {
        tempStoreys.push(i + 1);
      }
      return tempStoreys;
    }
  },
  methods: {
    async getMunicipality() {
      this.isLoading = true;
      let munTemp = this.$store.getters.getMunicipality;
      try {
        if (!Object.keys(munTemp).length) {
          await this.$store.dispatch("fetchMunicipality")
        }
        let noOfWards = this.$store.getters.getMunicipality.no_of_wards;
        for (let i = 0; i < noOfWards; i++) {
          this.wards.push(i + 1);
        }

      } catch (error) {
        console.log("Error while retrieving municipality");
        console.log(error);
      }
    },
    async constructionType() {
      this.isLoading = true;
      let constructionTypeListTemp = this.$store.getters.constructionTypeList;
      try {

        if (!constructionTypeListTemp.length) {
          await this.$store.dispatch("getConstructionTypeList")
        }
        this.constructiontypes = this.$store.getters.constructionTypeList;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    async getBuildingStructure() {
      this.isLoading = true;
      let buildingStructureTemp = this.$store.getters.buildingStructureList;
      try {
        if (!buildingStructureTemp.length) {
          await this.$store.dispatch("getBuildingStructureList");
        }
        this.buildingStructureList = this.$store.getters.buildingStructureList;
        this.isLoading = false;
      }
      catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    async getBuildingCategory() {
      this.isLoading = true;
      let buildingCategoryTemp = this.$store.getters.buildingCategoryList;
      try {
        if (!buildingCategoryTemp.length) {
          await this.$store.dispatch("getBuildingCategoryList");
        }
        this.buildingCategoryList = this.$store.getters.buildingCategoryList;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    filterData() {
      const filterUrl = `api/report/building-filter`;
      this.$axios.post(filterUrl, this.filters).then(response => {
        this.responseCoordinates = response.data.mapdata.features;
      });
    }
  }
};
</script>