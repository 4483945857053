<template>
  <div>
    <v-row no-gutters>
      <v-col cols="3">
        <v-card class="mx-auto my-4" max-width="370">
          <v-card-text>
            <p class="text-h4 text--primary">User and Desk</p>
            <div class="text--primary">User with desk all kind of information</div>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary"> View More </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="mx-auto my-4" max-width="370">
          <v-card-text>
            <p class="text-h4 text--primary">Authority Transfer</p>
            <div class="text--primary">Authority Transfer all kind of information</div>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary"> View More </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="mx-auto my-4" max-width="370">
          <v-card-text>
            <p class="text-h4 text--primary">Ward Assignment</p>
            <div class="text--primary">Ward Assignment all kind of information</div>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary"> View More </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="mx-auto my-4" max-width="370">
          <v-card-text>
            <p class="text-h4 text--primary">Role Transfer</p>
            <div class="text--primary">Role Transfer all kind of information</div>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary"> View More </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5">
        <GChart class="piechart" type="PieChart" @ready="buildingStorey" style="width: 900px; height: 500px;" />
      </v-col>
      <v-col cols="6">
        <GChart class="columnchart" type="ColumnChart"  @ready="buildingFootprintArea" style="width: 900px; height: 500px;" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "Report",
  data() {
    return {
      i: 0,
      basePath: process.env.VUE_APP_BASE_URL,
      buildingExcelExport: "api/export-all",
      buildingStage: [],
      applicationStatus: [],
      applicationHeader: [],
      revenueStatus: [],
      revenueHeader: [],
      consultancy: [],
      dateFilter: {
        start_date: "",
        end_date: "",
      },
    };
  },
  computed: {
    dateRangeText() {
      return "From " + this.dateRange.join(" to ");
    },
  },
  methods: {
    buildingStorey(chart, google) {
      VueAxios.get(`api/report/building-storey`).then((response) => {
        this.buildingStoreyTitle = response.data.Title;
        const options = {
          title: response.data.Title,
          is3D: true,
        };
        chart.draw(google.visualization.arrayToDataTable(response.data.data), options);
      });
    },
    buildingFootprintArea(chart, google) {
      VueAxios.get(`api/report/building-area`).then((response) => {
        const options = {
          title: response.data.Title,
          is3D: true,
        };
        chart.draw(google.visualization.arrayToDataTable(response.data.data), options);
      });
    },
  },
};
</script>
