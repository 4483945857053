<template>
  <div>
    <v-row>
      <v-col>
        <MemberForm/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MemberForm from "./inputforms/MemberForm.vue";

export default {
  props: {
    conId: Number,
  },
  components: {
    MemberForm,
  },
};
</script>
