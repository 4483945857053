<template>
  <v-card class="nibedanBibaran elevation-12 my-2">
    <v-expansion-panels :value="opened">
      <v-expansion-panel v-for="(item, i) in 1" :key="i">
        <v-expansion-panel-header>
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title> निवेदन विवरणहरु </v-toolbar-title>
          </v-toolbar>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table class="sober elevation-2">
            <thead>
              <tr class="" v-if="regConstructionId==8">
                <th class="text-left">कित्ताकाट भएको छ कि छैन</th>
                <th class="text-left"></th>
                <th class="text-left">
                  <!-- <v-dialog v-model="dialog" persistent max-width="290">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small class="blue elevation-0 white--text" dark v-bind="attrs" v-on="on"> छ | छैन ?</v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h5"> कित्ताकाट भएको छ कि छैन ?</v-card-title>
                      <v-card-text
                        >Kitta kaat bhako cha ki chhaina?</v-card-text
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="dialog = false">
                          छ
                        </v-btn>
                        <v-btn color="red darken-1" text @click="dialog = false"> छैन </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog> -->
                  <v-switch v-model="ex11" label="छ" color="primary" value="primary" hide-details></v-switch>
                </th>
              </tr>

              <tr class="">
                <th class="text-left">Name</th>
                <th class="text-left">Actions</th>
                <th class="text-left">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in nibedanBibarans" :key="item.name"
                :class="item.status == 'Completed' ? 'green lighten-5' : 'red lighten-5'">
                <td class="">{{ item.name }}</td>
                <td class="">
                  <router-link :to="{
                    name: 'tabbed',
                    params: {
                      id: item.id,
                      regId: regId,
                      sn: item.sn,
                      isPostAuthorized: isAddEditAuthorized,
                      regConstructionId: regConstructionId,
                    },
                  }">
                    <v-btn small class="blue elevation-0 white--text">{{
                    item.nameEng == "contractor" ? contractorActionButtonText : actionButtonText
                    }}</v-btn>
                  </router-link>
                </td>
                <td>{{ item.status }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
export default {
  name: "NibedanBibaran",
  props: {
    regId: {
      type: [Number, String],
    },
    isAddEditAuthorized: {
      type: Boolean,
    },
    regConstructionId: {
      type: Number,
    },
  },
  computed: {
    actionButtonText() {
      if (this.isAddEditAuthorized) {
        return "Add/Edit";
      }
      return "View";
    },
    // This is just a temporary solution to allow different add/edit action in contractor
    // Long term solution will be available soon.
    contractorActionButtonText() {
      if (this.contractorFormAddEdit) {
        return "Add/Edit";
      }
      return "View";
    },
    applicationText() {
      return this.reg.construction_type_id == 4 ? "पर्खालको विवरण" : "निवेदन";
    },
  },
  data() {
    return {
      dialog: false,
      opened: 0,
      user_role_id: localStorage.getItem("auth_user_role_id"),
      registrationStatus: [],
      contractorFormAddEdit: false,
      reg: {
        construction_type_id: 1,
      },
      nibedanBibarans: [
        {
          id: 1,
          name: "निवेदन",
          nameEng: "application",
          action: "Add/Edit",
          status: "Loading..",
          sn: 1,
        },
        {
          id: 2,
          name: "घरधनीको विवरण",
          nameEng: "houseowner_detail",
          action: "Add/Edit",
          status: "Loading..",
          sn: 2,
        },
        {
          id: 3,
          name: "डिजाइनर",
          nameEng: "designer",
          action: "Add/Edit",
          status: "Loading..",
          sn: 3,
        },
        {
          id: 4,
          name: "सुपरभाइजर",
          nameEng: "supervisor",
          action: "Add/Edit",
          status: "Loading..",
          sn: 4,
        },
        {
          id: 5,
          name: " जग्गाको विवरण (ठेगाना)",
          nameEng: "land_info_address",
          action: "Add/Edit",
          status: "Loading..",
          sn: 5,
        },
        {
          id: 6,
          name: "चारकिल्ला",
          nameEng: "charkilla",
          action: "Add/Edit",
          status: "Loading..",
          sn: 6,
        },
        {
          id: 7,
          name: "जग्गाको विवरण (क्षेत्रफल)",
          nameEng: "land_info_area",
          action: "Add/Edit",
          status: "Loading..",
          sn: 7,
        },
        {
          id: 8,
          name: "जग्गाधनीको विवरण",
          nameEng: "landowner_info",
          action: "Add/Edit",
          status: "Loading..",
          sn: 8,
        },
        {
          id: 9,
          name: "निर्माणकर्मी / निर्माण व्यवसायी",
          nameEng: "contractor",
          action: "Add/Edit",
          status: "Loading..",
          sn: 9,
        },
        {
          id: 10,
          name: "निवेदकको विवरण",
          nameEng: "applicant",
          action: "Add/Edit",
          status: "Loading..",
          sn: 10,
        },
      ],
    };
  },
  created() {
    this.getConstructionType();
  },
  mounted() {
    const statusUrl = `api/registration/${this.regId}/status`;
    const temp = this.nibedanBibarans;

    this.$axios
      .get(statusUrl)
      .then((response) => {
        this.registrationStatus = response.data.data;

        temp.forEach((item) => {
          this.nibedanBibarans[item.sn - 1].status = this.registrationStatus[item.nameEng];
        });
      })
      .catch((error) => {
        console.log(`api error:${error}`);
        temp.forEach((item) => {
          this.nibedanBibarans[item.sn - 1].status = "Not Completed";
        });
      });

    this.getContractorFormAuthorization();
  },
  methods: {
    getConstructionType() {
      this.reg = this.$store.getters.registrationData;
      this.nibedanBibarans[0].name =
        this.reg.construction_type_id == 4 ? "पर्खालको विवरण" : "निवेदन";
    },
    getContractorFormAuthorization() {
      const authUrl = `api/registration/${this.regId}/contractor/is-authorized`;
      this.$axios
        .get(authUrl)
        .then((response) => {
          this.contractorFormAddEdit = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
