<template>
  <div>
    <!-- <notification></notification> -->
    <!-- <HtmlRender /> -->
    <!-- <NewRegistration/> -->
    <!-- <RegistrationDataTable /> -->
    <b-button @click="modalClick">Launch demo modal 7c81febf</b-button>

    <b-modal id="modal-1" title="BootstrapVue" @ok="increaseCount">
      <p class="my-4">Hello from modal! {{ clickCount }}</p>
    </b-modal>
    {{ clickCount }}
  </div>
</template>

<script>
import Notification from "../navbar/components/Notification.vue";
import HtmlRender from "./HtmlRender.vue";
import NewRegistration from "../newregistration/NewRegistration.vue";
// import RegistrationDataTable from "../home/components/RegistrationDataTable.vue"
export default {
  name: "Posts",
  components: {
    // Notification,
    // NewRegistration,
    // HtmlRender,
    // RegistrationDataTable
  },
  data() {
    return {
      clickCount: 1,
    };
  },
  methods: {
    modalClick() {
      // this.clickCount = this.clickCount + 1;
      const nice = 1;
      this.clickCount= nice;
      this.$bvModal.show("modal-1");
    },
    increaseCount() {
      this.clickCount = this.clickCount + 1;
    },
  },
};
</script>
