<template>
  <div
    class="container pa-4 text-center text-no-wrap rounded-xl"
    style="border-style: ridge; margin-bottom: 10px"
  >
    <div>
      <h3 class="blue--text font-weight-bold">घरधनीको विवरण</h3>
      <hr />
    </div>
    <v-form>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <label for="">{{ $t("houseowner.type") }}</label>
          <v-select
            v-model="houseowner.houseowner_type"
            :label="$t('houseowner.type')"
            :items="houseowner_type_items"
            item-text="name"
            item-value="id"
            outlined
            filled
          ></v-select>
        </v-col>
        <template v-if="houseownerIsPerson">
          <v-col cols="12" sm="6" md="6">
            <label for="">{{ $t("houseowner.photo") }} <span> *</span></label>
            <v-file-input
              v-model="houseowner.photo"
              :rules="[$valid.isRequired]"
              label="Select File..."
              outlined
            ></v-file-input>
          </v-col>
          <!-- <v-col cols="12" sm="6" md="4">
            <img :src="uploadedDocument" width="200px" />
          </v-col> -->
          <v-col cols="12" sm="6" md="4"> </v-col>
          <v-col cols="12" sm="6" md="4">
            <div>
              <h3
                class="black--text font-weight-light text-center"
                style="background-color: #e8e8e8"
              >
                Houseowner Information
              </h3>
              <hr />
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4"> </v-col>
          <v-col cols="12" sm="6" md="4">
            <label for="">{{ $t("houseowner.name") }} <span> *</span></label>
            <v-text-field
              v-model="houseowner.name_np"
              :label="$t('houseowner.name')"
              class="custom-text-field"
              :rules="[$valid.isRequired, $valid.isNepaliAlphabet]"
              outlined
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <label for="">{{ $t("houseowner.nameEn") }} <span> *</span></label>
            <v-text-field
              v-model="houseowner.name"
              :label="$t('houseowner.nameEn')"
              class="custom-text-field"
              :rules="[$valid.isRequired, $valid.isEnglishAlphabet]"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <label for="">{{ $t("houseowner.husbandName") }} <span> *</span></label>
            <v-text-field
              v-model="houseowner.father_or_husband_name_np"
              class="custom-text-field"
              :label="$t('houseowner.husbandName')"
              :rules="[$valid.isRequired, $valid.isNepaliAlphabet]"
              outlined
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <label for="">{{ $t("houseowner.grandfatherName") }}</label>
            <v-text-field
              v-model="houseowner.grandfather_name_np"
              :label="$t('houseowner.grandfatherName')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <label for="">{{ $t("houseowner.citizenshipIssueDistrict") }}</label>
            <v-autocomplete
              v-model="houseowner.citizenship_district_id"
              :label="$t('houseowner.citizenshipIssueDistrict')"
              :items="districts"
              item-value="id"
              item-text="district_name"
              :key="id"
              clearable
              outlined
              filled
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <label for="">{{ $t("houseowner.phoneNo") }} <span> *</span></label>
            <v-text-field
              v-model="houseowner.phone_no"
              :label="$t('houseowner.phoneNo')"
              :rules="[$valid.isRequired, $valid.isPhone]"
              type="text"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <label for="">{{ $t("houseowner.email") }}</label>
            <v-text-field
              v-model="houseowner.email"
              type="text"
              :label="$t('houseowner.email')"
              :rules="[$valid.isEmail]"
              outlined
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <label for="">{{ $t("houseowner.citizenshipNo") }} <span> *</span></label>
            <v-text-field
              v-model="houseowner.citizenship_no"
              :label="$t('houseowner.citizenshipNo')"
              :rules="[$valid.isRequired, $valid.isNepaliAlphabet]"
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <label for="">{{ $t("houseowner.citizenshipIssueDate") }} <span> *</span></label>
            <nepali-date-picker
              v-model="houseowner.citizenship_issue_date_np"
              :label="$t('houseowner.citizenshipIssueDate')"
              hint="Required*"
              :rules="[$valid.isRequired, $valid.isNepaliDate]"
              outlined
              filled
            ></nepali-date-picker>
          </v-col>
          <v-col cols="12" sm="6" md="4"> </v-col>
          <v-col cols="12" sm="6" md="4">
            <div>
              <h3
                class="black--text font-weight-light text-center"
                style="background-color: #e8e8e8"
              >
                Address Information
              </h3>
              <hr />
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4"> </v-col>
          <v-col cols="12" sm="6" md="3">
            <label for="">{{ $t("houseowner.wardNo") }} <span> *</span></label>
            <v-select
              :items="wards"
              v-model="houseowner.ward_no"
              :label="$t('houseowner.wardNo')"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <label for="">{{ $t("houseowner.address") }} <span> *</span></label>
            <v-text-field
              v-model="houseowner.address"
              :label="$t('houseowner.address')"
              :rules="[$valid.isRequired, $valid.isNepaliAlphabet]"
              outlined
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <label for="">{{ $t("houseowner.tole") }} <span> *</span></label>
            <v-text-field
              v-model="houseowner.tole"
              :label="$t('houseowner.tole')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <label for="">{{ $t("houseowner.houseNo") }}</label>
            <v-text-field
              v-model="houseowner.house_no"
              :label="$t('houseowner.houseNo')"
              outlined
              filled
            ></v-text-field>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12" sm="6" md="6">
            <label for="">{{ $t("houseowner.photo") }}</label>
            <v-file-input v-model="houseowner.photo" label="Select File..." outlined></v-file-input>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <label for="">{{ $t("houseowner.name") }}</label>
            <v-text-field
              v-model="houseowner.name_np"
              :label="$t('houseowner.name')"
              class="custom-text-field"
              outlined
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <label for="">{{ $t("houseowner.citizenshipIssueDistrict") }}</label>
            <v-autocomplete
              v-model="houseowner.citizenship_district_id"
              :label="$t('houseowner.citizenshipIssueDistrict')"
              :items="districts"
              item-value="id"
              item-text="district_name"
              :key="id"
              clearable
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <label for="">{{ $t("houseowner.address") }} <span> *</span></label>
            <v-text-field
              v-model="houseowner.address"
              :label="$t('houseowner.address')"
              outlined
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <label for="">{{ $t("houseowner.citizenshipNo") }} <span> * </span></label>
            <v-text-field
              v-model="houseowner.citizenship_no"
              :label="$t('houseowner.citizenshipNo')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <label for="">{{ $t("houseowner.citizenshipIssueDate") }}</label>
            <nepali-date-picker
              v-model="houseowner.citizenship_issue_date_np"
              :label="$t('houseowner.citizenshipIssueDate')"
              hint="Required*"
              outlined
              filled
            ></nepali-date-picker>
          </v-col>
        </template>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import NepaliDatePicker from "@/custom-inputs/nepali-date-picker/NepaliDatePicker.vue";
import api from "@/api/api";
export default {
  components: {
    NepaliDatePicker,
  },
  data() {
    return {
      houseowner: {
        id: 0,
        houseowner_type: "p",
        reg_id: this.registrationId,
        // salutation: "श्री",
        citizenship_no: "",
        name: "",
        name_np: "",
        father_or_husband_name: "null",
        father_or_husband_name_np: "",
        grandfather_name: "",
        grandfather_name_np: "",
        citizenship_district_id: 100,
        phone_no: "",
        email: "",
        citizenship_issue_date: "2016-8-8",
        citizenship_issue_date_np: "",
        address: "",
        tole: "",
        ward_no: 0,
        house_no: "",
        photo: "",
      },
      houseowner_type_items: [
        { name: "व्यक्ति", id: "p" },
        { name: "संस्था", id: "c" },
      ],
      districts: [],
      wards: [],
    };
  },
  computed: {
    houseownerIsPerson() {
      return this.houseowner.houseowner_type == "p" ? true : false;
    },
  },
  created() {
    this.getDistricts();
    this.getWards();
  },
  methods: {
    async getDistricts() {
      try {
        let responseDistricts = await api.dropdowns.districts();
        this.districts = responseDistricts.data.data;
      } catch (error) {
        console.log(`district api error: ${error}`);
      }
    },
    async getWards() {
      try {
        let responseMun = await api.dropdowns.municipality();
        let noOfWards = parseInt(responseMun.data.data[0].no_of_wards);
        for (let i = 0; i < noOfWards; i++) {
          this.wards.push(i + 1);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
span {
  color: red;
}
</style>
