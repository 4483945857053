<style scoped>
.registrationtable td,
.registrationtable th {
  border: 1px solid #ddd;
  padding: 8px;
  width: 1000px;
}
div.options > label > input {
  visibility: hidden;
}

div.options > label {
  display: block;
  margin: 0 0 0 -10px;
  padding: 0 0 20px 0;
  height: 20px;
  width: 150px;
}

div.options > label > img {
  display: inline-block;
  padding: 0px;
  height: 30px;
  width: 30px;
  background: none;
}

div.options > label > input:checked + img {
  background: url(http://cdn1.iconfinder.com/data/icons/onebit/PNG/onebit_34.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30px 30px;
}
</style>
<template>
  <div>
    <v-card class="mb-12" color="grey lighten-1" height="auto">
      <v-app id="inspire">
        <v-container>
          <div>
            <v-btn class="primary ma-1" :to="{ name: 'application_tab', params: { id: this.$route.params.regId } }">Back</v-btn>
          </div>
          <h3>
            <center>
              Masonry Checklist
            </center>
          </h3>

          <h4>BUILDING CODE ATTRIBUES</h4>
          <v-form>
            <table class="registrationtable buildingcode sober" style="padding-bottom:10px">
              <thead>
                <tr class="registrationtable">
                  <th class="text-left registrationtable">S.N</th>
                  <th class="text-left registrationtable">Attributes</th>
                  <th class="text-left registrationtable">Specific Conditions</th>
                  <th class="text-left registrationtable">Check</th>
                  <th class="text-left registrationtable">Recommendation</th>
                </tr>
              </thead>
              <tbody>
                <tr class="registrationtable">
                  <td colspan="5" class="registrationtable">
                    <strong>CONFIGURATION RELATED CHECKLIST</strong>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">1</td>
                  <td class="registrationtable" rowspan="2">Wall thickness</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.overall_dim_ratio_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.wall_thickness_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.wall_thickness_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.wall_thickness_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">2</td>
                  <td class="registrationtable" rowspan="2">
                    Bands (Foundation, plinth, still, lintel, roof)s
                  </td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.overall_dim_ratio_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.bands_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.bands_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.bands_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">3</td>
                  <td class="registrationtable" rowspan="2">Soft Storey</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.soft_storey_compliance" />
                        <img />
                      </label>
                    </div>
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.soft_storey_compliance" class="ma-0 pa-0"></v-checkbox> -->
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.soft_storey_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.beam_column_joint_compliance" class="ma-0 pa-0"></v-checkbox>
                    -->
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.soft_storey_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">4</td>
                  <td class="registrationtable" rowspan="2">Overall Dimension Ratio</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.soft_storey_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.overall_dim_ratio_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.overall_dim_ratio_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.beam_column_joint_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.overall_dim_ratio_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">5</td>
                  <td class="registrationtable" rowspan="2">No. of storey</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.overall_dim_ratio_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.no_of_storey_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.no_of_storey_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.beam_column_joint_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.no_of_storey_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">6</td>
                  <td class="registrationtable" rowspan="2">Length of Wings</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.overall_dim_ratio_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.wings_length_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.wings_length_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.beam_column_joint_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.wings_length_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">7</td>
                  <td class="registrationtable" rowspan="2">Thickness to height ratio</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.thickness_ht_ratio_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.thickness_ht_ratio_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.beam_column_joint_compliance" class="ma-0 pa-0"></v-checkbox>
                    -->
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.thickness_ht_ratio_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">8</td>
                  <td class="registrationtable" rowspan="2">Setbacks</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.overall_dim_ratio_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.setbacks_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.setbacks_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.setbacks_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">9</td>
                  <td class="registrationtable" rowspan="2">Redundancy</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.overall_dim_ratio_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.redundancy_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.redundancy_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.redundancy_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">10</td>
                  <td class="registrationtable" rowspan="2">
                    Vertical Discontinuity of Major wall
                  </td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.overall_dim_ratio_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.vertical_discontinuity_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.vertical_discontinuity_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.vertical_discontinuity_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">11</td>
                  <td class="registrationtable" rowspan="2">Cantilever Projection</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.overall_dim_ratio_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.cantilever_projection_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.cantilever_projection_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.cantilever_projection_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">12</td>
                  <td class="registrationtable" rowspan="2">Torsion</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.torsion_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.torsion_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.torsion_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">13</td>
                  <td class="registrationtable" rowspan="2">Adjacent Buildings</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.adjacent_building_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.adjacent_building_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.adjacent_building_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">14</td>
                  <td class="registrationtable" rowspan="2">Openings Percentage</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.opening_percentage_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.opening_percentage_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.opening_percentage_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">15</td>
                  <td class="registrationtable" rowspan="2">Position of Opening</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.opening_position_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.opening_position_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.opening_position_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">16</td>
                  <td class="registrationtable" rowspan="2">Unsupported Wall</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.unsupported_wall_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.unsupported_wall_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.unsupported_wall_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td colspan="5" class="registrationtable">
                    <strong>STRENGTH RELATED CHECKLIST</strong>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">17</td>
                  <td class="registrationtable" rowspan="2">Foundation</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.foundation_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.foundation_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.foundation_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">17</td>
                  <td class="registrationtable" rowspan="2">Joint Mortar Ratio</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.joint_mortar_ratio_strength_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.joint_mortar_ratio_strength_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.joint_mortar_ratio_strength_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td colspan="5" class="registrationtable">
                    <strong>DUCTILITY RELATED CHECKLIST</strong>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">17</td>
                  <td class="registrationtable" rowspan="2">Vertical Bar</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.vertical_bar_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.vertical_bar_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.vertical_bar_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td colspan="5" class="registrationtable">
                    <strong>CONNECTION RELATED ATTRIBUTES</strong>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">17</td>
                  <td class="registrationtable" rowspan="2">Corner stitch</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.corner_stitch_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.corner_stitch_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.corner_stitch_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">17</td>
                  <td class="registrationtable" rowspan="2">Joint Mortar Ratio</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.joint_mortar_ratio_conn_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.joint_mortar_ratio_conn_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.joint_mortar_ratio_conn_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">17</td>
                  <td class="registrationtable" rowspan="2">Roof wall connection</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.roof_wall_conn_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.roof_wall_conn_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.roof_wall_conn_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">17</td>
                  <td class="registrationtable" rowspan="2">Through Stone</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.through_stone_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.through_stone_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.through_stone_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td colspan="5" class="registrationtable">
                    <strong>NON STRUCTURAL ATTRIBUTES</strong>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">17</td>
                  <td class="registrationtable" rowspan="2">Gable Wall</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.gable_wall_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.gable_wall_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.gable_wall_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td colspan="5" class="registrationtable">
                    <strong>E. BUILDING BYELAWS</strong>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">32</td>
                  <td class="registrationtable" rowspan="2">Front width</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.front_width_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.front_width_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.front_width_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">33</td>
                  <td class="registrationtable" rowspan="2">Floor Area Ratio(FAR)</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.floor_area_ratio_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.floor_area_ratio_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.floor_area_ratio_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">34</td>
                  <td class="registrationtable" rowspan="2">Front Setback</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.front_setback_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.front_setback_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.front_setback_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">35</td>
                  <td class="registrationtable" rowspan="2">Ground Coverage Ratio(GCR)</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.ground_coverage_ratio_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.ground_coverage_ratio_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.ground_coverage_ratio_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="sober">
              <thead>
                <tr>
                  <th class="text-left ma-1"><strong>Observations</strong></th>
                  <th class="ma-1"><strong>Recommendation</strong></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong
                      ><v-text-field
                        v-model="masonry.observation"
                        solo
                        class="ma-1 pa-0"
                      ></v-text-field
                    ></strong>
                  </td>
                  <td>
                    <strong
                      ><v-text-field
                        v-model="masonry.recommendation"
                        solo
                        class="ma-1 pa-0"
                      ></v-text-field
                    ></strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <span v-if="successfulMessage">
              <v-alert dense outlined type="alert">
                {{ successfulMessage }}
              </v-alert>
            </span>
            <!-- <v-btn v-if="userRoleId == 4" primary v-on:click="save" class="primary">Save</v-btn> -->
            <v-btn v-if="userRoleId == 4 || userRoleId == 8" primary v-on:click="save" class="primary ma-3">Submit</v-btn>
                <v-btn
                  class="primary ma-1"
                  :to="{
                    name: 'application_tab',
                    params: { id: this.$route.params.regId },
                  }"
                  >Back</v-btn
                >
          </v-form>
        </v-container>
      </v-app>
    </v-card>
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "masonry",
  props: {
    nextStep: {
      type: Function
    }
  },
  data() {
    return {
      userRoleId: localStorage.getItem("auth_user_role_id"),
      designer: "",
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success"
      },
      application: "",
      registration: "",
      houseownerDetail: "",
      landinfoarea: "",
      masonry: {
        id: 0,
        regId: this.$route.params.regId,
        wall_thickness_compliance: "",
        wall_thickness_remarks: "",
        bands_compliance: "",
        bands_remarks: "",
        soft_storey_compliance: "",
        soft_storey_remarks: "",
        overall_dim_ratio_compliance: "",
        overall_dim_ratio_remarks: "",
        no_of_storey_compliance: "",
        no_of_storey_remarks: "",
        wings_length_compliance: "",
        wings_length_remarks: "",
        thickness_ht_ratio_compliance: "",
        thickness_ht_ratio_remarks: "",
        setbacks_compliance: "",
        setbacks_remarks: "",
        redundancy_compliance: "",
        redundancy_remarks: "",
        vertical_discontinuity_compliance: "",
        vertical_discontinuity_remarks: "",
        cantilever_projection_compliance: "",
        cantilever_projection_remarks: "",
        opening_percentage_compliance: "",
        opening_percentage_remarks: "",
        opening_position_compliance: "",
        opening_position_remarks: "",
        unsupported_wall_compliance: "",
        unsupported_wall_remarks: "",
        foundation_compliance: "",
        foundation_remarks: "",
        joint_mortar_ratio_strength_compliance: "",
        joint_mortar_ratio_strength_remarks: "",
        vertical_bar_compliance: "",
        vertical_bar_remarks: "",
        corner_stitch_compliance: "",
        corner_stitch_remarks: "",
        joint_mortar_ratio_conn_compliance: "",
        joint_mortar_ratio_conn_remarks: "",
        roof_wall_conn_compliance: "",
        roof_wall_conn_remarks: "",
        through_stone_compliance: "",
        through_stone_remarks: "",
        gable_wall_compliance: "",
        gable_wall_remarks: "",
        front_width_compliance: "",
        front_width_remarks: "",
        floor_area_ratio_compliance: "",
        floor_area_ratio_remarks: "",
        front_setback_compliance: "",
        front_setback_remarks: "",
        ground_coverage_ratio_compliance: "",
        ground_coverage_ratio_remarks: "",
        observation: "",
        recommendation: ""
      }
    };
  },
  mounted() {
    const url = `api/registration/${this.registrationId}/designer`;
    VueAxios.get(url)
      .then(response => {
        // debugger;
        this.designer = response.data.data;
        // this.newDesigner.clear();
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
    const applicationUrl = `api/registration/${this.$route.params.regId}/application`;
    VueAxios.get(
      applicationUrl,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      },
      {}
    )
      .then(response => {
        // debugger;
        this.application = response.data.data;
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
    const urlMasonry = `api/registration/${this.$route.params.regId}/masonry`;
    VueAxios.get(
      urlMasonry,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      },
      {}
    )
      .then(response => {
        // debugger;
        this.masonry = response.data.data;
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
    const urlRegistration = "api/registration";
    VueAxios.get(
      urlRegistration,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      },
      {}
    )
      .then(response => {
        // debugger;
        this.registration = response.data;
        console.log(this.registration);
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
    const urlHouseowner = `api/registration/${this.$route.params.regId}/houseowner-detail`;
    VueAxios.get(
      urlHouseowner,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      },
      {}
    )
      .then(response => {
        // debugger;
        this.houseownerDetail = response.data.data;
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
    const landinfoareaUrl = `api/registration/${this.registrationId}/land-info-area`;
    VueAxios.get(landinfoareaUrl)
      .then(response => {
        // debugger;
        this.landinfoarea = response.data.data;
        // this.newLandinfoarea.clear();
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
  },
  methods: {
    save() {
      // #TODO calculate total amount automatically
      // console.log(this.$route.params.regId);
      //   debugger;
      if (this.masonry.id === null || this.masonry.id === 0 || this.masonry.id === undefined) {
        // debugger;
        const url = `/api/registration/${this.$route.params.regId}/masonry`;
        VueAxios.post(
          url,
          this.masonry,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`
            }
          },
          {
            timeout: 10000
          }
        )
          .then(response => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.masonry = response.data.data;
              this.snackbar.show = true;
              this.successfulMessage = "Successful.";
            }
          })
          .catch(error => {
            // alert(2);
            console.log(`api error:${error}`);
          });
      } else {
        const eurl = `/api/registration/${this.$route.params.regId}/masonry/${this.masonry.id}`;
        VueAxios.put(
          eurl,
          this.masonry,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`
            },
            _method: "patch"
          },
          {}
        )
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.successfulMessage = "Data Updated successfully";
            }
            // else {
            //     this.$router.push({
            //         name: 'tabbed',
            //     });
            // }
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    edit(id) {
      //   debugger;
      //    const url = `api/registration/${this.$route.params.regId}/masonry/${this.$route.params.id}`;
      const url = `api/registration/${this.$route.params.regId}/masonry/${id}`;
      VueAxios.get(
        url,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        },
        {
          timeout: 100000
        }
      ).then(response => {
        // debugger;
        this.masonry = response.data.data;
        console.log(response.data.data);
      });
    },
    deleterecord(id) {
      const deleteurl = `api/registration/${this.$route.params.regId}/masonry/${id}`;
      VueAxios.delete(
        deleteurl,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        },
        {
          timeout: 100000
        }
      )
        .then(response => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
          }
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}
.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}
.row,
.col {
  height: 58px;
}
.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}
</style>
