<style scoped>
.title {
  color: #000752;
}

.mt-n3 {
  margin-top: -3rem;
  /* margin-left: -0.5rem; */
  width: 6rem;
}

.container {
  display: block;
}
</style>

<template>
  <v-container>
    <v-layout row>
      <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></loading>
      <v-flex style="text-align: center">
        <!-- <v-img src="http://cdn.wallpapersafari.com/7/86/gqiGH7.jpg" height="50px" width="50px"> -->

        <v-container class="sober fill-height" fluid>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
              <!-- <img :src="require('@/assets/images/logo-dark.png')" width="140px" /> -->
              <img :src="require('@/assets/images/logo-light-2.png')" width="140px" class="nav-top-logo" />
              <!-- <h4 class="title">GharNaksha Software</h4> -->
              <!-- <p class="title mt-n3"> Check your file entry status</p> -->
              <h4 class="title">Check Your File Entry Status</h4>
            </v-col>
          </v-row>

          <v-col cols="6 m-auto">
            <v-card class="elevation-3">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>
                  कृपया फाइल कोड इन्ट्री गर्नुहोस्
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field v-model="file_code" name="file_code" label="फाइल कोड" outlined>
                  </v-text-field>

                </v-form>
                <v-snackbar v-model="snackbar.show" :bottom="y === 'bottom'" :color="snackbar.color" :left="x === 'left'" :multi-line="mode === 'multi-line'" :right="x === 'right'"
                  :timeout="3000" :top="y === 'top'" :vertical="mode === 'vertical'">
                  {{ message }}
                </v-snackbar>
                <v-card-actions>
                  <!-- <v-spacer></v-spacer> -->
                  <v-btn color="primary" v-on:click="register" class="mt-n3">Submit</v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-col>


          <!-- <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field label="कृपया फाइल कोड इन्ट्री गर्नुहोस्" outlined>

                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container> -->

          <v-card v-if="success">
            <center>
              <h3>Application Status ( {{ houseowner }})</h3>
            </center>
            <br />
            <v-simple-table class="sober">
              <template v-slot:default>
                <thead>
                  <tr class="registrationtable">
                    <!-- <th class="text-left registrationtable">Level</th> -->
                    <th class="text-left registrationtable" v-for="p in applicationHeader" :key="p">
                      {{ p }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="registrationtable" v-for="applicationStatusList in applicationStatus" :key="applicationStatusList.id">
                    <td class="registrationtable" v-for="key in applicationHeader" :key="key">
                      <span v-html="applicationStatusList[key]">{{ applicationStatusList[key] }}</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "HouseOwnerStatus",
  components: {},
  data() {
    return {
      success: false,
      file_code: "",
      applicationStatus: [],
      applicationHeader: [],
      houseowner: "",
      snackbar: {
        show: false,
        message: this.message,
        color: "success"
      },
    };
  },

  created() {
  },
  methods: {
    register() {
      this.isLoading = true;
      const url = "api/houseowner-status";
      VueAxios.post(url,
        {
          file_code: this.file_code,
        })
        .then(response => {
          this.isLoading = false;
          if (response.status === 200) {
            this.snackbar.show = true;
            this.success = true;
            this.houseowner = response.data.houseowner;
            this.applicationStatus = response.data.data;
            this.applicationHeader = Object.keys(this.applicationStatus[0]);
            this.message = "Information found!";
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.snackbar.show = true;
          this.success = false;
          this.snackbar.color = "error";
          this.message = "File with the provided file code not found!!";
        });
      // .catch(error => this.error = error.response.data.errors);
    },
  }
};
</script>
