<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <v-container class="border">
      <v-form class="mt-8 pl-2">
        <v-row>
          <v-col>
            <span class="text-h5 text-decoration-underline">थप विवरण</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4"></v-col>
          <v-col cols="4"><span class="font-weight-bold">स्वीकृत अनसार</span></v-col>
        </v-row>
        <v-row v-for="(item, index) in registrationKeyValue" :key="index">
          <v-col cols="4" class="m-auto">{{ item.title }}</v-col>
          <v-col cols="4"><v-text-field v-model="item.value" type="text" solo hide-details></v-text-field></v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-btn v-if="isAddEditAuthorized" v-on:click="saveRegistrationKeyValuePair" color="primary" class="ma-1 px-6">Save</v-btn>
          <v-btn
            class="ma-1"
            color="primary"
            :to="{
              name: 'application_tab',
              params: { id: this.$route.params.regId },
            }"
            >Back to Main</v-btn
          >
        </v-row>
        <v-snackbar v-model="snackbar.show" :color="snackbar.color">
          {{ successfulMessage }}
        </v-snackbar>
      </v-form>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ByelawsKeyValueForm",
  props: {
    isAddEditAuthorized: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rccRegKey: [
        {
          key: "road_width",
          title: "सडकको चौडाइ (मिटर)",
          value: "",
        },
        {
          key: "road_to_plinth_height",
          title: "सडकको सतहबाट प्लिन्थ लेभल सम्मको उचाइ (फिट)",
          value: "",
        },
        {
          key: "rcc_foundation_depth",
          title: "जगको गहिराइ",
          value: "",
        },
        {
          key: "rcc_foundation_breadth",
          title: "जगको चौडाइ",
          value: "",
        },
        {
          key: "rcc_pillar_width",
          title: "पिलरको चौडाइ (इन्च)",
          value: "",
        },
        {
          key: "rcc_pillar_thickness",
          title: "पिलरको मोटाइ (इन्च)",
          value: "",
        },
        {
          key: "rcc_pillar_rod_count",
          title: "पिलरमा प्रयोग गर्ने डण्डीको संख्या",
          value: "",
        },
        {
          key: "rcc_pillar_rod_thickness",
          title: "पिलरमा प्रयोग गर्ने डण्डीको मोटाइ",
          value: "",
        },
        {
          key: "rcc_pillar_tip_thickness",
          title: "पिलरको चुरीको मोटाइ ( एमएम)",
          value: "",
        },
        {
          key: "rcc_pillar_tip_spacing",
          title: "पिलरको चुरीको दूरी(इन्च)",
          value: "",
        },
        {
          key: "rcc_slab_cast_cement_composition",
          title: "ढलान मसलाको मिश्रणको विवरण",
          value: "",
        },
        {
          key: "rcc_plinth_beam_description",
          title: "प्लिन्थ बीमको विवरण",
          value: "",
        },
      ],
      masRegKey: [
        {
          key: "mas_foundation_depth",
          title: "जगको गहिराइ",
          value: "",
        },
        {
          key: "mas_foundation_breadth",
          title: "जगको चौडाइ",
          value: "",
        },
        {
          key: "mas_wall_thickness_inch",
          title: "गारोको मोटाइ (इन्च)",
          value: "",
        },
        {
          key: "mas_wall_composition",
          title: "गारो जोडाइको मसला र मिश्रणको विवरण",
          value: "",
        },
        {
          key: "mas_corner_post_description",
          title: "कुनाको पोष्टको विवरण",
          value: "",
        },
        {
          key: "mas_foundation_description",
          title: "जग बन्धनको विवरण",
          value: "",
        },
        {
          key: "mas_plinth_bond_description",
          title: "प्लिन्थ लेभल बन्धनको विवरण",
          value: "",
        },
      ],

      isRccValid: 1,
      registrationKeyValue: [],
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      isLoading: false,
    };
  },
  created() {
    this.getChecklistType();
  },
  mounted() {
    this.getRegistrationKeyValuePairs();
  },
  methods: {
    saveRegistrationKeyValuePair() {
      this.isLoading = true;
      const storeUrl = `/api/registration/${this.$route.params.regId}/key-value-pairs/list`;
      this.$axios
        .post(storeUrl, { key_value_pairs: this.registrationKeyValue })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.isFormSubmitting = true;
            this.isLoading = false;
            this.snackbar.show = true;
            this.successfulMessage = "Data Saved Successfully";
            this.registrationKeyValue = response.data.key_value_pairs;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    getRegistrationKeyValuePairs() {
      const keyValueUrl = `/api/registration/${this.$route.params.regId}/key-value-pairs`;
      this.$axios
        .get(keyValueUrl)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.isFormSubmitting = true;
            this.registrationKeyValue = response.data.data;
            if (!response.data.data.length) {
              if (this.isRccValid) {
                this.registrationKeyValue = this.rccRegKey;
              } else {
                this.registrationKeyValue = this.masRegKey;
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // This below code is redundant, please fix later, Fixed for now, in store later
    getChecklistType() {
      const url = `api/registration/${this.$route.params.regId}/application`;

      this.$axios.get(url).then((response) => {
        const applicationData = response.data.data;
        const building_structure_id = applicationData.building_structure_id;
        const building_category_id = applicationData.building_category_id;
        if (building_structure_id == 1) {
          this.isRccValid = 1;
        } else if (building_structure_id != 1) {
          this.isRccValid = 2;
        } else {
          this.isRccValid = 0;
        }
      });
    },
  },
};
</script>

<style>
</style>