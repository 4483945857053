import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../modules/home/Home.vue";
import Letter from "../modules/home/Letter.vue";
import ConsultantFiles from "../modules/home/consultantFiles.vue";
import Login from "../modules/login/Login.vue";
import HouseOwnerStatus from "../modules/login/HouseOwnerStatus.vue";
import Register from "../modules/login/Register.vue";
import ResetPassword from "../modules/login/ResetPassword.vue";
import Index from "../modules/Index.vue";
import Test from "../modules/test.vue";
import JicaOyo from "../modules/home/JicaOyo.vue";
import NewRegistration from "../modules/home/NewRegistration.vue";
import OldRegistration from "../modules/oldregistration/OldRegistration.vue";
import RegistrationInfo from "../modules/home/RegistrationInfo.vue";
import Yellowslip from "../modules/home/Yellowslip.vue";
import Rcc from "../modules/home/Rcc.vue";
import AminComment from "../modules/tabbedElements/components/AminComment.vue";
import Byelaws from "../modules/tabbedElements/components/Byelaws.vue";
import RevenueInfo from "../modules/home/RevenueInfo.vue";
import Masonry from "../modules/home/Masonry.vue";
import ApplicationstatusPage from "../modules/home/ApplicationstatusPage.vue";
import ApplicationTab from "../modules/applicationlevel/ApplicationTab.vue";
import OldRegApplicationTab from "../modules/oldregistration/OldRegApplicationTab.vue";
import ResultAnalysis from "../modules/home/ResultAnalysis.vue";
import Report from "../modules/home/Report.vue";
import Map from "../modules/home/Map.vue";
import Mason from "../modules/home/Mason.vue";
import Consultant from "../modules/home/Consultant.vue";
import Technician from "../modules/home/Technician.vue";
import ReferenceFiles from "../modules/home/ReferenceFiles.vue";
import MunicipalityDetail from "../modules/home/MunicipalityDetail.vue";
import AreaFiftyOne from "../modules/test-files/AreaFiftyOne.vue";
import OldRegistrationDashboard from "../modules/oldregistration/OldRegistrationDashboard.vue";
import ForgotPassword from "../modules/login/ForgotPassword.vue";
import RccChecklist from "../modules/home/RccChecklist";

// import AdminChecklist from "../modules/admin/AdminChecklist.vue"
// import AdminHome from "../modules/admin/AdminHome.vue"
import ConsultancyProfile from "../modules/consultancy/ConsultancyProfile.vue";

import RevenueList from "../modules/applicationlevel/Revenue/RevenueList.vue";

// Import from adminrouter.js
// import adminrouter from "./adminrouter.js"

// Vue.use(VueRouter);
import store from "@/store"; // Using @ points to the root of the src directory

const routes = [
    {
        path: "/login",
        name: "login",
        component: Login,
        beforeEnter: (to, from, next) => {
            const token = window.localStorage.getItem("token");

            // forward to home if token is present i.e user is already logged in
            if (token) {
                next({
                    name: "home"
                });
            } else {
                next();
            }
        }
    },
    {
        path: "/consultancyprofile",
        name: "consultancy_profile",
        component: ConsultancyProfile
    },
    {
        path: "/forgotpassword",
        name: "forgot_password",
        component: ForgotPassword,
    },
    {
        path: "/houseownerstatus",
        name: "houseownerstatus",
        component: HouseOwnerStatus
    },
    {
        path: "/register",
        name: "register",
        component: Register,
        beforeEnter: (to, from, next) => {
            const roleId = window.localStorage.getItem("auth_user_role_id");

            // forward to home if the user is not admin
            if (roleId != 1) {
                next({
                    name: "home"
                });
            } else {
                next();
            }
        }
    },
    {
        path: "/rcc/:regId",
        name: "rcc",
        component: Rcc,
        props: true,
        beforeEnter: (to, from, next) => {
            // console.log("beforeEnter");
            // console.log(window.localStorage.getItem("user"));
            const token = window.localStorage.getItem("token");

            if (token) {
                next();
            } else {
                next({
                    name: "login"
                });
            }
        }
    },
    {
        path: "/checklist/:regId",
        name: "checklist",
        component: RccChecklist,
        props: true,
        beforeEnter: (to, from, next) => {
            // console.log("beforeEnter");
            // console.log(window.localStorage.getItem("user"));
            const token = window.localStorage.getItem("token");

            if (token) {
                next();
            } else {
                next({
                    name: "login"
                });
            }
        }
    },

    {
        path: "/masonry/:regId",
        name: "masonry",
        component: Masonry,
        props: true,
        beforeEnter: (to, from, next) => {
            const token = window.localStorage.getItem("token");

            if (token) {
                next();
            } else {
                next({
                    name: "login"
                });
            }
        }
    },
    {
        path: "/yellowslip/:regId",
        name: "yellowslip",
        component: Yellowslip,
        props: true,
        beforeEnter: (to, from, next) => {
            const token = window.localStorage.getItem("token");

            if (token) {
                next();
            } else {
                next({
                    name: "login"
                });
            }
        }
    },
    {
        path: "/jicaoyo",
        name: "jicaoyo",
        component: JicaOyo
    },
    {
        path: "/",
        // name: "index",
        component: Index,
        beforeEnter: (to, from, next) => {
            const token = window.localStorage.getItem("token");

            if (token) {
                next();
            } else {
                next({
                    name: "login"
                });
            }
        },
        children: [
            {
                path: "/resetPassword",
                name: "resetpassword",
                component: ResetPassword
            },
            {
                path: "/home",
                name: "home",
                component: Home
            },
            {
                path: "/oldregistrationdashboard",
                name: "old_registration_dashboard",
                component: OldRegistrationDashboard
            },
            {
                path: "/revenue/:regId",
                name: "revenue",
                component: RevenueList,
                props: true,
                beforeEnter: (to, from, next) => {
                    // console.log("beforeEnter");
                    // console.log(window.localStorage.getItem("user"));
                    const token = window.localStorage.getItem("token");

                    if (token) {
                        next();
                    } else {
                        next({
                            name: "login"
                        });
                    }
                }
            },
            {
                path: "/revenueinfo/:regId",
                name: "revenueinfo",
                component: RevenueList,
                props: true,
                beforeEnter: (to, from, next) => {
                    // console.log("beforeEnter");
                    // console.log(window.localStorage.getItem("user"));
                    const token = window.localStorage.getItem("token");

                    if (token) {
                        next();
                    } else {
                        next({
                            name: "login"
                        });
                    }
                }
            },
            {
                path: "/consultantfiles",
                name: "consultantfiles",
                component: ConsultantFiles,
                beforeEnter: (to, from, next) => {
                    const user_role_id = window.localStorage.getItem("auth_user_role_id");
                    console.log("before check: " + user_role_id);
                    if (user_role_id == 8) {
                        console.log("after check: " + user_role_id);
                        next({
                            name: "home"
                        });
                    } else {
                        next();
                    }
                }
            },
            {
                path: "/aminComment/:regId",
                name: "aminComment",
                component: AminComment,
                props: true,
                beforeEnter: (to, from, next) => {
                    // console.log("beforeEnter");
                    // console.log(window.localStorage.getItem("user"));
                    const token = window.localStorage.getItem("token");

                    if (token) {
                        next();
                    } else {
                        next({
                            name: "login"
                        });
                    }
                }
            },
            {
                path: "/byelaws/:regId",
                name: "byelaws",
                component: Byelaws,
                props: true,
                beforeEnter: (to, from, next) => {
                    // console.log("beforeEnter");
                    // console.log(window.localStorage.getItem("user"));
                    const token = window.localStorage.getItem("token");

                    if (token) {
                        next();
                    } else {
                        next({
                            name: "login"
                        });
                    }
                }
            },
            {
                path: "newregistration",
                name: "new_registration",
                component: NewRegistration
            },
            {
                path: "testchat",
                name: "test",
                component: Test
            },
            {
                path: "report",
                name: "report",
                component: Report
            },
            {
                path: "resultanalysis",
                name: "resultanalysis",
                component: ResultAnalysis
            },
            {
                path: "applicationstatuspage",
                name: "applicationstatuspage",
                component: ApplicationstatusPage
            },
            {
                path: "map",
                name: "map",
                component: Map
            },
            {
                path: "mason",
                name: "mason",
                component: Mason
            },
            {
                path: "consultancy",
                name: "consultancy",
                component: Consultant
            },

            {
                path: "technician",
                name: "technician",
                component: Technician
            },
            {
                path: "downloads",
                name: "referencefiles",
                component: ReferenceFiles
            },
            {
                path: "/municipalitydetail",
                name: "municipalitydetail",
                component: MunicipalityDetail
            },
            {
                path: "/letters",
                name: "letters",
                component: Letter
            },
            {
                path: "applicationtab/:id",
                name: "application_tab",
                component: ApplicationTab,
                beforeEnter: (to, from, next) => {
                    // store variable is imported at the top haii sarkar !! 
                    // to.params.id to use inside the below function, ( to.params.id === :id )
                    store.dispatch("getRegistration", parseInt(to.params.id)).then(() => {
                        // console.log('get registration dispatch called');
                        next();
                    });
                }
            },
            {
                path: "oldregistrationapplicationtab/:id",
                name: "old_registration_application_tab",
                component: OldRegApplicationTab
            },
            {
                path: "registrationinfo",
                name: "registrationinfo",
                component: RegistrationInfo
            },
            // {
            //   path: 'yellowslip',
            //   name: 'yellowslip',
            //   component: Yellowslip,
            // },
            {
                path: "tabbed/:id/:regId/:sn",
                name: "tabbed",
                component: () =>
                    import(/* webpackChunkName: "multiple" */ "../modules/tabbedElements/Index.vue"),
                props: true
                // props: { regConstructionId: 8 }
            },
            {
                path: "fifteentabbed/:id/:regId/:sn",
                name: "fifteentabbed",
                component: () =>
                    import(
            /* webpackChunkName: "multiple" */ "../modules/tabbedElements/FifteenDaysBibaranTabbed.vue"
                    ),
                props: true
            },
            {
                path: "documentupload/:phaseId-:id-:regId-:sn",
                name: "documentupload",
                component: () =>
                    import(
            /* webpackChunkName: "multiple" */ "../modules/applicationlevel/subComponents/DocumentUploadTab.vue"
                    ),
                props: true
            },
            {
                path: "areafiftyone",
                name: "area_fifty_one",
                component: AreaFiftyOne
            },
            // {
            //     path: "areatest",
            //     name: "area_test",
            //     component: AreaTest
            // },
            {
                path: "oldregistration",
                name: "old_registration",
                component: OldRegistration
            },
            {
                path: "/",
                redirect: { name: "home" }
            },
            {
                path: "*",
                redirect: { name: "home" }
            },
            // {
            //     path: "/admin/checklist",
            //     name: "admin_checklist",
            //     component: AdminChecklist
            // },
            // {
            //     path: "admin/frmatrix",
            //     name: "admin_frmatrix",
            //     component: AdminFRMatrix
            // },
        ]
    }
    // {
    //   path: '/home/:id',
    //   name: 'home',
    //   component: Home,
    // },
];
// routes = routes.concat(adminrouter);
// const router = new VueRouter({
//   routes
// });

export default routes;
