<style scoped>
.map {
  height: 400px;
}
</style>
<template>
  <v-card class="mb-12">
    <v-app id="inspire">
      <div class="row">
        <div class="col-md-9">
          <l-map :zoom="zoom" :center="center" @click="add" style="height:250px; width:300px">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-marker :lat-lng="marker" :icon="defaultIcon"></l-marker>
            <l-geo-json :geojson="geojson" :options="options"></l-geo-json>
          </l-map>
        </div>
      </div>
    </v-app>
  </v-card>
</template>
<script>
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';
import L from "leaflet";
import "../../../node_modules/leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LGeoJson } from "vue2-leaflet";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LGeoJson
  },
  data() {
    const myMarkerIcon = L.icon({
      iconUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png",
      shadowUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-shadow.png",
      iconSize: [25, 41], // size of the icon
      shadowSize: [25, 41], // size of the shadow
      iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
      shadowAnchor: [9, 41], // the same for the shadow
      popupAnchor: [-3, -76] // point from which the po
    });
    return {
      zoom: 11,
      center: L.latLng(27.692149607288425, 86.02294921875001),
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      defaultIcon: myMarkerIcon,
      marker: L.latLng(27.692149607288425, 86.02294921875001),

      geojson: {
        // ward1
        features: [
          {
            type: "Feature",
            properties: { party: "Dolakha-ward" },
            geometry: {
              type: "LineString",
              coordinates: [
                [86.069244622, 27.6961038040001],
                [86.068197072, 27.6961035360001],
                [86.0678407460001, 27.6960425350001],
                [86.067082725, 27.695843353],
                [86.066330957, 27.6954299400001],
                [86.065928199, 27.695397873],
                [86.0657335370001, 27.6953807460001],
                [86.0654161420001, 27.6953673670001],
                [86.0640268270001, 27.6952609160001],
                [86.0635295770001, 27.695388458],
                [86.063018764, 27.695624116],
                [86.0627596730001, 27.6956426730001],
                [86.062369915, 27.6954753680001],
                [86.06231561, 27.695452432],
                [86.061906084, 27.693362737],
                [86.0612555270001, 27.6912624140001],
                [86.061067559, 27.690940814],
                [86.0609472020001, 27.690822698],
                [86.0607146710001, 27.69078726],
                [86.0603218430001, 27.6910079210001],
                [86.0591256800001, 27.6913630560001],
                [86.0586414270001, 27.6915650920001],
                [86.0585566890001, 27.6915419050001],
                [86.0584168980001, 27.691371793],
                [86.0582578130001, 27.690488557],
                [86.0581746410001, 27.6902850110001],
                [86.057559741, 27.689396393],
                [86.0570813960001, 27.6879943230001],
                [86.0567219000001, 27.687132331],
                [86.0559225260001, 27.686147718],
                [86.0554825980001, 27.685472396],
                [86.055164721, 27.684601656],
                [86.055340335, 27.6840297100001],
                [86.055320785, 27.6835875620001],
                [86.0551793110001, 27.6832413400001],
                [86.0550713280001, 27.6830916640001],
                [86.0549572840001, 27.6829329890001],
                [86.0547388130001, 27.6829247360001],
                [86.0542126890001, 27.6831805790001],
                [86.0540088960001, 27.6835582300001],
                [86.05370012, 27.683732084],
                [86.0534134690001, 27.6837978230001],
                [86.0529248450001, 27.6838147510001],
                [86.0521876330001, 27.6836653140001],
                [86.0518224290001, 27.6837733610001],
                [86.050841861, 27.684222429],
                [86.0505488360001, 27.6842564840001],
                [86.0501470770001, 27.684228917],
                [86.0499868510001, 27.6841127370001],
                [86.0497103320001, 27.6837747000001],
                [86.0495987850001, 27.683704017],
                [86.0494487430001, 27.6836962030001],
                [86.0485761020001, 27.6839699760001],
                [86.0477720210001, 27.683939652],
                [86.0469613170001, 27.683832523],
                [86.04576166, 27.683937056],
                [86.0447470490001, 27.6837089970001],
                [86.0435175160001, 27.684043446],
                [86.0430425160001, 27.684026627],
                [86.042233814, 27.6838743770001],
                [86.0418085620001, 27.683630017],
                [86.0417266370001, 27.6835460230001],
                [86.041767758, 27.683474092],
                [86.041875684, 27.6833823530001],
                [86.0417895720001, 27.6832982340001],
                [86.0417159570001, 27.6832751090001],
                [86.04127458, 27.683141301],
                [86.040967062, 27.6829879970001],
                [86.0406592320001, 27.6827625120001],
                [86.040446203, 27.6824857830001],
                [86.04015287, 27.682341604],
                [86.039598691, 27.682423525],
                [86.0392249870001, 27.682596878],
                [86.0386879860001, 27.6830353920001],
                [86.0382352280001, 27.6832601140001],
                [86.037661105, 27.6837749960001],
                [86.037200344, 27.684189201],
                [86.0361853350001, 27.68501911],
                [86.036018411, 27.6854105100001],
                [86.035733695, 27.6855844890001],
                [86.035682577, 27.6855931750001],
                [86.035367303, 27.685703722],
                [86.033385556, 27.686089279],
                [86.032705207, 27.68614545],
                [86.0313995700001, 27.685942321],
                [86.02904913, 27.6859552180001],
                [86.028167501, 27.6857031010001],
                [86.02800546, 27.6856839120001],
                [86.0275447100001, 27.6856287840001],
                [86.0270102790001, 27.6856656470001],
                [86.0265070930001, 27.6857794410001],
                [86.0260938930001, 27.6859750410001],
                [86.025467221, 27.686191761],
                [86.0247355660001, 27.6862452440001],
                [86.0244437270001, 27.6863605410001],
                [86.0236913790001, 27.686819987],
                [86.0231804420001, 27.6870238980001],
                [86.0223556010001, 27.687577585],
                [86.021115195, 27.687916283],
                [86.020405473, 27.688028574],
                [86.0181388930001, 27.6881862710001],
                [86.0174870390001, 27.6883192490001],
                [86.0171894490001, 27.6884705420001],
                [86.0153954230001, 27.689064709],
                [86.015166327, 27.689139761],
                [86.0145502170001, 27.6892753010001],
                [86.013940479, 27.689577764],
                [86.0138206760001, 27.6898296140001],
                [86.0138347970001, 27.6899537910001],
                [86.0140965670001, 27.6903256370001],
                [86.014153929, 27.6905764900001],
                [86.0140896780001, 27.6910271990001],
                [86.013860385, 27.6915084020001],
                [86.0138949940001, 27.692065853],
                [86.014178567, 27.692769545],
                [86.0145681970001, 27.693056588],
                [86.015485819, 27.6933970110001],
                [86.0158769500001, 27.6935915620001],
                [86.0161790290001, 27.6939276000001],
                [86.016205578, 27.6943564370001],
                [86.016116014, 27.6950145640001],
                [86.0158279780001, 27.6955494490001],
                [86.015759544, 27.695797176],
                [86.0157355370001, 27.6962865690001],
                [86.0161809300001, 27.698552561],
                [86.016142845, 27.699836008],
                [86.0157533770001, 27.70072436],
                [86.015728182, 27.701240813],
                [86.0159162, 27.702142361],
                [86.0157410690001, 27.7035105480001],
                [86.015753434, 27.7039032000001],
                [86.0162079740001, 27.7050772910001],
                [86.0161201600001, 27.7056857970001],
                [86.0158849800001, 27.706785132],
                [86.0158647870001, 27.7071979080001],
                [86.0158636610001, 27.7072181560001],
                [86.0159970650001, 27.7079612790001],
                [86.015983061, 27.708247753],
                [86.0156960800001, 27.709154917],
                [86.015309706, 27.711462392],
                [86.0154029730001, 27.713003943],
                [86.015235977, 27.7138533020001],
                [86.014916875, 27.714762465],
                [86.0148989940001, 27.7151345560001],
                [86.0149928490001, 27.715484901],
                [86.015408161, 27.7159165570001],
                [86.015960508, 27.716728119],
                [86.0162325860001, 27.7172715130001],
                [86.017178868, 27.718679278],
                [86.0173802680001, 27.719228919],
                [86.0177817050001, 27.7196920100001],
                [86.0180239750001, 27.7201742830001],
                [86.0184283520001, 27.7205245710001],
                [86.0187255600001, 27.720637191],
                [86.0194910820001, 27.7207531940001],
                [86.0196645570001, 27.720878498],
                [86.019923389, 27.721275217],
                [86.020284587, 27.721496578],
                [86.0207165890001, 27.72166657],
                [86.0211766450001, 27.7220669170001],
                [86.0215903270001, 27.722656371],
                [86.0218707050001, 27.7237345300001],
                [86.023110687, 27.725706062],
                [86.0232050380001, 27.726241391],
                [86.0233393890001, 27.726562739],
                [86.0234861120001, 27.7269157100001],
                [86.024122316, 27.728041433],
                [86.024696163, 27.7287652520001],
                [86.0248397, 27.7290527920001],
                [86.024983235, 27.7294440090001],
                [86.024853487, 27.730171817],
                [86.0249313350001, 27.7309439360001],
                [86.025113424, 27.731522763],
                [86.0255172920001, 27.732294951],
                [86.025418362, 27.7325491760001],
                [86.0252835410001, 27.7337687520001],
                [86.0251925410001, 27.7343140770001],
                [86.0250349870001, 27.734888273],
                [86.025006293, 27.735368604],
                [86.0254270400001, 27.735845381],
                [86.025711937, 27.736000747],
                [86.0268264050001, 27.736448539],
                [86.0270082530001, 27.7366303380001],
                [86.0269971850001, 27.736948372],
                [86.027043355, 27.7374608270001],
                [86.027210573, 27.737913226],
                [86.0273641050001, 27.7383925610001],
                [86.0277567910001, 27.738999951],
                [86.0277847180001, 27.739318235],
                [86.0277420950001, 27.739473596],
                [86.027905262, 27.7394116040001],
                [86.0280428680001, 27.739477976],
                [86.0281326080001, 27.7393635500001],
                [86.0286552960001, 27.739044587],
                [86.029076305, 27.738997849],
                [86.0299972550001, 27.7385779640001],
                [86.0305403790001, 27.738196008],
                [86.0313446580001, 27.7377504360001],
                [86.0319055350001, 27.7370820680001],
                [86.0327203140001, 27.7365914380001],
                [86.0332086330001, 27.736168422],
                [86.0340219760001, 27.735621359],
                [86.0345832310001, 27.7348401890001],
                [86.0350081830001, 27.734446044],
                [86.035663356, 27.734046778],
                [86.0361497390001, 27.7335877020001],
                [86.03660338, 27.732968203],
                [86.0369572750001, 27.7327653520001],
                [86.03764519, 27.732526448],
                [86.037835542, 27.732396963],
                [86.0385317820001, 27.7315354890001],
                [86.039617214, 27.7309338740001],
                [86.0399596720001, 27.730798642],
                [86.040878809, 27.730435002],
                [86.0418493180001, 27.7298483210001],
                [86.042889072, 27.7291312780001],
                [86.0435465530001, 27.728894498],
                [86.044495688, 27.728422806],
                [86.044674415, 27.728387937],
                [86.047389207, 27.727258272],
                [86.0484647750001, 27.726241444],
                [86.0494982770001, 27.725616829],
                [86.0499388530001, 27.7252069990001],
                [86.0524098110001, 27.7236311190001],
                [86.0529175670001, 27.723147296],
                [86.0534565730001, 27.722456117],
                [86.0538531650001, 27.721560891],
                [86.0542754370001, 27.720880209],
                [86.055562115, 27.719257876],
                [86.055911144, 27.718687183],
                [86.0565718230001, 27.717917888],
                [86.0575817120001, 27.716895934],
                [86.0579466170001, 27.7161697550001],
                [86.059676788, 27.7150715080001],
                [86.0607371090001, 27.71399581],
                [86.061617213, 27.712460837],
                [86.0617672670001, 27.711958759],
                [86.0619664970001, 27.7116758500001],
                [86.0620351150001, 27.7115792980001],
                [86.062554618, 27.711151907],
                [86.0627876520001, 27.711031672],
                [86.062857331, 27.7109960510001],
                [86.0634601300001, 27.71087376],
                [86.0636051170001, 27.7105543530001],
                [86.0639705770001, 27.710180081],
                [86.0642151170001, 27.7097327500001],
                [86.064260677, 27.709557079],
                [86.0645504130001, 27.7084309970001],
                [86.0648170100001, 27.7081214670001],
                [86.0651499090001, 27.7078800560001],
                [86.0656048060001, 27.706958146],
                [86.0660935670001, 27.706377144],
                [86.06618525, 27.70599193],
                [86.0661032150001, 27.7052491200001],
                [86.066280695, 27.705058578],
                [86.0671879660001, 27.704372092],
                [86.0678184550001, 27.70389577],
                [86.068220287, 27.703438256],
                [86.068474081, 27.7027676320001],
                [86.0686167710001, 27.701739789],
                [86.0687665080001, 27.7014271950001],
                [86.0690499130001, 27.701223843],
                [86.0690818470001, 27.7012014700001],
                [86.0693195110001, 27.7008195080001],
                [86.0693832580001, 27.700551471],
                [86.0693369140001, 27.6994028230001],
                [86.0693350400001, 27.699357702],
                [86.069630267, 27.6986173980001],
                [86.0696095900001, 27.6982878650001],
                [86.0693565060001, 27.6979162060001],
                [86.06906294, 27.696966661],
                [86.069084321, 27.696531388],
                [86.069244622, 27.6961038040001]
              ]
            }
          },
          {
            type: "Feature",
            properties: { party: "Dolakha-ward" },
            geometry: {
              type: "LineString",
              coordinates: [
                [86.0839308870001, 27.689760558],
                [86.0843710240001, 27.689449843],
                [86.084555133, 27.689063005],
                [86.084787921, 27.6887690360001],
                [86.0850512680001, 27.688457193],
                [86.0855412130001, 27.6880136790001],
                [86.086751565, 27.6874666850001],
                [86.0869806010001, 27.6873125160001],
                [86.0871647700001, 27.6870722900001],
                [86.0877625150001, 27.6865460970001],
                [86.0880049290001, 27.686157386],
                [86.0882068500001, 27.6856849320001],
                [86.0884760700001, 27.68542496],
                [86.088511253, 27.6853914010001],
                [86.0888070930001, 27.685242169],
                [86.088844088, 27.6852469190001],
                [86.0894154490001, 27.6853047990001],
                [86.089734342, 27.6854332300001],
                [86.089994866, 27.685615468],
                [86.0903335630001, 27.685992191],
                [86.0906190850001, 27.6861339330001],
                [86.0909995370001, 27.686064258],
                [86.091191516, 27.6858308450001],
                [86.0911844620001, 27.685496937],
                [86.0914865540001, 27.6851695340001],
                [86.0915778, 27.6847843200001],
                [86.0913610310001, 27.6841083140001],
                [86.0913601650001, 27.683672915],
                [86.0914314140001, 27.683296575],
                [86.0916212690001, 27.683051913],
                [86.0919239190001, 27.682963676],
                [86.092337177, 27.682979931],
                [86.0927448680001, 27.6832194790001],
                [86.0929177770001, 27.6834913330001],
                [86.093026949, 27.683546205],
                [86.093382777, 27.683528213],
                [86.093947277, 27.6830062060001],
                [86.0940768280001, 27.682734108],
                [86.094089077, 27.68270711],
                [86.0943664160001, 27.6826569950001],
                [86.0950931950001, 27.6826843170001],
                [86.095675811, 27.682453535],
                [86.0960975200001, 27.681790726],
                [86.096299747, 27.6816250570001],
                [86.0965367760001, 27.681725865],
                [86.0967665530001, 27.681982346],
                [86.0967026760001, 27.6824308670001],
                [86.096902335, 27.6825765450001],
                [86.0971626740001, 27.682616608],
                [86.0973697110001, 27.682563804],
                [86.097711728, 27.6825773090001],
                [86.0983365920001, 27.682184292],
                [86.0987702850001, 27.682173613],
                [86.098940326, 27.682104747],
                [86.09965393, 27.6816581740001],
                [86.1003197190001, 27.681556507],
                [86.100486512, 27.6814605800001],
                [86.100790723, 27.6814061530001],
                [86.1008729660001, 27.6812329200001],
                [86.1009259050001, 27.680842956],
                [86.101047518, 27.6806474750001],
                [86.101353293, 27.6805253040001],
                [86.101719496, 27.6804465050001],
                [86.1020208350001, 27.680283774],
                [86.1022958680001, 27.679965182],
                [86.103157265, 27.679496989],
                [86.1051679980001, 27.6795980140001],
                [86.1061613730001, 27.6793698010001],
                [86.105786945, 27.6782776420001],
                [86.1057118320001, 27.6781665890001],
                [86.1055540460001, 27.6779354820001],
                [86.1051424390001, 27.6767100220001],
                [86.1048644200001, 27.6763450490001],
                [86.1045870250001, 27.676104004],
                [86.1043730540001, 27.6760417560001],
                [86.1040029800001, 27.675933322],
                [86.1036124080001, 27.6758833820001],
                [86.1028091360001, 27.6760045450001],
                [86.1022802680001, 27.6759853500001],
                [86.1017074740001, 27.675717677],
                [86.1011908660001, 27.6751750920001],
                [86.1009162860001, 27.6747694360001],
                [86.100640523, 27.6741427370001],
                [86.1004711790001, 27.6737377700001],
                [86.1002571490001, 27.6735310980001],
                [86.0996370540001, 27.673026195],
                [86.0990730070001, 27.6728443260001],
                [86.0981060770001, 27.6726372040001],
                [86.097112343, 27.6721434180001],
                [86.0960648010001, 27.6717958690001],
                [86.094891153, 27.671305456],
                [86.094434472, 27.6708692990001],
                [86.0940352280001, 27.6701560440001],
                [86.093837831, 27.669468852],
                [86.0931701270001, 27.668505677],
                [86.0930605230001, 27.668204889],
                [86.092636967, 27.667561377],
                [86.092151802, 27.6665903300001],
                [86.09129806, 27.6656100910001],
                [86.0902496050001, 27.664120956],
                [86.0898807250001, 27.663687614],
                [86.0894905440001, 27.6628683050001],
                [86.089294651, 27.6620165030001],
                [86.089284278, 27.661971319],
                [86.0889194120001, 27.6608566],
                [86.0886143390001, 27.660326705],
                [86.088062731, 27.6600117240001],
                [86.087828705, 27.6597371820001],
                [86.0877567810001, 27.659553885],
                [86.0876836180001, 27.6588247610001],
                [86.0872858550001, 27.6589643040001],
                [86.0868647840001, 27.6590337280001],
                [86.085850727, 27.6591736990001],
                [86.085497961, 27.6591600690001],
                [86.0839224160001, 27.6592940300001],
                [86.082759438, 27.659559362],
                [86.081654261, 27.6600213020001],
                [86.080240813, 27.6604315540001],
                [86.0798087430001, 27.660509914],
                [86.0795975820001, 27.6605807170001],
                [86.0792259410001, 27.660702325],
                [86.0788219300001, 27.6607628130001],
                [86.078317934, 27.6607707410001],
                [86.077727322, 27.6608773460001],
                [86.0765900870001, 27.661311976],
                [86.0751932580001, 27.6619230850001],
                [86.074436097, 27.6623895930001],
                [86.0738374210001, 27.6626585590001],
                [86.072801305, 27.662746408],
                [86.072528339, 27.6628528320001],
                [86.0721277590001, 27.663179546],
                [86.07172681, 27.6632897170001],
                [86.071408353, 27.6632244040001],
                [86.0714159140001, 27.6632334660001],
                [86.070981461, 27.6637043540001],
                [86.0708173470001, 27.6642356790001],
                [86.0705977430001, 27.6645861440001],
                [86.0703381490001, 27.6647175030001],
                [86.0699651360001, 27.6647037480001],
                [86.069594937, 27.664624561],
                [86.0690023880001, 27.6646904830001],
                [86.068691051, 27.6648395890001],
                [86.0685014460001, 27.665084189],
                [86.0684318880001, 27.665291294],
                [86.06841282, 27.665674697],
                [86.068210396, 27.666278015],
                [86.0683141850001, 27.666734413],
                [86.068324364, 27.667070634],
                [86.068184187, 27.667426162],
                [86.0678559770001, 27.6675841420001],
                [86.0673720940001, 27.668011721],
                [86.066516392, 27.6681437570001],
                [86.065659506, 27.6680408140001],
                [86.064140652, 27.667667384],
                [86.0638660610001, 27.6677467480001],
                [86.062939729, 27.66854591],
                [86.062822989, 27.6687887620001],
                [86.0627923550001, 27.669406769],
                [86.062616179, 27.6699380310001],
                [86.062443574, 27.670078953],
                [86.060845332, 27.6707404910001],
                [86.060563491, 27.6709122840001],
                [86.059692338, 27.67173882],
                [86.0591162090001, 27.672540364],
                [86.0585275760001, 27.6735900100001],
                [86.057947884, 27.6744772330001],
                [86.0577361470001, 27.675157106],
                [86.0572007690001, 27.6756746140001],
                [86.0564984750001, 27.6761932430001],
                [86.056196884, 27.6764912740001],
                [86.0558247960001, 27.677032469],
                [86.055642682, 27.6776539100001],
                [86.0556035450001, 27.6784500260001],
                [86.055811496, 27.679459939],
                [86.0560718820001, 27.6802761210001],
                [86.0561812880001, 27.681075551],
                [86.0560769160001, 27.6815689430001],
                [86.0560327320001, 27.681658871],
                [86.055606707, 27.6825380980001],
                [86.0550713280001, 27.6830916640001],
                [86.0551793110001, 27.6832413400001],
                [86.055320785, 27.6835875620001],
                [86.055340335, 27.6840297100001],
                [86.055164721, 27.684601656],
                [86.0554825980001, 27.685472396],
                [86.0559225260001, 27.686147718],
                [86.0567219000001, 27.687132331],
                [86.0570813960001, 27.6879943230001],
                [86.057559741, 27.689396393],
                [86.0581746410001, 27.6902850110001],
                [86.0582578130001, 27.690488557],
                [86.0584168980001, 27.691371793],
                [86.0585566890001, 27.6915419050001],
                [86.0586414270001, 27.6915650920001],
                [86.0591256800001, 27.6913630560001],
                [86.0603218430001, 27.6910079210001],
                [86.0607146710001, 27.69078726],
                [86.0609472020001, 27.690822698],
                [86.061067559, 27.690940814],
                [86.0612555270001, 27.6912624140001],
                [86.061906084, 27.693362737],
                [86.06231561, 27.695452432],
                [86.062369915, 27.6954753680001],
                [86.0627596730001, 27.6956426730001],
                [86.063018764, 27.695624116],
                [86.0635295770001, 27.695388458],
                [86.0640268270001, 27.6952609160001],
                [86.0654161420001, 27.6953673670001],
                [86.0657335370001, 27.6953807460001],
                [86.065928199, 27.695397873],
                [86.066330957, 27.6954299400001],
                [86.067082725, 27.695843353],
                [86.0678407460001, 27.6960425350001],
                [86.068197072, 27.6961035360001],
                [86.069244622, 27.6961038040001],
                [86.0694051010001, 27.6961048690001],
                [86.069904668, 27.6957404720001],
                [86.0701683290001, 27.6953631980001],
                [86.070372692, 27.6946471410001],
                [86.0706686, 27.694236307],
                [86.0711339200001, 27.6939123440001],
                [86.0718168320001, 27.6938221140001],
                [86.072231339, 27.693883553],
                [86.0731489610001, 27.69420554],
                [86.0731968300001, 27.6942036030001],
                [86.073710513, 27.694191238],
                [86.0746598970001, 27.693766667],
                [86.075134898, 27.693706681],
                [86.07560734, 27.69351808],
                [86.0763033750001, 27.693445974],
                [86.0770535880001, 27.693484858],
                [86.0779185390001, 27.693364258],
                [86.0781502610001, 27.6932394610001],
                [86.0782684370001, 27.69305073],
                [86.0783209990001, 27.6927487580001],
                [86.0784198650001, 27.692559901],
                [86.078477234, 27.6924858460001],
                [86.078895184, 27.6922833090001],
                [86.0791285390001, 27.691772734],
                [86.079261462, 27.691611188],
                [86.079591173, 27.691423835],
                [86.0799623120001, 27.691406031],
                [86.080171663, 27.6912630470001],
                [86.0802734650001, 27.6910990010001],
                [86.080212358, 27.690651915],
                [86.0803296000001, 27.690300698],
                [86.0805202050001, 27.6900515360001],
                [86.0806376900001, 27.6900568500001],
                [86.0809392720001, 27.690207779],
                [86.0812911520001, 27.6907131780001],
                [86.081640351, 27.690970473],
                [86.0822383940001, 27.691093972],
                [86.082678028, 27.690918745],
                [86.0834005740001, 27.690136077],
                [86.0839308870001, 27.689760558]
              ]
            }
          },
          {
            type: "Feature",
            properties: { party: "Dokha2" },
            geometry: {
              type: "LineString",
              coordinates: [
                [86.0550713280001, 27.6830916640001],
                [86.055606707, 27.6825380980001],
                [86.0560327320001, 27.681658871],
                [86.0560769160001, 27.6815689430001],
                [86.0561812880001, 27.681075551],
                [86.0560718820001, 27.6802761210001],
                [86.055811496, 27.679459939],
                [86.0556035450001, 27.6784500260001],
                [86.055642682, 27.6776539100001],
                [86.0558247960001, 27.677032469],
                [86.056196884, 27.6764912740001],
                [86.0564984750001, 27.6761932430001],
                [86.0572007690001, 27.6756746140001],
                [86.0577361470001, 27.675157106],
                [86.057947884, 27.6744772330001],
                [86.0585275760001, 27.6735900100001],
                [86.0591162090001, 27.672540364],
                [86.059692338, 27.67173882],
                [86.060563491, 27.6709122840001],
                [86.060845332, 27.6707404910001],
                [86.062443574, 27.670078953],
                [86.062616179, 27.6699380310001],
                [86.0627923550001, 27.669406769],
                [86.062822989, 27.6687887620001],
                [86.062939729, 27.66854591],
                [86.0638660610001, 27.6677467480001],
                [86.064140652, 27.667667384],
                [86.065659506, 27.6680408140001],
                [86.066516392, 27.6681437570001],
                [86.0673720940001, 27.668011721],
                [86.0678559770001, 27.6675841420001],
                [86.068184187, 27.667426162],
                [86.068324364, 27.667070634],
                [86.0683141850001, 27.666734413],
                [86.068210396, 27.666278015],
                [86.06841282, 27.665674697],
                [86.0684318880001, 27.665291294],
                [86.0685014460001, 27.665084189],
                [86.068691051, 27.6648395890001],
                [86.0690023880001, 27.6646904830001],
                [86.069594937, 27.664624561],
                [86.0699651360001, 27.6647037480001],
                [86.0703381490001, 27.6647175030001],
                [86.0705977430001, 27.6645861440001],
                [86.0708173470001, 27.6642356790001],
                [86.070981461, 27.6637043540001],
                [86.0714159140001, 27.6632334660001],
                [86.071408353, 27.6632244040001],
                [86.0712600030001, 27.6629797360001],
                [86.06985595, 27.662376954],
                [86.0692065470001, 27.6618627390001],
                [86.0687534910001, 27.661397148],
                [86.0681637660001, 27.6609464910001],
                [86.0677163970001, 27.660510334],
                [86.0661180100001, 27.6591933],
                [86.0656045220001, 27.6588694450001],
                [86.0648047600001, 27.6584985900001],
                [86.0647595770001, 27.6585952680001],
                [86.063526429, 27.658469633],
                [86.0628414610001, 27.6582709520001],
                [86.0623011660001, 27.65785673],
                [86.0618429800001, 27.6577001740001],
                [86.0614664660001, 27.657726977],
                [86.0610261460001, 27.6578480840001],
                [86.0607098140001, 27.657798583],
                [86.058666228, 27.656850696],
                [86.0580887420001, 27.6568264380001],
                [86.0567742200001, 27.6571761970001],
                [86.0564867580001, 27.657165256],
                [86.0563594660001, 27.6570154550001],
                [86.0557424940001, 27.653627122],
                [86.0556802570001, 27.6534213890001],
                [86.055574461, 27.6532762750001],
                [86.0553853470001, 27.6539450870001],
                [86.0550208820001, 27.6545269040001],
                [86.05402574, 27.655636913],
                [86.053798016, 27.6558225800001],
                [86.0531263500001, 27.6559939910001],
                [86.052211771, 27.6565765490001],
                [86.0518527410001, 27.65725092],
                [86.0516183280001, 27.657553889],
                [86.0513551110001, 27.6576288150001],
                [86.0510847090001, 27.6576179360001],
                [86.0505503460001, 27.6574450680001],
                [86.048915875, 27.657357173],
                [86.048875631, 27.6573253],
                [86.0484553040001, 27.6576585760001],
                [86.0482324540001, 27.657898426],
                [86.048247255, 27.658347511],
                [86.0479432170001, 27.6590515680001],
                [86.047817659, 27.6596418250001],
                [86.047827463, 27.6599735460001],
                [86.0473354370001, 27.6613034810001],
                [86.04738023, 27.6619084280001],
                [86.047683675, 27.662641492],
                [86.048082363, 27.663057087],
                [86.0481634700001, 27.6634118690001],
                [86.048339502, 27.663753779],
                [86.0484177970001, 27.664076939],
                [86.0484493370001, 27.664963798],
                [86.0483310810001, 27.665966956],
                [86.0483942540001, 27.6662448700001],
                [86.0490147180001, 27.6670523080001],
                [86.0491463180001, 27.6674006540001],
                [86.0491190600001, 27.6679531670001],
                [86.0493340260001, 27.6682231460001],
                [86.0493585790001, 27.6684940600001],
                [86.0482687480001, 27.670241823],
                [86.048066013, 27.670784084],
                [86.0480005110001, 27.6712890370001],
                [86.047894022, 27.671405588],
                [86.0474462020001, 27.671614624],
                [86.046793409, 27.6717951590001],
                [86.0453120380001, 27.671965431],
                [86.0449907020001, 27.67209179],
                [86.044727983, 27.672284018],
                [86.0438830000001, 27.673760434],
                [86.0430127820001, 27.674715522],
                [86.0428078680001, 27.674854006],
                [86.0425007760001, 27.675197032],
                [86.0423960360001, 27.6753655160001],
                [86.0423741550001, 27.675805288],
                [86.0422910990001, 27.67599877],
                [86.0412094000001, 27.6773652540001],
                [86.040319127, 27.678069739],
                [86.0400182830001, 27.6784579490001],
                [86.039722994, 27.679186879],
                [86.039124369, 27.6799723610001],
                [86.0390045030001, 27.680251334],
                [86.0389439370001, 27.6807923470001],
                [86.0384709890001, 27.681294419],
                [86.0381579, 27.681529143],
                [86.0375280250001, 27.6824745480001],
                [86.037386596, 27.682960877],
                [86.0375064420001, 27.683600197],
                [86.037661105, 27.6837749960001],
                [86.0382352280001, 27.6832601140001],
                [86.0386879860001, 27.6830353920001],
                [86.0392249870001, 27.682596878],
                [86.039598691, 27.682423525],
                [86.04015287, 27.682341604],
                [86.040446203, 27.6824857830001],
                [86.0406592320001, 27.6827625120001],
                [86.040967062, 27.6829879970001],
                [86.04127458, 27.683141301],
                [86.0417159570001, 27.6832751090001],
                [86.0417895720001, 27.6832982340001],
                [86.041875684, 27.6833823530001],
                [86.041767758, 27.683474092],
                [86.0417266370001, 27.6835460230001],
                [86.0418085620001, 27.683630017],
                [86.042233814, 27.6838743770001],
                [86.0430425160001, 27.684026627],
                [86.0435175160001, 27.684043446],
                [86.0447470490001, 27.6837089970001],
                [86.04576166, 27.683937056],
                [86.0469613170001, 27.683832523],
                [86.0477720210001, 27.683939652],
                [86.0485761020001, 27.6839699760001],
                [86.0494487430001, 27.6836962030001],
                [86.0495987850001, 27.683704017],
                [86.0497103320001, 27.6837747000001],
                [86.0499868510001, 27.6841127370001],
                [86.0501470770001, 27.684228917],
                [86.0505488360001, 27.6842564840001],
                [86.050841861, 27.684222429],
                [86.0518224290001, 27.6837733610001],
                [86.0521876330001, 27.6836653140001],
                [86.0529248450001, 27.6838147510001],
                [86.0534134690001, 27.6837978230001],
                [86.05370012, 27.683732084],
                [86.0540088960001, 27.6835582300001],
                [86.0542126890001, 27.6831805790001],
                [86.0547388130001, 27.6829247360001],
                [86.0549572840001, 27.6829329890001],
                [86.0550713280001, 27.6830916640001]
              ]
            }
          },
          {
            type: "Feature",
            properties: { party: "Dokha2" },
            geometry: {
              type: "LineString",
              coordinates: [
                [86.0876836180001, 27.6588247610001],
                [86.0876681240001, 27.6586712120001],
                [86.08725357, 27.65786309],
                [86.087237336, 27.6572267710001],
                [86.0872803990001, 27.6569631080001],
                [86.0873970170001, 27.6565418980001],
                [86.087676178, 27.6560586960001],
                [86.087858795, 27.65531314],
                [86.0878476870001, 27.6545911420001],
                [86.087972812, 27.6538249000001],
                [86.087965259, 27.653438997],
                [86.0877903720001, 27.652149609],
                [86.0875255430001, 27.6516199650001],
                [86.0873725820001, 27.650745853],
                [86.0870226440001, 27.6499787280001],
                [86.0864417380001, 27.6491536670001],
                [86.0864051890001, 27.648747327],
                [86.086743912, 27.6479644030001],
                [86.0867689730001, 27.647881098],
                [86.0869578360001, 27.647257407],
                [86.08695491, 27.6467272040001],
                [86.086638356, 27.645391756],
                [86.0867730630001, 27.643650914],
                [86.086734644, 27.64301447],
                [86.0866774670001, 27.642883231],
                [86.086343443, 27.6431066430001],
                [86.0858968750001, 27.6431871280001],
                [86.0853503800001, 27.6434024120001],
                [86.0850266720001, 27.6434476520001],
                [86.0839741230001, 27.643404576],
                [86.082404947, 27.6437822030001],
                [86.0812811490001, 27.6441854600001],
                [86.079971942, 27.644382046],
                [86.0768206560001, 27.6449860010001],
                [86.0764882620001, 27.6449725590001],
                [86.075713996, 27.6446650730001],
                [86.07500028, 27.644545259],
                [86.0738586090001, 27.644925706],
                [86.0723969210001, 27.6451685970001],
                [86.0712995650001, 27.645179328],
                [86.071074781, 27.645245505],
                [86.069811133, 27.6453272890001],
                [86.0691639030001, 27.645433456],
                [86.0681702850001, 27.6453794440001],
                [86.068052863, 27.64537188],
                [86.0663633440001, 27.6449633880001],
                [86.0650032730001, 27.644994862],
                [86.0640801470001, 27.644875857],
                [86.0629260580001, 27.6444438770001],
                [86.062293018, 27.6443358130001],
                [86.0619417490001, 27.6444506720001],
                [86.0611470320001, 27.6448785580001],
                [86.0608266930001, 27.6451967740001],
                [86.0608468770001, 27.645205898],
                [86.0609669820001, 27.6454210690001],
                [86.060728134, 27.645649544],
                [86.0600685920001, 27.6458255800001],
                [86.0595315950001, 27.6460678610001],
                [86.059326991, 27.6463304600001],
                [86.059217618, 27.6469004690001],
                [86.0586271610001, 27.648509566],
                [86.058538599, 27.6490527030001],
                [86.0582864900001, 27.6498631260001],
                [86.0581176300001, 27.6502027180001],
                [86.0578427830001, 27.650570868],
                [86.057366652, 27.6508473980001],
                [86.0570178790001, 27.651151865],
                [86.0564828810001, 27.6514212070001],
                [86.0562104730001, 27.6517171130001],
                [86.055964246, 27.652182442],
                [86.055821816, 27.652725204],
                [86.0555963350001, 27.6531974700001],
                [86.055574461, 27.6532762750001],
                [86.0556802570001, 27.6534213890001],
                [86.0557424940001, 27.653627122],
                [86.0563594660001, 27.6570154550001],
                [86.0564867580001, 27.657165256],
                [86.0567742200001, 27.6571761970001],
                [86.0580887420001, 27.6568264380001],
                [86.058666228, 27.656850696],
                [86.0607098140001, 27.657798583],
                [86.0610261460001, 27.6578480840001],
                [86.0614664660001, 27.657726977],
                [86.0618429800001, 27.6577001740001],
                [86.0623011660001, 27.65785673],
                [86.0628414610001, 27.6582709520001],
                [86.063526429, 27.658469633],
                [86.0647595770001, 27.6585952680001],
                [86.0648047600001, 27.6584985900001],
                [86.0656045220001, 27.6588694450001],
                [86.0661180100001, 27.6591933],
                [86.0677163970001, 27.660510334],
                [86.0681637660001, 27.6609464910001],
                [86.0687534910001, 27.661397148],
                [86.0692065470001, 27.6618627390001],
                [86.06985595, 27.662376954],
                [86.0712600030001, 27.6629797360001],
                [86.071408353, 27.6632244040001],
                [86.07172681, 27.6632897170001],
                [86.0721277590001, 27.663179546],
                [86.072528339, 27.6628528320001],
                [86.072801305, 27.662746408],
                [86.0738374210001, 27.6626585590001],
                [86.074436097, 27.6623895930001],
                [86.0751932580001, 27.6619230850001],
                [86.0765900870001, 27.661311976],
                [86.077727322, 27.6608773460001],
                [86.078317934, 27.6607707410001],
                [86.0788219300001, 27.6607628130001],
                [86.0792259410001, 27.660702325],
                [86.0795975820001, 27.6605807170001],
                [86.0798087430001, 27.660509914],
                [86.080240813, 27.6604315540001],
                [86.081654261, 27.6600213020001],
                [86.082759438, 27.659559362],
                [86.0839224160001, 27.6592940300001],
                [86.085497961, 27.6591600690001],
                [86.085850727, 27.6591736990001],
                [86.0868647840001, 27.6590337280001],
                [86.0872858550001, 27.6589643040001],
                [86.0876836180001, 27.6588247610001]
              ]
            }
          },
          {
            type: "Feature",
            properties: { party: "Dokha2" },
            geometry: {
              type: "LineString",
              coordinates: [
                [86.0482324540001, 27.657898426],
                [86.0484553040001, 27.6576585760001],
                [86.048875631, 27.6573253],
                [86.048915875, 27.657357173],
                [86.0505503460001, 27.6574450680001],
                [86.0510847090001, 27.6576179360001],
                [86.0513551110001, 27.6576288150001],
                [86.0516183280001, 27.657553889],
                [86.0518527410001, 27.65725092],
                [86.052211771, 27.6565765490001],
                [86.0531263500001, 27.6559939910001],
                [86.053798016, 27.6558225800001],
                [86.05402574, 27.655636913],
                [86.0550208820001, 27.6545269040001],
                [86.0553853470001, 27.6539450870001],
                [86.055574461, 27.6532762750001],
                [86.0555963350001, 27.6531974700001],
                [86.055821816, 27.652725204],
                [86.055964246, 27.652182442],
                [86.0562104730001, 27.6517171130001],
                [86.0564828810001, 27.6514212070001],
                [86.0570178790001, 27.651151865],
                [86.057366652, 27.6508473980001],
                [86.0578427830001, 27.650570868],
                [86.0581176300001, 27.6502027180001],
                [86.0582864900001, 27.6498631260001],
                [86.058538599, 27.6490527030001],
                [86.0586271610001, 27.648509566],
                [86.059217618, 27.6469004690001],
                [86.059326991, 27.6463304600001],
                [86.0595315950001, 27.6460678610001],
                [86.0600685920001, 27.6458255800001],
                [86.060728134, 27.645649544],
                [86.0609669820001, 27.6454210690001],
                [86.0608468770001, 27.645205898],
                [86.0608266930001, 27.6451967740001],
                [86.0611470320001, 27.6448785580001],
                [86.0619417490001, 27.6444506720001],
                [86.062293018, 27.6443358130001],
                [86.0629260580001, 27.6444438770001],
                [86.0640801470001, 27.644875857],
                [86.0650032730001, 27.644994862],
                [86.0663633440001, 27.6449633880001],
                [86.068052863, 27.64537188],
                [86.0681702850001, 27.6453794440001],
                [86.0691639030001, 27.645433456],
                [86.069811133, 27.6453272890001],
                [86.071074781, 27.645245505],
                [86.0712995650001, 27.645179328],
                [86.0723969210001, 27.6451685970001],
                [86.0738586090001, 27.644925706],
                [86.07500028, 27.644545259],
                [86.075713996, 27.6446650730001],
                [86.0764882620001, 27.6449725590001],
                [86.0768206560001, 27.6449860010001],
                [86.079971942, 27.644382046],
                [86.0812811490001, 27.6441854600001],
                [86.082404947, 27.6437822030001],
                [86.0839741230001, 27.643404576],
                [86.0850266720001, 27.6434476520001],
                [86.0853503800001, 27.6434024120001],
                [86.0858968750001, 27.6431871280001],
                [86.086343443, 27.6431066430001],
                [86.0866774670001, 27.642883231],
                [86.0865404290001, 27.6425709440001],
                [86.0847833820001, 27.640950746],
                [86.084414882, 27.6402917990001],
                [86.0842765950001, 27.639936703],
                [86.084333912, 27.639400127],
                [86.084509461, 27.638934234],
                [86.085171518, 27.638027139],
                [86.085243914, 27.636592706],
                [86.085232918, 27.636473091],
                [86.0851673170001, 27.6357552170001],
                [86.0850835860001, 27.635386936],
                [86.0847222780001, 27.634473074],
                [86.0845659270001, 27.6343118360001],
                [86.0843085290001, 27.634043918],
                [86.083677934, 27.633606571],
                [86.0835338320001, 27.633449958],
                [86.083088274, 27.6330431740001],
                [86.0827767650001, 27.632427411],
                [86.0828252800001, 27.631464373],
                [86.0827508660001, 27.6308231790001],
                [86.0822868320001, 27.629398736],
                [86.082072693, 27.628427194],
                [86.081915995, 27.6269754460001],
                [86.0817717130001, 27.626435304],
                [86.081305237, 27.6252680260001],
                [86.0805103010001, 27.6242317300001],
                [86.0803102850001, 27.623269312],
                [86.080263243, 27.6226101830001],
                [86.0801174640001, 27.621948177],
                [86.079873073, 27.621260734],
                [86.0796997900001, 27.6209707570001],
                [86.079443643, 27.6206262210001],
                [86.0786195810001, 27.6198197790001],
                [86.0783963700001, 27.6193715650001],
                [86.0780771080001, 27.618967846],
                [86.0773004750001, 27.6182903930001],
                [86.0756115930001, 27.617284205],
                [86.075267458, 27.616972789],
                [86.075084551, 27.6166692510001],
                [86.0749534540001, 27.6161404210001],
                [86.0749568360001, 27.6158155750001],
                [86.0751320100001, 27.6153564310001],
                [86.0758688710001, 27.614427339],
                [86.075974052, 27.614042313],
                [86.0759462510001, 27.6136991560001],
                [86.0758228350001, 27.6134638],
                [86.0757919650001, 27.613404867],
                [86.0756936050001, 27.613322997],
                [86.0755731880001, 27.6131055770001],
                [86.0755693130001, 27.6131168260001],
                [86.075272096, 27.6134036080001],
                [86.074953066, 27.61385275],
                [86.0745164260001, 27.614310077],
                [86.074099151, 27.615139808],
                [86.0735208340001, 27.615952663],
                [86.073270542, 27.6165376070001],
                [86.0731640540001, 27.6166293470001],
                [86.0728621550001, 27.6166882740001],
                [86.072577569, 27.616648023],
                [86.072346726, 27.6165381540001],
                [86.072015218, 27.616048629],
                [86.0718941800001, 27.615650725],
                [86.071849447, 27.615097711],
                [86.071729094, 27.6148059230001],
                [86.0714991310001, 27.614517757],
                [86.0712494780001, 27.614421387],
                [86.070996887, 27.614415133],
                [86.0701619910001, 27.6146532230001],
                [86.068457761, 27.615467558],
                [86.0681986640001, 27.6157094700001],
                [86.0681970310001, 27.616126808],
                [86.0687205090001, 27.6168545640001],
                [86.0689327260001, 27.617070298],
                [86.0690190820001, 27.6174521410001],
                [86.068923091, 27.6176116870001],
                [86.0685748830001, 27.6177831040001],
                [86.0680286400001, 27.617919332],
                [86.0678288510001, 27.6180330060001],
                [86.067606561, 27.618331601],
                [86.067556933, 27.6187757480001],
                [86.0671587300001, 27.619057341],
                [86.066494629, 27.6190845150001],
                [86.065076198, 27.618743146],
                [86.064697998, 27.618733891],
                [86.0645133960001, 27.6188093810001],
                [86.0641843670001, 27.619224526],
                [86.0638089110001, 27.619519805],
                [86.0634775140001, 27.6196213530001],
                [86.0632053, 27.6196150370001],
                [86.062596016, 27.61910326],
                [86.0619734250001, 27.618460557],
                [86.061763392, 27.6183598740001],
                [86.060895944, 27.618313426],
                [86.060248964, 27.618442154],
                [86.059988558, 27.6186073220001],
                [86.0599776830001, 27.6186636300001],
                [86.0599074340001, 27.619028658],
                [86.0600391420001, 27.620173433],
                [86.059854661, 27.6204203460001],
                [86.0591803670001, 27.620817361],
                [86.0589025900001, 27.6208718520001],
                [86.0586055690001, 27.620729984],
                [86.0579517950001, 27.6200216620001],
                [86.0577012690001, 27.6198891070001],
                [86.0575496000001, 27.6199173520001],
                [86.0571607700001, 27.620217068],
                [86.057106833, 27.620530289],
                [86.0567868030001, 27.6209996170001],
                [86.056199179, 27.6216273640001],
                [86.0557934760001, 27.621940454],
                [86.0544398260001, 27.6226509300001],
                [86.053486375, 27.62333254],
                [86.0523360070001, 27.6242203790001],
                [86.051927054, 27.624535657],
                [86.0515127880001, 27.624916366],
                [86.051088463, 27.6251797100001],
                [86.050451288, 27.625601724],
                [86.049209309, 27.6264235040001],
                [86.0484211470001, 27.6271309900001],
                [86.0468880870001, 27.6277071010001],
                [86.0461983640001, 27.62775421],
                [86.0455914350001, 27.6281494140001],
                [86.04529941, 27.6282015300001],
                [86.0448150380001, 27.6281508380001],
                [86.044299487, 27.6278314210001],
                [86.0434291710001, 27.6274937490001],
                [86.0427478890001, 27.6273694980001],
                [86.0424468640001, 27.627437362],
                [86.0416275890001, 27.6278152520001],
                [86.0413171260001, 27.6280138540001],
                [86.0410282170001, 27.628431499],
                [86.040997657, 27.6285238030001],
                [86.0410768290001, 27.62874091],
                [86.041277108, 27.629085258],
                [86.041322037, 27.6291938740001],
                [86.041575245, 27.629608466],
                [86.041639547, 27.6297149580001],
                [86.0420076180001, 27.6299543800001],
                [86.0421977110001, 27.6303008520001],
                [86.0422526360001, 27.6305426440001],
                [86.042214576, 27.6306551960001],
                [86.0420672160001, 27.630891047],
                [86.0416229590001, 27.631034652],
                [86.0404218030001, 27.631087252],
                [86.0395412840001, 27.6315549450001],
                [86.0392779960001, 27.63204046],
                [86.039258928, 27.632414801],
                [86.039352276, 27.6330697440001],
                [86.039243721, 27.6333938380001],
                [86.0390749270001, 27.6335776940001],
                [86.038628482, 27.6337551080001],
                [86.038400257, 27.634001707],
                [86.0382039580001, 27.634573403],
                [86.0382097610001, 27.6349637430001],
                [86.038089336, 27.635156911],
                [86.037882424, 27.6352095280001],
                [86.0375636580001, 27.635013353],
                [86.0372238400001, 27.634413151],
                [86.036900077, 27.634097298],
                [86.0365482720001, 27.633012014],
                [86.0362283200001, 27.632700599],
                [86.0359845410001, 27.6326357890001],
                [86.035749631, 27.632798832],
                [86.035603833, 27.633032434],
                [86.0356066910001, 27.6338288630001],
                [86.0357950840001, 27.6347348480001],
                [86.035773265, 27.6351633720001],
                [86.035661211, 27.635449157],
                [86.0351062140001, 27.635835613],
                [86.033544964, 27.6366820110001],
                [86.032935471, 27.637138022],
                [86.0327516130001, 27.637520297],
                [86.032754412, 27.6381384920001],
                [86.0325871090001, 27.638723875],
                [86.0325498000001, 27.6387890560001],
                [86.0324398720001, 27.6389800370001],
                [86.0320043620001, 27.6392500050001],
                [86.0315370470001, 27.639303118],
                [86.0308849480001, 27.6391677440001],
                [86.03065754, 27.639177427],
                [86.0301487900001, 27.6394085220001],
                [86.0298221400001, 27.6396476200001],
                [86.0295547300001, 27.6400180200001],
                [86.0292363200001, 27.6406813310001],
                [86.0291757030001, 27.640698953],
                [86.0289094190001, 27.6409858600001],
                [86.028644758, 27.641408068],
                [86.0280787570001, 27.6419885070001],
                [86.027643927, 27.642569822],
                [86.026633239, 27.6429845810001],
                [86.02619229, 27.643283797],
                [86.026215032, 27.6435388990001],
                [86.0265342880001, 27.6442134690001],
                [86.0263937980001, 27.644629867],
                [86.0261386380001, 27.644907776],
                [86.0258813500001, 27.64531886],
                [86.0253984060001, 27.645712566],
                [86.0243931310001, 27.647190042],
                [86.0240757940001, 27.64740433],
                [86.024525585, 27.6485332990001],
                [86.024792145, 27.6499723000001],
                [86.024965551, 27.6503502690001],
                [86.0249184910001, 27.6505485010001],
                [86.0246979510001, 27.650844783],
                [86.0242209470001, 27.651064755],
                [86.0238664870001, 27.6513532860001],
                [86.023781494, 27.651551205],
                [86.023770428, 27.6517677480001],
                [86.023913156, 27.651953733],
                [86.02533995, 27.652766935],
                [86.0259543550001, 27.6534480720001],
                [86.0262331850001, 27.653860665],
                [86.0267141780001, 27.654157334],
                [86.0286562260001, 27.6545522060001],
                [86.028859949, 27.654576207],
                [86.0293397570001, 27.6547556370001],
                [86.0295257920001, 27.6548899410001],
                [86.029649399, 27.6549495],
                [86.029712578, 27.6549679990001],
                [86.030289565, 27.6549381990001],
                [86.0311171390001, 27.655189878],
                [86.0313694770001, 27.6553383070001],
                [86.0317326100001, 27.6556476600001],
                [86.0325437550001, 27.6556059280001],
                [86.033158235, 27.6556711200001],
                [86.03452837, 27.655522719],
                [86.034848889, 27.6555926550001],
                [86.03551717, 27.6558793290001],
                [86.0357360130001, 27.656023007],
                [86.036441165, 27.6562918700001],
                [86.037432783, 27.656330321],
                [86.0392336030001, 27.6565865160001],
                [86.039927945, 27.656792072],
                [86.0402230890001, 27.6570151820001],
                [86.0407932580001, 27.657296666],
                [86.041757052, 27.658054615]
              ]
            }
          },
          {
            type: "Feature",
            properties: { party: "Dokha2" },
            geometry: {
              type: "LineString",
              coordinates: [
                [86.037661105, 27.6837749960001],
                [86.0375064420001, 27.683600197],
                [86.037386596, 27.682960877],
                [86.0375280250001, 27.6824745480001],
                [86.0381579, 27.681529143],
                [86.0384709890001, 27.681294419],
                [86.0389439370001, 27.6807923470001],
                [86.0390045030001, 27.680251334],
                [86.039124369, 27.6799723610001],
                [86.039722994, 27.679186879],
                [86.0400182830001, 27.6784579490001],
                [86.040319127, 27.678069739],
                [86.0412094000001, 27.6773652540001],
                [86.0422910990001, 27.67599877],
                [86.0423741550001, 27.675805288],
                [86.0423960360001, 27.6753655160001],
                [86.0425007760001, 27.675197032],
                [86.0428078680001, 27.674854006],
                [86.0430127820001, 27.674715522],
                [86.0438830000001, 27.673760434],
                [86.044727983, 27.672284018],
                [86.0449907020001, 27.67209179],
                [86.0453120380001, 27.671965431],
                [86.046793409, 27.6717951590001],
                [86.0474462020001, 27.671614624],
                [86.047894022, 27.671405588],
                [86.0480005110001, 27.6712890370001],
                [86.048066013, 27.670784084],
                [86.0482687480001, 27.670241823],
                [86.0493585790001, 27.6684940600001],
                [86.0493340260001, 27.6682231460001],
                [86.0491190600001, 27.6679531670001],
                [86.0491463180001, 27.6674006540001],
                [86.0490147180001, 27.6670523080001],
                [86.0483942540001, 27.6662448700001],
                [86.0483310810001, 27.665966956],
                [86.0484493370001, 27.664963798],
                [86.0484177970001, 27.664076939],
                [86.048339502, 27.663753779],
                [86.0481634700001, 27.6634118690001],
                [86.048082363, 27.663057087],
                [86.047683675, 27.662641492],
                [86.04738023, 27.6619084280001],
                [86.0473354370001, 27.6613034810001],
                [86.047827463, 27.6599735460001],
                [86.047817659, 27.6596418250001],
                [86.0479432170001, 27.6590515680001],
                [86.048247255, 27.658347511],
                [86.0482324540001, 27.657898426],
                [86.0482263920001, 27.6579051750001],
                [86.047706836, 27.657885793],
                [86.0456906570001, 27.6581697330001],
                [86.0442173530001, 27.658087403],
                [86.0438353380001, 27.6582065740001],
                [86.0434847590001, 27.6582335030001],
                [86.0420575740001, 27.6581334250001],
                [86.041757052, 27.658054615],
                [86.0407932580001, 27.657296666],
                [86.0402230890001, 27.6570151820001],
                [86.039927945, 27.656792072],
                [86.0392336030001, 27.6565865160001],
                [86.037432783, 27.656330321],
                [86.036441165, 27.6562918700001],
                [86.0357360130001, 27.656023007],
                [86.03551717, 27.6558793290001],
                [86.034848889, 27.6555926550001],
                [86.03452837, 27.655522719],
                [86.033158235, 27.6556711200001],
                [86.0325437550001, 27.6556059280001],
                [86.0317326100001, 27.6556476600001],
                [86.0313694770001, 27.6553383070001],
                [86.0311171390001, 27.655189878],
                [86.030289565, 27.6549381990001],
                [86.029712578, 27.6549679990001],
                [86.029851808, 27.655052432],
                [86.030140385, 27.6555102080001],
                [86.0307288540001, 27.6562655880001],
                [86.031179594, 27.6569094760001],
                [86.0314422320001, 27.6575813580001],
                [86.031477346, 27.6579064540001],
                [86.0313739760001, 27.6582779820001],
                [86.0315789970001, 27.658958549],
                [86.0316272320001, 27.6594055100001],
                [86.032054224, 27.660049272],
                [86.0320879050001, 27.660178199],
                [86.0321223340001, 27.6603339980001],
                [86.03212675, 27.6613086610001],
                [86.03217643, 27.661385717],
                [86.0323647150001, 27.6614795250001],
                [86.032552502, 27.6615439600001],
                [86.0324340770001, 27.6616943820001],
                [86.032069999, 27.6617121240001],
                [86.0305618820001, 27.6618392120001],
                [86.0302518610001, 27.66182802],
                [86.030095132, 27.661822393],
                [86.0297949220001, 27.661781954],
                [86.0297009970001, 27.661770016],
                [86.028942165, 27.661509777],
                [86.0280180400001, 27.6613589],
                [86.0262871310001, 27.661892698],
                [86.0257399530001, 27.661913686],
                [86.0253892470001, 27.6619924230001],
                [86.025026917, 27.6621365290001],
                [86.024666021, 27.6624047490001],
                [86.02436749, 27.6627793980001],
                [86.024319926, 27.6631400530001],
                [86.0243765340001, 27.6636141990001],
                [86.0245735620001, 27.6640081670001],
                [86.0245844230001, 27.6646038640001],
                [86.024540173, 27.664874276],
                [86.024142151, 27.665397098],
                [86.0239662920001, 27.6657636250001],
                [86.023979093, 27.6662081480001],
                [86.0239810290001, 27.6662781420001],
                [86.0240193880001, 27.6667702230001],
                [86.024160297, 27.6672449960001],
                [86.024339582, 27.667456042],
                [86.0243655780001, 27.667485603],
                [86.024651035, 27.667695151],
                [86.0249939180001, 27.6681397420001],
                [86.0251746920001, 27.6688358710001],
                [86.0251599380001, 27.669131344],
                [86.0246984620001, 27.6708427460001],
                [86.0241615090001, 27.67195345],
                [86.0238686040001, 27.6722627300001],
                [86.0246688020001, 27.672685705],
                [86.024893331, 27.672867818],
                [86.025708889, 27.6738797410001],
                [86.0262710540001, 27.6744297010001],
                [86.0273774890001, 27.6765107840001],
                [86.0275460810001, 27.676996993],
                [86.0281569150001, 27.6780955310001],
                [86.028763827, 27.6784538840001],
                [86.029432162, 27.679142271],
                [86.0308134250001, 27.6817170640001],
                [86.0309734570001, 27.682178526],
                [86.0309874450001, 27.6826410470001],
                [86.0308570780001, 27.6830868170001],
                [86.0305962880001, 27.6836355140001],
                [86.0288986050001, 27.685096855],
                [86.0282316230001, 27.685464435],
                [86.02800546, 27.6856839120001],
                [86.028167501, 27.6857031010001],
                [86.02904913, 27.6859552180001],
                [86.0313995700001, 27.685942321],
                [86.032705207, 27.68614545],
                [86.033385556, 27.686089279],
                [86.035367303, 27.685703722],
                [86.035682577, 27.6855931750001],
                [86.035733695, 27.6855844890001],
                [86.036018411, 27.6854105100001],
                [86.0361853350001, 27.68501911],
                [86.037200344, 27.684189201],
                [86.037661105, 27.6837749960001]
              ]
            }
          },
          {
            type: "Feature",
            properties: { party: "Dokha2" },
            geometry: {
              type: "LineString",
              coordinates: [
                [86.0277420950001, 27.739473596],
                [86.0277847180001, 27.739318235],
                [86.0277567910001, 27.738999951],
                [86.0273641050001, 27.7383925610001],
                [86.027210573, 27.737913226],
                [86.027043355, 27.7374608270001],
                [86.0269971850001, 27.736948372],
                [86.0270082530001, 27.7366303380001],
                [86.0268264050001, 27.736448539],
                [86.025711937, 27.736000747],
                [86.0254270400001, 27.735845381],
                [86.025006293, 27.735368604],
                [86.0250349870001, 27.734888273],
                [86.0251925410001, 27.7343140770001],
                [86.0252835410001, 27.7337687520001],
                [86.025418362, 27.7325491760001],
                [86.0255172920001, 27.732294951],
                [86.025113424, 27.731522763],
                [86.0249313350001, 27.7309439360001],
                [86.024853487, 27.730171817],
                [86.024983235, 27.7294440090001],
                [86.0248397, 27.7290527920001],
                [86.024696163, 27.7287652520001],
                [86.024122316, 27.728041433],
                [86.0234861120001, 27.7269157100001],
                [86.0233393890001, 27.726562739],
                [86.0232050380001, 27.726241391],
                [86.023110687, 27.725706062],
                [86.0218707050001, 27.7237345300001],
                [86.0215903270001, 27.722656371],
                [86.0211766450001, 27.7220669170001],
                [86.0207165890001, 27.72166657],
                [86.020284587, 27.721496578],
                [86.019923389, 27.721275217],
                [86.0196645570001, 27.720878498],
                [86.0194910820001, 27.7207531940001],
                [86.0187255600001, 27.720637191],
                [86.0184283520001, 27.7205245710001],
                [86.0180239750001, 27.7201742830001],
                [86.0177817050001, 27.7196920100001],
                [86.0173802680001, 27.719228919],
                [86.017178868, 27.718679278],
                [86.0162325860001, 27.7172715130001],
                [86.015960508, 27.716728119],
                [86.015408161, 27.7159165570001],
                [86.0149928490001, 27.715484901],
                [86.0148989940001, 27.7151345560001],
                [86.014916875, 27.714762465],
                [86.015235977, 27.7138533020001],
                [86.0154029730001, 27.713003943],
                [86.015309706, 27.711462392],
                [86.0156960800001, 27.709154917],
                [86.015983061, 27.708247753],
                [86.0159970650001, 27.7079612790001],
                [86.0158636610001, 27.7072181560001],
                [86.0158647870001, 27.7071979080001],
                [86.0158849800001, 27.706785132],
                [86.0161201600001, 27.7056857970001],
                [86.0162079740001, 27.7050772910001],
                [86.015753434, 27.7039032000001],
                [86.0157410690001, 27.7035105480001],
                [86.0159162, 27.702142361],
                [86.015728182, 27.701240813],
                [86.0157533770001, 27.70072436],
                [86.016142845, 27.699836008],
                [86.0161809300001, 27.698552561],
                [86.0157355370001, 27.6962865690001],
                [86.015759544, 27.695797176],
                [86.0158279780001, 27.6955494490001],
                [86.016116014, 27.6950145640001],
                [86.016205578, 27.6943564370001],
                [86.0161790290001, 27.6939276000001],
                [86.0158769500001, 27.6935915620001],
                [86.015485819, 27.6933970110001],
                [86.0145681970001, 27.693056588],
                [86.014178567, 27.692769545],
                [86.0138949940001, 27.692065853],
                [86.013860385, 27.6915084020001],
                [86.0140896780001, 27.6910271990001],
                [86.014153929, 27.6905764900001],
                [86.0140965670001, 27.6903256370001],
                [86.0138347970001, 27.6899537910001],
                [86.0138206760001, 27.6898296140001],
                [86.013940479, 27.689577764],
                [86.0145502170001, 27.6892753010001],
                [86.015166327, 27.689139761],
                [86.0153954230001, 27.689064709],
                [86.0171894490001, 27.6884705420001],
                [86.0174870390001, 27.6883192490001],
                [86.0181388930001, 27.6881862710001],
                [86.020405473, 27.688028574],
                [86.021115195, 27.687916283],
                [86.0223556010001, 27.687577585],
                [86.0231804420001, 27.6870238980001],
                [86.0236913790001, 27.686819987],
                [86.0244437270001, 27.6863605410001],
                [86.0247355660001, 27.6862452440001],
                [86.025467221, 27.686191761],
                [86.0260938930001, 27.6859750410001],
                [86.0265070930001, 27.6857794410001],
                [86.0270102790001, 27.6856656470001],
                [86.0275447100001, 27.6856287840001],
                [86.02800546, 27.6856839120001],
                [86.0282316230001, 27.685464435],
                [86.0288986050001, 27.685096855],
                [86.0305962880001, 27.6836355140001],
                [86.0308570780001, 27.6830868170001],
                [86.0309874450001, 27.6826410470001],
                [86.0309734570001, 27.682178526],
                [86.0308134250001, 27.6817170640001],
                [86.029432162, 27.679142271],
                [86.028763827, 27.6784538840001],
                [86.0281569150001, 27.6780955310001],
                [86.0275460810001, 27.676996993],
                [86.0273774890001, 27.6765107840001],
                [86.0262710540001, 27.6744297010001],
                [86.025708889, 27.6738797410001],
                [86.024893331, 27.672867818],
                [86.0246688020001, 27.672685705],
                [86.0238686040001, 27.6722627300001],
                [86.0241615090001, 27.67195345],
                [86.0246984620001, 27.6708427460001],
                [86.0251599380001, 27.669131344],
                [86.0251746920001, 27.6688358710001],
                [86.0249939180001, 27.6681397420001],
                [86.024651035, 27.667695151],
                [86.0243655780001, 27.667485603],
                [86.024339582, 27.667456042],
                [86.024160297, 27.6672449960001],
                [86.0240193880001, 27.6667702230001],
                [86.0239810290001, 27.6662781420001],
                [86.023979093, 27.6662081480001],
                [86.0239662920001, 27.6657636250001],
                [86.024142151, 27.665397098],
                [86.024540173, 27.664874276],
                [86.0245844230001, 27.6646038640001],
                [86.0245735620001, 27.6640081670001],
                [86.0243765340001, 27.6636141990001],
                [86.024319926, 27.6631400530001],
                [86.02436749, 27.6627793980001],
                [86.024666021, 27.6624047490001],
                [86.025026917, 27.6621365290001],
                [86.0253892470001, 27.6619924230001],
                [86.0257399530001, 27.661913686],
                [86.0262871310001, 27.661892698],
                [86.0280180400001, 27.6613589],
                [86.028942165, 27.661509777],
                [86.0297009970001, 27.661770016],
                [86.0297949220001, 27.661781954],
                [86.030095132, 27.661822393],
                [86.0302518610001, 27.66182802],
                [86.0305618820001, 27.6618392120001],
                [86.032069999, 27.6617121240001],
                [86.0324340770001, 27.6616943820001],
                [86.032552502, 27.6615439600001],
                [86.0323647150001, 27.6614795250001],
                [86.03217643, 27.661385717],
                [86.03212675, 27.6613086610001],
                [86.0321223340001, 27.6603339980001],
                [86.0320879050001, 27.660178199],
                [86.032054224, 27.660049272],
                [86.0316272320001, 27.6594055100001],
                [86.0315789970001, 27.658958549],
                [86.0313739760001, 27.6582779820001],
                [86.031477346, 27.6579064540001],
                [86.0314422320001, 27.6575813580001],
                [86.031179594, 27.6569094760001],
                [86.0307288540001, 27.6562655880001],
                [86.030140385, 27.6555102080001],
                [86.029851808, 27.655052432],
                [86.029712578, 27.6549679990001],
                [86.029649399, 27.6549495],
                [86.0295257920001, 27.6548899410001],
                [86.0293397570001, 27.6547556370001],
                [86.028859949, 27.654576207],
                [86.0286562260001, 27.6545522060001],
                [86.0267141780001, 27.654157334],
                [86.0262331850001, 27.653860665],
                [86.0259543550001, 27.6534480720001],
                [86.02533995, 27.652766935],
                [86.023913156, 27.651953733],
                [86.023770428, 27.6517677480001],
                [86.023781494, 27.651551205],
                [86.0238664870001, 27.6513532860001],
                [86.0242209470001, 27.651064755],
                [86.0246979510001, 27.650844783],
                [86.0249184910001, 27.6505485010001],
                [86.024965551, 27.6503502690001],
                [86.024792145, 27.6499723000001],
                [86.024525585, 27.6485332990001],
                [86.0240757940001, 27.64740433],
                [86.0228443060001, 27.6485665800001],
                [86.022399663, 27.6492087030001],
                [86.022140746, 27.6498678900001],
                [86.0223296490001, 27.6503159150001],
                [86.022277086, 27.6506359480001],
                [86.0219130660001, 27.650933416],
                [86.0212176490001, 27.6513210570001],
                [86.021011984, 27.651513661],
                [86.0204662290001, 27.652200153],
                [86.0193639280001, 27.6526096600001],
                [86.018859235, 27.653023552],
                [86.0184393470001, 27.653295771],
                [86.017974968, 27.653448187],
                [86.01729462, 27.6534208030001],
                [86.0165427150001, 27.6536388960001],
                [86.015978907, 27.653966482],
                [86.0154747780001, 27.6543486890001],
                [86.0152639230001, 27.6546855930001],
                [86.015279272, 27.655804743],
                [86.0151771500001, 27.656284574],
                [86.0148843690001, 27.6566163520001],
                [86.0147093900001, 27.6567369630001],
                [86.0140112220001, 27.657214783],
                [86.013664766, 27.657277709],
                [86.0125670370001, 27.6572112590001],
                [86.012292947, 27.65726125],
                [86.012073098, 27.657367924],
                [86.011343373, 27.6577959360001],
                [86.009891413, 27.6590038040001],
                [86.0091079420001, 27.6595577410001],
                [86.008368589, 27.660168362],
                [86.007825891, 27.661091707],
                [86.007646525, 27.661737959],
                [86.0072250650001, 27.6624997590001],
                [86.0067254900001, 27.6632090620001],
                [86.006306851, 27.663551274],
                [86.0059031500001, 27.663710816],
                [86.0049850080001, 27.6643313700001],
                [86.0047060420001, 27.6644647910001],
                [86.004288655, 27.664667078],
                [86.0036126670001, 27.6653345070001],
                [86.0032948300001, 27.665526234],
                [86.002938752, 27.665534978],
                [86.001643926, 27.6653339110001],
                [86.00110656, 27.665341276],
                [86.0007728510001, 27.6654358870001],
                [86.000237161, 27.6659373960001],
                [86.0001900980001, 27.666266428],
                [86.0003350480001, 27.667736174],
                [86.0001793710001, 27.668177257],
                [86.000021889, 27.6683182420001],
                [85.999878095, 27.668353299],
                [85.9997638550001, 27.6685871510001],
                [85.999549314, 27.6688743720001],
                [85.999640109, 27.6691570360001],
                [85.9991038160001, 27.6715648740001],
                [85.998888483, 27.6738194180001],
                [85.9984737930001, 27.676534981],
                [85.997661372, 27.6807547780001],
                [85.9972116040001, 27.6814418340001],
                [85.9965174870001, 27.682420484],
                [85.9951519860001, 27.685065975],
                [85.994798512, 27.685995136],
                [85.994352716, 27.6880020740001],
                [85.9942878360001, 27.6886175170001],
                [85.9943502890001, 27.6904544170001],
                [85.9944124560001, 27.6910278660001],
                [85.99452761, 27.6919062900001],
                [85.99481524, 27.692817588],
                [85.9956743480001, 27.6941729810001],
                [85.9960732870001, 27.6945819520001],
                [85.996345429, 27.695035166],
                [85.996910384, 27.696553039],
                [85.9972824770001, 27.6986877300001],
                [85.9973652890001, 27.7011383870001],
                [85.9976310970001, 27.7026631300001],
                [85.9976155190001, 27.7034684330001],
                [85.9976596330001, 27.7037350350001],
                [85.9976608200001, 27.703712474],
                [85.998791537, 27.7070889410001],
                [85.9988547, 27.7078384380001],
                [85.9986187690001, 27.709005329],
                [85.9985208790001, 27.710286088],
                [85.99838413, 27.7111040130001],
                [85.9983384920001, 27.712010681],
                [85.998199239, 27.7129932780001],
                [85.998003435, 27.7137882660001],
                [85.9980342200001, 27.7148962300001],
                [85.997966465, 27.715545483],
                [85.9980298280001, 27.715719656],
                [85.9982603540001, 27.716019133],
                [85.9988318800001, 27.7171399800001],
                [85.999068961, 27.717728306],
                [85.9991927470001, 27.7181893920001],
                [85.9992655980001, 27.7188916430001],
                [85.999364519, 27.719020883],
                [85.9995303470001, 27.7202268410001],
                [85.9995783140001, 27.721461231],
                [86.00012359, 27.722757874],
                [86.000325806, 27.723106784],
                [86.000430151, 27.7238272210001],
                [86.0006551750001, 27.72427781],
                [86.000763588, 27.7247500820001],
                [86.001372104, 27.726108158],
                [86.0020509120001, 27.7279923130001],
                [86.0023363660001, 27.728350849],
                [86.0028279690001, 27.729281649],
                [86.0028917050001, 27.7295595620001],
                [86.0027489060001, 27.7297886640001],
                [86.002778273, 27.730003208],
                [86.0028826930001, 27.7301573840001],
                [86.0033989900001, 27.7306167890001],
                [86.003214819, 27.730994502],
                [86.0032402470001, 27.7312721650001],
                [86.0039753170001, 27.732270151],
                [86.0040448610001, 27.7326723030001],
                [86.0039851710001, 27.7331748820001],
                [86.004252492, 27.734063432],
                [86.0044115530001, 27.736088254],
                [86.0043709280001, 27.7363022960001],
                [86.0044617, 27.737649865],
                [86.00487701, 27.738131079],
                [86.005219884, 27.7389750720001],
                [86.0053233030001, 27.739242049],
                [86.0055693240001, 27.739654392],
                [86.0057779730001, 27.7401702870001],
                [86.006480464, 27.742111],
                [86.0067000430001, 27.7429202440001],
                [86.006752965, 27.74330646],
                [86.0069653610001, 27.743932907],
                [86.0070430280001, 27.7444207400001],
                [86.007182936, 27.7449316340001],
                [86.0083437870001, 27.7447346710001],
                [86.0098329680001, 27.7446303310001],
                [86.010561125, 27.7445159160001],
                [86.011164991, 27.7441276490001],
                [86.0113613410001, 27.7440771560001],
                [86.0115291930001, 27.7441618390001],
                [86.0122708950001, 27.744719864],
                [86.0129388080001, 27.7446794420001],
                [86.014127348, 27.744261624],
                [86.0145203600001, 27.7442012610001],
                [86.0156022910001, 27.743692325],
                [86.0160169320001, 27.74330043],
                [86.016501621, 27.7431865730001],
                [86.0174225630001, 27.7431660280001],
                [86.018565542, 27.742957566],
                [86.0200245510001, 27.7423137110001],
                [86.021966568, 27.741224216],
                [86.0223751400001, 27.741179727],
                [86.0227367830001, 27.741035621],
                [86.0231331130001, 27.740711219],
                [86.0234452610001, 27.740639044],
                [86.0242641510001, 27.74075536],
                [86.025466737, 27.7410051700001],
                [86.0258098790001, 27.741016675],
                [86.026355374, 27.7407994540001],
                [86.0267050840001, 27.74054017],
                [86.0276544800001, 27.739565461],
                [86.0277420950001, 27.739473596]
              ]
            }
          },
          {
            type: "Feature",
            properties: { party: "Dokha2" },
            geometry: {
              type: "LineString",
              coordinates: [
                [86.007182936, 27.7449316340001],
                [86.0070430280001, 27.7444207400001],
                [86.0069653610001, 27.743932907],
                [86.006752965, 27.74330646],
                [86.0067000430001, 27.7429202440001],
                [86.006480464, 27.742111],
                [86.0057779730001, 27.7401702870001],
                [86.0055693240001, 27.739654392],
                [86.0053233030001, 27.739242049],
                [86.005219884, 27.7389750720001],
                [86.00487701, 27.738131079],
                [86.0044617, 27.737649865],
                [86.0043709280001, 27.7363022960001],
                [86.0044115530001, 27.736088254],
                [86.004252492, 27.734063432],
                [86.0039851710001, 27.7331748820001],
                [86.0040448610001, 27.7326723030001],
                [86.0039753170001, 27.732270151],
                [86.0032402470001, 27.7312721650001],
                [86.003214819, 27.730994502],
                [86.0033989900001, 27.7306167890001],
                [86.0028826930001, 27.7301573840001],
                [86.002778273, 27.730003208],
                [86.0027489060001, 27.7297886640001],
                [86.0028917050001, 27.7295595620001],
                [86.0028279690001, 27.729281649],
                [86.0023363660001, 27.728350849],
                [86.0020509120001, 27.7279923130001],
                [86.001372104, 27.726108158],
                [86.000763588, 27.7247500820001],
                [86.0006551750001, 27.72427781],
                [86.000430151, 27.7238272210001],
                [86.000325806, 27.723106784],
                [86.00012359, 27.722757874],
                [85.9995783140001, 27.721461231],
                [85.9995303470001, 27.7202268410001],
                [85.999364519, 27.719020883],
                [85.9992655980001, 27.7188916430001],
                [85.9991927470001, 27.7181893920001],
                [85.999068961, 27.717728306],
                [85.9988318800001, 27.7171399800001],
                [85.9982603540001, 27.716019133],
                [85.9980298280001, 27.715719656],
                [85.997966465, 27.715545483],
                [85.9980342200001, 27.7148962300001],
                [85.998003435, 27.7137882660001],
                [85.998199239, 27.7129932780001],
                [85.9983384920001, 27.712010681],
                [85.99838413, 27.7111040130001],
                [85.9985208790001, 27.710286088],
                [85.9986187690001, 27.709005329],
                [85.9988547, 27.7078384380001],
                [85.998791537, 27.7070889410001],
                [85.9976608200001, 27.703712474],
                [85.9976596330001, 27.7037350350001],
                [85.9976155190001, 27.7034684330001],
                [85.9976310970001, 27.7026631300001],
                [85.9973652890001, 27.7011383870001],
                [85.9972824770001, 27.6986877300001],
                [85.996910384, 27.696553039],
                [85.996345429, 27.695035166],
                [85.9960732870001, 27.6945819520001],
                [85.9956743480001, 27.6941729810001],
                [85.99481524, 27.692817588],
                [85.99452761, 27.6919062900001],
                [85.9944124560001, 27.6910278660001],
                [85.9943502890001, 27.6904544170001],
                [85.9942878360001, 27.6886175170001],
                [85.994352716, 27.6880020740001],
                [85.994798512, 27.685995136],
                [85.9951519860001, 27.685065975],
                [85.9965174870001, 27.682420484],
                [85.9972116040001, 27.6814418340001],
                [85.997661372, 27.6807547780001],
                [85.9984737930001, 27.676534981],
                [85.998888483, 27.6738194180001],
                [85.9991038160001, 27.6715648740001],
                [85.999640109, 27.6691570360001],
                [85.999549314, 27.6688743720001],
                [85.9997638550001, 27.6685871510001],
                [85.999878095, 27.668353299],
                [85.999723927, 27.6683815440001],
                [85.9989008410001, 27.668709501],
                [85.9982663530001, 27.6691313270001],
                [85.9979518260001, 27.669410984],
                [85.9976617400001, 27.669334486],
                [85.9968651450001, 27.6699536670001],
                [85.9959858730001, 27.6705158520001],
                [85.9954483070001, 27.6710669190001],
                [85.9948732430001, 27.671773409],
                [85.994507716, 27.6723596],
                [85.9940920760001, 27.672715311],
                [85.9935868250001, 27.672959781],
                [85.993244932, 27.672970837],
                [85.992726691, 27.672831715],
                [85.9908168960001, 27.672044004],
                [85.9901251790001, 27.671833636],
                [85.9887930450001, 27.671679564],
                [85.9872538720001, 27.6717224720001],
                [85.9864027310001, 27.6718606330001],
                [85.985377677, 27.6719275470001],
                [85.984183145, 27.672038392],
                [85.9827282740001, 27.672041242],
                [85.982470371, 27.672009991],
                [85.9822072840001, 27.67182769],
                [85.9817044690001, 27.672146528],
                [85.981497367, 27.672345819],
                [85.9809558040001, 27.6727817710001],
                [85.980200644, 27.6732251560001],
                [85.97977932, 27.673429631],
                [85.9792328230001, 27.673712096],
                [85.978983913, 27.673949384],
                [85.977967139, 27.675512102],
                [85.977490366, 27.6766005590001],
                [85.9772093920001, 27.677144443],
                [85.9768517960001, 27.677960801],
                [85.9765450020001, 27.679003015],
                [85.9763814560001, 27.6792387420001],
                [85.976191279, 27.679934426],
                [85.976213387, 27.680584368],
                [85.9762287500001, 27.6810447030001],
                [85.9761967480001, 27.6813490500001],
                [85.9761317430001, 27.6819689930001],
                [85.9759104380001, 27.6830096460001],
                [85.9758521780001, 27.683816886],
                [85.975700374, 27.684395581],
                [85.975724106, 27.685086083],
                [85.9757745320001, 27.685314313],
                [85.9757071540001, 27.685853014],
                [85.9750776400001, 27.6874817950001],
                [85.9743546430001, 27.6888819070001],
                [85.9737833250001, 27.6897033870001],
                [85.9726635750001, 27.69077121],
                [85.9720107040001, 27.6917093650001],
                [85.971369198, 27.6929928640001],
                [85.971346064, 27.693568],
                [85.9715478310001, 27.6944832340001],
                [85.9715788070001, 27.695401652],
                [85.9717378290001, 27.6963165110001],
                [85.971881399, 27.698036734],
                [85.9720872240001, 27.69906702],
                [85.972232132, 27.6995599160001],
                [85.9723321100001, 27.6999802550001],
                [85.9725650670001, 27.7005482710001],
                [85.972778513, 27.7018064120001],
                [85.9731515070001, 27.702716149],
                [85.9737442890001, 27.706339273],
                [85.973844017, 27.7067596740001],
                [85.974566871, 27.7091519100001],
                [85.9746366570001, 27.7099555890001],
                [85.9744965310001, 27.710879504],
                [85.9740297500001, 27.712272559],
                [85.9738274570001, 27.712623024],
                [85.973714774, 27.713086918],
                [85.9740643210001, 27.7145717290001],
                [85.9750201950001, 27.7175342300001],
                [85.9752532140001, 27.718102308],
                [85.975478803, 27.718440032],
                [85.975762311, 27.719236278],
                [85.975851457, 27.7206131560001],
                [85.9757387120001, 27.7210770500001],
                [85.9752953780001, 27.7218949690001],
                [85.9744711540001, 27.7228386840001],
                [85.9740625700001, 27.7234244370001],
                [85.9739462030001, 27.7237732790001],
                [85.9739575070001, 27.7241185600001],
                [85.9742957500001, 27.7252580890001],
                [85.9753608990001, 27.7263781940001],
                [85.9759516120001, 27.727395551],
                [85.976534643, 27.7281827400001],
                [85.976860958, 27.728979237],
                [85.9771213320001, 27.730350618],
                [85.9772027700001, 27.732762904],
                [85.97695758, 27.734380942],
                [85.977247521, 27.735368921],
                [85.977633907, 27.7353966750001],
                [85.9786653940001, 27.7355193070001],
                [85.9793134920001, 27.7356933020001],
                [85.9798356680001, 27.7359453510001],
                [85.9804837040001, 27.7361193460001],
                [85.9810059420001, 27.7363713330001],
                [85.9826947700001, 27.736936498],
                [85.983520529, 27.7372967920001],
                [85.9853516410001, 27.738278048],
                [85.9865182940001, 27.7385911650001],
                [85.987298497, 27.7388744020001],
                [85.988480575, 27.7396455410001],
                [85.9896574690001, 27.7402633180001],
                [85.990694202, 27.7405393110001],
                [85.9914692840001, 27.7406713120001],
                [85.991986464, 27.740772124],
                [85.993933133, 27.7413661040001],
                [85.9944594300001, 27.741735455],
                [85.995242444, 27.7420953110001],
                [85.9958903550001, 27.742269181],
                [85.996915722, 27.742199955],
                [85.9976600680001, 27.7418714340001],
                [85.9978684000001, 27.7426264330001],
                [85.9981610440001, 27.7428541670001],
                [85.998414133, 27.742980098],
                [85.9994765490001, 27.743294588],
                [86.0005810900001, 27.743336415],
                [86.0014624120001, 27.743284122],
                [86.0016713840001, 27.7433127480001],
                [86.0018178000001, 27.743408555],
                [86.0030741080001, 27.744683212],
                [86.003555851, 27.744966445],
                [86.0046146430001, 27.7452358130001],
                [86.0051507590001, 27.7452576960001],
                [86.005738185, 27.745106219],
                [86.0064314700001, 27.7450299880001],
                [86.006914033, 27.7449770630001],
                [86.007182936, 27.7449316340001]
              ]
            }
          },
          {
            type: "Feature",
            properties: { party: "ward 9" },
            geometry: {
              type: "LineString",
              coordinates: [
                [85.977247521, 27.735368921],
                [85.97695758, 27.734380942],
                [85.9772027700001, 27.732762904],
                [85.9771213320001, 27.730350618],
                [85.976860958, 27.728979237],
                [85.976534643, 27.7281827400001],
                [85.9759516120001, 27.727395551],
                [85.9753608990001, 27.7263781940001],
                [85.9742957500001, 27.7252580890001],
                [85.9739575070001, 27.7241185600001],
                [85.9739462030001, 27.7237732790001],
                [85.9740625700001, 27.7234244370001],
                [85.9744711540001, 27.7228386840001],
                [85.9752953780001, 27.7218949690001],
                [85.9757387120001, 27.7210770500001],
                [85.975851457, 27.7206131560001],
                [85.975762311, 27.719236278],
                [85.975478803, 27.718440032],
                [85.9752532140001, 27.718102308],
                [85.9750201950001, 27.7175342300001],
                [85.9740643210001, 27.7145717290001],
                [85.973714774, 27.713086918],
                [85.9738274570001, 27.712623024],
                [85.9740297500001, 27.712272559],
                [85.9744965310001, 27.710879504],
                [85.9746366570001, 27.7099555890001],
                [85.974566871, 27.7091519100001],
                [85.973844017, 27.7067596740001],
                [85.9737442890001, 27.706339273],
                [85.9731515070001, 27.702716149],
                [85.972778513, 27.7018064120001],
                [85.9725650670001, 27.7005482710001],
                [85.9723321100001, 27.6999802550001],
                [85.972232132, 27.6995599160001],
                [85.9720872240001, 27.69906702],
                [85.971881399, 27.698036734],
                [85.9717378290001, 27.6963165110001],
                [85.9715788070001, 27.695401652],
                [85.9715478310001, 27.6944832340001],
                [85.971346064, 27.693568],
                [85.971369198, 27.6929928640001],
                [85.9720107040001, 27.6917093650001],
                [85.9726635750001, 27.69077121],
                [85.9737833250001, 27.6897033870001],
                [85.9743546430001, 27.6888819070001],
                [85.9750776400001, 27.6874817950001],
                [85.9757071540001, 27.685853014],
                [85.9757745320001, 27.685314313],
                [85.975724106, 27.685086083],
                [85.975700374, 27.684395581],
                [85.9758521780001, 27.683816886],
                [85.9759104380001, 27.6830096460001],
                [85.9761317430001, 27.6819689930001],
                [85.9761967480001, 27.6813490500001],
                [85.9762287500001, 27.6810447030001],
                [85.976213387, 27.680584368],
                [85.976191279, 27.679934426],
                [85.9763814560001, 27.6792387420001],
                [85.9765450020001, 27.679003015],
                [85.9768517960001, 27.677960801],
                [85.9772093920001, 27.677144443],
                [85.977490366, 27.6766005590001],
                [85.977967139, 27.675512102],
                [85.978983913, 27.673949384],
                [85.9792328230001, 27.673712096],
                [85.97977932, 27.673429631],
                [85.980200644, 27.6732251560001],
                [85.9809558040001, 27.6727817710001],
                [85.981497367, 27.672345819],
                [85.9817044690001, 27.672146528],
                [85.9822072840001, 27.67182769],
                [85.9820232540001, 27.6714472210001],
                [85.9816952520001, 27.670614603],
                [85.980688399, 27.6699528950001],
                [85.9806314780001, 27.6695328690001],
                [85.9806130540001, 27.6689958540001],
                [85.9804576540001, 27.668193736],
                [85.9797500120001, 27.6675252210001],
                [85.9793924430001, 27.6670736930001],
                [85.979252407, 27.666731971],
                [85.979193987, 27.6662735740001],
                [85.9791031890001, 27.66612396],
                [85.9788388540001, 27.665900913],
                [85.9781520700001, 27.665843969],
                [85.977631144, 27.6656281050001],
                [85.976840698, 27.665037957],
                [85.9759117500001, 27.6654078460001],
                [85.9752277140001, 27.6654275820001],
                [85.9740203160001, 27.665154649],
                [85.9727334880001, 27.6650751970001],
                [85.9723449160001, 27.664973074],
                [85.9718564290001, 27.664448301],
                [85.9716176630001, 27.663726861],
                [85.971310583, 27.6635057510001],
                [85.9711382940001, 27.6634728760001],
                [85.9706757870001, 27.6637153460001],
                [85.970547492, 27.663718906],
                [85.97041676, 27.6636457230001],
                [85.9698801580001, 27.6629717120001],
                [85.969355297, 27.66264067],
                [85.968660894, 27.662353497],
                [85.96800655, 27.661987643],
                [85.9666189320001, 27.661451668],
                [85.966269045, 27.6612324320001],
                [85.9647088880001, 27.660661331],
                [85.9642698280001, 27.6603286660001],
                [85.9637385340001, 27.6598080160001],
                [85.9630373860001, 27.6593289850001],
                [85.9618557460001, 27.658557596],
                [85.9615447310001, 27.658221371],
                [85.9615368, 27.6579912670001],
                [85.9616962860001, 27.657640488],
                [85.9616846070001, 27.657297456],
                [85.96146158, 27.6570341010001],
                [85.9609822730001, 27.6567799910001],
                [85.960026096, 27.6563465130001],
                [85.9592810170001, 27.655831047],
                [85.958705792, 27.6552717120001],
                [85.9584724600001, 27.654701447],
                [85.958371233, 27.654244986],
                [85.958126222, 27.653331688],
                [85.9579240190001, 27.652416454],
                [85.957733368, 27.6518465020001],
                [85.957717755, 27.6513883550001],
                [85.9577916900001, 27.6510415130001],
                [85.9577565290001, 27.65000573],
                [85.957698109, 27.64954727],
                [85.9574079540001, 27.649824865],
                [85.9564223080001, 27.65104024],
                [85.9556763910001, 27.651752038],
                [85.9555596490001, 27.6521008180001],
                [85.9557114890001, 27.652785572],
                [85.95559481, 27.653134413],
                [85.955229783, 27.6537204800001],
                [85.9543151990001, 27.654512206],
                [85.9532234550001, 27.6551176340001],
                [85.9528569290001, 27.655665329],
                [85.952626256, 27.656439693],
                [85.9527429080001, 27.65735655],
                [85.952794833, 27.657623152],
                [85.9529414900001, 27.6581544200001],
                [85.9529338440001, 27.6591898900001],
                [85.9530310120001, 27.6595335480001],
                [85.9537228890001, 27.6610097400001],
                [85.9538203690001, 27.661351086],
                [85.95379711, 27.6619284720001],
                [85.9535248780001, 27.6627408950001],
                [85.9524642360001, 27.6642647400001],
                [85.9523050620001, 27.664615456],
                [85.952239058, 27.6651925910001],
                [85.9515846250001, 27.6660922500001],
                [85.950496753, 27.6668127290001],
                [85.9500081770001, 27.66755578],
                [85.9492127080001, 27.6680754070001],
                [85.9489687320001, 27.668466118],
                [85.9480164620001, 27.6694132050001],
                [85.947375234, 27.66943088],
                [85.9462090810001, 27.6691197010001],
                [85.9455678530001, 27.669137376],
                [85.9444045110001, 27.66890069],
                [85.9429948820001, 27.66897791],
                [85.941964645, 27.668893337],
                [85.9413297870001, 27.669102808],
                [85.9405602, 27.6691240430001],
                [85.9399149120001, 27.6690266030001],
                [85.939150634, 27.6692012],
                [85.9393371620001, 27.6696561000001],
                [85.9392723450001, 27.6702715440001],
                [85.939330328, 27.670729941],
                [85.9392226410001, 27.6713472590001],
                [85.9392057520001, 27.6721141900001],
                [85.9389750160001, 27.6728862420001],
                [85.9386249780001, 27.6739326430001],
                [85.9384214980001, 27.674244737],
                [85.938029901, 27.67532907],
                [85.93823173, 27.6762443040001],
                [85.9382472190001, 27.6767047010001],
                [85.938182339, 27.6773200820001],
                [85.937495341, 27.678526338],
                [85.9365203740001, 27.680089057],
                [85.9362792090001, 27.680554199],
                [85.9358321280001, 27.6812569410001],
                [85.93567133, 27.681569285],
                [85.9355960830001, 27.6818777560001],
                [85.9355894990001, 27.6829515970001],
                [85.935326198, 27.684032496],
                [85.9351654000001, 27.684344841],
                [85.93481661, 27.6854273000001],
                [85.934301777, 27.6866686810001],
                [85.9342029580001, 27.687554537],
                [85.934432294, 27.6880075010001],
                [85.9349220300001, 27.688568521],
                [85.936111414, 27.6895703270001],
                [85.9369157240001, 27.690584937],
                [85.9373575320001, 27.6909944080001],
                [85.9378785220001, 27.6912082100001],
                [85.938273526, 27.6915045650001],
                [85.9384174350001, 27.6919614010001],
                [85.938785183, 27.6927177150001],
                [85.9388962770001, 27.6934810860001],
                [85.938857253, 27.6948637740001],
                [85.9387068220001, 27.695480779],
                [85.938727494, 27.6960945370001],
                [85.9388334670001, 27.6967044840001],
                [85.9391635910001, 27.69761622],
                [85.9391739580001, 27.697923131],
                [85.9390663340001, 27.698538136],
                [85.9388302900001, 27.699159077],
                [85.937612061, 27.6998445570001],
                [85.937408518, 27.7001565890001],
                [85.9371348500001, 27.700932827],
                [85.9367040690001, 27.702132025],
                [85.9365083960001, 27.702674223],
                [85.9363682700001, 27.703598076],
                [85.9363885670001, 27.7042118960001],
                [85.9364844850001, 27.7045171830001],
                [85.9363819820001, 27.7052857370001],
                [85.9360382510001, 27.706521621],
                [85.936031604, 27.707595462],
                [85.9362710560001, 27.708357585],
                [85.9363345350001, 27.7089694690001],
                [85.9365260590001, 27.709575543],
                [85.936760641, 27.7101842430001],
                [85.9373460450001, 27.7110483000001],
                [85.9381366160001, 27.7116408860001],
                [85.9386601030001, 27.7119336810001],
                [85.9395699140001, 27.7122519800001],
                [85.9402166390001, 27.712387791],
                [85.9409980290001, 27.7127119630001],
                [85.9423085270001, 27.713479916],
                [85.943232327, 27.7142201780001],
                [85.9435809650001, 27.7144033550001],
                [85.94472798, 27.7154070340001],
                [85.9452087230001, 27.7156994540001],
                [85.9457374560001, 27.716145673],
                [85.9461380820001, 27.71659314],
                [85.9467287950001, 27.717612871],
                [85.9473103270001, 27.718364063],
                [85.947485489, 27.718473681],
                [85.947666709, 27.7187750950001],
                [85.948603124, 27.7198966980001],
                [85.9492650870001, 27.7204927810001],
                [85.9498415610001, 27.7210882370001],
                [85.9500175970001, 27.7212362270001],
                [85.9505487660001, 27.721759127],
                [85.950942771, 27.722014798],
                [85.9514183300001, 27.722156043],
                [85.9518575150001, 27.722488771],
                [85.9523312360001, 27.7238549690001],
                [85.95287527, 27.724759272],
                [85.9529281320001, 27.725064246],
                [85.952852948, 27.725372717],
                [85.952455855, 27.726303752],
                [85.9524814600001, 27.727068746],
                [85.9532479950001, 27.7294997920001],
                [85.9546326510001, 27.7312248480001],
                [85.955608376, 27.7322315870001],
                [85.9558700260001, 27.732377953],
                [85.9571670340001, 27.732762253],
                [85.9574730530001, 27.7329450550001],
                [85.9586636860001, 27.7339827320001],
                [85.959232603, 27.734350271],
                [85.9602003970001, 27.7351290930001],
                [85.960291194, 27.735278706],
                [85.9607305040001, 27.7356113710001],
                [85.9609545930001, 27.7359108480001],
                [85.9612367270001, 27.736671034],
                [85.9611872730001, 27.7377445000001],
                [85.9613942860001, 27.7388109080001],
                [85.961404653, 27.7391178180001],
                [85.9611087870001, 27.7405053140001],
                [85.9611240880001, 27.740965648],
                [85.96106689, 27.7418113210001],
                [85.9610369480001, 27.742194537],
                [85.963220973, 27.742208697],
                [85.9646012840001, 27.742514568],
                [85.9653710590001, 27.7424932710001],
                [85.9665169140001, 27.742190442],
                [85.967275322, 27.741823863],
                [85.9687971340001, 27.74124419],
                [85.9698148190001, 27.7409471710001],
                [85.9714582440001, 27.7401720190001],
                [85.9725901720001, 27.7394494770001],
                [85.9737682800001, 27.738840114],
                [85.9745214420001, 27.7383222980001],
                [85.975474149, 27.7373750240001],
                [85.9768743130001, 27.7357249460001],
                [85.977247521, 27.735368921]
              ]
            }
          }
        ]
      },
      options: {
        style(feature) {
          switch (feature.properties.party) {
            case "Republican":
              return { color: "#04316a" };
            case "Democrat":
              return { color: "#006fb9" };
            case "Dolakha2":
              return { color: "#000" };
            case "Dolakha-ward":
              return { color: "#3F3C3C" };
            default:
              return { color: "#228b22" };
          }
        }
      }
    };
  },
  mounted() {
    // this.coordinates=L.geoJSON(this.states);
  },
  methods: {
    add(e) {
      console.log(e.latlng);
      this.marker = L.latLng(e.latlng.lat, e.latlng.lng);
    }
  }
};
</script>
