<template>
  <div>
    <b-container fluid class="mx-0">
      <v-row>
        <v-col cols="12" md="4" class="elevation-2">
          <registration-info></registration-info>
        </v-col>
        <v-col cols="12" md="4" class="sober elevation-2">
          <application-status-card></application-status-card>
        </v-col>
        <v-col cols="12" md="4" class="sober elevation-2">
          <file-action-list></file-action-list>
        </v-col>
      </v-row>
    </b-container>
  </div>
</template>

<script>
import FileActionList from "../owner-info-action/FileActionList.vue";
import RegistrationInfo from "../owner-info-action/RegistrationInfo.vue";
import ApplicationStatusCard from "../owner-info-action/ApplicationStatusCard.vue";

export default {
  name: "Ownerinfocard",
  components: {
    RegistrationInfo,
    ApplicationStatusCard,
    FileActionList
  },
};
</script>

<style scoped>
.v-btn.v-btn--has-bg.border-primary {
  border: 1px solid var(--v-primary-base) !important;
}
</style>