<style scoped>
.multiline {
  white-space: pre-line;
}
table,
th,
td {
  border: 2px solid #dee0e3;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.no-border,
.no-border tr,
.no-border th,
.no-border td {
  border: none;
}
</style>
<template>
  <b-container fluid>
    <div class="def-padding px-lr">
      <div>
        <v-btn
          class="primary ma-1"
          :to="{
            name: 'application_tab',
            params: { id: this.$route.params.regId },
          }"
          >Back</v-btn
        >
      </div>
      <div class="con-center">
        <h3>
          "ग" वर्गका पिलरवाला भवनको नक्सा र डिजाईन सम्बन्धी परामर्शदाताले भर्ने
          फाराम
        </h3>
      </div>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th style="font-size: 22px">S.N</th>
              <th style="font-size: 22px; width: 40%">Criteria</th>
              <th style="font-size: 22px; width: 20%">Variable</th>
              <th style="font-size: 22px; width: 10%">Compliance</th>
              <th style="font-size: 22px">Comments</th>
            </tr>
          </thead>
          <tr v-for="(item, index) in checklist" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              <strong>{{ item.title }}</strong>
              {{ item.content }}
            </td>
            <td>
              <div v-for="gchild in item.checklist_variables" :key="gchild">
                <v-text-field
                  dense
                  :label="gchild.var_name"
                  v-model="gchild.value"
                ></v-text-field>
              </div>
            </td>
            <td>
              <table class="no-border">
                <tr>
                  <td>
                    <v-checkbox
                      v-model="item.compliance"
                      color="success"
                      value="c"
                      hide-details
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox
                      v-model="item.compliance"
                      color="red darken-3"
                      value="nc"
                      hide-details
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox
                      v-model="item.compliance"
                      color="black"
                      value="na"
                      hide-details
                    ></v-checkbox>
                  </td>
                </tr>
                <tr>
                  <td><strong>C</strong></td>
                  <td><strong>NC</strong></td>
                  <td><strong>NA</strong></td>
                </tr>
              </table>
            </td>
            <td>
              <div>
                <v-text-field v-model="item.comment"></v-text-field>
              </div>
            </td>
          </tr>
        </template>
      </v-simple-table>

      <v-btn color="primary" v-on:click="save" class="ma-3">Save</v-btn>

      <v-snackbar
        v-model="snackbar.show"
        :bottom="y === 'bottom'"
        :color="snackbar.color"
        :left="x === 'left'"
        :multi-line="mode === 'multi-line'"
        :right="x === 'right'"
        :timeout="3000"
        :top="y === 'top'"
        :vertical="mode === 'vertical'"
      >
        {{ successfulMessage }}
      </v-snackbar>

      <!-- <div class="eng">
        <table class="eng">
          <tr class="con-center">
            <th style="font-size:22px;">S.N</th>
            <th style="font-size:22px; width:40%;">Criteria</th>
            <th style="font-size:22px; width:20%;"></th>
            <th style="font-size:22px;width:10%">Compliance</th>
            <th style="font-size:22px;">Comments</th>
          </tr>
          <tr>
            <td>1</td>
            <td v-for="(item, index) in checklistMaster" :key="index">
              <strong>{{ item.title }}</strong
              ><br />
              {{ item.content }}
            </td>
            <td></td>
            <td><check-set var-name="username" :set-var="setvariable"></check-set></td>
            <td><textarea name="" id="" cols="25" rows="4"></textarea></td>
          </tr>
        </table>
      </div> -->
    </div>
  </b-container>
</template>

<script>
export default {
  name: "checklist",
  data() {
    return {
      isLoading: false,
      userRoleId: localStorage.getItem("auth_user_role_id"),
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      regId: this.$route.params.regId,
      checklistMaster: [], // For getting checklist mastser
      checklistVariableMaster: [], // For getting checklist var master
      checklist: [],
      newChecklist: {
        // checklist_master_id: "",
        // compliance: "",
        // comment: ""
        checklist: [],
      },
    };
  },
  created() {
    this.getChecklist();
    // this.getChecklistMasterAndVar(); moved to mounted see
  },
  mounted(){
    this.getChecklistMasterAndVar();
  },
  methods: {
    getChecklist() {
      const cUrl = `api/registration/${this.$route.params.regId}/checklist`;
      this.$axios
        .get(cUrl)
        .then((response) => {
          this.checklist = response.data.data;
        })
        .catch((error) => {
          console.log(`Checklist Exception Error: ${error}`);
        });
    },

    // Fn to create empty array to store new data
    getChecklistMasterAndVar() {
      // Get Checklist Master
      const mUrl = `api/checklist-master`;
      this.$axios
        .get(mUrl)
        .then((response) => {
          this.checklistMaster = response.data.data;
          this.checklistMaster.forEach((item) => {
            // Check if there is not already filled data for the given master data
            if (
              !this.checklist
                .map((e) => e.checklist_master_id)
                .includes(item.id)
            ) {
              this.checklist.push({
                id: 0,
                reg_id: this.regId,
                checklist_master_id: item.id,
                title: item.title,
                content: item.content,
                compliance: "",
                comment: "",
                checklist_variables: [],
              });
            }
          });
        })
        .catch((error) => {
          console.log(`Checklist Master Error: ${error}`);
        });
      // Get checklist variable master !element.id == item.checklist_id
      const vUrl = `api/checklist-variable-master`;
      this.$axios
        .get(vUrl)
        .then((response) => {
          this.checklistVariableMaster = response.data.data;
          this.checklist.forEach((element) => {
            this.checklistVariableMaster.forEach((item) => {
              // Check if there is not already a valued var and check if its belongs to that checklist id
              if (
                !element.checklist_variables
                  .map((e) => e.checklist_variable_master_id)
                  .includes(item.id) &&
                element.checklist_master_id == item.checklist_master_id
              ) {
                element.checklist_variables.push({
                  id: 0,
                  checklist_id: 0,
                  var_name: item.var_name,
                  checklist_variable_master_id: item.id,
                  value: "",
                });
              }
            });
          });
        })
        .catch((error) => {
          console.log(`Checklist Master Error: ${error}`);
        });
    },

    save() {
      if (
        this.checklist[0].id == null ||
        this.checklist[0].id == 0 ||
        this.checklist[0].id == undefined
      ) {
        const sUrl = `api/registration/${this.$route.params.regId}/checklist`;
        this.$axios
          .post(sUrl, { checklist: this.checklist })
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.checklist = response.data.data;
              this.successfulMessage = "Successfully saved checklist";
            }
          })
          .catch((error) => {
            console.log(`api error: ${error}`);
          });
      } else {
        const sUrl = `api/registration/${this.$route.params.regId}/checklist/${this.checklist[0].id}`;
        this.$axios
          .put(sUrl, { checklist: this.checklist })
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.checklist = response.data.data;
              this.successfulMessage = "Successfully updated checklist";
            }
          })
          .catch((error) => {
            console.log(`api error: ${error}`);
          });
      }
    },
  },
};
</script>
