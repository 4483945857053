<template>
  <div class="ma-4">
    <h3>Forward Revert Matrix Control Centre</h3>
    <b-container fluid>
      <v-row>
        <v-col cols="2">Current Phase</v-col>
        <v-col cols="2">Current Desk</v-col>
        <v-col cols="2">Forward Phase</v-col>
        <v-col cols="2">Forward Desk</v-col>
        <v-col cols="2">Revert Phase</v-col>
        <v-col cols="2">Revert Desk</v-col>
      </v-row>
      <v-row v-for="i in forwardRevertMatrixList" :key="i">
        <v-col cols="2">
          <v-select v-model="i.reg_phase_id" label="Select" :items="phaseList" item-value="phase_id" item-text="phase_name"></v-select>
        </v-col>
        <v-col cols="2">
          <v-select v-model="i.reg_desk_id" label="Select" :items="deskList" item-value="role_id" item-text="role_name"></v-select>
        </v-col>
        <v-col cols="2">
          <v-select v-model="i.forward_phase_id" label="Select" :items="phaseList" item-value="phase_id" item-text="phase_name"></v-select>
        </v-col>
        <v-col cols="2">
          <v-select v-model="i.forward_desk_id" label="Select" :items="deskList" item-value="role_id" item-text="role_name"></v-select>
        </v-col>
        <v-col cols="2">
          <v-select v-model="i.revert_phase_id" label="Select" :items="phaseList" item-value="phase_id" item-text="phase_name"></v-select>
        </v-col>
        <v-col cols="2">
          <v-select v-model="i.revert_desk_id" label="Select" :items="deskList" item-value="role_id" item-text="role_name"></v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="i.forward_text" label="Forward Text" class="custom-text-field" height="10" hide-details="auto" solo></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="i.revert_text" class="custom-text-field" label="Revert Text" height="10" hide-details="auto" solo></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn class="primary" @click="save()">Save</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-snackbar
          v-model="snackbar.show"
          :bottom="y === 'bottom'"
          :color="snackbar.color"
          :left="x === 'left'"
          :multi-line="mode === 'multi-line'"
          :right="x === 'right'"
          :timeout="3000"
          :top="y === 'top'"
          :vertical="mode === 'vertical'"
        >
          {{ snackbar.message }}
        </v-snackbar> -->
      </v-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phaseList: [],
      deskList: [],
      forwardRevertMatrixList: [],
      //   snackbar: {
      //     show: false,
      //     message: "",
      //     color: green,
      //   },
    };
  },
  created() {
    this.getPhaseList();
    this.getDeskList();
  },
  mounted() {
    this.getForwardRevertMatrixList();
  },
  methods: {
    getForwardRevertMatrixList() {
      const matrixUrl = `api/forward-revert-matrix`;
      this.$axios
        .get(matrixUrl)
        .then((response) => {
          this.forwardRevertMatrixList = response.data.data;
        })
        .catch((error) => {
          console.log("Phase List not loaded");
          console.log(error.response.data.message);
        });
    },
    async getPhaseList() {
      let phaseTemp = this.$store.getters.getPhaseList;
      try {

        if (!phaseTemp.length) {
          await this.$store.dispatch("fetchPhaseList")
        }
        this.phaseList = this.$store.getters.getPhaseList;
      } catch (error) {
        console.log(error.response.data.message);
      }

    },
    async getDeskList() {
      let userRoleTemp = this.$store.getters.getUserRoleList;
      try {
        userRoleTemp.length || await this.$store.dispatch("fetchUserRoleList");
        this.deskList = this.$store.getters.getUserRoleList;
      } catch (error) {
        console.log("Desk User Role not loaded");
        console.log(error.response.data.message);
      }
    },
    save() {
      const saveUrl = `api/forward-revert-matrix/save`;
      this.$axios
        .put(saveUrl, { frlist: this.forwardRevertMatrixList })
        .then((response) => {
          this.forwardRevertMatrixList = response.data.data;
          this.snackbar.show = true;
          this.snackbar.message = "Data updated successfully";
        })
        .catch();
    },
  },
};
</script>