<template>
  <b-container-fluid>
    <v-btn class="primary" :to="{ name: 'home' }">Back</v-btn>
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></loading>

    <v-dialog v-model="dialog" persistent max-width="1100px" v-if="userRoleId == 1">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" class="ma-3" dark v-bind="attrs" v-on="on" @click="clearAllData()">
          Create New Consultancy
        </v-btn>
      </template>
      <v-card>
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title class="flex text-center"> New Consultancy Form </v-toolbar-title>

          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form v-if="userRoleId == 1" ref="form1" v-model="valid" lazy-validation>
            <v-row align="center">
              <v-col md="6">
                <v-radio-group v-model="newconsultancy.is_consulting_firm" :mandatory="true">
                  <v-radio label="Consulting Firm" v-bind:value="true"></v-radio>
                  <v-radio label="Freelancer" v-bind:value="false"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="2"> Registration No. <span>*</span></v-col>
              <v-col cols="4">
                <v-text-field v-model="newconsultancy.registration_number" class="custom-text-field" label="" height="10" hide-details="auto" solo></v-text-field>
              </v-col>
            </v-row>
            <br />
            <v-row align="center">
              <v-col md="2"> Consultancy Name <span>*</span></v-col>
              <v-col md="4">
                <v-text-field v-model="newconsultancy.name" label="" height="10" hide-details="auto" solo></v-text-field>
              </v-col>
              <v-col md="2"> Consultancy Name (नेपालि अक्षरमा) </v-col>
              <v-col md="4">
                <v-text-field v-model="newconsultancy.name_np" label="" height="10" hide-details="auto" solo></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col md="2"> Registration Date <span>*</span> </v-col>
              <v-col md="4">
                <!-- <v-text-field
                  v-model="newconsultancy.registration_date"
                  label=""
                  class="custom-text-field"
                  hide-details="auto"
                  ref="dateField"
                  required
                  hint="2022-01-01 English Date"
                  solo
                ></v-text-field> -->

                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="newconsultancy.registration_date" label="2023-01-01" readonly v-bind="attrs" v-on="on" solo></v-text-field>
                  </template>
                  <v-date-picker v-model="newconsultancy.registration_date" @input="menu2 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col md="2"> दर्ता गरिएको नेपालि मिति <span>*</span></v-col>
              <v-col md="4">
                <!-- <v-text-field
                  v-model="newconsultancy.registration_date_np"
                  label=""
                  class="custom-text-field"
                  hide-details="auto"
                  ref="dateField"
                  required
                  hint="2078-11-12 नेपालि मिति"
                  solo
                ></v-text-field> -->
                <nepali-date-picker v-model="newconsultancy.registration_date_np" label="२०७९-०१-०१" solo></nepali-date-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2"> District <span>*</span></v-col>
              <v-col cols="4">
                <v-autocomplete v-model="newconsultancy.district_id" :items="districts" item-value="id" item-text="district_name" hide-details="auto" :key="id" solo></v-autocomplete>
              </v-col>
              <v-col md="2"> Location </v-col>
              <v-col md="4">
                <v-text-field v-model="newconsultancy.location" hide-details="auto" label="" height="10" solo></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2"> Phone Number </v-col>
              <v-col cols="4">
                <v-text-field v-model="newconsultancy.phone_no" hide-details="auto" solo></v-text-field>
              </v-col>
              <v-col cols="2"> Email <span>*</span></v-col>
              <v-col cols="4">
                <v-text-field v-model="newconsultancy.email" hide-details="auto" solo></v-text-field>
              </v-col>
            </v-row>
            <!-- <v-row align="center">
                <v-col cols="2"> First Name </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newconsultancy.first_name"
                    hide-details="auto"
                    solo
                  ></v-text-field>
                </v-col>
                <v-col cols="2"> Last Name </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newconsultancy.last_name"
                    hide-details="auto"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row> -->
            <v-row align="center">
              <!-- <v-col cols="2"> N.E.C Number </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newconsultancy.person_nec_no"
                    hide-details="auto"
                    solo
                  ></v-text-field>
                </v-col> -->
              <v-col cols="2"> Account Expiry Date </v-col>
              <v-col cols="4">
                <!-- <v-text-field
                  v-model="newconsultancy.expiry_date"
                  hide-details="auto"
                  hint="2078-02-21"
                  solo
                ></v-text-field> -->
                <nepali-date-picker v-model="newconsultancy.expiry_date" label="२०७९-०१-०१" solo></nepali-date-picker>
                <!-- <v-menu
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="newconsultancy.expiry_date"
                      label="2023-01-01"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      solo
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="newconsultancy.expiry_date" @input="menu3 = false"></v-date-picker>
                </v-menu> -->
              </v-col>
            </v-row>
            <!-- <v-row>
                <v-col cols="2"> Account Expiry Date </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newconsultancy.expiry_date"
                    hide-details="auto"
                    hint="2078-02-21"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row> -->
          </v-form>
        </v-card-text>
        <h6 class="text-center">Consultancy Member Details</h6>
        <v-card-text>
          <v-form v-if="userRoleId == 1" ref="form2" v-model="valid" style="margin-bottom: 1rem">
            <b-container>
              <v-row align="center">
                <v-col cols="2"> First Name <span>*</span></v-col>
                <v-col cols="4">
                  <v-text-field v-model="newconsultancymember.first_name" class="custom-text-field" label="" hide-details="auto" solo></v-text-field>
                </v-col>
                <v-col cols="2"> Middle Name</v-col>
                <v-col cols="4">
                  <v-text-field v-model="newconsultancymember.middle_name" class="custom-text-field" label="" hide-details="auto" solo></v-text-field>
                </v-col>
                <v-col cols="2"> Last Name <span>*</span> </v-col>
                <v-col cols="4">
                  <v-text-field v-model="newconsultancymember.last_name" class="custom-text-field" label="" hide-details="auto" solo></v-text-field>
                </v-col>
                <v-col cols="2"> Member Mobile No.</v-col>
                <v-col cols="4">
                  <v-text-field v-model="newconsultancymember.member_phone_no" class="custom-text-field" label="" hide-details="auto" solo></v-text-field>
                </v-col>
                <v-col cols="2"> Member Email <span>*</span> </v-col>
                <v-col cols="4">
                  <v-text-field v-model="newconsultancymember.member_email" class="custom-text-field" label="" hide-details="auto" solo></v-text-field>
                </v-col>
                <v-col cols="2"> Username </v-col>
                <v-col cols="4">
                  <v-text-field v-model="newconsultancymember.username" hide-details="auto" solo readonly></v-text-field>
                </v-col>
              </v-row>
            </b-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <small> ( <span>*</span> ) indicates required field</small>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog = false"> Close </v-btn>
          <v-btn color="primary" large v-if="userRoleId == 1" primary v-on:click="saveConsultancy" :disabled="!valid">
            Save
          </v-btn>
        </v-card-actions>
        <br />
      </v-card>
    </v-dialog>

    <!-- <br /> -->
    <v-snackbar v-model="snackbar.show" :bottom="y === 'bottom'" :color="snackbar.color" :left="x === 'left'" :multi-line="mode === 'multi-line'" :right="x === 'right'"
      :timeout="3000" :top="y === 'top'" :vertical="mode === 'vertical'">
      {{ successfulMessage }}
    </v-snackbar>
    <!-- <v-toolbar color="primary mt-2 pa-0" dark dense flat>
        <v-toolbar-title>
          List
        </v-toolbar-title>
      </v-toolbar> -->
    <div>
      <v-simple-table class="sober" width="100%">
        <template v-slot:default>
          <thead>
            <tr class="registrationtable">
              <th class="text-left registrationtable">S. No.</th>
              <th class="text-left registrationtable">Consultancy Name</th>
              <th class="text-left registrationtable">Registration Number</th>
              <th class="text-left registrationtable">Location</th>
              <th class="text-left registrationtable">Registration Date</th>
              <th class="text-left registrationtable">Phone No</th>
              <th class="text-left registrationtable">Email</th>
              <!-- <th class="text-left registrationtable">Engineer Name</th>
              <th class="text-left registrationtable">NEC No.</th> -->
              <!-- <th class="text-left registrationtable" v-if="userRoleId == 1" width="14%">Action</th> -->
              <th class="text-left registrationtable" v-if="userRoleId == 1" width="15%">Action</th>
            </tr>
          </thead>
          <tbody>
            <!-- Search Function for consultancy pending -->
            <tr>
              <td></td>
              <td>
                <v-text-field label="Search" type="text" v-model="searchKeys.name" @keyup="searchConsultancy"></v-text-field>
              </td>
              <td>
                <v-text-field label="Search" type="text" v-model="searchKeys.registration_number" @keyup="searchConsultancy"></v-text-field>
              </td>
              <td>
                <v-text-field label="Search" type="text" v-model="searchKeys.location" @keyup="searchConsultancy"></v-text-field>
              </td>
              <td>
                <v-text-field label="Search" type="text" v-model="searchKeys.registration_date_np" @keyup="searchConsultancy"></v-text-field>
              </td>
              <td>
                <v-text-field label="Search" type="text" v-model="searchKeys.phone_no" @keyup="searchConsultancy"></v-text-field>
              </td>
              <td>
                <v-text-field label="Search" type="text" v-model="searchKeys.email" @keyup="searchConsultancy"></v-text-field>
              </td>

              <!-- <td>
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="searchKeys.person_name"
                  @keyup="searchConsultancy"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="searchKeys.person_nec_no"
                  @keyup="searchConsultancy"
                ></v-text-field>
              </td> -->
            </tr>
            <tr class="registrationtable" v-for="consultancyList in consultancy" :key="consultancyList.id">
              <td class="registrationtable">{{ consultancyList.sno }}</td>
              <td class="registrationtable">{{ consultancyList.name }}</td>
              <td class="registrationtable">
                {{ consultancyList.registration_number }}
              </td>
              <td class="registrationtable">{{ consultancyList.location }}</td>
              <td class="registrationtable">
                {{ consultancyList.registration_date_np }}
              </td>
              <td class="registrationtable">{{ consultancyList.phone_no }}</td>
              <td class="registrationtable">{{ consultancyList.email }}</td>
              <!-- <td class="registrationtable">
                {{ consultancyList.person_name }}
              </td>
              <td class="registrationtable">
                {{ consultancyList.person_nec_no }}
              </td> -->
              <td v-if="userRoleId == 1">
                <v-btn class="primary" small v-on:click="editConsultancy(consultancyList.id)">Edit</v-btn>
                <custom-dialog :click-dialog="deleterecordConsultancy" :regId="consultancyList.id" click-type="delete">Delete</custom-dialog>
                <custom-dialog :click-dialog="renewConsultancy" :regId="consultancyList.id" click-type="renew" v-if="consultancyList.is_renewed != true">Renew</custom-dialog>
                <br />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <!-- </v-app> -->
    <!-- </v-card> -->
  </b-container-fluid>
</template>
<script>
import { VueAxios } from "@/plugins/axios";
import NepaliDatePicker from "@/custom-inputs/nepali-date-picker/NepaliDatePicker.vue";

export default {
  name: "consultancy",
  components: {
    NepaliDatePicker,
  },

  data() {
    return {
      isLoading: false,
      userRoleId: localStorage.getItem("auth_user_role_id"),
      valid: true,
      date: "",
      consultancy: [],
      districts: [],
      newconsultancy: {
        id: 0,
        reg_id: this.registrationId,
        is_consulting_firm: "",
        registration_number: "",
        name: "",
        name_np: "",
        registration_date: "",
        registration_date_np: "",
        district_id: "",
        location: "",
        phone_no: "",
        email: "",
        expiry_date: "",
      },
      newconsultancymember: {
        id: 0,
        bps_user_id: "",
        username: "",
        consultancy_id: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        designer_type_id: "",
        member_phone_no: "",
        member_email: "",
        nec_certificate: "",
        created_at: "",
        updated_at: "",
      },
      searchKeys: {
        name: "",
        registration_number: "",
        location: "",
        registration_date_np: "",
        phone_no: "",
        email: "",
      },
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      dialog: false,
      menu2: false,
      menu3: false,
    };
  },
  created() {
    this.getDistricts();
    this.getConsultancy();
  },
  watch: {
    "newconsultancymember.member_email": function (val) {
      this.newconsultancymember.username = this.newconsultancymember.member_email;
    },
  },
  methods: {
    getConsultancy() {
      return new Promise((resolve, reject) => {
        const ConsultancyListUrl = "api/consultancy";
        this.isLoading = true;
        VueAxios.get(ConsultancyListUrl)
          .then((response) => {
            this.consultancy = response.data.data;
            this.consultancy = this.consultancy.map((d, index) => ({
              ...d,
              sno: index + 1,
            }));
            this.isLoading = false;
            resolve(this.consultancy);
          })
          .catch((error) => {
            console.log(`api error:${error}`);
            this.isLoading = false;
            reject(error);
          });
      });
    },
    getDistricts() {
      const districtUrl = "api/districts";
      VueAxios.get(districtUrl)
        .then((response) => {
          this.districts = response.data.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    clearAllData() {
      this.$refs.form1.reset();
      this.$refs.form2.reset();
    },
    saveConsultancy() {
      this.isLoading = true;
      if (
        this.newconsultancy.id === "null" ||
        this.newconsultancy.id === 0 ||
        this.newconsultancy.id === undefined
      ) {
        const saveUrl = "/api/consultancy";

        const consultancy_array = [this.newconsultancy, this.newconsultancymember];
        this.$axios
          .post(saveUrl, consultancy_array)
          .then((response) => {
            this.isLoading = false;
            if (response.status === 200 || response.status === 201) {
              this.consultancy.push(response.data.data);
              this.snackbar.show = true;
              this.successfulMessage = response.data.message;
              this.$refs.form1.reset();
              this.dialog = false;
              this.getConsultancy();
            }
          })
          .catch((error) => {
            this.snackbar.show = true;
            this.successfulMessage = error.response.data.message;
            this.isLoading = false;
          });
      } else {
        const consultancy_array = [this.newconsultancy, this.newconsultancymember];
        const url = `/api/consultancy/${this.newconsultancy.id}`;
        this.$axios
          .put(
            url,
            consultancy_array,
            {
              headers: {
                "X-Requested-With": "XMLHttpRequest",
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
              },
              _method: "patch",
            },
            {}
          )
          .then((response) => {
            this.isLoading = false;
            if (response.status === 200 || response.status === 201) {
              this.dialog = false;
              this.snackbar.show = true;
              this.successfulMessage = response.data.message;
              this.newconsultancy.id = 0; // reset the edit id
              this.getConsultancy();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    editConsultancy(id) {
      //    const url = `api/registration/${this.$route.params.regId}/consultancy/${this.$route.params.id}`;
      const url = `api/consultancy/${id}`;
      this.$axios.get(url).then((response) => {
        this.newconsultancy = response.data.data.consultancy;
        if (response.data.data.member) {
          this.newconsultancymember = response.data.data.member;
        }
        else{
          // this.$refs.form2.reset(); // reset is not working so 
          this.newconsultancymember = {
            first_name : '',
            middle_name: '',
            last_name: '',
            member_phone_no: '',
            member_email: '',
            username: ''
          }
        }
        this.dialog = true;
        console.log(`testt:${response.data.data}`);
      });
    },
    deleterecordConsultancy(id) {
      const deleteurl = `api/consultancy/${id}`;
      this.isLoading = true;
      this.$axios
        .delete(deleteurl)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.isLoading = false;
            this.successfulMessage = "Data deleted successfully";
            const i = this.consultancy.map((item) => item.id).indexOf(id); // find index of your object
            this.consultancy.splice(i, 1);
          }
          console.log(response);
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    renewConsultancy(id) {
      const renewurl = `api/consultancy/${id}/renew`;
      this.isLoading = false;
      VueAxios.post(renewurl)
        .then((response) => {
          this.isLoading = true;
          if (response.status === 200 || response.status === 201) {
            this.consultancy.push(response.data.data);
            this.snackbar.show = false;
            this.successfulMessage = response.data.message;
            this.getConsultancy().then((response) => {
              this.snackbar.show = true;
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    searchConsultancy() {
      const searchUrl = "api/consultancy/search";
      this.$axios
        .post(searchUrl, this.searchKeys)
        .then((response) => {
          this.consultancy = response.data.data;
          this.consultancy = this.consultancy.map((d, index) => ({
            ...d,
            sno: index + 1,
          }));
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
  },
};
</script>

<style scoped>
span {
  color: red;
}
</style>
