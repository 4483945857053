<style>
.houseownerinfo {
  font-weight: normal;
  width: 250px;
}

.v-btn.v-btn--has-bg.border-secondary {
  border: 1px solid var(--v-secondary-darken1) !important;
}

.v-stepper__wrapper {
  min-height: 300vh;
}
</style>
<template>
  <b-container fluid class="mx-0 pa-0">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <v-row>
      <v-col>
        <v-btn class="secondary border-secondary coltext--text" :to="{ name: 'home' }">
          <v-icon dark left> mdi-arrow-left </v-icon> Back to home</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mb-8">
        <owner-info-card></owner-info-card>
      </v-col>
    </v-row>
    <div>
      <v-stepper v-model="el">
        <v-stepper-header>
          <template v-for="n in steps">
            <v-stepper-step :key="n.sn" :step="n.sn" :complete="stepperComplete(n.sn)" :editable="stepperEdit(n.sn)">
              {{ n.text }}
            </v-stepper-step>
          </template>
        </v-stepper-header>
        <v-stepper-items>
          <div v-for="n in steps" :key="`${n.sn}-content`" :step="n.sn" class="sober px-1">
            <component :is="getComponent(n.sn)" :id="results.id" v-if="n.sn === el" :next-step="nextStep" :current-application-phase="results.phase_id"
              :current-application-desk="results.desk_user_role_id" :submit-status="submitStatus" />
          </div>
        </v-stepper-items>
      </v-stepper>
    </div>
  </b-container>
</template>
<script>
import RegistrationLevel from "./components/RegistrationLevel.vue";
import FifteenDays from "./components/FifteenDays.vue";
import PlinthLevel from "./components/PlinthLevel.vue";
import SuperStructuralLevel from "./components/SuperStructuralLevel.vue";
import CompletionLevel from "./components/CompletionLevel.vue";
import ApplicationStatus from "./components/ApplicationStatus.vue";
import { engNumConvert } from "../mixins/numericToUnicode.js";
import "../../../node_modules/nepali-date-picker/dist/nepaliDatePicker.min.css";
import OwnerInfoCard from "./components/OwnerInfoCard.vue";

// import Test from '../test.vue'

const COMPONENT_MAP = {
  1: "RegistrationLevel",
  2: "FifteenDays",
  3: "PlinthLevel",
  4: "SuperStructuralLevel",
  5: "CompletionLevel",
  6: "ApplicationStatus",
};
export default {
  name: "ApplicationTab",
  mixins: [engNumConvert],
  data() {
    return {
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },

      el: 1,
      // testAr: [1,2,3,4,6],
      steps: [
        {
          text: "कागजात जाँच तथा दर्ता",
          sn: 1,
        },
        {
          text: "१५ दिने सूचना (सर्जमिन)",
          sn: 2,
        },
        {
          // text: "प्लिन्थ लेभल निर्माण अनुमति",
          text: "प्लिन्थ निर्माण अनुमति",
          sn: 3,
        },
        {
          // text: "सुपरस्ट्रक्चर लेभल निर्माण अनुमति",
          text: "सुपरस्ट्रक्चर निर्माण अनुमति",
          sn: 4,
        },
        {
          text: "निर्माण सम्पन्न",
          sn: 5,
        },
        {
          text: "आवेदन स्थिति",
          sn: 6,
        },
      ],
      data: {
        registration_date_np: "",
        registration_no: "",
      },
      submitStatus: {},
      results: [],
      log: "",
      all_logs: "",
      regDateDialog: false,
      user_role_id: localStorage.getItem("auth_user_role_id"),
      isForwardRevertAuthorized: false,
    };
  },
  components: {
    RegistrationLevel,
    FifteenDays,
    PlinthLevel,
    SuperStructuralLevel,
    CompletionLevel,
    ApplicationStatus,
    OwnerInfoCard,
    // Test
  },
  computed: {
    isLoading() {
      if (this.results === undefined || this.results.length === 0) {
        // registration value not yet initiated from api. Loading spinner active
        return true;
      }
      return false;
    },
    // stepperEdit: function () {
    //   // return sn <= this.results.phase_id || sn == "6";
    //   return true;
    //   // return sn < 3 ? true : false;
    // },
  },
  watch: {
    steps(val) {
      if (this.el > val) {
        this.el = val;
      }
      // this.el = 3
    },
    "$route.params.id": function (id) {
      this.getFileData();
    },
  },
  created() {
    this.getDate();
    this.getSubmitStatus();
  },
  mounted() {
    // debugger;
    this.getFileData();
    this.changeStepper();

    // This is executed so that it can be called from registrationaction.vue file
    this.$root.$on("onFileForwardRevert", () => {
      this.getFileData();
      this.getSubmitStatus();
    });
  },
  methods: {
    getDate() {
      const dateUrl = "api/current-date";
      this.$axios
        .get(dateUrl)
        .then((response) => {
          this.data.registration_date_np = response.data.date_np;
          // console.log(response.data.date_np);
          // this.registration.registration_date = response.data.date;
        })
        .catch(() => { });
    },

    getComponent(n) {
      return COMPONENT_MAP[n];
    },
    nextStep(n) {
      if (n === this.steps.sn) {
        this.el = 1;
      } else {
        this.el += n;
      }
    },
    getFileData() {
      this.results = [];
      this.results = this.$store.getters.registrationData;
      this.data.registration_date_np = this.results.registration_date_np;
      this.el = this.results.phase_id;
      // console.log("registration pachhi chaleko chij");

      // check if the user is authorized to forward/revert the file, so registration action can be hidded if they are not authorized
      this.$store.dispatch("getForwardRevertActionAuth", parseInt(this.$route.params.id)).then(() => {
        // console.log("get Forward Revert Action Auth");
        // console.log(this.$store.getters.forwardRevertActionAuth);
      });

      // this.checkRegDateDialog();

      // sconsole.log(response.data.data);

      // Select the tab that the registration phase is in
      // });

      const regLogUrl = `api/registration/${this.$route.params.id}/log`;
      this.$axios.get(regLogUrl).then((response) => {
        this.all_logs = response.data.data;
        this.log = response.data.data[0].remarks;
      });
    },
    getSubmitStatus() {
      const url = `api/registration/${this.$route.params.id}/status`;
      this.$axios
        .get(url)
        .then((response) => {
          this.submitStatus = response.data.data;
        })
        .catch((error) => {
          console.log(`submit api error: ${error}`);
        });
    },
    stepperEdit(sn) {
      let editable = [1, 2, 3];
      return sn <= this.results.phase_id || sn == "6";
      // return editable.includes(sn);
      // return sn == 4;
    },
    stepperComplete(sn) {
      let complete = [1, 3];
      // return complete.includes(sn);
      return sn <= this.results.phase_id || sn == "6";
    },
    changeStepper() {
      const constructionTypeId = this.$store.getters.registrationData.construction_type_id;
      if (constructionTypeId == 4) {
        this.steps = [
          {
            text: "कागजात जाँच तथा दर्ता",
            sn: 1,
          },
          {
            text: "१५ दिने सूचना (सर्जमिन)",
            sn: 2,
          },
          {
            text: "प्लिन्थ निर्माण अनुमति",
            sn: 3,
          },
          {
            text: "सुपरस्ट्रक्चर निर्माण अनुमति",
            sn: 4,
          },
          {
            text: "पर्खाल सम्पन्न",
            sn: 5,
          },
          {
            text: "आवेदन स्थिति",
            sn: 6,
          },
        ]
      }
      else if (constructionTypeId == 12) {
        this.steps = [
          {
            text: "कागजात जाँच तथा दर्ता",
            sn: 1,
          },
          {
            text: "१५ दिने सूचना (सर्जमिन)",
            sn: 2,
          },
          {
            text: "निर्माण सम्पन्न",
            sn: 5,
          },
          {
            text: "आवेदन स्थिति",
            sn: 6,
          },
        ]
      }
    }
  },
};
</script>
