<template>
  <div>
    <div v-if="!isDataLoaded">
      <v-expansion-panels :value="opened">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title> कागजातहरु </v-toolbar-title>
            </v-toolbar>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-skeleton-loader
              v-bind="attrs"
              type="list-item, list-item, list-item, list-item, list-item, list-item, list-item, list-item, list-item"
            >
            </v-skeleton-loader>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div v-else>
      <v-expansion-panels :value="opened">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title> कागजातहरु </v-toolbar-title>
            </v-toolbar>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table class="sober elevation-2">
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in documents"
                  :key="item.name"
                  class="registrationtable"
                  :class="submitStatus[item.value] == 'Completed' ? 'green lighten-5' : 'red lighten-5'"
                >
                  <td :class="item.typography == 'bold' ? 'font-weight-bold' : ''">
                    {{ item.name }}
                  </td>
                  <td>
                    <span>
                      <router-link
                        :to="{
                          name: 'documentupload',
                          params: {
                            id: item.id,
                            regId: $store.getters.registrationData.id,
                            sn: item.sn,
                            isPostAuthorized: isDocumentAddEditAuthorized,
                            phaseId: phaseId,
                          },
                        }"
                      >
                        <v-btn small class="blue elevation-0 white--text"
                          >{{ isDocumentAddEditAuthorized || uploadLevel(item.nativePhaseId) ? "Upload" : "View" }}
                        </v-btn>
                      </router-link>
                    </span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
import api from "@/api/api";

export default {
  name: "UploadDocumentList",
  props: {
    phaseId: {
      type: [Number, String],
    },
    submitStatus: {
      type: Object,
    },
  },
  data() {
    return {
      opened: 0,
      documents: [],
      isDocumentAddEditAuthorized: false,
      // submitStatus: {},

      isDataLoaded: false,
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
    };
  },
  computed: {
    constructionTypeIdStr() {
      return this.$store.getters.registrationData.construction_type_id.toString();
    },
  },
  created() {
    this.getDocumentMaster();
    this.getDocumentAuthorization();
  },
  methods: {
    // List of documents to be uploaded (कागजातहरु)
    async getDocumentMaster() {
      try {
        this.isDataLoaded = false;
        let response = await api.documents.phaseDocumentMaster(this.constructionTypeIdStr, this.phaseId);
        let documentData = response.data.data;
        documentData.forEach((element) => {
          this.documents.push({
            id: element.index,
            name: `${element.index_np}| ${element.document_master.title}`,
            value: element.document_master.document_name,
            action: "Upload",
            sn: element.index,
            nativePhaseId: element.document_master.native_phase_id,
          });
          this.isDataLoaded = true;
        });
      } catch (error) {
        console.log(error);
        this.isDataLoaded = true;
      }
    },
    getDocumentAuthorization() {
      const regId = parseInt(this.$route.params.id);
      const phase = this.phaseId;
      const payload = { regId: regId, phase: phase };
      this.$store.dispatch("getDocumentsAuth", payload).then(() => {
        this.isDocumentAddEditAuthorized = this.$store.getters.documentsAuthData;
      });
    },
    uploadLevel(nativePhaseId) {
      if (parseInt(nativePhaseId) > parseInt(this.phaseId)) {
        return true;
      }
      return false;
    },
  },
};
</script>