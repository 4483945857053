import Vue from "vue";
import Dropdowns from "@/api/modules/dropdowns";
import HouseownerInfo from "@/api/modules/houseowner_info";
import documents from "./modules/documents";
import adminAuthority from "@/api/modules/admin_authority";
import registration from "@/api/modules/registration";
import { VueAxios } from "@/plugins/axios";


const factories = {
    dropdowns: Dropdowns(VueAxios),
    houseownerInfo: HouseownerInfo(VueAxios),
    documents: documents(VueAxios), // galat naming vayex adocument ko
    adminAuthority: adminAuthority(VueAxios),
    registration: registration(VueAxios),

}

// I have created registration file for now, which I will be preparing to add all ghardhanis related api here
// 

export const Api = factories;

Vue.prototype.$api = factories;

export default factories;