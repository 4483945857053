<template>
  <div>
    <v-form ref="form" class="mx-4" v-model="valid">
      <!-- <div v-for="(item, i) in valid" :key="i"> -->
      <div v-for="(n, index) in newhouseownerinfo.houseownerinfo" :key="n.id">
        <v-toolbar v-if="index > 0" color="primary" dark flat height="50px">
          <v-spacer></v-spacer>
          <v-btn icon dark @click="deleteForm(index, n.id)"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-row class="mt-4">
          <v-col cols="5">
            <label for="houseowner-type">घरधनीको प्रकार</label>
          </v-col>
          <v-col cols="7"
            ><v-select
              v-model="n.houseowner_type"
              label="घरधनीको प्रकार"
              :items="houseowner_type_items"
              item-text="name"
              item-value="id"
              dense
            ></v-select
          ></v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <label for="company_reg_no">नाम <span>*</span></label>
          </v-col>
          <v-col cols="7">
            <v-text-field
              v-model="n.name_np"
              :rules="required('Name')"
              label="नाम"
              dense
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="5">
            <label>{{ ghardhaniId(n.houseowner_type) }}</label>
            <!-- <label>संस्था दर्ता नं.</label> -->
          </v-col>
          <v-col md="7">
            <v-text-field
              v-model="n.citizenship_no"
              :label="ghardhaniId(n.houseowner_type)"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="n.houseowner_type == 'p'">
          <v-col cols="5">
            <label> नागरिकता लिएको जिल्ला</label>
          </v-col>
          <v-col cols="7">
            <!-- <v-text-field v-model="newhouseownerinfo.citizenship_district_id" label=""     ></v-text-field>
                                 -->
            <v-autocomplete
              v-model="n.citizenship_district_id"
              label="नागरिकता लिएको जिल्ला"
              :items="districts"
              item-value="id"
              item-text="district_name"
              :key="id"
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <label> फोन नं.</label>
          </v-col>
          <v-col cols="7">
            <v-text-field
              v-model="n.phone_no"
              type="number"
              label="Phone"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <label> Email</label>
          </v-col>
          <v-col cols="7">
            <v-text-field
              v-model="n.email"
              :rules="email"
              type="text"
              label="Email"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <label> वडा नं.</label>
          </v-col>
          <v-col cols="7">
            <!-- <v-text-field v-model="n.ward_no" label="Ward No." dense></v-text-field> -->
            <v-select
              :items="wards"
              v-model="n.ward_no"
              label=""
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <label> ठेगाना</label>
          </v-col>
          <v-col cols="7">
            <v-text-field
              v-model="n.address"
              label="Address"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </div>
    </v-form>
    <v-row class="white">
      <v-col cols="auto">
        <v-btn
          primary
          v-if="isPostAuthorized"
          v-on:click="save"
          class="primary"
          :disabled="!valid"
          >Save</v-btn
        >
      </v-col>
      <v-col cols="auto">
        <v-btn
          primary
          v-if="isPostAuthorized"
          v-on:click="addForm"
          class="primary"
          :disabled="!valid"
          >+ Add Another</v-btn
        >
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar.show"
      :bottom="y === 'bottom'"
      :color="snackbar.color"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="3000"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      {{ successfulMessage }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    isPostAuthorized: Boolean,
  },
  data() {
    return {
      required(propertyType) {
        return (v) => !!v || `${propertyType} is required`;
      },
      requiredUnicode(propertyType) {
        return [
          (v) => !!v || `${propertyType} is required`,
          // v => /^\W$/.test(v) || 'nice one'
          (v) =>
            /^[\W]+$/.test(v) ||
            `${propertyType} नेपाली युनिकोडमा लेख्नुहोला ।`,
        ];
      },
      requiredFormat(propertyType, regexexp) {
        var regex = new RegExp(regexexp, "g");
        return [
          (v) => !!v || `${propertyType} is required`,
          (v) => regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
          // /^२[०-९]{3}-[०१]?[०-९]-[०-३]?[०-९]$/   ‍=> Date
        ];
      },
      validPhoneNumber(propertyType) {
        return [
          // v => !!v || `${propertyType} is required`,
          // v => /^\w$/ || 'Please write in english format',
          (v) =>
            /^\+?(977|९७७)? ?[0-9०-९]{9,15}$/.test(v) ||
            `${propertyType} is not valid phone number`,
        ];
      },
      valid: false,
      totalForms: 1,
      districts: [],
      formData: new FormData(),
      houseownerinfo: [],
      newhouseownerinfo: {
        houseownerinfo: [],
      },
      // newhouseownerinfo: {
      //   id: 0,
      //   houseowner_type: { name: "व्यक्ति", id: "p" },
      //   // houseowner_type: "",
      //   // reg_id: this.registrationId,
      //   citizenship_no: "",
      //   name: "",
      //   name_np: "",
      //   phone_no: "",
      //   email: "",
      //   address: ""
      // },
      // ghardhani_id_no: "",
      uploadedDocument: "",
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      houseowner_type_items: [
        { name: "व्यक्ति", id: "p" },
        { name: "संस्था", id: "c" },
      ],
      noOfWards: "",
      wards: [],
    };
  },
  computed: {},
  created() {
    const url = `api/registration/${this.$route.params.id}/houseowner-detail`;
    this.$axios
      .get(url)
      .then((response) => {
        // debugger;
        this.newhouseownerinfo.houseownerinfo = [];
        this.newhouseownerinfo.houseownerinfo = response.data.data;
        // this.edit(this.houseownerinfo[0].id);
        this.isLoading = false; //stop the loading spinner
        //   this.newhouseownerinfo.clear();
        if (this.newhouseownerinfo.houseownerinfo.length === 0) {
          this.addForm();
        }
      })
      .catch((error) => {
        console.log(`api error:${error}`);
        this.isLoading = false;
      });
    this.getDistricts();
    this.getWardNo();
  },
  mounted() {},
  methods: {
    save() {
      this.isLoading = true;
      if (
        this.newhouseownerinfo.houseownerinfo[0].id === "null" ||
        this.newhouseownerinfo.houseownerinfo[0].id === 0 ||
        this.newhouseownerinfo.houseownerinfo[0].id === undefined
      ) {
        const saveUrl = `/api/registration/${this.$route.params.id}/houseowner-detail/list`;
        this.$axios
          .post(saveUrl, this.newhouseownerinfo)
          .then((response) => {
            //  debugger;
            if (response.status === 200 || response.status === 201) {
              this.newhouseownerinfo.houseownerinfo = response.data.data;
              this.snackbar.show = true;
              this.successfulMessage = "Ghardhani data added successfully.";
              this.isLoading = false;
              // this.$refs.form.reset();
            }
          })
          .catch((error) => {
            console.log(`api error:${error}`);
            this.isLoading = false;
          });
      } else {
        // self.formData.append("_method", "PUT"); //Method spoofing to insert files via post for edit
        const eurl = `/api/registration/${this.$route.params.id}/houseowner-detail/list`;
        this.$axios
          .put(eurl, this.newhouseownerinfo)
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.successfulMessage = "Ghardhani data updated successfully";
              this.newhouseownerinfo.houseownerinfo = response.data.data;
              this.isLoading = false;
              // this.$refs.form.reset();
              // this.newhouseownerinfo.id = 0;
            }
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
          });
      }
    },
    ghardhaniId(x) {
      if (x == "c") {
        return "संस्थाको दर्ता नं.";
      }
      return "नागरिकता नं.";
    },
    // edit(id) {
    //   //    const url = `api/registration/${this.$route.params.id}/houseownerinfo/${this.$route.params.id}`;
    //   const url = `api/registration/${this.$route.params.id}/houseowner-detail/${id}`;
    //   this.$axios.get(url).then(response => {
    //     this.newhouseownerinfo.houseownerinfo = response.data.data;
    //   });
    // },
    addForm() {
      this.newhouseownerinfo.houseownerinfo.push({
        id: 0,
        houseowner_type: "",
        // houseowner_type: "",
        // reg_id: this.registrationId,
        citizenship_no: "",
        citizenship_district_id: "",
        // name: "",
        name_np: "",
        phone_no: "",
        email: "",
        address: "",
      });
    },
    deleteForm(index, id) {
      this.newhouseownerinfo.houseownerinfo.splice(index, 1);
      const deleteurl = `api/registration/${this.$route.params.id}/houseowner-detail/${id}`;
      this.$axios
        .delete(deleteurl)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
            // const i = this.landinfoarea.map(item => item.id).indexOf(id); // find index of your object
            // this.landinfoarea.splice(i, 1);
          }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDistricts() {
      const districtUrl = "api/districts";
      this.$axios
        .get(districtUrl)
        .then((response) => {
          // debugger;
          this.districts = response.data.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    getWardNo() {
      const municipality_url = "api/municipality";
      this.$axios
        .get(municipality_url)
        .then((response) => {
          // debugger;
          if (response.status === 200) {
            this.noOfWards = response.data.data[0].no_of_wards;
            for (let i = 0; i < this.noOfWards; i++) {
              this.wards.push(i + 1);
            }
            // console.log(`wards: ${this.wards}`);
          }
        })
        .catch((error) => {
          console.log("Navbar error while retrieving municipality");
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
.col {
  padding: 0 15px;
}

.row:nth-child(odd) {
  /* background-clip: content-box; */
  background-color: #eceff1;
}

span {
  color: red;
}
</style>
