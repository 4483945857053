<template>
  <div>
    <v-container class="w-100">
      <div v-for="i in forms" :key="i.id">
        <v-checkbox v-model="i.show" label="John" :value="i.show"></v-checkbox>
        {{ i.show }}
        <component
          :is="i.name"
          :key="i.id"
          v-if="i.show"
          :ref="i.refName"
        ></component>
      </div>
      <v-btn class="primary" @click="getData">Get Data Here</v-btn>
      {{ clickCount }} clicks
    </v-container>
  </div>
</template>

<script>
import Application from "./forms/Application.vue";
import Houseowner from "./forms/Houseowner.vue";
import Designer from "./forms/Designer.vue";
import ComponentOne from "./ComponentOne.vue";
import ComponentTwo from "./ComponentTwo.vue";
import ValidationTest from "./forms/ValidationTest.vue";

export default {
  components: {
    // Application,
    // Houseowner,
    Designer,
    ComponentOne,
    ComponentTwo,
    ValidationTest
  },
  data() {
    return {
      clickCount: 0,
      allData: {},
      forms: [
        {
          id: 1,
          name: "ValidationTest",
          show: true,
          refName: "designer",
        },
        // {
        //   id: 1,
        //   name: "Houseowner",
        //   show: false,
        //   refName: "houseowner",
        // },
        {
          id: 2,
          name: "ComponentOne",
          show: false,
          refName: "componentone",
        },
        {
          id: 3,
          name: "ComponentTwo",
          show: false,
          refName: "componenttwo",
        },
      ],
    };
  },
  methods: {
    // getData from inside the components on button click;
    // each component is going to emit data
    getData() {
      this.clickCount += 1;
      let datas = {};
      this.allData = {};
      this.forms.forEach((component) => {
        if (component.show) {
          let refName = component.refName;
          let componentRef = this.$refs[refName];
          datas[refName] = componentRef[0].newData;
        }
      });
      // let formD = new FormData();
      // formD.append("photo", datas.componentone.photo);
      // formD = datas.componentone
      this.allData = datas;
      this.$axios.post("okok", formD);
    },
  },
};
</script>

<style>
.w-100 {
  width: 700px;
}
</style>