<template>
  <div class="box" id="infinite-list">
    <v-list>
      <v-list-item
        class="light-blue lighten-5"
        v-for="message in messages"
        :key="message"
        :to="{
          name: 'application_tab',
          params: { id: message.reg_id },
        }"
      >
        <!-- <v-list-item-avatar size="20" dark>
          <v-icon small color="indigo">mdi-bell</v-icon>
        </v-list-item-avatar> -->

        <v-list-item-content>
          <v-list-item-title>
            <span class="grey--text">File of</span>
            {{ message.registration.houseowner_name_np }}
          </v-list-item-title>
          <v-list-item-subtitle
            ><strong>
              <span class="indigo--text"
                >Forwarded to
                {{
                  message.desk_bps_user == "null" ? "" : message.desk_bps_user
                }}
                ( {{ message.desk_user_role }} )</span
              >
              <br />
              <span class="grey--text">Message from </span>
              <span class="indigo--text">{{ message.created_by.username }}</span
              >:
              {{ message.message }}
            </strong></v-list-item-subtitle
          >
        </v-list-item-content>
        <!-- <v-list-item-action>
          <v-button icon @click="notificationMore">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-button>
        </v-list-item-action> -->
      </v-list-item>
    </v-list>
    <!-- <span
      v-for="message in messages"
      :key="message"
      >{{ message.message }}<br
    /></span> -->
    <v-progress-circular
      v-if="loadMoreSpinner"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "Posts",
  data() {
    return {
      messages: [],
      pages: [1],
      currentPage: 1,
      loadMoreSpinner: true,
      pageLoaded: false,
    };
  },
  computed: {
    url() {
      return "api/messages?page=" + this.currentPage;
    },
  },
  created() {
    this.getInitialMessage();
  },
  mounted() {
    this.handleScroll();
  },
  methods: {
    getInitialMessage() {
      const messages_url = this.url;
      this.$axios
        .get(messages_url)
        .then((response) => {
          // debugger;
          if (response.status === 200) {
            this.messages = response.data.data;
          }
        })
        .catch((error) => {
          console.log("Navbar error");
          console.error(error);
        });
    },
    getNextMessage() {
      console.log(`loading spinner: ${this.loadMoreSpinner}`);
      this.currentPage = this.currentPage + 1; // this.page++ caused build error;
      this.$axios.get(this.url).then((response) => {
        if (response.status === 200) {
          this.loadMoreSpinner = false;
          response.data.data.forEach((item) => this.messages.push(item));
          this.loadMoreSpinner = true;
          this.pageLoaded = false;
        }
      });
    },
    handleScroll() {
      const listElm = document.querySelector("#infinite-list");
      listElm.addEventListener("scroll", (e) => {
        if (
          listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight &&
          !this.pageLoaded
        ) {
          console.log("Testing one two three");
          this.pageLoaded = true;
          this.getNextMessage();
        }
      });
    },
  },
};
</script>

<style scoped>
.box {
  width: 700px;
  height: 400px;
  overflow: scroll;
}
</style>