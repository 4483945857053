<template>
  <v-container>
    <v-layout row>
      <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></loading>
      <v-flex style="text-align:center">
        <!-- <v-img src="http://cdn.wallpapersafari.com/7/86/gqiGH7.jpg" height="50px" width="50px"> -->

        <v-container class="sober fill-height" fluid>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
              <img :src="require('@/assets/images/gharnaksha_logo.png')" width="140px" />
              <h4>GharNaksha Software</h4>
            </v-col>
          </v-row>

          <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
              <v-card class="elevation-12">
                <v-toolbar color="primary" dark flat>
                  <v-toolbar-title>Password Reset</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-form>
                    <v-text-field v-model="password" name="currentpassword" label="Current Password" type="password">
                    </v-text-field>
                    <v-text-field v-model="new_password" name="new_password" label="New Password" type="password">
                    </v-text-field>
                    <v-text-field v-model="retyped_password" name="retype_password" :rules="[requiredSamePassword()]" label="Re-type New Password" type="password">
                    </v-text-field>
                  </v-form>
                  <v-snackbar v-model="snackbar.show" :bottom="y === 'bottom'" :color="snackbar.color" :left="x === 'left'" :multi-line="mode === 'multi-line'" :right="x === 'right'"
                    :timeout="3000" :top="y === 'top'" :vertical="mode === 'vertical'">
                    {{ message }}
                  </v-snackbar>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" v-on:click="submit">Submit</v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "ResetPassword",
  components: {},
  data() {
    return {
      requiredSamePassword() {
        return v => (this.new_password == this.retyped_password) || `Must be same as new password`;
      },
      password: "",
      new_password: "",
      retyped_password: "",
      token: "",
      message: "",
      snackbar: {
        show: false,
        message: this.message,
        color: "success"
      },
      isLoading: false,
      userRoles: [],
    };
  },

  created() {

  },
  mounted() {
    this.getUserRole();
  },
  methods: {
    submit() {
      this.isLoading = true;
      const url = "api/password-reset";
      VueAxios.post(
        url,
        {
          password: this.password,
          new_password: this.new_password,
          remember: true,
        },
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${window.localStorage.getItem("token")}`
          }
        },
        {
          timeout: 10000
        }
      ).then(response => {
        this.isLoading = false;
        if (response.status === 200 || response.status === 201) {
          this.snackbar.show = true;
          this.snackbar.color = "success";
          this.message = response.data.message;
        }
      })
        .catch(() => {
          this.isLoading = false;
          this.snackbar.show = true;
          this.snackbar.color = "error";
          this.message = "Error!!";
        });
      // .catch(error => this.error = error.response.data.errors);
    },
    async getUserRole() {
      let userRoleTemp = this.$store.getters.getUserRoleList;
      try {
        userRoleTemp.length || await this.$store.dispatch("fetchUserRoleList");
        this.userRoles = this.$store.getters.getUserRoleList;
      } catch (error) {
        console.log("Desk User Role not loaded");
        console.log(error.response.data.message);
      }
    }
  }
};
</script>
