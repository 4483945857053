<template>
  <l-map style="height: 500px" :zoom="zoom" :center="center" v-resize="onResize" ref="analysismap">
    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
    <template v-if="buildingCoordinates.length">
      <l-marker v-for="(marker, index) in buildingCoordinates" :key="index" :lat-lng="marker.geometry.coordinates" :icon="markerIcon">
        <l-tooltip :options="tooltipOptions" className="tooltipClass">
          <router-link :to="{ name: 'home' }" target="_blank">{{ marker.properties.houseowner }}</router-link>
        </l-tooltip>
      </l-marker>
    </template>
    <l-geo-json :geojson="geojson"></l-geo-json>
  </l-map>
</template>

<script>
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LGeoJson, LIcon, LTooltip, LPopup } from "vue2-leaflet";
import 'leaflet/dist/leaflet.css';



export default {
  name: "ResultAnalysisMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LGeoJson,
    // LIcon,
    LTooltip,
    // LPopup
  },
  props: {
    buildingCoordinates: {
      type: Array
    }
  },
  data() {
    const myMarkerIcon = L.icon({
      iconUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png",
      // iconUrl: "/images/home_marker_icon.png",
      shadowUrl:
        "https://unpkg.com/leaflet@1.6.0/dist/images/marker-shadow.png",
      iconSize: [25, 41], // [25, 41], // size of the icon
      shadowSize: [25, 41], // size of the shadow
      iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
      shadowAnchor: [9, 41], // the same for the shadow
    });
    return {
      zoom: 10,
      center: L.latLng(28.539856291720323, 82.00828711109587),
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      // markerLatLng: L.latLng(27.63183217924464, 84.37191009521486),
      markerLatLng: [27.63183217924464, 84.37191009521486],
      geojson: null,
      markerIcon: myMarkerIcon,
      // iconSize: 60,
      iconAnchor: [12,],
      tooltipOptions: {
        permanent: true,
        // sticky: true,
        interactive: true
      }
    };
  },
  created() {
    this.getMapJson();
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    }
  },
  methods: {
    async getMapJson() {
      let map = this.$store.getters.getMap;
      try {
        if (!Object.keys(map).length) {
          await this.$store.dispatch('fetchMap')
        }
        this.geojson = this.$store.getters.getMap;
        this.center = L.latLng(
          this.geojson.features[0].geometry.coordinates[0][0][1],
          this.geojson.features[0].geometry.coordinates[0][0][0]
        );
        this.markerLatLng = L.latLng(27.63183217924464, 84.37191009521486);

      } catch (error) {
        console.log(`api error:${error}`);
      }
    },
    onResize() {
      setTimeout(() => {
        this.$refs.analysismap.mapObject.invalidateSize();
      }, 100);
    },
  },
}
</script>

<style scoped>
.someExtraClass {
  background-color: aqua;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 0 20px 20px 20px;
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
}
</style>