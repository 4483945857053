<template>
  <div>
    <v-row class="two-button-row mx-2">
      <v-col>
        <v-btn class="primary" :to="{ name: 'home' }">Back</v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto"
        ><v-btn
          v-if="user_role_id != 8"
          large
          depressed=""
          class="primary right subtitle-1"
          :to="{ name: 'old_registration' }"
        >
          <v-icon left>mdi-plus</v-icon>
          पुरानो दर्ता
        </v-btn></v-col
      >
    </v-row>
    <v-row>
      <v-col>
        <registration-data-table
          title="पुरानो दर्ता"
          :tableHeaders="tableHeaders"
          :isOld="true"
          fileOpenLink="old_registration_application_tab"
        ></registration-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RegistrationDataTable from "../home/components/RegistrationDataTable.vue";

export default {
  name: "OldRegistrationDashboard",
  components: {
    RegistrationDataTable,
  },
  data() {
    return {
      user_role_id: localStorage.getItem("auth_user_role_id"),
      tableHeaders: [
        {
          text: "S.No.",
          value: "sno",
          searchable: false,
        },
        {
          text: "आर्थिक बर्ष",
          value: "fiscal_year",
          searchable: true,
          searchKey: "fiscal_year",
          defaultSearch: null,
          searchType: "text",
        },
        {
          text: "घरधनीको नाम",
          align: "start",
          value: "houseowner_name_np",
          searchable: true,
          searchKey: "houseowner_name_np",
          defaultSearch: "",
          searchType: "text",
        },
        // {
        //   text: "मिति (वि.स.)",
        //   value: "registration_date_np",
        //   searchable: true,
        //   searchKey: "registration_date_np",
        //   defaultSearch: "",
        // },
        {
          text: "फारम बुझाउने",
          value: "created_by_consultancy",
          searchable: true,
          searchKey: "created_by_name",
          defaultSearch: "",
          searchType: "text",
        },
        // {
        //   text: "वार्ड",
        //   value: "ward_no",
        //   searchable: false,
        // },
        {
          text: "कित्ता नं",
          value: "land_plot_no",
          searchable: true,
          searchKey: "land_plot_no",
          defaultSearch: "",
          searchType: "text",
        },
        {
          text: "Code No",
          value: "file_code",
          searchable: true,
          searchKey: "file_code",
          defaultSearch: "",
          searchType: "text",
        },
        // {
        //   text: "स्थिति",
        //   value: "phase",
        //   searchable: true,
        //   searchKey: "phase_id",
        //   defaultSearch: null,
        // },
        // {
        //   text: "Desk",
        //   value: "desk",
        //   searchable: true,
        //   searchKey: "desk_user_role_id",
        //   defaultSearch: parseInt(localStorage.getItem("auth_user_role_id")),
        // },
        {
          text: "निर्माणको प्रकार",
          value: "construction_type",
          searchable: false,
          searchKey: "construction_type_id",
          defaultSearch: "",
          searchType: "dropdown",
        },
        // {
        //   text: "Pending Days",
        //   value: "pending_days",
        //   searchable: false,
        // },
        {
          text: "कार्य",
          value: "status",
          searchable: false,
        },
      ],
    };
  },
};
</script>

<style>
</style>