<template>
  <div>
    <!-- <v-card> -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></loading>
    <v-row>
      <v-col cols="6">
        <strong>Current Consultant</strong> <br>
        {{ registration.created_by_consultancy }}
      </v-col>
      <v-col cols="6">
        Transfer Consultancy to <br>
        <v-select v-model="newSelectedConsultancy" :items="consultancyList" item-value="id" item-text="name"></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!-- <v-btn class="primary" @click="transferConsultant">Transfer Consultant</v-btn> -->
        <custom-dialog click-type="transferConsultant" :click-dialog="transferConsultant" :reg-id="registration.id">Transfer Consultant</custom-dialog>
      </v-col>
    </v-row>
    <!-- </v-card> -->
  </div>
</template>

<script>
export default {
  name: "ConsultancyTransfer",
  data() {
    return {
      registration: [],
      newSelectedConsultancy: null,
      consultancyList: [],
      regId: null,
      isLoading: false,
    }
  },
  created() {
    this.getRegDataFromStore();
    this.getConsultancyList();
  },
  methods: {
    getRegDataFromStore() {
      this.registration = this.$store.getters.registrationData;
      this.regId = this.registration.id;
      // this.consultancyId = this.registration.created_by.id;
    },
    getConsultancyList() {
      this.loading = true;
      const apiUrl = "api/consultancy";
      this.$axios.get(apiUrl).then(response => {
        this.consultancyList = response.data.data;
        this.isLoading = false;
      }).catch(error => {
        this.isLoading = false;
        console.log(`api error: ${error}`);
      })
    },
    transferConsultant() {
      this.isLoading = true;
      const url = `api/registration/${this.regId}/transfer-to-another-consultant/${this.newSelectedConsultancy}`;
      this.$axios.post(url).then(response => {
        this.$store.dispatch("updateRegistrationLocal", response.data.data);
        this.registration = response.data.data;
        this.$root.$emit("refreshLogs");
        this.isLoading = false;
        // this.snackbar.message = "Successfully transferred consultancy";
        // this.snackbar.show = true;
        this.newSelectedConsultancy = null;
        this.$emit("closePanelAndSnack", "Successfully transferred consultancy");
      }).
        catch(error => {
          this.isLoading = false;
          // this.snackbar.message = error.response.data.message;
          console.log("transfer error:" + error);
        })
    }
  }
}
</script>

<style scoped>
.snack-z-top {
  z-index: 999 !important;
}
</style>