<template>
  <v-container>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <v-row class="my-2">
      <v-btn
        color="primary"
        :to="{
          name: 'application_tab',
          params: { id: this.$route.params.regId },
        }"
        >Back</v-btn
      >

      <v-dialog v-model="addNewReceiptDialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mx-2" color="primary" dark v-bind="attrs" v-on="on"> Add New Receipt </v-btn>
        </template>
        <v-card class="pa-4">
          <v-form v-model="valid">
            <v-text-field v-model="newReceipt.name" label="New Receipt Name" :rules="[(v) => !!v || 'Item is required']"> </v-text-field>
            <v-textarea v-model="newReceipt.description" label="Description"></v-textarea>
            <v-btn color="error" class="ma-1" @click="addNewReceiptDialog = false">Cancel </v-btn>
            <v-btn color="primary" class="ma-1" @click="addNewReceipt" :disabled="!valid">Add New Receipt </v-btn>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Tabbed revenue -->
    <v-row v-if="revenueInfo.length">
      <v-tabs v-model="tab" background-color="primary" dark>
        <v-tab v-for="(item, i) in revenueInfo" :key="i">
          {{ item.meta.name ? item.meta.name : "No Name" }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(item, j) in revenueInfo" :key="j">
          <v-card flat>
            <Revenue
              :revenue-info="item"
              :other-revenue-form-fields="otherRevenueFormFields"
              :is-add-edit-authorized="true"
              @deletereceipt="removeReceiptFromList(j)"
            />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-row>
    <!-- <v-row v-if="!revenueInfo.length && !isLoading">
      <v-col>
        <h3>Not a single revenue recept found. Create new by clicking the above button</h3>
      </v-col>
    </v-row> -->
  </v-container>
</template>
<script>
import Revenue from "./Revenue.vue";
import testData from "./test_data";

export default {
  name: "RevenueList",
  data() {
    return {
      valid: false,
      addNewReceiptDialog: false,
      newReceipt: {
        name: "",
        description: "",
      },
      receiptName: "",
      tab: null,
      receiptEditable: false,
      revenueInfo: [],
      otherRevenueFormFields: [],
      floorDetail: [],
      isLoading: false,
    };
  },
  components: {
    Revenue,
  },
  created() {
    this.getFloorDetail();
    this.getRevenueInfo();
    this.getOtherRevenueFormFields();
  },
  mounted() {
    this.createReceiptIfEmpty();
  },
  methods: {
    createReceiptIfEmpty() {
      if (this.revenueInfo.length == 0) {
        if (this.isLoading) {
          setTimeout(this.createReceiptIfEmpty, 100);
          console.log("Check check nice");
        } else {
          console.log("No saved receipt. Creating new");
          this.newReceipt.name = "Receipt One";
          this.newReceipt.description = "New receipt";
          this.addNewReceipt();
        }
      }
    },
    addNewReceipt() {
      let newEmptyFloorRevenue = [];
      if (this.floorDetail.length) {
        this.floorDetail.forEach((element) => {
          newEmptyFloorRevenue.push({
            id: 0,
            application_id: element.application_id,
            floor_detail_id: element.id,
            area: element.area,
            prev_area: element.prev_area,
            block: element.block,
            floor: element.floor,
            floor_name: element.floor_name,
            revenue_rate: null,
            revenue_amount: null,
            revenue_remarks: "",
          });
        });
      }

      const newRevenue = {
        meta: {
          receipt_id: 0,
          name: this.newReceipt.name,
          description: this.newReceipt.description,
          reg_id: this.$route.params.regId,
        },
        floorRevenue: newEmptyFloorRevenue,
        otherRevenue: [],
        compoundWallRevenue: [],
      };
      this.revenueInfo.push(newRevenue);
      this.tab = this.revenueInfo.length - 1;
      this.addNewReceiptDialog = false;
      // Clear the meta local value
      this.newReceipt.name = "";
      this.newReceipt.description = "";
      //   console.log("Created");
    },

    // Get revenueinfo has been transferred here from revenue component
    getRevenueInfo() {
      // #test code here for now, change later
      // this.revenueInfo = testData;
      this.isLoading = true;
      const url = `api/registration/${this.$route.params.regId}/revenue-receipt`;
      this.$axios
        .get(url)
        .then((response) => {
          this.revenueInfo = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(`api error:${error}`);
        });
    },
    getOtherRevenueFormFields() {
      const url = `api/other-revenue-form-fields`;
      this.$axios
        .get(url)
        .then((response) => {
          this.otherRevenueFormFields = response.data.data;
        })
        .catch((error) => {
          console.log(`Error Other revenue fields: ${error}`);
        });
    },
    getFloorDetail() {
      const url = `api/registration/${this.$route.params.regId}/floor-detail`;
      this.$axios
        .get(url)
        .then((response) => {
          this.floorDetail = response.data.data;
        })
        .catch((error) => {
          console.log(`Floor Detail api error: ${error}`);
        });
    },

    removeReceiptFromList(index) {
      // console.log("Receipt deleted index"+index);
      this.revenueInfo.splice(index, 1);
    },

    // save() {
    //     // Save api call below
    //     console.log("save from parent");

    //     const eurl = `api/registration/${this.$route.params.regId}/floor-info-revenue/1`;
    //     this.$axios
    //         .put(eurl, this.revenueInfo)
    //         .then((response) => {
    //             if (response.status === 200 || response.status === 201) {
    //                 this.isFormSubmitting = true;
    //                 this.revenueInfo = response.data.data;
    //                 this.snackbar.show = true;
    //                 this.successfulMessage = "Data Updated successfully";
    //             }
    //             console.log(response);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }
  },
};
</script>
<style>
</style>