<template>
  <div class="my-4 border border-black mx-4">
    <v-row class="border primary mb-0 mx-0 min-height-toolbar">
      <v-col class="ma-0 pa-0 d-flex align-center px-2 background--text">
        <!-- <v-toolbar dark flat class="mx-4 mt-3 coltext--text"> -->
        <v-toolbar-title v-if="user_role_id != 8" class="text-h5"> {{ title }}</v-toolbar-title>
        <v-toolbar-title v-if="user_role_id == 8" class="text-h5"> My Files </v-toolbar-title>
        <!-- </v-toolbar> -->
      </v-col>
      <v-col class="ma-0 pa-0 mr-4 pb-2 px-4 d-flex align-center" cols="auto" v-if="parseInt(configurations.show_user_only_file) == 1 && user_role_id != 8 && !isOld">
        <v-switch v-if="user_role_id != 8" v-model="searchKeys.user_file_only" hide-details="auto" inset @change="changeLocalConfigurations" class="" color="primary lighten-3"
          dark>
          <template v-slot:label>
            <span class="background--text">{{ searchKeys.user_file_only == true ? "Files on your Desk" : "All Submitted Files" }}</span>
          </template>

          <template v-slot:default>
            nice
          </template>
        </v-switch>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-spacer></v-spacer>
      <v-col
        class="pa-0 mr-4 pb-2 px-4 border-switch d-flex align-center"
        cols="auto"
        v-if="parseInt(configurations.show_user_only_file) == 1 && user_role_id != 8 && !isOld"
      >
        <v-switch
          v-if="user_role_id != 8"
          v-model="searchKeys.user_file_only"
          :label="searchKeys.user_file_only == true ? 'Files on your Desk' : 'All Submitted Files'"
          hide-details="auto"
          inset
          @change="changeLocalConfigurations"
        ></v-switch>
      </v-col>
    </v-row> -->
    <loading :active.sync="isDataLoading" :can-cancel="false" :is-full-page="false"></loading>
    <v-data-table :headers="headers" :header-props="{ sortIcon: null }" :items="items" :search="search" :items-per-page="itemsPerPage" :hide-default-header="false"
      :hide-default-footer="false" :loading="isLoading" :server-items-length="total" :footer-props="{
          'items-per-page-options': [10, 20, 50],
          'show-first-last': true,
        }" :page.sync="page" @update:page="searchFilters" item-key="name" class="sober" @update:items-per-page="updatePageItemSize" :disable-sort="$vuetify.breakpoint.smAndDown">
      <!-- If mobile view be same as default view put mobile-breakpoint="0" in v-data-table attr -->

      <template v-slot:[`body.prepend`]>
        <tr class="searchInput mb-sm-4">
          <template v-for="(item, index) in tableHeaders">
            <td :key="index" v-if="item.searchable && item.searchType == 'text'">
              <v-text-field :label="item.text" type="text" v-model="searchKeys[item.searchKey]" @keyup="searchFilters">
              </v-text-field>
            </td>
            <td :key="index" v-if="item.searchable &&
          item.searchType == 'dateRange' &&
          item.searchKey[0] == 'start_date'
          ">
              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0" cols="12">
                  <!-- <NepaliDatePicker v-model="searchKeys[item.searchKey[0]]" label="देखि" @keyup="searchFilters" /> -->
                  <v-text-field label="देखि" type="text" v-model="searchKeys[item.searchKey[0]]" @keyup="searchFilters" dense>
                  </v-text-field>
                </v-col>
                <v-col class="ma-0 pa-0" cols="12">
                  <!-- <NepaliDatePicker v-model="searchKeys[item.searchKey[1]]" label="सम्म" @keyup="searchFilters" /> -->
                  <v-text-field label="सम्म" type="text" v-model="searchKeys[item.searchKey[1]]" @keyup="searchFilters" dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <!-- <v-dialog v-model="dialog" persistent max-width="290">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark v-bind="attrs" v-on="on">Date Filter </v-btn>
                </template>
  <v-card>
    <v-card-title class="text-h5"> Use Google's location service? </v-card-title>
    <v-card-text>Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are
      running.</v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="green darken-1" text @click="dialog = false"> Disagree </v-btn>
      <v-btn color="green darken-1" text @click="dialog = false"> Agree </v-btn>
    </v-card-actions>
  </v-card>
  </v-dialog> -->
            </td>
            <td :key="index" v-if="item.searchable && item.searchType == 'dropdown' && item.searchKey == 'phase_id'">
              <v-select v-if="item.searchKey == 'phase_id'" class="filter-select" v-model="searchKeys.phase_id" label="स्थिति" clearable :items="phaseList" item-value="phase_id"
                item-text="phase_name" v-on:change="searchFilters">
              </v-select>
            </td>
            <td :key="index" v-if="item.searchable && item.searchType == 'dropdown' && item.searchKey == 'desk_user_role_id'">
              <v-select class="filter-select" v-model="searchKeys.desk_user_role_id" label="Desk" clearable :items="userDesk" item-value="role_id" item-text="role_name"
                v-on:change="searchFilters"></v-select>
            </td>
            <td :key="index" v-if="item.searchable && item.searchType == 'dropdown' && item.searchKey == 'construction_type_id'">
              <v-select class="filter-select" v-model="searchKeys.construction_type_id" label="निर्माणको प्रकार" clearable :items="constructionTypeList"
                item-value="construction_type_id" item-text="construction_type_name" v-on:change="searchFilters"></v-select>
            </td>
            <td :key="index" v-if="item.searchable && item.searchType == 'dropdown' && item.searchKey == 'building_plinth_area'">
              <v-select class="filter-select" v-model="searchKeys.building_plinth_area" label="प्लिन्थ एरिया" clearable :items="buildingPlinthAreaType" item-value="value"
                item-text="label" v-on:change="searchFilters"></v-select>
            </td>
            <td v-if="!item.searchable" :key="index" class="display-none-sm"></td>
          </template>
        </tr>
      </template>
      <!-- Skeleton Loader Here -->

      <template v-slot:[`item.yellowslip`]="{ item }">
        <v-btn v-if="user_role_id != 8" color="primary small-btn" small :to="{ name: 'yellowslip', params: { regId: item.id } }">दर्ता रसिद
        </v-btn>
      </template>

      <template v-slot:[`item.desk_user_role_id`]="{ item }">
        {{ deskName(item) }} <span style="background-color: antiquewhite; border-color: black; border-radius: 1rem; "> {{ !item.is_mun_file ? "(Ward)" : "" }}</span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-btn color="primary background--text small-btn" small :to="{ name: fileOpenLink, params: { id: item.id } }">Open </v-btn>
        <custom-dialog v-if="item.desk_user_role_id == 8 && item.phase_id == 1 && user_role_id == 8" :click-dialog="deleteRecord" :regId="item.id" click-type="delete">
          Delete
        </custom-dialog>
      </template>

      <template v-slot:[`item.pending_days`]="{ item }">
        {{ item.pending_days_array.nepali_date_pending_day }}

        <v-tooltip bottom color="danger">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="red" v-if="pendingDaysLimitCrossed(item)" v-bind="attrs" v-on="on">mdi-calendar-alert-outline</v-icon>
          </template>
          <span>
            <v-icon color="red">mdi-calendar-alert-outline</v-icon> signifies that the file has crossed the pending days limit :
            {{ pendingDaysLimit }} days</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <!-- <div v-if="pendingDaysLimit && headerContainPendingDays">
      <p><i>General Info</i></p>
      <v-icon color="red">mdi-calendar-alert-outline</v-icon> signifies that the file has crossed
      the pending days limit : {{ pendingDaysLimit }} days
    </div> -->
  </div>
</template>

<script>
import NepaliDatePicker from "@/custom-inputs/nepali-date-picker/NepaliDatePicker.vue";
export default {
  name: "RegistrationDataTable",
  components: {
    // NepaliDatePicker,
  },
  props: {
    title: {
      type: String,
      default: "दर्ता भएका निवेदनहरु",
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    fileOpenLink: {
      type: String,
      default: "application_tab",
    },
    tableHeaders: {
      type: Array,
      default: () => [
        {
          text: "S.No.",
          value: "sno",
          searchable: false,
        },
        {
          text: "घरधनीको नाम",
          align: "start",
          value: "houseowner_name_np",
          searchable: true,
          searchKey: "houseowner_name_np",
          defaultSearch: "",
          searchType: "text",
        },
        {
          text: "मिति (वि.स.)",
          value: "registration_date_np",
          searchable: true,
          searchKey: ["start_date", "end_date"],
          defaultSearch: ["", ""],
          searchType: "dateRange",
          // searchType: "text",
        },
        {
          text: "फारम बुझाउने",
          value: "created_by_consultancy",
          searchable: true,
          searchKey: "created_by_name",
          defaultSearch: "",
          searchType: "text",
        },
        {
          text: "वार्ड",
          value: "ward_no",
          searchable: true,
          searchKey: "ward_no",
          defaultSearch: null,
          searchType: "text",
        },
        {
          text: "कित्ता नं",
          value: "land_plot_no",
          searchable: true,
          searchKey: "land_plot_no",
          defaultSearch: "",
          searchType: "text",
        },
        {
          text: "Code No",
          value: "file_code",
          searchable: true,
          searchKey: "file_code",
          defaultSearch: "",
          searchType: "text",
        },
        {
          text: "स्थिति",
          value: "phase",
          searchable: true,
          searchKey: "phase_id",
          defaultSearch: null,
          searchType: "dropdown",
        },
        {
          text: "Desk",
          value: "desk_user_role_id",
          searchable: true,
          searchKey: "desk_user_role_id",
          defaultSearch: parseInt(localStorage.getItem("auth_user_role_id")) != 1 ? parseInt(localStorage.getItem("auth_user_role_id")) : null,
          searchType: "dropdown",
        },
        {
          text: "निर्माणको प्रकार",
          value: "construction_type",
          searchable: true,
          searchKey: "construction_type_id",
          defaultSearch: "",
          searchType: "dropdown",
        },
        {
          text: "Plinth Area (sq ft)",
          value: "building_plinth_area",
          searchable: true,
          searchKey: "building_plinth_area",
          defaultSearch: "",
          searchType: "dropdown",
        },
        {
          text: "Pending Days",
          value: "pending_days",
          searchable: false,
        },
        {
          text: "कार्य",
          value: "status",
          searchable: false,
        },
      ],
    },
  },
  data() {
    return {
      // boolean to start/stop progress spinner
      isDataLoading: true,
      user_role_id: localStorage.getItem("auth_user_role_id"),
      enabled: null,
      items: [],
      search: null,
      userDesk: [],
      phaseList: [],
      user_role_name: "",
      searchkeyFormData: new FormData(),
      // For pagination info
      pageInfo: [],
      // itemsPerPage: 10,
      total: 0,
      page: 1,
      options: {},

      configurations: {
        show_user_only_file: 0
      },
      constructionTypeList: [],
      pendingDaysLimit: 5,
      searchFiltersHTML: "",
      buildingPlinthAreaType: [
        { value: '>1000', label: '१००० माथि' },
        { value: '<1000', label: '१००० तल' }
      ]
    };
  },
  computed: {
    // Put this function in created maybe
    headerContainPendingDays() {
      let tempTableHeaders = [];
      tempTableHeaders = this.tableHeaders;
      let contains = tempTableHeaders.some((e) => e.value == "pending_days");
      // console.log('contains value '+contains);
      return contains;
    },
    searchKeys() {
      // if (Object.keys(this.$store.getters.searchKeysAll).length) {
      //   if (this.$store.getters.searchKeysAll.is_old !== this.isOld) {
      //     console.log("search keys old " + this.$store.getters.searchKeysAll.is_old + this.isOld);
      //     const tempSearchKeys = {};
      //     this.$store.dispatch("updateSearchKeys", tempSearchKeys);

      //   }
      //   return this.$store.getters.searchKeysAll;
      // }
      // else {
      let tempTableHeaders = [];
      let tempSearchKeys = {};
      tempTableHeaders = this.tableHeaders;
      tempTableHeaders.forEach((item) => {
        if (item.searchable && item.searchType != "dateRange") {
          tempSearchKeys[item.searchKey] = item.defaultSearch;
        } else if (item.searchable && item.searchType == "dateRange") {
          tempSearchKeys[item.searchKey[0]] = item.defaultSearch[0];
          tempSearchKeys[item.searchKey[1]] = item.defaultSearch[1];
        }
      });
      this.isOld ? (tempSearchKeys["is_old"] = 1) : (tempSearchKeys["is_old"] = 0);
      tempSearchKeys["items_per_page"] = this.itemsPerPage;
      if (tempSearchKeys["user_file_only"]) {
        tempSearchKeys["user_file_only"] = this.$store.getters.getUserDeskFileOnly;
      }

      console.log("search keys" + JSON.stringify(tempSearchKeys));
      this.$store.dispatch("updateSearchKeys", tempSearchKeys);
      return tempSearchKeys;
      // }

    },
    isLoading() {
      return this.isEnabled("progress");
    },
    headers() {
      if (this.user_role_id != 8) {
        return this.tableHeaders;
      } else {
        let tempTableHeaders = [];
        tempTableHeaders = this.tableHeaders;
        let createdByIndex = tempTableHeaders.findIndex(
          (e) => e.value === "created_by_consultancy"
        );
        console.log("test headers " + createdByIndex);
        if (createdByIndex > 0) {
          tempTableHeaders.splice(createdByIndex, 1);
        }
        return tempTableHeaders;
      }
    },
  },
  watch: {
    enabled(slot) {
      if (slot === "no-data") {
        this.items = [];
      } else if (slot === "no-results") {
        this.search = "...";
      } else {
        this.search = null;
      }
    },
    searchKeys: {
      handler() {
        this.page = 1;
      },
      deep: true,
    },
    page() {
      this.isDataLoading = true;
    },
  },
  created() {
    this.getSiteConfiguration();
  },
  mounted() {
    this.getConstructionType();
    this.getUserDesk();
    this.getPhaseList();
  },
  methods: {
    pendingDaysLimitCrossed(item) {
      let inputPendingDaysInt = 0;
      // Fallback if pending_days_int is not added to registration resources
      item.pending_days["english_date_pending_day"]
        ? (inputPendingDaysInt = item.pending_days["english_date_pending_day"])
        : parseInt(item.pending_days.substring(0, 3));
      if (this.configurations.pending_days_limit) {
        this.pendingDaysLimit = this.configurations.pending_days_limit;
      }
      if (
        (item.phase_id == 1 && item.desk_user_role_id == 2) ||
        item.desk_user_role_id != 2 ||
        item.phase_id <= 5
      ) {
        return inputPendingDaysInt > this.pendingDaysLimit;
      }
      return false;
    },
    deskName(file) {
      let userRoleList = this.userDesk;
      let userDeskName = userRoleList.filter((e) => e.role_id == file.desk_user_role_id)[0].role_name;
      // console.log("user desk name" + userDeskName);
      return userDeskName;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    isEnabled(slot) {
      return this.enabled === slot;
    },
    updatePageItemSize(pageSize) {
      this.searchKeys.items_per_page = pageSize;
      this.searchFilters();
    },
    preSearchFilter() {
      console.log("pre search filter activated, nice");
      if (this.searchKeys.start_date && this.searchKeys.end_date) {
        this.searchFilters();
      } else {
        console.log("one of them is empty");
      }
    },
    searchFilters() {
      this.isDataLoading = true;
      this.searchkeyFormData = this.jsonToFormData(this.searchKeys);
      const statusurl = `api/registration/search`;
      this.$axios
        .post(statusurl, this.searchKeys, {
          params: { page: parseInt(this.page) },
        })
        .then((response) => {
          let newItems = [];
          newItems = response.data.data;
          // Get paginate info
          this.pageInfo = response.data;
          this.itemsPerPage = response.data.per_page;
          this.total = response.data.total;

          newItems = newItems.map((d, index) => ({
            ...d,
            sno: index + 1,
          }));
          this.items = newItems;
          this.isDataLoading = false;
          this.$store.dispatch("updateSearchKeys", this.searchKeys);
        })
        .catch((error) => {
          console.log("No data");
          console.error(error);
          this.isDataLoading = false;
        });
    },
    deleteRecord(id) {
      const deleteurl = `api/registration/${id}`;
      this.$axios.delete(deleteurl).then((response) => {
        if (response.status === 200 || response.status === 201) {
          // this.snackbar.show = true;
          // this.successfulMessage = "Data deleted successfully";
          this.$router.go();
        }
      });
    },
    buildFormData(formData, data, parentKey) {
      if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
      } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
      }
    },
    jsonToFormData(data) {
      this.searchkeyFormData = new FormData();
      this.buildFormData(this.searchkeyFormData, data);

      return this.searchkeyFormData;
    },
    async getUserDesk() {
      let userRoleTemp = this.$store.getters.getUserRoleList;
      try {
        userRoleTemp.length || await this.$store.dispatch("fetchUserRoleList");
        this.userDesk = this.$store.getters.getUserRoleList;
        this.userDesk.forEach((user) => (user.role_id == this.user_role_id ? (this.user_role_name = user.role_name) : null));
        this.searchFilters();
      } catch (error) {
        console.log("Desk User Role not loaded");
        console.log(error.response.data.message);
      }
    },
    async getPhaseList() {
      let phaseTemp = this.$store.getters.getPhaseList;
      phaseTemp.length || await this.$store.dispatch("fetchPhaseList");
      this.phaseList = this.$store.getters.getPhaseList;
    },
    async getSiteConfiguration() {
      let siteConfTemp = this.$store.getters.siteConfigurationsList;
      try {
        if (!siteConfTemp) {
          console.log("no site confs local")
          await this.$store.dispatch("getSiteConfigurations");
        }
        this.configurations = this.$store.getters.siteConfigurationsList;
      } catch (error) {
        console.log("Site configuration not loaded");
        console.log(error.response.data.message);
      }
    },
    async getConstructionType() {
      let constructionTypeListTemp = this.$store.getters.constructionTypeList;
      try {

        if (!constructionTypeListTemp.length) {
          await this.$store.dispatch("getConstructionTypeList")
        }
        this.constructionTypeList = this.$store.getters.constructionTypeList;
      } catch (error) {
        console.log(error);
      }
    },
    // getLocalConfigurations() {
    //   this.searchKeys.user_file_only = this.$store.getters.getUserDeskFileOnly;
    // },
    changeLocalConfigurations() {
      // these vuex methods are just workarounds for now. Pre
      this.searchFilters();
      this.$store.commit("changeUserDeskFileOnly", this.searchKeys.user_file_only);
    },
  },
};
</script>

<style scoped>
.two-button-row {
  margin-bottom: 5px;
}

.d-flex .v-card .v-sheet {
  border: none !important;
  box-shadow: none !important;
}

.small-btn {
  height: 35px;
}

tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.filter-select {
  max-width: 10rem;
}

@media screen and (max-width: 600px) {
  .searchInput {
    display: flex;
    flex-direction: column;
  }

  .filter-select {
    max-width: none;
  }

  .display-none-sm {
    display: none;
  }

  .mb-sm-4 {
    margin-bottom: 1rem;
  }
}
</style>

<style>
.v-data-table>.v-data-table__wrapper .v-data-table__mobile-table-row {
  border: solid purple 1px !important;
  margin-bottom: 1rem;
}

.v-btn.v-btn--has-bg.border-primary {
  border-color: var(--v-primary-base) !important;
}

.border-switch {
  border: 1px solid var(--v-primary-lighten2);
  /* padding: 0 20px !important; */
  border-radius: 40px;
}

.min-height-toolbar {
  min-height: 60px;
}
</style>