<template>
  <div>Two is rendering</div>
</template>

<script>
export default {
  name: "ComponentTwo",
  data() {
    return {
      formInfo: [
        {
          two: "ok chha ta!!",
        },
        {
            hora: "yes ho ni",
        }
      ],
    };
  },
};
</script>

<style>
</style>