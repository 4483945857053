<style scoped>
.text-vertical-align {
  display: inline-block;
  vertical-align: middle;
}
</style>
<template>
  <div>
    <b-container>
      <b-overlay spinner-variant="primary" :show="isLoading" rounded="lg">
        <b-form>
          <b-row v-for="(n, index) in checklistMaster" :key="n">
            <b-col>
              <b-row class="primary text-white block ma-0">
                <b-col class="pa-0"
                  ><span
                    class="pa-1 text-vertical-align"
                    style="font-size: 18px"
                  >
                    {{ index + 1 }})
                    {{ n.title ? n.title : "Title of the compliance" }}
                  </span></b-col
                >
                <b-col cols="auto" class="pa-0">
                  <b-button class="primary" v-on:click="deleteForm(index, n.id)"
                    >Close</b-button
                  >
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="8">
                  <b-form-group
                    label="Title"
                    detail="Title/ Heading of the checklist"
                  >
                    <b-form-input v-model="n.title"></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Content"
                    detail="Content of the checklist"
                  >
                    <b-form-textarea
                      id="textarea"
                      rows="4"
                      v-model="n.content"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-row
                    v-for="(m, vindex) in n.variable_master"
                    :key="m"
                    class="ma"
                  >
                    <b-col class="pa-0">
                      <b-form-group
                        label="Var Name"
                        detail="Variable of the checklist"
                        class="pr-1"
                      >
                        <b-form-input v-model="m.var_name"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col class="pa-0">
                      <b-form-group
                        label="Var Type"
                        detail="Variable type of the checklist"
                        class="pr-1"
                      >
                        <b-form-input v-model="m.var_type"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col class="text-vertical-align pa-0">
                      <b-button
                        variant="danger"
                        size="sm"
                        v-on:click="deleteVarForm(index, vindex, m.id)"
                        class="text-vertical-align mt-8 ml-2"
                        >Delete</b-button
                      >
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-button
                        pill
                        variant="primary"
                        class="primary"
                        v-on:click="addNewVarForm(index, n.id)"
                        >Add</b-button
                      >
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-button class="primary mr-2" v-on:click="save()"
            >Save Checklist</b-button
          >
          <b-button class="primary" v-on:click="addNewForm()"
            >Add another</b-button
          >
        </b-form>
      </b-overlay>
    </b-container>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="y === 'bottom'"
      :color="snackbar.color"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="3000"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      {{ successfulMessage }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      valid: false,
      checklistMaster: [
        {
          id: 0,
          checklist_division_id: 1,
          title: "",
          content: "",
          variable_master: [
            {
              var_name: "",
              var_type: "",
            },
          ],
        },
      ],
      sucessfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
    };
  },
  mounted() {
    this.getChecklistMaster();
  },
  methods: {
    getChecklistMaster() {
      const cMasterUrl = `api/checklist-master`;
      this.$axios
        .get(cMasterUrl)
        .then((response) => {
          this.checklistMaster = [];
          this.checklistMaster = response.data.data;
          if (this.checklistMaster.length === 0) {
            this.addNewForm();
          }
        })
        .catch((error) => {
          console.log(`${error}`);
        });
    },
    addNewForm() {
      this.checklistMaster.push({
        id: 0,
        checklist_division_id: 1,
        title: "",
        content: "",
        variable_master: [
          {
            id: 0,
            var_name: "",
            var_type: "",
          },
        ],
      });
    },
    addNewVarForm(index, id) {
      // the index is the index of checklist master
      this.checklistMaster[index].variable_master.push({
        id: 0,
        var_name: "",
        var_type: "",
      });
    },
    deleteVarForm(index, vindex, id) {
      this.checklistMaster[index].variable_master.splice(vindex, 1);
      if (id !== 0) {
        const deleteUrl = `api/checklist-variable-master/${id}`;
        this.$axios
          .delete(deleteUrl)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.successfulMessage = "Data deleted successfully";
              // const i = this.landinfoarea.map(item => item.id).indexOf(id); // find index of your object
              // this.landinfoarea.splice(i, 1);
            }
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    deleteForm(index, id) {
      this.checklistMaster.splice(index, 1);
      if (id !== 0) {
        const deleteUrl = `api/checklist-master/${id}`;
        this.$axios
          .delete(deleteUrl)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.successfulMessage = "Data deleted successfully";
              // const i = this.landinfoarea.map(item => item.id).indexOf(id); // find index of your object
              // this.landinfoarea.splice(i, 1);
            }
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    save() {
      this.isLoading = true;
      console.log("Save init");
      if (
        this.checklistMaster[0].id === "null" ||
        this.checklistMaster[0].id === 0 ||
        this.checklistMaster[0].id === undefined
      ) {
        console.log("save tir aayo");
        const saveUrl = `/api/checklist-master`;
        this.$axios
          .post(saveUrl, { data: this.checklistMaster })
          .then((response) => {
            //  debugger;
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.checklistMaster = response.data.data;
              this.successfulMessage = "Ghardhani data added successfully.";
              this.isLoading = false;
              // this.$refs.form.reset();
            }
          })
          .catch((error) => {
            console.log(`api error:${error}`);
            this.isLoading = false;
          });
      } else {
        // self.formData.append("_method", "PUT"); //Method spoofing to insert files via post for edit
        console.log("edit tir aayo");
        const eurl = `/api/checklist-master/1`;
        this.$axios
          .put(eurl, { data: this.checklistMaster })
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.successfulMessage = "Ghardhani data updated successfully";
              this.checklistMaster = response.data.data;
              this.isLoading = false;
              // this.$refs.form.reset();
              // this.newhouseownerinfo.id = 0;
            }
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
          });
      }
    },
  },
};
</script>   