<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-text-field label="Nepali names" :rules="[$valid.isRequired, $valid.isPhone]">
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          label="English names"
          :rules="[$valid.isEnglishAlphabet]"
        >
        </v-text-field>
        <!-- {{$regexList.engName}} -->
        <!-- {{$valid}} -->
      </v-col>
      <v-col cols="12" sm="6" md="4"></v-col>
      <v-col cols="12" sm="6" md="4"></v-col>
      <v-col cols="12" sm="6" md="4"></v-col>
      <v-col cols="12" sm="6" md="4"></v-col>
      <v-col cols="12" sm="6" md="4"></v-col>
      <v-col cols="12" sm="6" md="4"></v-col>
    </v-row>
  </div>
</template>

<script>
// import validator from "@/form-validator/index";
export default {
  data() {
    return {
      valid: {
        // nepaliName: (v) => validator.isNepaliAlphabet(v) || "Bruh nepali ma k",
        // englishName: (v) => validator.isEnglishAlphabet(v),
        required: (v) => this.$valid.isRequired(v, "nice"),
      },
    };
  },
};
</script>

<style>
</style>