<template>
  <b-container class="h-2000">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <v-row>
      <v-btn color="primary" :to="{
        name: 'application_tab',
        params: { id: this.$route.params.regId },
      }">Back</v-btn>
    </v-row>
    <v-row>
      <!-- <div class="pa-2">
        <p>
          भवनको वर्गिकरणः <strong>{{ application.building_category }}</strong>
        </p>
        <p>
          भवनको संरचना प्रणाली:
          <strong>{{ application.building_structure }}</strong>
        </p>
      </div> -->
      <v-simple-table class="sober">
        <thead>
          <tr class="registrationtable">
            <th rowspan="2" class="registrationtable" v-if="$store.getters.registrationData.construction_type_id == 11">
              <h5>Block</h5>
            </th>
            <th rowspan="2" class="registrationtable">
              <h5>Floor</h5>
            </th>
            <th v-if="hasHistory" rowspan="2" class="registrationtable">
              <h5>Previous Area</h5>
            </th>
            <th rowspan="2" class="registrationtable">
              <h5>Area</h5>
            </th>
            <th colspan="2" class="registrationtable">
              <h5>Municipality Fees</h5>
            </th>
            <th rowspan="2" class="registrationtable">
              <h5>Remarks</h5>
            </th>
          </tr>
          <tr class="registrationtable">
            <th class="registrationtable">
              <h5>Rate</h5>
            </th>
            <th class="registrationtable">
              <h5>Amount</h5>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="registrationtable" v-for="(floor, index) in revenueInfo.floorRevenue" :key="index">
            <td class="registrationtable" v-if="$store.getters.registrationData.construction_type_id == 11">Block {{
            floor.block }}</td>
            <td class="registrationtable">{{ floor.floor_name }}</td>
            <td class="registrationtable" v-if="hasHistory">
              <v-text-field v-model="floor.prev_area" type="number" hide-details="auto" solo class="my-2"
                @input="calculateAmount"></v-text-field>
            </td>
            <td class="registrationtable">
              <v-text-field v-model="floor.area" type="number" hide-details="auto" solo class="my-2"
                @input="calculateAmount"></v-text-field>
            </td>
            <td class="registrationtable">
              <v-text-field v-model="floor.revenue_rate" type="number" hide-details="auto" solo class="ma-0 pa-0"
                @input="calculateAmount"></v-text-field>
            </td>
            <td class="registrationtable">
              <v-text-field v-model="floor.revenue_amount" hide-details="auto" solo class="ma-0 pa-0"></v-text-field>
            </td>
            <td class="registrationtable">
              <v-text-field v-model="floor.revenue_remarks" hide-details="auto" solo class="ma-0 pa-0"></v-text-field>
            </td>
          </tr>
          <tr class="registrationtable">
            <td class="registrationtable" colspan="3">
              <b>Sub Total</b>
            </td>
            <td class="registrationtable">
              <v-text-field :value="subTotal" hide-details="auto" solo class="my-2"></v-text-field>
            </td>
            <td class="registrationtable">
              <v-text-field solo hide-details="auto" class="ma-0 pa-0"></v-text-field>
            </td>
          </tr>

          <tr class="registrationtable" v-for="field in revenueInfo.otherRevenue" :key="field">
            <td class="registrationtable" colspan="3">
              {{ field.name }}
            </td>
            <td class="registrationtable">
              <v-text-field v-model="field.amount" hide-details="auto" solo class="my-2" @input="calculateGrandTotal">
              </v-text-field>
            </td>
            <td>
              <v-text-field v-model="field.remarks" hide-details="auto" solo></v-text-field>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-btn color="primary" v-on:click="save" class="ma-3">Save</v-btn>
      <div class="mt-3">
        <pdf-dialog :src="basePath + printRevenue" :isSmall="false" displayText="Print Revenue"></pdf-dialog>
      </div>

      <v-snackbar v-model="snackbar.show" :bottom="y === 'bottom'" :color="snackbar.color" :left="x === 'left'"
        :multi-line="mode === 'multi-line'" :right="x === 'right'" :timeout="3000" :top="y === 'top'"
        :vertical="mode === 'vertical'">
        {{ successfulMessage }}
      </v-snackbar>
    </v-row>
  </b-container>
</template>

<script>
export default {
  name: "revenueinfo",
  data() {
    return {
      isLoading: false,
      userRoleId: localStorage.getItem("auth_user_role_id"),
      basePath: process.env.VUE_APP_BASE_URL,
      printRevenue: `api/registration/${this.$route.params.regId}/floor-revenue-dastur`,
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      revenueInfo: [],
      otherRevenueFormFields: [],
      floorRevenueSurface: [],
      floorRevenueBase: [],
      application: {},
      // hasHistory: false,
      historyFloorRevenue: [],
    };
  },
  created() {
    this.getFloorRevenueInfo();
    // this.getOtherRevenueFormFields();
    this.getApplicationData();
    this.getFloorRevenueHistory();
  },
  watch: {
    // revenueInfo: {
    // }
  },
  computed: {
    hasHistory: function () {
      if (this.historyFloorRevenue.length || this.$store.getters.registrationData.construction_type_id == 5) {
        return true;
      } else {
        return false;
      }
    },
    subTotal: function () {
      let tempSubTotal = 0;
      this.revenueInfo.floorRevenue.forEach((element) => {
        tempSubTotal =
          parseFloat(
            element.revenue_amount == "" || element.revenue_amount == null
              ? 0
              : element.revenue_amount
          ) + tempSubTotal;
      });
      return tempSubTotal.toFixed(2);
    },
  },
  mounted() {
    // this.getOtherRevenueFormFields();
    // this.getFloorRevenueInfo();
    // this.createEmptyOtherRevenue();
  },
  methods: {
    getFloorRevenueInfo() {
      const url = `api/registration/${this.$route.params.regId}/floor-info-revenue`;
      this.$axios
        .get(url)
        .then((response) => {
          // debugger;
          this.revenueInfo = response.data.data[0];
          // this.revenueInfo.floorRevenue.sort(
          //   (a, b) => parseInt(a.floor) - parseInt(b.floor)
          // );
          this.getOtherRevenueFormFields();
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    getOtherRevenueFormFields() {
      const url = `api/other-revenue-form-fields`;
      this.$axios
        .get(url)
        .then((response) => {
          this.otherRevenueFormFields = response.data.data;
          this.createEmptyOtherRevenue();
        })
        .catch((error) => {
          console.log(`Error Other revenue fields: ${error}`);
        });
    },
    createEmptyOtherRevenue() {
      // If empty not filled revenue (new revenue)
      if (
        this.revenueInfo.otherRevenue == null ||
        this.revenueInfo.otherRevenue == ""
      ) {
        for (let i = 0; i < this.otherRevenueFormFields.length; i++) {
          this.revenueInfo.otherRevenue.push({
            id: 0,
            amount: "",
            remarks: "",
            var_name: this.otherRevenueFormFields[i].var_name,
            name: this.otherRevenueFormFields[i].name,
            revenue_weight: this.otherRevenueFormFields[i].revenue_weight,
          });
        }
      } else {
        // if revenue already filled partialy and want to edit on it
        for (let i = 0; i < this.otherRevenueFormFields.length; i++) {
          if (
            !this.revenueInfo.otherRevenue
              .map((e) => e.var_name)
              .includes(this.otherRevenueFormFields[i].var_name)
          ) {
            this.revenueInfo.otherRevenue.push({
              id: 0,
              amount: "",
              remarks: "",
              var_name: this.otherRevenueFormFields[i].var_name,
              name: this.otherRevenueFormFields[i].name,
              revenue_weight: this.otherRevenueFormFields[i].revenue_weight,
              other_revenue_master_id: this.otherRevenueFormFields[i].id,
            });
          }
        }
        this.revenueInfo.otherRevenue.sort(
          (a, b) => a.other_revenue_master_id - b.other_revenue_master_id
        );
      }
      //   console.log(`other-revenue:ok`);
    },
    save() {
      // this.floorInfo = this.floorRevenueSurface;
      // this.floorInfo = this.floorInfo.concat(this.floorRevenueBase); // this is not working, check it later
      if (
        // this.revenueInfo[0].id === null ||
        // this.revenueInfo[0].id === 0 ||
        // this.revenueInfo[0].id === undefined
        //  this part not needed for now
        false
      ) {
        const url = `api/registration/${this.$route.params.regId}/floor-info-revenue`;
        this.$axios
          .post(url, this.revenueInfo)
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.isFormSubmitting = true;
              this.revenueInfo = response.data.data;
              this.snackbar.show = true;
              this.successfulMessage = "Successful.";
            }
          })
          .catch((error) => {
            // alert(2);
            console.log(`api error:${error}`);
          });
      } else {
        const eurl = `api/registration/${this.$route.params.regId}/floor-info-revenue/1`;
        this.$axios
          .put(eurl, this.revenueInfo)
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.isFormSubmitting = true;
              this.revenueInfo = response.data.data;
              this.snackbar.show = true;
              this.successfulMessage = "Data Updated successfully";
            }
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    calculateAmount() {
      this.revenueInfo.floorRevenue.forEach((element) => {
        if (!this.hasHistory) {
          element.revenue_amount = (
            parseFloat(
              element.area == "" || element.area == null ? 0 : element.area
            ) *
            parseFloat(
              element.revenue_rate == "" || element.revenue_rate == null
                ? 0
                : element.revenue_rate
            )
          ).toFixed(2);
        } else {
          element.revenue_amount = parseFloat(
            ((element.area == "" && element.prev_area == "") ||
              (element.area == null && element.prev_area == null)
              ? 0
              : element.area - element.prev_area) *
            parseFloat(
              element.revenue_rate == "" || element.revenue_rate == null
                ? 0
                : element.revenue_rate
            )
          ).toFixed(2);
        }
      });
      this.calculateGrandTotal();
    },
    calculateGrandTotal() {
      let grandTotal = 0;
      grandTotal =
        parseFloat(
          this.subTotal == "" || this.subTotal == null ? 0 : this.subTotal
        ) + grandTotal;
      let tempOtherRevenue = [];
      tempOtherRevenue = this.revenueInfo.otherRevenue.filter(
        (e) => e.var_name != "grand_total"
      );
      tempOtherRevenue.forEach((e) => {
        e.revenue_weight = e.revenue_weight ? e.revenue_weight : 1;
        grandTotal =
          grandTotal +
          parseFloat(
            e.amount == "" || e.amount == null ? 0 : e.amount * e.revenue_weight
          );
      });
      let gIndex = this.revenueInfo.otherRevenue.findIndex(
        (e) => e.var_name === "grand_total"
      );
      this.revenueInfo.otherRevenue[gIndex].amount = grandTotal.toFixed(2);
    },
    getApplicationData() {
      const dataUrl = `api/registration/${this.$route.params.regId}/application`;
      this.$axios
        .get(dataUrl)
        .then((response) => {
          this.application = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getApplicationIdList() {
      const apiUrl = `api/registration/${this.$route.params.regId}/applicationIdList`;
      this.$axios
        .get(apiUrl)
        .then((response) => {
          this.applicationIdList = response.data.data;
        })
        .catch((error) => console.log(error));
    },
    getFloorRevenueHistory() {
      const apiUrl = `api/registration/${this.$route.params.regId}/floor-info-revenue/history`;
      this.$axios
        .get(apiUrl)
        .then((response) => {
          if (response.status == 200) {
            this.historyFloorRevenue = response.data.data[0].floorRevenue;
          }
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style scoped>
.h-2000 {
  height: 1500px;
}

#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}

.registrationtable td,
.registrationtable th {
  border: 1px solid #ddd;
  padding: 8px;
  width: 1000px;
}

.revenue td,
.revenue th {
  width: 500px;
}

.document td,
.document th {
  padding: 8px;
}

.technicaltable td,
.technicaltable th {
  border: 1px;
  padding: 8px;
}

div.options>label {
  display: block;
  margin: 0 0 0 -10px;
  padding: 0 0 20px 0;
  height: 20px;
  width: 150px;
}

div.options>label>img {
  display: inline-block;
  padding: 0px;
  height: 30px;
  width: 30px;
  background: none;
}

div.options>label>input:checked+img {
  background: url(http://cdn1.iconfinder.com/data/icons/onebit/PNG/onebit_34.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30px 30px;
}

/* .v-text-field__details{
  display: none !important;
} */
</style>
