import isArea from "./lib/isArea";
import isCitizenshipNumber from "./lib/isCitizenshipNumber";
import isEmail from "./lib/isEmail";
import isEnglishAlphabet from "./lib/isEnglishAlphabet";
import isLength from "./lib/isLength";
import isNepaliAddress from "./lib/isNepaliAddress";
import isNepaliAlphabet from "./lib/isNepaliAlphabet";
import isNepaliDate from "./lib/isNepaliDate";
import isNumber from "./lib/isNumber";
import isPhone from "./lib/isPhone";
import isRequired from "./lib/isRequired";
import isEngNumber from "./lib/isEngNumber";
import isNepNumber from "./lib/isNepNumber";

const validator = {
    isArea,
    isCitizenshipNumber,
    isEmail,
    isEnglishAlphabet,
    isLength,
    isNepaliAddress,
    isNepaliAlphabet,
    isNepaliDate,
    isNumber,
    isPhone,
    isRequired,
    isEngNumber,
    isNepNumber
};

export const FormValidator = validator;

export default {
    install(Vue) {
        Vue.prototype.$valid = validator;
    }
};

// A plugin is defined as either an object that exposes an install() method, or simply a 
// function that acts as the install function itself. 
// The install function receives the app instance along with additional options passed to app.use()