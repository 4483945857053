import { VueAxios } from '@/plugins/axios';
import api from '@/api/api';

const state = {
    buildingUse: [],    // भवनको प्रयोजन
    buildingCategory: [],
    buildingStructure: [],
    landUseArea: [],
    constructionType: [],
    municipality: [],
    phaseList: [],
    userRoleList: [],
    fiscalYearList: [],
    map: {},
};

const getters = {
    buildingUseList: state => state.buildingUse,
    constructionTypeList: state => state.constructionType,
    buildingCategoryList: state => state.buildingCategory,
    buildingStructureList: state => state.buildingStructure,
    landUseAreaList: state => {

    },
    getMunicipality: state => state.municipality,
    getPhaseList: state => state.phaseList,
    getUserRoleList: state => state.userRoleList,
    getFiscalYearList: state => state.fiscalYearList,
    getMap: state => state.map,

};

const actions = {
    async getBuildingUseList({ commit }) {
        const response = await VueAxios.get(`api/building-use`);
        commit('setBuildingUseList', response.data);
    },
    async getBuildingCategoryList({ commit }) {
        const response = await VueAxios.get(`api/building-category`);
        commit('setBuildingCategoryList', response.data);
    },
    async getBuildingStructureList({ commit }) {
        const response = await VueAxios.get(`api/building-structure`);
        commit('setBuildingStructureList', response.data);
    },
    async getLandUseAreaList({ commit }) {
        const response = await VueAxios.get(`api/land-use-area`);
        commit('setLandUseAreaList', response.data.data);
    },

    async getConstructionTypeList({ commit }) {
        const response = await api.dropdowns.constructionType();
        commit('setConstructionTypeList', response.data);
    },

    async fetchMunicipality({ commit }) {
        const response = await api.dropdowns.municipalityNoLogin();
        commit('setMunicipality', response.data.data[0]);
    },

    async saveMunicipality({ commit, getters }, payload) {
        const response = await api.dropdowns.putMunicipality(getters.getMunicipality.id, payload);
        commit('setMunicipality', response.data.data);
    },

    async fetchPhaseList({ commit }) {
        // if (!state.phaseList) {
        const response = await api.dropdowns.phaseList();
        commit('setPhase', response.data.data);
        // }

    },

    async fetchUserRoleList({ commit }) {
        const response = await api.dropdowns.userRoleList();
        commit('setUserRoleList', response.data.data);
    },

    async fetchFiscalYearList({ commit }) {
        const response = await api.dropdowns.fiscalYearList();
        commit('setFiscalYear', response.data.data);
    },

    async fetchMap({ commit }) {
        const response = await api.dropdowns.map();
        commit('setMap', response.data);
    },

};

const mutations = {
    setBuildingUseList: (state, buildingUse) => (state.buildingUse = buildingUse),
    setConstructionTypeList: (state, constructionType) => (state.constructionType = constructionType),
    setBuildingCategoryList: (state, buildingCategory) => (state.buildingCategory = buildingCategory),
    setBuildingStructureList: (state, buildingStructure) => (state.buildingStructure = buildingStructure),
    setMunicipality: (state, municipality) => (state.municipality = municipality),
    setPhase: (state, phaseList) => (state.phaseList = phaseList),
    setUserRoleList: (state, userRoleList) => (state.userRoleList = userRoleList),
    setFiscalYear: (state, fiscalYearList) => (state.fiscalYearList = fiscalYearList),
    setMap: (state, map) => (state.map = map),
}

export default {
    state,
    getters,
    actions,
    mutations
};