<template>
  <div>
    <v-simple-table class="sober">
      <template v-slot:default>
        <thead>
          <tr class="registrationtable">
            <th class="text-left registrationtable table-title">
              घरधनीको नाम
            </th>
            <th class="text-left registrationtable table-title">
              फोन
            </th>
            <th class="text-left registrationtable table-title">नागरिकता न</th>
            <th class="text-left registrationtable table-title">नागरिकता लिएको मिति</th>
            <th class="text-left registrationtable table-title">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="registrationtable"
            v-for="houseownerinfoList in houseownerinfo"
            :key="houseownerinfoList.id"
          >
            <td class="registrationtable">{{ houseownerinfoList.name }}</td>
            <td>{{ houseownerinfoList.phone_no }}</td>
            <td>{{ houseownerinfoList.citizenship_no }}</td>
            <td class="registrationtable">
              {{ houseownerinfoList.citizenship_issue_date_np }}
            </td>
            <td>
              <v-btn
                small
                color="primary"
                v-if="isPostAuthorized"
                v-on:click="edit(houseownerinfoList.id)"
                >Edit</v-btn
              >
              <!-- <v-btn
                  small
                  color="primary"
                  v-if="isPostAuthorized"
                  v-on:click="deleterecord(houseownerinfoList.id)"
                  >Delete</v-btn
                > -->
              <custom-dialog
                v-if="isPostAuthorized"
                :click-dialog="deleterecord"
                :id="houseownerinfoList.id"
                click-type="delete"
              >
                Delete
              </custom-dialog>
              <v-btn small color="primary" v-else v-on:click="edit(houseownerinfoList.id)"
                >View</v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      houseownerinfo: []
    };
  },
  created() {
    const url = `api/registration/${this.$route.params.id}/houseowner-detail`;
    this.$axios
      .get(url)
      .then(response => {
        // debugger;
        this.houseownerinfo = response.data.data;
        this.edit(this.houseownerinfo[0].id);
        this.uploadedDocument = this.basePath + this.houseownerinfo[0].photo;
        console.log(this.basePath);
        console.log(this.houseownerinfo[0].photo);
        this.isLoading = false; //stop the loading spinner
        //   this.newhouseownerinfo.clear();
      })
      .catch(error => {
        console.log(`api error:${error}`);
        this.isLoading = false;
      });
    const districtUrl = "api/districts";
    this.$axios
      .get(districtUrl)
      .then(response => {
        // debugger;
        this.districts = response.data.data;
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
  },
  edit(id) {
    //    const url = `api/registration/${this.$route.params.id}/houseownerinfo/${this.$route.params.id}`;
    const url = `api/registration/${this.$route.params.id}/houseowner-detail/${id}`;
    this.$axios.get(url).then(response => {
      this.newhouseownerinfo = response.data.data;
      this.uploadedDocument = this.basePath + response.data.data.photo;
      console.log(response.data.data.photo);
    });
  },
  deleterecord(id) {
    const deleteurl = `api/registration/${this.$route.params.id}/houseowner-detail/${id}`;
    this.$axios
      .delete(deleteurl)
      .then(response => {
        // debugger;
        if (response.status === 200 || response.status === 201) {
          this.snackbar.show = true;
          this.successfulMessage = "Data deleted successfully";
          const i = this.houseownerinfo.map(item => item.id).indexOf(id); // find index of your object
          this.houseownerinfo.splice(i, 1);
        }
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>

<style scoped>
.table-title {
  font-size: 16px;
}

.text-bottom {
  padding-top: 1.5rem;
}
</style>
