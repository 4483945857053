export default axios => ({
    phaseDocumentMaster(constructionTypeId, phaseId) {
        return axios.get('api/' + constructionTypeId + '/' + phaseId + '/phase-document-master');
    },
    phaseNoticeMaster(constructionTypeId, phaseId) {
        return axios.get('api/' + constructionTypeId + '/' + phaseId + '/phase-notice-master');
    },

    getDocumentsAuth(registrationId, phaseId){
        let url = null;
        const phase = phaseId.toString();
        switch (phase) {
            case '1':
                url = `api/registration/${registrationId}/nibedan-documents/is-authorized`;
                break;
            case '2':
                url = `api/registration/${registrationId}/fifteen-day-documents/is-authorized`;
                break;
            case '3':
                url = `api/registration/${registrationId}/plinth-documents/is-authorized`;
                break;
            case '4':
                url = `api/registration/${registrationId}/superstructure-documents/is-authorized`;
                break;
            case '5':
                url = `api/registration/${registrationId}/completion-documents/is-authorized`;
                break;
            default:
                url = `api/registration/${registrationId}/nibedan-documents-nice/is-authorized`; //change wrong
        };

        return axios.get(url);
    }
});
