<template>
  <div>
    <!-- Link below for primary motivation of dual navbar -->
    <!-- https://mdbootstrap.com/docs/standard/extended/double-navigation-with-2-navbars/#  -->
    <PrimaryNavbar />
    <SecondaryNavbar />
  </div>
</template>

<script>
import PrimaryNavbar from "./PrimaryNavbar.vue";
import SecondaryNavbar from "./SecondaryNavbar.vue";
export default {
  name: "Navbar",
  components: {
    PrimaryNavbar,
    SecondaryNavbar,
  },
};
</script>

<style>
</style>