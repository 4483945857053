<template>
  <div>
    <v-simple-table class="sober">
      <template v-slot:default>
        <thead>
          <tr class="registrationtable">
            <th class="text-left registrationtable table-title">
              म्याप शीट नं
            </th>
            <th class="text-left registrationtable table-title">
              जग्गा कित्ता नं
            </th>
            <!-- <th class="text-left registrationtable table-title">Actual Setback</th> -->
            <th class="text-left registrationtable table-title">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="registrationtable"
            v-for="landinfoareaList in landinfoarea"
            :key="landinfoareaList.id"
          >
            <td class="registrationtable">{{ landinfoareaList.map_sheet_no }}</td>
            <td class="registrationtable">
              {{ landinfoareaList.land_plot_no }}
            </td>
            <!-- <td>{{ landinfoareaList.actual_setback }}</td> -->
            <td>
              <v-btn
                small
                color="primary"
                v-if="isPostAuthorized"
                v-on:click="edit(landinfoareaList.id)"
                >Edit</v-btn
              >
              <custom-dialog
                v-if="isPostAuthorized"
                :click-dialog="deleterecord"
                :regId="landinfoareaList.id"
                click-type="delete"
                >Delete</custom-dialog
              >
              <v-btn small color="primary" v-else v-on:click="edit(landinfoareaList.id)"
                >View</v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      landinfoarea: []
    };
  },
  mounted() {
    const url = `api/registration/${this.$route.params.id}/land-info-area`;

    this.$axios
      .get(url, {
        timeout: 100000
      })
      .then(response => {
        // debugger;
        this.landinfoarea = response.data.data;
        this.edit(this.landinfoarea[0].id);
        this.isLoading = false; //stop the loading spinner
        // this.newLandinfoarea.clear();
      })
      .catch(error => {
        console.log(`api error:${error}`);
        this.isLoading = false; //stop the loading spinner
      });
  },
  methods: {
    edit(id) {
      //    const url = `api/registration/${this.$route.params.id}/designer/${this.$route.params.id}`;
      const url = `api/registration/${this.$route.params.id}/land-info-area/${id}`;
      this.$axios.get(url).then(response => {
        // debugger;
        this.newLandinfoarea = response.data.data;
        console.log(response.data.data);
      });
    },
    deleterecord(id) {
      const deleteurl = `api/registration/${this.$route.params.id}/land-info-area/${id}`;
      this.$axios
        .delete(deleteurl, {
          timeout: 100000
        })
        .then(response => {
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
            const i = this.landinfoarea.map(item => item.id).indexOf(id); // find index of your object
            this.landinfoarea.splice(i, 1);
          }
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
.table-title {
  font-size: 16px;
}

.text-bottom {
  padding-top: 1.5rem;
}
</style>
