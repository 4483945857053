<style scoped>
.registrationtable td,
.registrationtable th {
  border: 1px solid #ddd;
  padding: 8px;
  width: 1000px;
}
.revenue td,
.revenue th {
  width: 500px;
}
.document td,
.document th {
  padding: 8px;
}
.technicaltable td,
.technicaltable th {
  border: 1px;
  padding: 8px;
}

div.options > label {
  display: block;
  margin: 0 0 0 -10px;
  padding: 0 0 20px 0;
  height: 20px;
  width: 150px;
}

div.options > label > img {
  display: inline-block;
  padding: 0px;
  height: 30px;
  width: 30px;
  background: none;
}

div.options > label > input:checked + img {
  background: url(http://cdn1.iconfinder.com/data/icons/onebit/PNG/onebit_34.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30px 30px;
}
</style>
<template>
  <div>
    <v-card  height="auto" class="m-4">
      <!-- <v-app id="inspire"> -->
        <v-form>
          <!-- <v-container height="auto"> -->
            <v-row height="auto">
              <v-col>
                <v-card>
                  <center>
                    <h4>JICA OYO</h4>
                  </center>
                  <br />
                  <v-toolbar color="primary" dark flat short dense>
                    <v-toolbar-title>
                      अ) तपाईको संस्था (Your organization)
                    </v-toolbar-title>
                  </v-toolbar>
                  <div>
                    <v-simple-table>
                      <thead>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            कृपया तपाईंको संगठनको बारेमा निम्न प्रश्नहरूको उत्तर दिनुहोस्, र तपाईंले प्रदान गर्न सक्षम हुनुभएको कुनै पनि सम्बन्धित कागजातहरू प्रदान गर्नुहोस् । (Please answer the following questions about your organization, and provide any relevant documents you are able to submit.)
                            <br> कृपया कागजातहरू स्थानीय परामर्शदातालाई उपलब्ध गराउनुहोस् । (Please submit the documents to the local consultant.) 
                            <br> <strong> 1) तपाइको नाम, कार्यालय/संस्था, विभागको नाम र पद (Please provide your name, affiliated organization, department name, and position.) </strong>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <v-simple-table>
                      <tbody>
                        <!-- <td> नाम (Name) :<span>*</span> </td> -->
                        <td>
                          <v-textarea
                            v-model="form.name" :rules="[required('नाम (Name)')]" label="नाम (Name)" auto-grow rows="1"
                          ></v-textarea>
                        </td>
                        <!-- <td> संलग्न संस्था (Affiliated organization) :<span>*</span> </td> -->
                        <td>
                          <v-textarea
                            v-model="form.aff_org" :rules="[required('संलग्न संस्था (Affiliated organization) ')]" label="संलग्न संस्था (Affiliated organization) " auto-grow rows="1"
                          ></v-textarea>
                        </td>
                        <!-- <td> विभाग (Bureau or Department name) :<span>*</span> </td> -->
                        <td>
                          <v-textarea
                            v-model="form.dept" :rules="[required('विभाग (Bureau or Department name)')]" label="विभाग (Bureau or Department name) :" auto-grow rows="1"
                          ></v-textarea>
                        </td>
                        <!-- <td> पद (Position) :<span>*</span> </td> -->
                        <td>
                          <v-textarea
                            v-model="form.position" :rules="[required('पद (Position)')]" label="पद (Position)" auto-grow rows="1"
                          ></v-textarea>
                        </td>
                      </tbody>
                    </v-simple-table>
                    <br>
                    <v-simple-table>
                      <tbody>
                        <tr>
                          <td>
                            <strong>2) विपद जोखिम न्यूनीकरण र फ्रतिक्रियाका लागि जनशत्तिको क्षमता विकास (Human resources development for disaster risk reduction and response)</strong>
                            <br> तपाइको संस्थाले विपद् जोखिम न्यूनीकरण तथा प्रतिकार्य सम्बन्धी तालिम वा जनशक्तिको क्षमता विकाससम्बन्धी केही काम गरेको छ ? (Does your organization conduct training and other human resources development for disaster risk reduction and response?)
                            
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input type="radio" value="yes" v-model="form.conduct_training"/> छ (Yes)
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input type="radio" value="no" v-model="form.conduct_training"/> छैन (No)
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table >
                    <v-simple-table v-if="form.conduct_training == 'yes'">
                      <tbody>
                        <tr>
                          <td> यदि त्यस्तो हो भने, कृपया तलका विकल्पहरूबाट लागू हुने सबै छान्नुहोस् । (बहु उत्तरहरू सम्भव छन) If so, please select all that apply from the options below. (Multiple answers possible)
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input type="checkbox" true-value="yes" false-value="no" v-model="form.regular_training" />  क) संस्थाभित्रैको नियमित तालिम  (Regular training within the organization)
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input type="checkbox" true-value="yes" false-value="no" v-model="form.country_training" />  ख) देशमा बेलाबखत हुने तालिम (Training in your country)
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input type="checkbox" true-value="yes" false-value="no" v-model="form.uni_training" />  ग) विश्वविद्यालय वा अध्ययन प्रतिष्ठानहरुद्धारा आयोजित तालिम (Training at universities or research institutes)
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input type="checkbox" true-value="yes" false-value="no" v-model="form.intl_training" />  घ) अन्तराष्ट्यि निकायहरुद्धारा आयोजना गरिने तालिम (Training conducted by international agencies)
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input type="checkbox" true-value="yes" false-value="no" v-model="form.other_training" />  ङ) अन्य (Other)
                            <v-textarea v-if="form.other_training == 'yes'"
                              v-model="form.other_specify" label="Specify Description" auto-grow rows="1"
                            ></v-textarea>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <br>
                    <v-simple-table>
                      <tbody>
                        <tr>
                          <td>
                            <strong> 3) तपाईँको संस्थाको अवस्थाबारे अवगत गराउन तल उल्लेखित कागजपत्रहरु संलग्न गरिदिनुहुन अनुरोध छ । (To help us understand the state of your organization, please submit the following documents.)
                            </strong>
                            <br> तलको तालिकामा कृपया कागजातको बारेमा जानकारी प्रदान गर्नुहोस्, जस्तै तिनीहरूका शीर्षकहरू वा तपाईं उनीहरूलाई पेश गर्न सक्षम हुनुहुन्छ वा हुनुहुन्न। (On the following table, please provide information about the documents, such as their titles or whether you are or are not able to submit them.)
                            <br> - अनलाइन उपलब्ध कागजातहरूका लागि यूआरएलहरू (URL) समावेश गर्नुहोस (Include URLs for documents available online)
                            <br> - कृपया कागजातहरू स्थानीय परामर्शदातालाई उपलब्ध गराइदिनुहोस्  (Please submit the documents to the local consultant.)
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <v-simple-table class="registrationtable buildingcode sober">
                      <thead>
                        <tr>
                          <th>कागजातपत्र को विवरण  (Description of documents) </th> 
                          <th> कागजात छ (Documents exist) ? </th>  
                          <th> कागजातका शीर्षक (Document title(s)) </th> 
                          <th> कागजपत्र समावेश गर्न सक्नुहुन्छ (Able to submit) ?</th> 
                          <th> कागजको किसिम (Format(s))</th>
                          <th> फाइलको नाम (Filename(s))</th>
                          <th> File Upload </th>
                          <th> इन्टरनेटको लिङ्क (URL) </th>
                          <th> कैफिएत (Remarks) </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            1) संस्थागत संरचना (Organizational chart)
                          </td>
                          <td>
                            <tr> <input type="radio" value="yes" v-model="form.org_chart_doc_exist" /> छ (Yes) </tr>
                            <tr> <input type="radio" value="no" v-model="form.org_chart_doc_exist" /> छैन (No) </tr>
                          </td>
                          <td v-if="form.org_chart_doc_exist == 'yes' ">
                            <v-textarea v-model="form.org_chart_doc_title" label="Org Chart Title" auto-grow rows="1" />
                          </td>
                          <td v-if="form.org_chart_doc_exist == 'yes' ">
                            <tr> <input type="radio" value="yes" v-model="form.org_chart_doc_submit" /> सकिन्छ (Yes) </tr>
                            <tr> <input type="radio" value="no" v-model="form.org_chart_doc_submit" /> सकिन्न (No) </tr>
                          </td>
                          <td v-if="form.org_chart_doc_exist == 'yes' && form.org_chart_doc_submit == 'yes' ">
                            <tr> <input type="radio" value="softcopy" v-model="form.org_chart_doc_type" /> Soft Copy </tr>
                            <tr> <input type="radio" value="hardcopy" v-model="form.org_chart_doc_type" /> Hard Copy </tr>
                          </td>
                          <td v-if="form.org_chart_doc_exist == 'yes' && form.org_chart_doc_submit == 'yes' ">
                            <v-textarea v-model="form.org_chart_doc_filename" label="Org Chart File Name" auto-grow rows="1" />
                          </td>
                          <td v-if="form.org_chart_doc_exist == 'yes' && form.org_chart_doc_submit == 'yes' ">
                            <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                            <v-file-input v-model="form.org_chart_doc_file" label="Select File.."></v-file-input>
                          </td>
                          <td v-if="form.org_chart_doc_exist == 'yes' && form.org_chart_doc_submit == 'yes' ">
                            <v-textarea v-model="form.org_chart_url" label="Org Chart URL" auto-grow rows="1" />
                          </td>
                          <td v-if="form.org_chart_doc_exist == 'yes' && form.org_chart_doc_submit == 'yes' ">
                            <v-textarea v-model="form.org_chart_remarks" label="Org Chart Remarks" auto-grow rows="1" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            2) कार्यविभाजन बारेका कागजात (Documents showing assignment of tasks in your organization)
                          </td>
                          <td>
                            <tr> <input type="radio" value="yes" v-model="form.tasks_doc_exist" /> छ (Yes) </tr>
                            <tr> <input type="radio" value="no" v-model="form.tasks_doc_exist" /> छैन (No) </tr>
                          </td>
                          <td v-if="form.tasks_doc_exist == 'yes' ">
                            <v-textarea v-model="form.tasks_doc_title" label="Tasks document title" auto-grow rows="1" />
                          </td>
                          <td v-if="form.tasks_doc_exist == 'yes' ">
                            <tr> <input type="radio" value="yes" v-model="form.tasks_doc_submit" /> सकिन्छ (Yes) </tr>
                            <tr> <input type="radio" value="no" v-model="form.tasks_doc_submit" /> सकिन्न (No) </tr>
                          </td>
                          <td v-if="form.tasks_doc_exist == 'yes' && form.tasks_doc_submit == 'yes' ">
                            <tr> <input type="radio" value="softcopy" v-model="form.tasks_doc_type" /> Soft Copy </tr>
                            <tr> <input type="radio" value="hardcopy" v-model="form.tasks_doc_type" /> Hard Copy </tr>
                          </td>
                          <td v-if="form.tasks_doc_exist == 'yes' && form.tasks_doc_submit == 'yes' ">
                            <v-textarea v-model="form.tasks_doc_filename" label="Tasks doc File Name" auto-grow rows="1" />
                          </td>
                          <td v-if="form.tasks_doc_exist == 'yes' && form.tasks_doc_submit == 'yes' ">
                            <v-file-input v-model="form.tasks_doc_file" label="Select File.."></v-file-input>
                          </td>
                          <td v-if="form.tasks_doc_exist == 'yes' && form.tasks_doc_submit == 'yes' ">
                            <v-textarea v-model="form.tasks_url" label="Tasks doc URL" auto-grow rows="1" />
                          </td>
                          <td v-if="form.tasks_doc_exist == 'yes' && form.tasks_doc_submit == 'yes' ">
                            <v-textarea v-model="form.tasks_remarks" label="Tasks doc Remarks" auto-grow rows="1" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            3) कर्मचारीहरुको संख्या र उनीहरुको परिचालन (Documents showing the number of employees in your organization, and a breakdown of their allocation)
                          </td>
                          <td>
                            <tr> <input type="radio" value="yes" v-model="form.emp_no_doc_exist" /> छ (Yes) </tr>
                            <tr> <input type="radio" value="no" v-model="form.emp_no_doc_exist" /> छैन (No) </tr>
                          </td>
                          <td v-if="form.emp_no_doc_exist == 'yes' ">
                            <v-textarea v-model="form.emp_no_doc_title" label="emp_no document title" auto-grow rows="1" />
                          </td>
                          <td v-if="form.emp_no_doc_exist == 'yes' ">
                            <tr> <input type="radio" value="yes" v-model="form.emp_no_doc_submit" /> सकिन्छ (Yes) </tr>
                            <tr> <input type="radio" value="no" v-model="form.emp_no_doc_submit" /> सकिन्न (No) </tr>
                          </td>
                          <td v-if="form.emp_no_doc_exist == 'yes' && form.emp_no_doc_submit == 'yes' ">
                            <tr> <input type="radio" value="softcopy" v-model="form.emp_no_doc_type" /> Soft Copy </tr>
                            <tr> <input type="radio" value="hardcopy" v-model="form.emp_no_doc_type" /> Hard Copy </tr>
                          </td>
                          <td v-if="form.emp_no_doc_exist == 'yes' && form.emp_no_doc_submit == 'yes' ">
                            <v-textarea v-model="form.emp_no_doc_filename" label="emp_no doc File Name" auto-grow rows="1" />
                          </td>
                          <td v-if="form.emp_no_doc_exist == 'yes' && form.emp_no_doc_submit == 'yes' ">
                            <v-file-input v-model="form.emp_no_doc_file" label="Select File.."></v-file-input>
                          </td>
                          <td v-if="form.emp_no_doc_exist == 'yes' && form.emp_no_doc_submit == 'yes' ">
                            <v-textarea v-model="form.emp_no_url" label="emp_no doc URL" auto-grow rows="1" />
                          </td>
                          <td v-if="form.emp_no_doc_exist == 'yes' && form.emp_no_doc_submit == 'yes' ">
                            <v-textarea v-model="form.emp_no_remarks" label="emp_no doc Remarks" auto-grow rows="1" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            4) संस्थागत जानकारी दिने पम्प्लेट वा अन्य कुनै सूचनामुलक कागजातहरु (Pamphlets and other documents about your organization’s activities)
                          </td>
                          <td>
                            <tr> <input type="radio" value="yes" v-model="form.pamphlet_doc_exist" /> छ (Yes) </tr>
                            <tr> <input type="radio" value="no" v-model="form.pamphlet_doc_exist" /> छैन (No) </tr>
                          </td>
                          <td v-if="form.pamphlet_doc_exist == 'yes' ">
                            <v-textarea v-model="form.pamphlet_doc_title" label="pamphlet document title" auto-grow rows="1" />
                          </td>
                          <td v-if="form.pamphlet_doc_exist == 'yes' ">
                            <tr> <input type="radio" value="yes" v-model="form.pamphlet_doc_submit" /> सकिन्छ (Yes) </tr>
                            <tr> <input type="radio" value="no" v-model="form.pamphlet_doc_submit" /> सकिन्न (No) </tr>
                          </td>
                          <td v-if="form.pamphlet_doc_exist == 'yes' && form.pamphlet_doc_submit == 'yes' ">
                            <tr> <input type="radio" value="softcopy" v-model="form.pamphlet_doc_type" /> Soft Copy </tr>
                            <tr> <input type="radio" value="hardcopy" v-model="form.pamphlet_doc_type" /> Hard Copy </tr>
                          </td>
                          <td v-if="form.pamphlet_doc_exist == 'yes' && form.pamphlet_doc_submit == 'yes' ">
                            <v-textarea v-model="form.pamphlet_doc_filename" label="pamphlet doc File Name" auto-grow rows="1" />
                          </td>
                          <td v-if="form.pamphlet_doc_exist == 'yes' && form.pamphlet_doc_submit == 'yes' ">
                            <v-file-input v-model="form.pamphlet_doc_file" label="Select File.."></v-file-input>
                          </td>
                          <td v-if="form.pamphlet_doc_exist == 'yes' && form.pamphlet_doc_submit == 'yes' ">
                            <v-textarea v-model="form.pamphlet_url" label="pamphlet doc URL" auto-grow rows="1" />
                          </td>
                          <td v-if="form.pamphlet_doc_exist == 'yes' && form.pamphlet_doc_submit == 'yes' ">
                            <v-textarea v-model="form.pamphlet_remarks" label="pamphlet doc Remarks" auto-grow rows="1" />
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <div> 
                      <v-simple-table>
                        <thead>
                          <th> संस्थागत किसिम (Select Category) </th>
                          <th> संरचना (Select Structure)</th>
                          <th>Note: </th>
                        </thead>
                        <tbody>
                          <tr> 
                            <td> 
                              <tr v-for="cat in category" :key="cat.id"> <input type="radio" :value="cat.name" v-model="selectedCategory" /> {{cat.name}} </tr>
                            </td>
                            <td>
                              <tr v-for="str in category.find(x => x.name === selectedCategory).structure" :key="str.id">
                                <input 
                                  type="checkbox" 
                                  :value="str.name"     
                                  :id="str.id" 
                                  v-model="selectedStructures" 
                                  @change="check(selectedStructures)"
                                > 
                                {{str.name}}
                              </tr>
                            </td>
                            <td>
                              Please Select the building Structure and Category based on following information:
                            </td>
                          </tr>
                          
                        </tbody>
                      </v-simple-table>
                      <!-- <div v-for='(item, index) in items' :key="index">
                        <v-text-field v-model="item.first_name" :label="`${index}-first name`"/>
                        <v-text-field v-model="item.last_name" :label="`${index}-last name`" />
                      </div>
                      <button @click.prevent='add_new_item'>Add new item</button> -->
                    </div>
                    <br>
                    <div v-if="this.selectedStructures.length">
                      <v-toolbar color="primary" dark flat short dense>
                        <v-toolbar-title>
                          आ) डिजाइन मापदण्ड, निर्माण कार्य तथा मर्मतसम्भार (Design criteria, construction work, and maintenance)
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                             <strong>१) तल दिइएको टेबल अनुसार तपाइको संस्थाले व्यवस्थापन गर्ने भवन तथा पूर्वाधारहरुको डिजाइन मापदण्डको व्यवस्थाबारे उल्लेख गर्नुहोस् । साथै तीसँग सम्बन्धित कागजातहरु पनि संलग्न गर्नुहोस् । 
                               </strong>
                               (On the following table, please provide information such as the titles and dates of design criteria for the buildings and infrastructure your organization manages. Select target structures from the drop-down lists. Also, please submit relevant documents.)

                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table class="registrationtable buildingcode sober">
                      <thead>
                        <tr>
                          <th> संरचना (Structure)</th> 
                          <th> डिजाइन मापदण्ड / नीति, ऐन, कानून (Criterion) </th>  
                          <th> "पछिल्लोपटक संशोधन गरिएको मिति (Year of latest revision)" </th> 
                          <th> कागजपत्र समावेश गर्न सक्नुहुन्छ (Able to submit) ?</th> 
                          <th> कागजको किसिम (Format(s))</th>
                          <th> फाइलको नाम (Filename(s))</th>
                          <th> File Upload </th>
                          <th> इन्टरनेटको लिङ्क (URL) </th>
                          <th> कैफिएत (Remarks) </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="question in form.questions" :key="question.structure">
                          <td>
                            {{question.structure}}
                          </td>
                          <td>
                            <v-textarea v-model="question.design_criteria" label="Criteria" auto-grow rows="1" />
                          </td>
                          <td>
                            <v-textarea v-model="question.design_year" label="Year" auto-grow rows="1" />
                          </td>
                          <td>
                            <tr> <input type="radio" value="yes" v-model="question.design_submit" /> सकिन्छ (Yes) </tr>
                            <tr> <input type="radio" value="no" v-model="question.design_submit" /> सकिन्न (No) </tr>
                          </td>
                          <td v-if="question.design_submit == 'yes' ">
                            <tr> <input type="radio" value="softcopy" v-model="question.design_format" /> Soft Copy </tr>
                            <tr> <input type="radio" value="hardcopy" v-model="question.design_format" /> Hard Copy </tr>
                          </td>
                          <td v-if="question.design_submit == 'yes' ">
                            <v-textarea v-model="question.design_filename" label="File Name" auto-grow rows="1" />
                          </td>
                          <td v-if="question.design_submit == 'yes' ">
                            <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                            <v-file-input v-model="question.design_file" label="Select File.."></v-file-input>
                          </td>
                          <td v-if="question.design_submit == 'yes' ">
                            <v-textarea v-model="question.design_url" label="URL" auto-grow rows="1" />
                          </td>
                          <td v-if="question.design_submit == 'yes' ">
                            <v-textarea v-model="question.design_remarks" label="Remarks" auto-grow rows="1" />
                          </td>
                        </tr>
                      </tbody>
                      </v-simple-table>
                      <br>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                             <strong>२) तपाइको संस्थाले व्यवस्थापन गर्ने भवन तथा संरचनाको निर्माणसँग सम्बन्धित निर्देशिकाहरु छ छ भने, तल तालिकामा उल्लेख भए अनुसार संलग्न गर्नुहोस् । 
                               </strong>(Does your organization have guidelines for construction work on the buildings and infrastructure you manage? If so, please submit relevant documents. Select target structures from the drop-down lists.

                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table class="registrationtable buildingcode sober">
                      <thead>
                        <tr>
                          <th> संरचना (Structure)</th> 

                          <th> निर्देशिका छ ? (Guidelines exist) ? </th>  
                          <th> निर्देशिकाको शीर्षक (Title of guidelines) </th> 
                          <th> "पछिल्लोपटक संशोधन गरिएको मिति (Year of latest revision)" </th>
                          <th> कागजपत्र समावेश गर्न सक्नुहुन्छ (Able to submit) ?</th> 
                          <th> कागजको किसिम (Format(s))</th>
                          <th> फाइलको नाम (Filename(s))</th>
                          <th> File Upload </th>
                          <th> इन्टरनेटको लिङ्क (URL) </th>
                          <th> कैफिएत (Remarks) </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="question in form.questions" :key="question.structure">
                          <td>
                            {{question.structure}}
                          </td>
                          <td>
                            <tr> <input type="radio" value="yes" v-model="question.cons_present" /> Yes </tr>
                            <tr> <input type="radio" value="no" v-model="question.cons_present" /> No </tr>
                          </td>
                          <td>
                            <v-textarea v-model="question.cons_title" label="Title" auto-grow rows="1" />
                          </td>
                          
                          <td>
                            <v-textarea v-model="question.cons_year" label="Year" auto-grow rows="1"/>
                          </td>
                          <td>
                            <tr> <input type="radio" value="yes" v-model="question.cons_submit" /> सकिन्छ (Yes) </tr>
                            <tr> <input type="radio" value="no" v-model="question.cons_submit" /> सकिन्न (No) </tr>
                          </td>
                          <td v-if="question.cons_submit == 'yes' ">
                            <tr> <input type="radio" value="softcopy" v-model="question.cons_format" /> Soft Copy </tr>
                            <tr> <input type="radio" value="hardcopy" v-model="question.cons_format" /> Hard Copy </tr>
                          </td>
                          <td v-if="question.cons_submit == 'yes' ">
                            <v-textarea v-model="question.cons_filename" label="File Name" auto-grow rows="1" />
                          </td>
                          <td v-if="question.cons_submit == 'yes' ">
                            <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                            <v-file-input v-model="question.cons_file" label="Select File.."></v-file-input>
                          </td>
                          <td v-if="question.cons_submit == 'yes' ">
                            <v-textarea v-model="question.cons_url" label="URL" auto-grow rows="1" />
                          </td>
                          <td v-if="question.cons_submit == 'yes' ">
                            <v-textarea v-model="question.cons_remarks" label="Remarks" auto-grow rows="1" />
                          </td>
                        </tr>
                      </tbody>
                      </v-simple-table>
                      <br>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                             <strong>३) तपाइको संस्थाले व्यवस्थापन गर्ने भवन तथा संरचनाको मर्मत सम्भारसँग सम्बन्धित निर्देशिकाहरु छ ? छ भने, तल तालिकामा उल्लेख भए अनुसार संलग्न गर्नुहोस् । 
                               </strong>(Does your organization have guidelines for maintenance work on the buildings and infrastructure you manage? If so, please submit documents. Select target structures from the drop-down lists.)

                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <br>
                      <v-simple-table class="registrationtable buildingcode sober">
                      <thead>
                        <tr>
                          <th> संरचना (Structure)</th> 

                          <th> निर्देशिका छ ? (Guidelines exist) ? </th>  
                          <th> निर्देशिकाको शीर्षक (Title of guidelines) </th> 
                          <th> "पछिल्लोपटक संशोधन गरिएको मिति (Year of latest revision)" </th>
                          <th> कागजपत्र समावेश गर्न सक्नुहुन्छ (Able to submit) ?</th> 
                          <th> कागजको किसिम (Format(s))</th>
                          <th> फाइलको नाम (Filename(s))</th>
                          <th> File Upload </th>
                          <th> इन्टरनेटको लिङ्क (URL) </th>
                          <th> कैफिएत (Remarks) </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="question in form.questions" :key="question.structure">
                          <td>
                            {{question.structure}}
                          </td>
                          <td>
                            <tr> <input type="radio" value="yes" v-model="question.main_present" /> Yes </tr>
                            <tr> <input type="radio" value="no" v-model="question.main_present" /> No </tr>
                          </td>
                          <td>
                            <v-textarea v-model="question.main_title" label="Title" auto-grow rows="1" />
                          </td>
                          
                          <td>
                            <v-textarea v-model="question.main_year" label="Year" auto-grow rows="1"/>
                          </td>
                          <td>
                            <tr> <input type="radio" value="yes" v-model="question.main_submit" /> सकिन्छ (Yes) </tr>
                            <tr> <input type="radio" value="no" v-model="question.main_submit" /> सकिन्न (No) </tr>
                          </td>
                          <td v-if="question.main_submit == 'yes' ">
                            <tr> <input type="radio" value="softcopy" v-model="question.main_format" /> Soft Copy </tr>
                            <tr> <input type="radio" value="hardcopy" v-model="question.main_format" /> Hard Copy </tr>
                          </td>
                          <td v-if="question.main_submit == 'yes' ">
                            <v-textarea v-model="question.main_filename" label="File Name" auto-grow rows="1" />
                          </td>
                          <td v-if="question.main_submit == 'yes' ">
                            <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                            <v-file-input v-model="question.main_file" label="Select File.."></v-file-input>
                          </td>
                          <td v-if="question.main_submit == 'yes' ">
                            <v-textarea v-model="question.main_url" label="URL" auto-grow rows="1" />
                          </td>
                          <td v-if="question.main_submit == 'yes' ">
                            <v-textarea v-model="question.main_remarks" label="Remarks" auto-grow rows="1" />
                          </td>
                        </tr>
                      </tbody>
                      </v-simple-table>
                    </div>
                    <div v-if="this.selectedStructures.length">
                      <v-toolbar color="primary" dark flat short dense>
                        <v-toolbar-title>
                          इ) भूकम्पीय प्रबलीकरण (Seismic reinforcement)
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                            <strong>१) के तपाइको संस्थासँग विद्यमान भवन तथा भौतिक पूर्वाधारहरुको भूकम्पीय प्रबलीकरण गर्ने योजना छ ? छ भने त्यससँग सम्बन्धित योजना, अपनाइएका विधि तथा परियोजनाको बजेट समेत संलग्न गरिदिनुहोस् ।
                               </strong> Does you organization have countermeasures or plans for promoting the seismic reinforcement for existing buildings and infrastructure? If so, please enter information such as name(s) of plans, seismic resistance measures applied, and project budgets, and submit relevant documents.
        
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input type="radio" value="yes" v-model="seismic_plan_present"/> छ (Yes)
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input type="radio" value="no" v-model="seismic_plan_present"/> छैन (No)
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <br>
                      <v-simple-table class="registrationtable buildingcode sober" v-if="seismic_plan_present == 'yes'">
                        <thead>
                          <tr>
                            <th> संरचना (Structure)</th> 
                            <th> योजनाको नाम (Name(s) of  Plans) </th> 
                            <th> कागजपत्र समावेश गर्न सक्नुहुन्छ (Able to submit) ?</th> 
                            <th> कागजको किसिम (Format(s))</th>
                            <th> फाइलको नाम (Filename(s))</th>
                            <th> File Upload </th>
                            <th> इन्टरनेटको लिङ्क (URL) </th>
                            <th> कैफिएत (Remarks) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="question in form.questions" :key="question.structure">
                            <td>
                              {{question.structure}}
                            </td>
                            <td>
                              <v-textarea v-model="question.seismic_plan_name" label="Name" auto-grow rows="1" />
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.seismic_plan_submit" /> सकिन्छ (Yes) </tr>
                              <tr> <input type="radio" value="no" v-model="question.seismic_plan_submit" /> सकिन्न (No) </tr>
                            </td>
                            
                            <td v-if="question.seismic_plan_submit == 'yes' ">
                              <tr> <input type="radio" value="softcopy" v-model="question.seismic_plan_format" /> Soft Copy </tr>
                              <tr> <input type="radio" value="hardcopy" v-model="question.seismic_plan_format" /> Hard Copy </tr>
                            </td>
                            <td v-if="question.seismic_plan_submit == 'yes' ">
                              <v-textarea v-model="question.seismic_plan_filename" label="File Name" auto-grow rows="1" />
                            </td>
                            <td v-if="question.seismic_plan_submit == 'yes' ">
                              <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                              <v-file-input v-model="question.seismic_plan_file" label="Select File.."></v-file-input>
                            </td>
                            <td v-if="question.seismic_plan_submit == 'yes' ">
                              <v-textarea v-model="question.seismic_plan_url" label="URL" auto-grow rows="1" />
                            </td>
                            <td v-if="question.seismic_plan_submit == 'yes' ">
                              <v-textarea v-model="question.seismic_plan_remarks" label="Remarks" auto-grow rows="1" />
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <br>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                            <strong>२) विद्यमान भवन तथा पूर्वाधारहरुको भूकम्पीय प्रबलीकरण गरिसक्नुभएको छ भने उहादरण पेश गर्नुहोस । 
                              </strong>Can you provide any examples of seismic reinforcement you have performed on existing buildings and infrastructure? If so, please enter information such as the project title, implementing agency, name(s) of target facilities, seismic resistance measures applied, and project budgets, and submit relevant documents.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input type="radio" value="yes" v-model="seismic_project_present"/> छ (Yes)
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input type="radio" value="no" v-model="seismic_project_present"/> छैन (No)
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <br>
                      <v-simple-table class="registrationtable buildingcode sober" v-if="seismic_project_present == 'yes' ">
                        <thead>
                          <tr>
                            <th> संरचना (Structure)</th> 
                            <th> योजनाको नाम (Project title)</th> 
                            <th> नीति, मापदण्ड तथा ऐन कानून (Criterion)</th> 
                            <th> योजनाको बजेट (Project budget)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="question in form.questions" :key="question.structure">
                            <td>
                              {{question.structure}}
                            </td>
                            <td>
                              <v-textarea v-model="question.seismic_project_title" label="Title" auto-grow rows="1" />
                            </td>
                            <td>
                              <v-textarea v-model="question.seismic_project_criteria" label="Criterion" auto-grow rows="1" />
                            </td>
                            <td>
                              <v-textarea v-model="question.seismic_project_budget" label="Budget" auto-grow rows="1"/>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <br>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                              <strong>३) विद्यमान भवन तथा संरचनाहरुको भूकम्पीय प्रबलीकरणबारे केही योजना बनाउनुभएको छ ?
                              </strong>
                              यदि छ भने तल उल्लेखित लक्षित क्षेत्र, समय र बजेट लगायतका दस्तावेजहरु संलग्न गर्नुहोस्
                              Are you planning any seismic reinforcement projects for existing buildings and infrastructure? If so, please enter information such as the name(s) of target facilities and areas, planned timing of implementation, and project budgets, and submit relevant documents.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input type="radio" value="yes" v-model="seismic_plan2_present"/> छ (Yes)
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input type="radio" value="no" v-model="seismic_plan2_present"/> छैन (No)
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table class="registrationtable buildingcode sober" v-if="seismic_plan2_present == 'yes'">
                        <thead>
                          <tr>
                            <th> संरचना (Structure)</th> 
                            <th> क्षेत्र (Area name)</th> 
                            <th> योजना कार्यान्वयन गर्ने समय (Planned timing of implementation)</th> 
                            <th> योजनाको बजेट (Project budget)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="question in form.questions" :key="question.structure">
                            <td>
                              {{question.structure}}
                            </td>
                            <td>
                              <v-textarea v-model="question.seismic_plan2_area" label="Area" auto-grow rows="1" />
                            </td>
                            <td>
                              <v-textarea v-model="question.seismic_plan2_time" label="Time" auto-grow rows="1" />
                            </td>
                            <td>
                              <v-textarea v-model="question.seismic_plan2_budget" label="Budget" auto-grow rows="1"/>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <br>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                              <strong>४) भूकम्पीय प्रबलीकरण गर्ने सम्बन्धमा केही चूनातिहरु वा व्यवधानहरु सामना गर्नुपरेको थियो ? 
                              </strong>(Has your organization encountered any challenges or difficulty in the process of earthquake-proofing) ?"					

                            </td>
                          </tr>
                          <tr>
                            <td>
                              <tr>
                                <input type="checkbox" true-value="yes" false-value="no" v-model="form.seismic_tech_difficulty" />  प्राविधिक समस्याहरु (Technical difficulties)
                              </tr>
                              <tr>
                                <input type="checkbox" true-value="yes" false-value="no" v-model="form.seismic_budget_difficulty" />  बजेटको समस्या (Budgetary difficulties)
                              </tr>
                              <tr>
                                <input type="checkbox" true-value="yes" false-value="no" v-model="form.seismic_lack_of_personnel" />  जनशक्तिको अभाव (Lack of personnel)
                              </tr>
                              <tr>
                                <input type="checkbox" true-value="yes" false-value="no" v-model="form.seismic_other" />  अन्य (Other)
                              </tr>
                              <tr>
                              <v-textarea v-if="form.seismic_other == 'yes'"
                                  v-model="form.seismic_other_specify" label="Specify Description" auto-grow rows="1"
                                ></v-textarea>
                              </tr>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <br>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                             <strong> ५) यदि तपाइहरुसँग भूकम्पीय प्रबलीकरणसँग सम्बन्धित मापदण्ड वा निर्देशिकाहरु भए उल्लेख गर्नुहोस् ।
                                अथवा तपाइले अबलम्बन गर्दै आइरहेको (स्वदेशी वा विदेशी) निर्देशिका वा मापदण्ड केही भए उल्लेख गर्नुहोस् ।
                                </strong>Please give us the titles of any seismic reinforcement criteria or guidelines your organization has for existing buildings and infrastructure. Also, please submit relevant documents. Alternatively, please give us the titles of any foreign criteria or guidelines you follow."										

                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table class="registrationtable buildingcode sober">
                        <thead>
                          <tr>
                            <th> संरचना (Structure)</th> 
                            <th> निर्देशिका छ ? (Guidelines exist) ? </th>  
                            <th> निर्देशिकाको शीर्षक (Title of guidelines) </th> 
                            <th> "पछिल्लोपटक संशोधन गरिएको मिति (Year of latest revision)" </th>
                            <th> कागजपत्र समावेश गर्न सक्नुहुन्छ (Able to submit) ?</th> 
                            <th> कागजको किसिम (Format(s))</th>
                            <th> फाइलको नाम (Filename(s))</th>
                            <th> File Upload </th>
                            <th> इन्टरनेटको लिङ्क (URL) </th>
                            <th> कैफिएत (Remarks) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="question in form.questions" :key="question.structure">
                            <td>
                              {{question.structure}}
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.seismic_guidelines_present" /> Yes </tr>
                              <tr> <input type="radio" value="no" v-model="question.seismic_guidelines_present" /> No </tr>
                            </td>
                            <td>
                              <v-textarea v-model="question.seismic_guidelines_title" label="Title" auto-grow rows="1" />
                            </td>
                            
                            <td>
                              <v-textarea v-model="question.seismic_guidelines_year" label="Year" auto-grow rows="1"/>
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.seismic_guidelines_submit" /> सकिन्छ (Yes) </tr>
                              <tr> <input type="radio" value="no" v-model="question.seismic_guidelines_submit" /> सकिन्न (No) </tr>
                            </td>
                            <td v-if="question.seismic_guidelines_submit == 'yes' ">
                              <tr> <input type="radio" value="softcopy" v-model="question.seismic_guidelines_format" /> Soft Copy </tr>
                              <tr> <input type="radio" value="hardcopy" v-model="question.seismic_guidelines_format" /> Hard Copy </tr>
                            </td>
                            <td v-if="question.seismic_guidelines_submit == 'yes' ">
                              <v-textarea v-model="question.seismic_guidelines_filename" label="File Name" auto-grow rows="1" />
                            </td>
                            <td v-if="question.seismic_guidelines_submit == 'yes' ">
                              <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                              <v-file-input v-model="question.seismic_guidelines_file" label="Select File.."></v-file-input>
                            </td>
                            <td v-if="question.seismic_guidelines_submit == 'yes' ">
                              <v-textarea v-model="question.seismic_guidelines_url" label="URL" auto-grow rows="1" />
                            </td>
                            <td v-if="question.seismic_guidelines_submit == 'yes' ">
                              <v-textarea v-model="question.seismic_guidelines_remarks" label="Remarks" auto-grow rows="1" />
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </div>
                    <div v-if="this.selectedStructures.length">
                      <v-toolbar color="primary" dark flat short dense>
                        <v-toolbar-title>
                         ई) भवन संरचनाबारेका तथ्यांक (Data about structures)
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                            <strong>१) तपाईको संस्थाले व्यवस्थापन गर्ने भवन संरचनाहरुको महत्वका आधारमा वर्गीकरण गरेको छ ? 
                              यदि छ भने वर्गीकरण पुष्टि गर्ने आधारहरु पेश गर्नुहोस् ।
                            </strong>(Does your organization classify the importance of the facilities of the buildings you manage? Select target structures from the drop-down lists. If so, please provide documents that clarify your approach.)
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table class="registrationtable buildingcode sober">
                        <thead>
                          <tr>
                            <th> संरचना (Structure)</th> 
                            <th> Importance classified? </th>  
                            <th> Levels of importance </th> 
                            <th> Titles of relevant documents </th>
                            <th> कागजपत्र समावेश गर्न सक्नुहुन्छ (Able to submit) ?</th> 
                            <th> कागजको किसिम (Format(s))</th>
                            <th> फाइलको नाम (Filename(s))</th>
                            <th> File Upload </th>
                            <th> इन्टरनेटको लिङ्क (URL) </th>
                            <th> कैफिएत (Remarks) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="question in form.questions" :key="question.structure">
                            <td>
                              {{question.structure}}
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.building_importance_present" /> Yes </tr>
                              <tr> <input type="radio" value="no" v-model="question.building_importance_present" /> No </tr>
                            </td>
                            <td>
                              <v-textarea v-model="question.building_importance_level" label="Level" auto-grow rows="1" />
                            </td>
                            
                            <td>
                              <v-textarea v-model="question.building_importance_title" label="Title" auto-grow rows="1"/>
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.building_importance_submit" /> सकिन्छ (Yes) </tr>
                              <tr> <input type="radio" value="no" v-model="question.building_importance_submit" /> सकिन्न (No) </tr>
                            </td>
                            <td v-if="question.building_importance_submit == 'yes' ">
                              <tr> <input type="radio" value="softcopy" v-model="question.building_importance_format" /> Soft Copy </tr>
                              <tr> <input type="radio" value="hardcopy" v-model="question.building_importance_format" /> Hard Copy </tr>
                            </td>
                            <td v-if="question.building_importance_submit == 'yes' ">
                              <v-textarea v-model="question.building_importance_filename" label="File Name" auto-grow rows="1" />
                            </td>
                            <td v-if="question.building_importance_submit == 'yes' ">
                              <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                              <v-file-input v-model="question.building_importance_file" label="Select File.."></v-file-input>
                            </td>
                            <td v-if="question.building_importance_submit == 'yes' ">
                              <v-textarea v-model="question.building_importance_url" label="URL" auto-grow rows="1" />
                            </td>
                            <td v-if="question.building_importance_submit == 'yes' ">
                              <v-textarea v-model="question.building_importance_remarks" label="Remarks" auto-grow rows="1" />
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                              2) Does your organization prioritize which facilities of the buildings you manage to utilize and restore during and after disasters? Select target structures from the drop-down lists.
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table class="registrationtable buildingcode sober">
                        <thead>
                          <tr>
                            <th> संरचना (Structure)</th> 
                            <th> Utilization/restoration prioritized during/after disasters?	 </th>  
                            <th> Titles of relevant documents </th>
                            <th> कागजपत्र समावेश गर्न सक्नुहुन्छ (Able to submit) ?</th> 
                            <th> कागजको किसिम (Format(s))</th>
                            <th> फाइलको नाम (Filename(s))</th>
                            <th> File Upload </th>
                            <th> इन्टरनेटको लिङ्क (URL) </th>
                            <th> कैफिएत (Remarks) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="question in form.questions" :key="question.structure">
                            <td>
                              {{question.structure}}
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.building_priority_present" /> Yes </tr>
                              <tr> <input type="radio" value="no" v-model="question.building_priority_present" /> No </tr>
                            </td>
                            <td>
                              <v-textarea v-model="question.building_priority_title" label="Title" auto-grow rows="1"/>
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.building_priority_submit" /> सकिन्छ (Yes) </tr>
                              <tr> <input type="radio" value="no" v-model="question.building_priority_submit" /> सकिन्न (No) </tr>
                            </td>
                            <td v-if="question.building_priority_submit == 'yes' ">
                              <tr> <input type="radio" value="softcopy" v-model="question.building_priority_format" /> Soft Copy </tr>
                              <tr> <input type="radio" value="hardcopy" v-model="question.building_priority_format" /> Hard Copy </tr>
                            </td>
                            <td v-if="question.building_priority_submit == 'yes' ">
                              <v-textarea v-model="question.building_priority_filename" label="File Name" auto-grow rows="1" />
                            </td>
                            <td v-if="question.building_priority_submit == 'yes' ">
                              <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                              <v-file-input v-model="question.building_priority_file" label="Select File.."></v-file-input>
                            </td>
                            <td v-if="question.building_priority_submit == 'yes' ">
                              <v-textarea v-model="question.building_priority_url" label="URL" auto-grow rows="1" />
                            </td>
                            <td v-if="question.building_priority_submit == 'yes' ">
                              <v-textarea v-model="question.building_priority_remarks" label="Remarks" auto-grow rows="1" />
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                              <strong> 3) Does your organization have GIS data on existing buildings?  
                              If so, please provide the GIS data.
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table class="registrationtable buildingcode sober">
                        <thead>
                          <tr>
                            <th> संरचना (Structure)</th> 
                            <th> GIS data exists?	 </th>  
                            <th> Preparer of GIS data </th>
                            <th> कागजपत्र समावेश गर्न सक्नुहुन्छ (Able to submit) ?</th> 
                            <th> कागजको किसिम (Format(s))</th>
                            <th> फाइलको नाम (Filename(s))</th>
                            <th> File Upload </th>
                            <th> इन्टरनेटको लिङ्क (URL) </th>
                            <th> कैफिएत (Remarks) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="question in form.questions" :key="question.structure">
                            <td>
                              {{question.structure}}
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.building_gis_present" /> Yes </tr>
                              <tr> <input type="radio" value="no" v-model="question.building_gis_present" /> No </tr>
                            </td>
                            <td>
                              <v-textarea v-model="question.building_gis_preparer" label="Title" auto-grow rows="1"/>
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.building_gis_submit" /> सकिन्छ (Yes) </tr>
                              <tr> <input type="radio" value="no" v-model="question.building_gis_submit" /> सकिन्न (No) </tr>
                            </td>
                            <td v-if="question.building_gis_submit == 'yes' ">
                              <tr> <input type="radio" value="softcopy" v-model="question.building_gis_format" /> Soft Copy </tr>
                              <tr> <input type="radio" value="hardcopy" v-model="question.building_gis_format" /> Hard Copy </tr>
                            </td>
                            <td v-if="question.building_gis_submit == 'yes' ">
                              <v-textarea v-model="question.building_gis_filename" label="File Name" auto-grow rows="1" />
                            </td>
                            <td v-if="question.building_gis_submit == 'yes' ">
                              <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                              <v-file-input v-model="question.building_gis_file" label="Select File.."></v-file-input>
                            </td>
                            <td v-if="question.building_gis_submit == 'yes' ">
                              <v-textarea v-model="question.building_gis_url" label="URL" auto-grow rows="1" />
                            </td>
                            <td v-if="question.building_gis_submit == 'yes' ">
                              <v-textarea v-model="question.building_gis_remarks" label="Remarks" auto-grow rows="1" />
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                              <strong>4) Does your organization keep a register that includes information such as the designs, construction dates, and applied criteria of existing buildings? Select target structures from the drop-down lists.
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table class="registrationtable buildingcode sober">
                        <thead>
                          <tr>
                            <th> संरचना (Structure)</th> 
                            <th> Register Exists? </th>  
                            <th> Title of register </th> 
                            <th> Approximate number of structures with prepared/registered registers </th>
                            <th> कागजपत्र समावेश गर्न सक्नुहुन्छ (Able to submit) ?</th> 
                            <th> कागजको किसिम (Format(s))</th>
                            <th> फाइलको नाम (Filename(s))</th>
                            <th> File Upload </th>
                            <th> इन्टरनेटको लिङ्क (URL) </th>
                            <th> कैफिएत (Remarks) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="question in form.questions" :key="question.structure">
                            <td>
                              {{question.structure}}
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.building_register_present" /> Yes </tr>
                              <tr> <input type="radio" value="no" v-model="question.building_register_present" /> No </tr>
                            </td>
                            <td>
                              <v-textarea v-model="question.building_register_title" label="Title" auto-grow rows="1" />
                            </td>
                            
                            <td>
                              <v-textarea v-model="question.building_register_number" label="Number" auto-grow rows="1"/>
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.building_register_submit" /> सकिन्छ (Yes) </tr>
                              <tr> <input type="radio" value="no" v-model="question.building_register_submit" /> सकिन्न (No) </tr>
                            </td>
                            <td v-if="question.building_register_submit == 'yes' ">
                              <tr> <input type="radio" value="softcopy" v-model="question.building_register_format" /> Soft Copy </tr>
                              <tr> <input type="radio" value="hardcopy" v-model="question.building_register_format" /> Hard Copy </tr>
                            </td>
                            <td v-if="question.building_register_submit == 'yes' ">
                              <v-textarea v-model="question.building_register_filename" label="File Name" auto-grow rows="1" />
                            </td>
                            <td v-if="question.building_register_submit == 'yes' ">
                              <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                              <v-file-input v-model="question.building_register_file" label="Select File.."></v-file-input>
                            </td>
                            <td v-if="question.building_register_submit == 'yes' ">
                              <v-textarea v-model="question.building_register_url" label="URL" auto-grow rows="1" />
                            </td>
                            <td v-if="question.building_register_submit == 'yes' ">
                              <v-textarea v-model="question.building_register_remarks" label="Remarks" auto-grow rows="1" />
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                              <strong> 5) Does the register include information about construction expenses, floor areas, and maximum capacities of buildings? Select target structures from the drop-down lists.
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table class="registrationtable buildingcode sober">
                        <thead>
                          <tr>
                            <th> संरचना (Structure)</th> 
                            <th> Register exists?	 </th>  
                            <th> Title of register or document </th>
                            <th> कागजपत्र समावेश गर्न सक्नुहुन्छ (Able to submit) ?</th> 
                            <th> कागजको किसिम (Format(s))</th>
                            <th> फाइलको नाम (Filename(s))</th>
                            <th> File Upload </th>
                            <th> इन्टरनेटको लिङ्क (URL) </th>
                            <th> कैफिएत (Remarks) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="question in form.questions" :key="question.structure">
                            <td>
                              {{question.structure}}
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.building_info_present" /> Yes </tr>
                              <tr> <input type="radio" value="no" v-model="question.building_info_present" /> No </tr>
                            </td>
                            <td>
                              <v-textarea v-model="question.building_info_preparer" label="Title" auto-grow rows="1"/>
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.building_info_submit" /> सकिन्छ (Yes) </tr>
                              <tr> <input type="radio" value="no" v-model="question.building_info_submit" /> सकिन्न (No) </tr>
                            </td>
                            <td v-if="question.building_info_submit == 'yes' ">
                              <tr> <input type="radio" value="softcopy" v-model="question.building_info_format" /> Soft Copy </tr>
                              <tr> <input type="radio" value="hardcopy" v-model="question.building_info_format" /> Hard Copy </tr>
                            </td>
                            <td v-if="question.building_info_submit == 'yes' ">
                              <v-textarea v-model="question.building_info_filename" label="File Name" auto-grow rows="1" />
                            </td>
                            <td v-if="question.building_info_submit == 'yes' ">
                              <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                              <v-file-input v-model="question.building_info_file" label="Select File.."></v-file-input>
                            </td>
                            <td v-if="question.building_info_submit == 'yes' ">
                              <v-textarea v-model="question.building_info_url" label="URL" auto-grow rows="1" />
                            </td>
                            <td v-if="question.building_info_submit == 'yes' ">
                              <v-textarea v-model="question.building_info_remarks" label="Remarks" auto-grow rows="1" />
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </div>
                    <div v-if="this.selectedStructures.length">
                      <v-toolbar color="primary" dark flat short dense>
                        <v-toolbar-title>
                         ई) १‌‌) भवन संरचनाबारेका तथ्यांक (Data about structures) (Infrastructure)
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                            <strong>१) तपाईको संस्थाले व्यवस्थापन गर्ने भवन संरचनाहरुको महत्वका आधारमा वर्गीकरण गरेको छ ? 
                              यदि छ भने वर्गीकरण पुष्टि गर्ने आधारहरु पेश गर्नुहोस् ।
                            </strong>(Does your organization classify the importance of the facilities of the infrastructure you manage? Select target structures from the drop-down lists. If so, please provide documents that clarify your approach.)
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table class="registrationtable buildingcode sober">
                        <thead>
                          <tr>
                            <th> संरचना (Structure)</th> 
                            <th> Importance classified? </th>  
                            <th> Levels of importance </th> 
                            <th> Titles of relevant documents </th>
                            <th> कागजपत्र समावेश गर्न सक्नुहुन्छ (Able to submit) ?</th> 
                            <th> कागजको किसिम (Format(s))</th>
                            <th> फाइलको नाम (Filename(s))</th>
                            <th> File Upload </th>
                            <th> इन्टरनेटको लिङ्क (URL) </th>
                            <th> कैफिएत (Remarks) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="question in form.questions" :key="question.structure">
                            <td>
                              {{question.structure}}
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.infra_importance_present" /> Yes </tr>
                              <tr> <input type="radio" value="no" v-model="question.infra_importance_present" /> No </tr>
                            </td>
                            <td>
                              <v-textarea v-model="question.infra_importance_level" label="Level" auto-grow rows="1" />
                            </td>
                            
                            <td>
                              <v-textarea v-model="question.infra_importance_title" label="Title" auto-grow rows="1"/>
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.infra_importance_submit" /> सकिन्छ (Yes) </tr>
                              <tr> <input type="radio" value="no" v-model="question.infra_importance_submit" /> सकिन्न (No) </tr>
                            </td>
                            <td v-if="question.infra_importance_submit == 'yes' ">
                              <tr> <input type="radio" value="softcopy" v-model="question.infra_importance_format" /> Soft Copy </tr>
                              <tr> <input type="radio" value="hardcopy" v-model="question.infra_importance_format" /> Hard Copy </tr>
                            </td>
                            <td v-if="question.infra_importance_submit == 'yes' ">
                              <v-textarea v-model="question.infra_importance_filename" label="File Name" auto-grow rows="1" />
                            </td>
                            <td v-if="question.infra_importance_submit == 'yes' ">
                              <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                              <v-file-input v-model="question.infra_importance_file" label="Select File.."></v-file-input>
                            </td>
                            <td v-if="question.infra_importance_submit == 'yes' ">
                              <v-textarea v-model="question.infra_importance_url" label="URL" auto-grow rows="1" />
                            </td>
                            <td v-if="question.infra_importance_submit == 'yes' ">
                              <v-textarea v-model="question.infra_importance_remarks" label="Remarks" auto-grow rows="1" />
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                              <strong> 2) Does your organization prioritize which facilities of the infrastructure you manage to utilize and restore during and after disasters? Select target structures from the drop-down lists.
                                </strong>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table class="registrationtable infracode sober">
                        <thead>
                          <tr>
                            <th> संरचना (Structure)</th> 
                            <th> Utilization/restoration prioritized during/after disasters?	 </th>  
                            <th> Titles of relevant documents </th>
                            <th> कागजपत्र समावेश गर्न सक्नुहुन्छ (Able to submit) ?</th> 
                            <th> कागजको किसिम (Format(s))</th>
                            <th> फाइलको नाम (Filename(s))</th>
                            <th> File Upload </th>
                            <th> इन्टरनेटको लिङ्क (URL) </th>
                            <th> कैफिएत (Remarks) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="question in form.questions" :key="question.structure">
                            <td>
                              {{question.structure}}
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.infra_priority_present" /> Yes </tr>
                              <tr> <input type="radio" value="no" v-model="question.infra_priority_present" /> No </tr>
                            </td>
                            <td>
                              <v-textarea v-model="question.infra_priority_title" label="Title" auto-grow rows="1"/>
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.infra_priority_submit" /> सकिन्छ (Yes) </tr>
                              <tr> <input type="radio" value="no" v-model="question.infra_priority_submit" /> सकिन्न (No) </tr>
                            </td>
                            <td v-if="question.infra_priority_submit == 'yes' ">
                              <tr> <input type="radio" value="softcopy" v-model="question.infra_priority_format" /> Soft Copy </tr>
                              <tr> <input type="radio" value="hardcopy" v-model="question.infra_priority_format" /> Hard Copy </tr>
                            </td>
                            <td v-if="question.infra_priority_submit == 'yes' ">
                              <v-textarea v-model="question.infra_priority_filename" label="File Name" auto-grow rows="1" />
                            </td>
                            <td v-if="question.infra_priority_submit == 'yes' ">
                              <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                              <v-file-input v-model="question.infra_priority_file" label="Select File.."></v-file-input>
                            </td>
                            <td v-if="question.infra_priority_submit == 'yes' ">
                              <v-textarea v-model="question.infra_priority_url" label="URL" auto-grow rows="1" />
                            </td>
                            <td v-if="question.infra_priority_submit == 'yes' ">
                              <v-textarea v-model="question.infra_priority_remarks" label="Remarks" auto-grow rows="1" />
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                              <strong> 3) Does your organization have GIS data on existing infrastructure?  
                              If so, please provide the GIS data.
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table class="registrationtable buildingcode sober">
                        <thead>
                          <tr>
                            <th> संरचना (Structure)</th> 
                            <th> GIS data exists?	 </th>  
                            <th> Preparer of GIS data </th>
                            <th> कागजपत्र समावेश गर्न सक्नुहुन्छ (Able to submit) ?</th> 
                            <th> कागजको किसिम (Format(s))</th>
                            <th> फाइलको नाम (Filename(s))</th>
                            <th> File Upload </th>
                            <th> इन्टरनेटको लिङ्क (URL) </th>
                            <th> कैफिएत (Remarks) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="question in form.questions" :key="question.structure">
                            <td>
                              {{question.structure}}
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.infra_gis_present" /> Yes </tr>
                              <tr> <input type="radio" value="no" v-model="question.infra_gis_present" /> No </tr>
                            </td>
                            <td>
                              <v-textarea v-model="question.infra_gis_preparer" label="Title" auto-grow rows="1"/>
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.infra_gis_submit" /> सकिन्छ (Yes) </tr>
                              <tr> <input type="radio" value="no" v-model="question.infra_gis_submit" /> सकिन्न (No) </tr>
                            </td>
                            <td v-if="question.infra_gis_submit == 'yes' ">
                              <tr> <input type="radio" value="softcopy" v-model="question.infra_gis_format" /> Soft Copy </tr>
                              <tr> <input type="radio" value="hardcopy" v-model="question.infra_gis_format" /> Hard Copy </tr>
                            </td>
                            <td v-if="question.infra_gis_submit == 'yes' ">
                              <v-textarea v-model="question.infra_gis_filename" label="File Name" auto-grow rows="1" />
                            </td>
                            <td v-if="question.infra_gis_submit == 'yes' ">
                              <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                              <v-file-input v-model="question.infra_gis_file" label="Select File.."></v-file-input>
                            </td>
                            <td v-if="question.infra_gis_submit == 'yes' ">
                              <v-textarea v-model="question.infra_gis_url" label="URL" auto-grow rows="1" />
                            </td>
                            <td v-if="question.infra_gis_submit == 'yes' ">
                              <v-textarea v-model="question.infra_gis_remarks" label="Remarks" auto-grow rows="1" />
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                              <strong>4) Does your organization keep a register that includes information such as the designs, construction dates, and applied criteria of existing infrastructure? Select target structures from the drop-down lists.
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table class="registrationtable buildingcode sober">
                        <thead>
                          <tr>
                            <th> संरचना (Structure)</th> 
                            <th> Register Exists? </th>  
                            <th> Title of register </th> 
                            <th> Approximate number of structures with prepared/registered registers </th>
                            <th> कागजपत्र समावेश गर्न सक्नुहुन्छ (Able to submit) ?</th> 
                            <th> कागजको किसिम (Format(s))</th>
                            <th> फाइलको नाम (Filename(s))</th>
                            <th> File Upload </th>
                            <th> इन्टरनेटको लिङ्क (URL) </th>
                            <th> कैफिएत (Remarks) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="question in form.questions" :key="question.structure">
                            <td>
                              {{question.structure}}
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.infra_register_present" /> Yes </tr>
                              <tr> <input type="radio" value="no" v-model="question.infra_register_present" /> No </tr>
                            </td>
                            <td>
                              <v-textarea v-model="question.infra_register_title" label="Title" auto-grow rows="1" />
                            </td>
                            
                            <td>
                              <v-textarea v-model="question.infra_register_number" label="Number" auto-grow rows="1"/>
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.infra_register_submit" /> सकिन्छ (Yes) </tr>
                              <tr> <input type="radio" value="no" v-model="question.infra_register_submit" /> सकिन्न (No) </tr>
                            </td>
                            <td v-if="question.infra_register_submit == 'yes' ">
                              <tr> <input type="radio" value="softcopy" v-model="question.infra_register_format" /> Soft Copy </tr>
                              <tr> <input type="radio" value="hardcopy" v-model="question.infra_register_format" /> Hard Copy </tr>
                            </td>
                            <td v-if="question.infra_register_submit == 'yes' ">
                              <v-textarea v-model="question.infra_register_filename" label="File Name" auto-grow rows="1" />
                            </td>
                            <td v-if="question.infra_register_submit == 'yes' ">
                              <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                              <v-file-input v-model="question.infra_register_file" label="Select File.."></v-file-input>
                            </td>
                            <td v-if="question.infra_register_submit == 'yes' ">
                              <v-textarea v-model="question.infra_register_url" label="URL" auto-grow rows="1" />
                            </td>
                            <td v-if="question.infra_register_submit == 'yes' ">
                              <v-textarea v-model="question.infra_register_remarks" label="Remarks" auto-grow rows="1" />
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                              <strong> 5) Does the register include information about construction expenses, floor areas, and maximum capacities of infrastructure? Select target structures from the drop-down lists.
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table class="registrationtable buildingcode sober">
                        <thead>
                          <tr>
                            <th> संरचना (Structure)</th> 
                            <th> Register exists?	 </th>  
                            <th> Title of register or document </th>
                            <th> कागजपत्र समावेश गर्न सक्नुहुन्छ (Able to submit) ?</th> 
                            <th> कागजको किसिम (Format(s))</th>
                            <th> फाइलको नाम (Filename(s))</th>
                            <th> File Upload </th>
                            <th> इन्टरनेटको लिङ्क (URL) </th>
                            <th> कैफिएत (Remarks) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="question in form.questions" :key="question.structure">
                            <td>
                              {{question.structure}}
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.infra_info_present" /> Yes </tr>
                              <tr> <input type="radio" value="no" v-model="question.infra_info_present" /> No </tr>
                            </td>
                            <td>
                              <v-textarea v-model="question.infra_info_preparer" label="Title" auto-grow rows="1"/>
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="question.infra_info_submit" /> सकिन्छ (Yes) </tr>
                              <tr> <input type="radio" value="no" v-model="question.infra_info_submit" /> सकिन्न (No) </tr>
                            </td>
                            <td v-if="question.infra_info_submit == 'yes' ">
                              <tr> <input type="radio" value="softcopy" v-model="question.infra_info_format" /> Soft Copy </tr>
                              <tr> <input type="radio" value="hardcopy" v-model="question.infra_info_format" /> Hard Copy </tr>
                            </td>
                            <td v-if="question.infra_info_submit == 'yes' ">
                              <v-textarea v-model="question.infra_info_filename" label="File Name" auto-grow rows="1" />
                            </td>
                            <td v-if="question.infra_info_submit == 'yes' ">
                              <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                              <v-file-input v-model="question.infra_info_file" label="Select File.."></v-file-input>
                            </td>
                            <td v-if="question.infra_info_submit == 'yes' ">
                              <v-textarea v-model="question.infra_info_url" label="URL" auto-grow rows="1" />
                            </td>
                            <td v-if="question.infra_info_submit == 'yes' ">
                              <v-textarea v-model="question.infra_info_remarks" label="Remarks" auto-grow rows="1" />
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </div>
                    <div v-if="this.selectedStructures.length">
                      <v-toolbar color="primary" dark flat short dense>
                        <v-toolbar-title>
                         ई) Data about evacuation facilities
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                              <strong> Does your organization manage any evacuation facilities for disasters?
                              If so, please provide the GIS data.
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table class="registrationtable buildingcode sober">
                        <thead>
                          <tr>
                            <th> Category</th> 
                            <th> संरचना (Structure)</th> 
                            <th> GIS data exists?	 </th>  
                            <th> Preparer of GIS data </th>
                            <th> कागजपत्र समावेश गर्न सक्नुहुन्छ (Able to submit) ?</th> 
                            <th> कागजको किसिम (Format(s))</th>
                            <th> फाइलको नाम (Filename(s))</th>
                            <th> File Upload </th>
                            <th> इन्टरनेटको लिङ्क (URL) </th>
                            <th> कैफिएत (Remarks) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="evacuation in form.evacuations" :key="evacuation.evac_category">
                            <td>
                              {{evacuation.evac_category}}
                            </td>
                            <td>
                               <v-select
                                v-model="evacuation.structure"
                                :items="evacStructures"
                                item-value="value"
                                item-text="value"
                                :key="id"
                              ></v-select>
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="evacuation.evac_data_present" /> Yes </tr>
                              <tr> <input type="radio" value="no" v-model="evacuation.evac_data_present" /> No </tr>
                            </td>
                            <td>
                              <v-textarea v-model="evacuation.evac_data_preparer" label="Preparer" auto-grow rows="1"/>
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="evacuation.evac_data_submit" /> सकिन्छ (Yes) </tr>
                              <tr> <input type="radio" value="no" v-model="evacuation.evac_data_submit" /> सकिन्न (No) </tr>
                            </td>
                            <td v-if="evacuation.evac_data_submit == 'yes' ">
                              <tr> <input type="radio" value="softcopy" v-model="evacuation.evac_data_format" /> Soft Copy </tr>
                              <tr> <input type="radio" value="hardcopy" v-model="evacuation.evac_data_format" /> Hard Copy </tr>
                            </td>
                            <td v-if="evacuation.evac_data_submit == 'yes' ">
                              <v-textarea v-model="evacuation.evac_data_filename" label="File Name" auto-grow rows="1" />
                            </td>
                            <td v-if="evacuation.evac_data_submit == 'yes' ">
                              <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                              <v-file-input v-model="evacuation.evac_data_file" label="Select File.."></v-file-input>
                            </td>
                            <td v-if="evacuation.evac_data_submit == 'yes' ">
                              <v-textarea v-model="evacuation.evac_data_url" label="URL" auto-grow rows="1" />
                            </td>
                            <td v-if="evacuation.evac_data_submit == 'yes' ">
                              <v-textarea v-model="evacuation.evac_data_remarks" label="Remarks" auto-grow rows="1" />
                            </td>
                          </tr>
                        </tbody>
                        <v-btn
                          color="primary"
                          dark
                          small
                          @click.prevent='add_new_evac'
                        >
                          Add row
                        </v-btn>
                      </v-simple-table>
                    </div>
                    <br>
                    <div v-if="this.selectedStructures.length">
                      <v-toolbar color="primary" dark flat short dense>
                        <v-toolbar-title>
                         5. Development plans and budgets
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                              <strong> Does your national or local government have a budgetary system for seismic reinforcement 
                                and other proactive investments in disaster risk reduction for buildings and infrastructure? 
                                Select target structures from the drop-down list.
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table class="registrationtable buildingcode sober">
                        <thead>
                          <tr>
                            <th> Category</th> 
                            <th> Budgetary system exists?	 </th>  
                            <th> Titles of relevant plans/documents </th>
                            <th> कागजपत्र समावेश गर्न सक्नुहुन्छ (Able to submit) ?</th> 
                            <th> कागजको किसिम (Format(s))</th>
                            <th> फाइलको नाम (Filename(s))</th>
                            <th> File Upload </th>
                            <th> इन्टरनेटको लिङ्क (URL) </th>
                            <th> कैफिएत (Remarks) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="budget in form.budgets" :key="budget.budget_category">
                            <td>
                              {{budget.budget_category}}
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="budget.budget_present" /> Yes </tr>
                              <tr> <input type="radio" value="no" v-model="budget.budget_present" /> No </tr>
                            </td>
                            <td >
                              <v-textarea v-model="budget.budget_title" label="Title" auto-grow rows="1" />
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="budget.budget_submit" /> सकिन्छ (Yes) </tr>
                              <tr> <input type="radio" value="no" v-model="budget.budget_submit" /> सकिन्न (No) </tr>
                            </td>
                            <td v-if="budget.budget_submit == 'yes' ">
                              <tr> <input type="radio" value="softcopy" v-model="budget.budget_format" /> Soft Copy </tr>
                              <tr> <input type="radio" value="hardcopy" v-model="budget.budget_format" /> Hard Copy </tr>
                            </td>
                            <td v-if="budget.budget_submit == 'yes' ">
                              <v-textarea v-model="budget.budget_filename" label="File Name" auto-grow rows="1" />
                            </td>
                            <td v-if="budget.budget_submit == 'yes' ">
                              <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                              <v-file-input v-model="budget.budget_file" label="Select File.."></v-file-input>
                            </td>
                            <td v-if="budget.budget_submit == 'yes' ">
                              <v-textarea v-model="budget.budget_url" label="URL" auto-grow rows="1" />
                            </td>
                            <td v-if="budget.budget_submit == 'yes' ">
                              <v-textarea v-model="budget.budget_remarks" label="Remarks" auto-grow rows="1" />
                            </td>
                          </tr>
                        </tbody>
                        <v-btn
                          color="primary"
                          dark
                          small
                          @click.prevent='add_new_budget'
                        >
                          Add row
                        </v-btn>
                      </v-simple-table>
                      <br>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                              <strong> 2) To help us understand the state of your organization’s finances and medium-term and long-term plans, please submit the following documents.
                                On the following table, please provide information about the documents, such as their titles or whether you are or are not able to submit them.
                                
                              </strong>
                              (Include URLs for documents available online)
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table class="registrationtable buildingcode sober">
                        <thead>
                          <tr>
                            <th> Description of documents	</th> 
                            <th> Documents exist?	 </th>  
                            <th>Document title(s) </th>
                            <th> कागजपत्र समावेश गर्न सक्नुहुन्छ (Able to submit) ?</th> 
                            <th> कागजको किसिम (Format(s))</th>
                            <th> फाइलको नाम (Filename(s))</th>
                            <th> File Upload </th>
                            <th> इन्टरनेटको लिङ्क (URL) </th>
                            <th> कैफिएत (Remarks) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              1) Documents showing your organization’s itemized budgets for the past five years
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="form.five_year_doc_present" /> Yes </tr>
                              <tr> <input type="radio" value="no" v-model="form.five_year_doc_present" /> No </tr>
                            </td>     
                            <td >
                              <v-textarea v-model="form.five_year_doc_title" label="Title" auto-grow rows="1" />
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="form.five_year_doc_submit" /> सकिन्छ (Yes) </tr>
                              <tr> <input type="radio" value="no" v-model="form.five_year_doc_submit" /> सकिन्न (No) </tr>
                            </td>
                            <td v-if="form.five_year_doc_submit == 'yes' ">
                              <tr> <input type="radio" value="softcopy" v-model="form.five_year_doc_format" /> Soft Copy </tr>
                              <tr> <input type="radio" value="hardcopy" v-model="form.five_year_doc_format" /> Hard Copy </tr>
                            </td>
                            <td v-if="form.five_year_docfive_year_doc_submit == 'yes' ">
                              <v-textarea v-model="form.five_year_doc_filename" label="File Name" auto-grow rows="1" />
                            </td>
                            <td v-if="form.five_year_doc_submit == 'yes' ">
                              <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                              <v-file-input v-model="form.five_year_doc_file" label="Select File.."></v-file-input>
                            </td>
                            <td v-if="form.five_year_doc_submit == 'yes' ">
                              <v-textarea v-model="form.five_year_doc_url" label="URL" auto-grow rows="1" />
                            </td>
                            <td v-if="form.five_year_doc_submit == 'yes' ">
                              <v-textarea v-model="form.five_year_doc_remarks" label="Remarks" auto-grow rows="1" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              2) Your organization’s medium-term and long-term plans
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="form.long_term_doc_present" /> Yes </tr>
                              <tr> <input type="radio" value="no" v-model="form.long_term_doc_present" /> No </tr>
                            </td>     
                            <td >
                              <v-textarea v-model="form.long_term_doc_title" label="Title" auto-grow rows="1" />
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="form.long_term_doc_submit" /> सकिन्छ (Yes) </tr>
                              <tr> <input type="radio" value="no" v-model="form.long_term_doc_submit" /> सकिन्न (No) </tr>
                            </td>
                            <td v-if="form.long_term_doc_submit == 'yes' ">
                              <tr> <input type="radio" value="softcopy" v-model="form.long_term_doc_format" /> Soft Copy </tr>
                              <tr> <input type="radio" value="hardcopy" v-model="form.long_term_doc_format" /> Hard Copy </tr>
                            </td>
                            <td v-if="form.long_term_doc_submit == 'yes' ">
                              <v-textarea v-model="form.long_term_doc_filename" label="File Name" auto-grow rows="1" />
                            </td>
                            <td v-if="form.long_term_doc_submit == 'yes' ">
                              <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                              <v-file-input v-model="form.long_term_doc_file" label="Select File.."></v-file-input>
                            </td>
                            <td v-if="form.long_term_doc_submit == 'yes' ">
                              <v-textarea v-model="form.long_term_doc_url" label="URL" auto-grow rows="1" />
                            </td>
                            <td v-if="form.long_term_doc_submit == 'yes' ">
                              <v-textarea v-model="form.long_term_doc_remarks" label="Remarks" auto-grow rows="1" />
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                              <strong> 3)To help us understand your organization’s disaster risk reduction plans and relevant laws for earthquakes, please submit the following documents.
                              </strong>
                              On the following table, please provide information about the documents, such as their titles or whether you are or are not able to submit them.
                              (Include URLs for documents available online) Please submit the documents to the local consultant.
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th> Description of documents	</th> 
                            <th> Documents exist?	 </th>  
                            <th>Document title(s) </th>
                            <th> कागजपत्र समावेश गर्न सक्नुहुन्छ (Able to submit) ?</th> 
                            <th> कागजको किसिम (Format(s))</th>
                            <th> फाइलको नाम (Filename(s))</th>
                            <th> File Upload </th>
                            <th> इन्टरनेटको लिङ्क (URL) </th>
                            <th> Relevant Laws </th>
                            <th> कैफिएत (Remarks) </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              1) Latest version of your organization’s disaster risk reduction plan for earthquakes
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="form.disaster_risk_doc_present" /> Yes </tr>
                              <tr> <input type="radio" value="no" v-model="form.disaster_risk_doc_present" /> No </tr>
                            </td>     
                            <td >
                              <v-textarea v-model="form.disaster_risk_doc_title" label="Title" auto-grow rows="1" />
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="form.disaster_risk_doc_submit" /> सकिन्छ (Yes) </tr>
                              <tr> <input type="radio" value="no" v-model="form.disaster_risk_doc_submit" /> सकिन्न (No) </tr>
                            </td>
                            <td v-if="form.disaster_risk_doc_submit == 'yes' ">
                              <tr> <input type="radio" value="softcopy" v-model="form.disaster_risk_doc_format" /> Soft Copy </tr>
                              <tr> <input type="radio" value="hardcopy" v-model="form.disaster_risk_doc_format" /> Hard Copy </tr>
                            </td>
                            <td v-if="form.disaster_risk_doc_submit == 'yes' ">
                              <v-textarea v-model="form.disaster_risk_doc_filename" label="File Name" auto-grow rows="1" />
                            </td>
                            <td v-if="form.disaster_risk_doc_submit == 'yes' ">
                              <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                              <v-file-input v-model="form.disaster_risk_doc_file" label="Select File.."></v-file-input>
                            </td>
                            <td v-if="form.disaster_risk_doc_submit == 'yes' ">
                              <v-textarea v-model="form.disaster_risk_doc_url" label="URL" auto-grow rows="1" />
                            </td>
                            <td v-if="form.disaster_risk_doc_submit == 'yes' ">
                              <v-textarea v-model="form.disaster_risk_doc_laws" label="Relevant law" auto-grow rows="1" />
                            </td>
                            <td v-if="form.disaster_risk_doc_submit == 'yes' ">
                              <v-textarea v-model="form.disaster_risk_doc_remarks" label="Remarks" auto-grow rows="1" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              2) Other documents
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="form.response_plan_doc_present" /> Yes </tr>
                              <tr> <input type="radio" value="no" v-model="form.response_plan_doc_present" /> No </tr>
                            </td>     
                            <td >
                              <v-textarea v-model="form.response_plan_doc_title" label="Title" auto-grow rows="1" />
                            </td>
                            <td>
                              <tr> <input type="radio" value="yes" v-model="form.response_plan_doc_submit" /> सकिन्छ (Yes) </tr>
                              <tr> <input type="radio" value="no" v-model="form.response_plan_doc_submit" /> सकिन्न (No) </tr>
                            </td>
                            <td v-if="form.response_plan_doc_submit == 'yes' ">
                              <tr> <input type="radio" value="softcopy" v-model="form.response_plan_doc_format" /> Soft Copy </tr>
                              <tr> <input type="radio" value="hardcopy" v-model="form.response_plan_doc_format" /> Hard Copy </tr>
                            </td>
                            <td v-if="form.response_plan_doc_submit == 'yes' ">
                              <v-textarea v-model="form.response_plan_doc_filename" label="File Name" auto-grow rows="1" />
                            </td>
                            <td v-if="form.response_plan_doc_submit == 'yes' ">
                              <!-- <input type="file" id="org_chart_doc_file" @change="onFileChange('org_chart_doc_file')" /> -->
                              <v-file-input v-model="form.response_plan_doc_file" label="Select File.."></v-file-input>
                            </td>
                            <td v-if="form.response_plan_doc_submit == 'yes' ">
                              <v-textarea v-model="form.response_plan_doc_url" label="URL" auto-grow rows="1" />
                            </td>
                            <td v-if="form.response_plan_doc_submit == 'yes' ">
                              <v-textarea v-model="form.response_plan_doc_laws" label="Relevant law" auto-grow rows="1" />
                            </td>
                            <td v-if="form.response_plan_doc_submit == 'yes' ">
                              <v-textarea v-model="form.response_plan_doc_remarks" label="Remarks" auto-grow rows="1" />
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </div>
                    <br>
                    <div v-if="this.selectedStructures.length">
                      <v-toolbar color="primary" dark flat short dense>
                        <v-toolbar-title>
                         6. Results and status of projects with other donors
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                              In the past 10 years, has your organization implemented any assistance projects for improving the buildings and
                               infrastructure you manage? Are any such projects ongoing? 
                              If so, please provide overviews on the following table.
                            </td>
                          </tr>
                          <tr> <input type="radio" value="yes" v-model="form.donor_project_present" /> Yes </tr>
                          <tr> <input type="radio" value="no" v-model="form.donor_project_present" /> No </tr>
                        </tbody>
                      </v-simple-table>
                      <br>
                      <v-simple-table class="registrationtable buildingcode sober">
                        <thead>
                          <tr>
                            <th> Title</th> 
                            <th> Implementation</th>  
                            <th> Donor Name</th>
                            <th> URL</th> 
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="donorProject in form.donorProjects" :key="donorProject.donor_project_title">
                            <td >
                              <v-textarea v-model="donorProject.donor_project_title" label="Title" auto-grow rows="1" />
                            </td>
                            <td>
                              <v-textarea v-model="donorProject.donor_project_period" label="Period" auto-grow rows="1" />
                            </td>
                            <td>
                              <v-textarea v-model="donorProject.donor_project_name" label="Donor Name" auto-grow rows="1" />
                            </td>
                            <td>
                              <v-textarea v-model="donorProject.donor_project_url" label="URL" auto-grow rows="1" />
                            </td>
                            
                          </tr>
                        </tbody>
                        <v-btn
                          color="primary"
                          dark
                          small
                          @click.prevent='add_new_donor_project'
                        >
                          Add row
                        </v-btn>
                      </v-simple-table>
                      <br>
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                              Are any infrastructure improvement (earthquake-proofing, earthquake disaster risk reduction, resilience improvement) 
                              projects with other donors currently being planned, or scheduled for implementation in the future? If so, please provide 
                              overviews on the following table. 
                              (e.g., earthquake-resistant design, standards-related matters, seismic reinforcement)
                            </td>
                          </tr>
                          <tr> <input type="radio" value="yes" v-model="form.donor_infra_project_present" /> Yes </tr>
                          <tr> <input type="radio" value="no" v-model="form.donor_infra_project_present" /> No </tr>
                        </tbody>
                      </v-simple-table>
                      <br>
                      <v-simple-table class="registrationtable buildingcode sober">
                        <thead>
                          <tr>
                            <th> Title</th> 
                            <th> Implementation</th>  
                            <th> Donor Name</th>
                            <th> URL</th> 
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="donorInfraProject in form.donorInfraProjects" :key="donorInfraProject.donor_infra_project_title">
                            <td >
                              <v-textarea v-model="donorInfraProject.donor_infra_project_title" label="Title" auto-grow rows="1" />
                            </td>
                            <td>
                              <v-textarea v-model="donorInfraProject.donor_infra_project_period" label="Period" auto-grow rows="1" />
                            </td>
                            <td>
                              <v-textarea v-model="donorInfraProject.donor_infra_project_name" label="Donor Name" auto-grow rows="1" />
                            </td>
                            <td>
                              <v-textarea v-model="donorInfraProject.donor_infra_project_url" label="URL" auto-grow rows="1" />
                            </td>
                            
                          </tr>
                        </tbody>
                        <v-btn
                          color="primary"
                          dark
                          small
                          @click.prevent='add_new_donor_infra_project'
                        >
                          Add row
                        </v-btn>
                      </v-simple-table>
                    </div>
                  </div>
                  
                </v-card>
                <span v-if="successfulMessage">
                  <v-alert dense outlined type="alert">
                    {{ successfulMessage }}
                  </v-alert>
                </span>
                <br>
                <v-btn
                  primary
                  v-on:click="save"
                  style="width:100px;height:45px;"
                  class="primary m-2"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
          <!-- </v-container> -->
        </v-form>
      <!-- </v-app> -->
    </v-card>
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "JicaOyo",
  data() {
    return {
      valid: false,
      required(propertyType) {
        return v => !!v || `${propertyType} is required`;
      },
      userRoleId: localStorage.getItem("auth_user_role_id"),
      successfulMessage: "",
      selectedCategory: "Government_Building",
      selectedStructures: [],

      seismic_plan_present: '',
      seismic_project_present: '',
      seismic_plan2_present: '',
      
      seismic_tech_difficulty: '',
      seismic_budget_difficulty: '',
      seismic_lack_of_personnel: '',
      seismic_other: '',
      seismic_other_specify: '',

      category: [
        {
          id: 1,
          name: "Government_Building",
          structure: [
            {
              id: 1,
              name: "Government Building"
            },
            {
              id: 2,
              name: "Local Government Building"
            },
          ],
        },
        {
          id: 2,
          name: "Public_Service_Building",
          structure: [
            {
              id: 1,
              name: "Public Hospital"
            },
            {
              id: 2,
              name: "Police Station"
            },
            {
              id: 3,
              name: "Fire Department"
            },
            {
              id: 4,
              name: "Public School"
            },
          ]
        },
        {
          id: 3,
          name: "Industrial_Production_facilities",
          structure: [
            {
              id: 1,
              name: "Factory"
            },
            {
              id: 2,
              name: "Distribution Warehouse"
            },
            {
              id: 3,
              name: "Industrial accumulation area including factories and distribution warehouses"
            },
          ],
        },    
        {
          id: 4,
          name: "Private_building",
          structure: [
            {
              id: 1,
              name: "Large Office Building"
            },
          ],
        },    
        {
          id: 5,
          name: "River_Management_Facility",
          structure: [
            {
              id: 1,
              name: "Dam"
            },
            {
              id: 2,
              name: "Reservoir"
            },
            {
              id: 3,
              name: "River Embankment"
            },
            {
              id: 4,
              name: "Pumping or drainage station"
            },
            {
              id: 5,
              name: "Weir"
            },
            {
              id: 6,
              name: "land lock"
            },
            {
              id: 7,
              name: "flood gate"
            },
            {
              id: 8,
              name: "sluiceway"
            },
          ],
        },    
        {
          id: 6,
          name: "Coastal_Protection_Facility- ",
          structure: [
            {
              id: 1,
              name: "Seawall (for Tsunami)"
            },
            {
              id: 2,
              name: "Land lock located on seawalls"
            },
          ],
        },    
        {
          id: 7,
          name: "Evacuation_Facility",
          structure: [
            {
              id: 1,
              name: "Facilities installed as an evacuation center for earthquakes and tsunamis"
            },
            {
              id: 2,
              name: "Buildings for public service"
            },
            {
              id: 3,
              name: "Religious buildings such as temples, churches and mosques"
            },
          ],
        },    
        {
          id: 8,
          name: "Water_Supply",
          structure: [
            {
              id: 1,
              name: "water purification(treatment) facilities"
            },
            {
              id: 2,
              name: "water distribution facilities"
            },
            {
              id: 3,
              name: "water intake facility"
            },
            {
              id: 4,
              name: "raw water main & distributing main (both underground and exposed)"
            },
          ],
        },    
        {
          id: 9,
          name: "Gas",
          structure: [
            {
              id: 1,
              name: "gas terminal and reservoir plant of piped(LNG) gas"
            },
            {
              id: 2,
              name: "high-pressure gas pipeline (both underground and exposed)"
            },
          ],
        },    
        {
          id: 10,
          name: "Electricity",
          structure: [
            {
              id: 1,
              name: "Powerplant"
            },
            {
              id: 2,
              name: "Substation"
            },
            {
              id: 3,
              name: "Transmission Line (overhead, subsurface)"
            },
          ],
        },    
        {
          id: 11,
          name: "Communication",
          structure: [
            {
              id: 1,
              name: "Mobile/Broadcasting Base Station (outside) "
            },
            {
              id: 2,
              name: "Telephone/Fiber Optic Network"
            },
          ],
        },    
        {
          id: 12,
          name: "Road_Bridge",
          structure: [
            {
              id: 1,
              name: "highway bridge on trunk road"
            },
            {
              id: 2,
              name: "long-span tunnel in urban area"
            },
            {
              id: 3,
              name: "earth structure such as slope protection structure and embankment"
            },
          ],
        },    
        {
          id: 13,
          name: "Port",
          structure: [
            {
              id: 1,
              name: "Quaywall (Revetment)"
            },
            {
              id: 2,
              name: "Pier"
            },
            {
              id: 3,
              name: "Passenger Facilities"
            },
            {
              id: 4,
              name: "Storage"
            },
            {
              id: 5,
              name: "Breakwater"
            },
            {
              id: 6,
              name: "sluice gate"
            },
            {
              id: 7,
              name: "Bank"
            },
          ],
        },    
        {
          id: 14,
          name: "Railway",
          structure: [
            {
              id: 1,
              name: "Station"
            },
            {
              id: 2,
              name: "Railway Bridge"
            },
            {
              id: 3,
              name: "Tunnel and Subsurface structure (Limited to long extended structures in urban areas)"
            },
            {
              id: 4,
              name: "Railway"
            },
            {
              id: 5,
              name: "Slope"
            },
            {
              id: 6,
              name: "Embankment"
            },
            {
              id: 7,
              name: "Garage"
            },
            {
              id: 8,
              name: "Overhead Line"
            },
          ],
        },    
        {
          id: 15,
          name: "Airport",
          structure: [
            {
              id: 1,
              name: "Terminal"
            },
            {
              id: 2,
              name: "Runway (Taxiway, Runway strip, Apron)"
            },
            {
              id: 3,
              name: "Civil Structures (Slope, Drainage, Revetment)"
            },
          ],
        },    
        {
          id: 16,
          name: "Others",
          structure: [
            
          ],
        },    

      ],
      evacStructures: [
        { text: "Facilities installed as an evacuation center for earthquakes and tsunamis", value: "Facilities installed as an evacuation center for earthquakes and tsunamis" },
        { text: "Buildings for public service", value: "Buildings for public service" },
        { text: "Religious buildings such as temples, churches and mosques", value: "Religious buildings such as temples, churches and mosques" },
      ],
      yesNo: [
        { text: "छ (Yes)", value: "yes" },
        { text: "छैन (No)", value: "no" },
      ],
      form: {
        id: 0,
        name: "",
        aff_org: "",
        dept: "",
        position: "",
        conduct_training: "",
        regular_training: "",
        country_training: "",
        uni_training: "",
        intl_training: "",
        other_training: "",
        other_specify: "",
        org_chart_doc_exist: "",
        org_chart_doc_title: "",
        org_chart_doc_submit: "",
        org_chart_doc_type: "",
        org_chart_doc_filename: "",
        org_chart_doc_file: "",
        org_chart_url: "",
        org_chart_remarks: "",
        tasks_doc_exist: "",
        tasks_doc_title: "",
        tasks_doc_submit: "",
        tasks_doc_type: "",
        tasks_doc_filename: "",
        tasks_doc_file: "",
        tasks_url: "",
        tasks_remarks: "",
        emp_no_doc_exist: "",
        emp_no_doc_title: "",
        emp_no_doc_submit: "",
        emp_no_doc_type: "",
        emp_no_doc_filename: "",
        emp_no_doc_file: "",
        emp_no_url: "",
        emp_no_remarks: "",
        pamphlet_doc_exist: "",
        pamphlet_doc_title: "",
        pamphlet_doc_submit: "",
        pamphlet_doc_type: "",
        pamphlet_doc_filename: "",
        pamphlet_doc_file: "",
        pamphlet_url: "",
        pamphlet_remark: "",

        //06 Projects with other donors
        donor_project_present:'',
        donor_infra_project_present:'',
        //table 3
        interested_donor_project:'',
        unddr_project_present:'',
        unddr_description:'',

        //05 Development plans, budgets
        five_year_doc_present:'',
        five_year_doc_title:'',
        five_year_doc_submit:'',
        five_year_doc_format:'',
        five_year_doc_filename:'',
        five_year_doc_file:'',
        five_year_doc_url:'',
        five_year_doc_remarks:'',

        long_term_doc_present:'',
        long_term_doc_title:'',
        long_term_doc_submit:'',
        long_term_doc_format:'',
        long_term_doc_filename:'',
        long_term_doc_file:'',
        long_term_doc_url:'',
        long_term_doc_remarks:'',

        disaster_risk_doc_present:'',
        disaster_risk_doc_title:'',
        disaster_risk_doc_submit:'',
        disaster_risk_doc_format:'',
        disaster_risk_doc_filename:'',
        disaster_risk_doc_file:'',
        disaster_risk_doc_url:'',
        disaster_risk_doc_laws:'',
        disaster_risk_doc_remarks:'',

        response_plan_doc_present:'',
        response_plan_doc_title:'',
        response_plan_doc_submit:'',
        response_plan_doc_format:'',
        response_plan_doc_filename:'',
        response_plan_doc_file:'',
        response_plan_doc_url:'',
        response_plan_doc_laws:'',
        response_plan_doc_remarks:'',

        questions: [],
        evacuations: [],
        budgets: [],
        donorProjects: [],
        donorInfraProjects: [],

      },
      items: [],
    };
  },
  watch: {
    seismic_plan_present() {
      if(this.form.questions) { this.form.questions.forEach(q => { q.seismic_plan_present = this.seismic_plan_present; }); }
    },
    seismic_plan2_present() {
      if(this.form.questions) { this.form.questions.forEach(q => { q.seismic_plan2_present = this.seismic_plan2_present; }); }
    },
    seismic_project_present() {
      if(this.form.questions) { this.form.questions.forEach(q => { q.seismic_project_present = this.seismic_project_present; }); }
    },
    seismic_tech_difficulty() {
      if(this.form.questions) { this.form.questions.forEach(q => { q.seismic_tech_difficulty = this.seismic_tech_difficulty; }); }
    },
    seismic_budget_difficulty() {
      if(this.form.questions) { this.form.questions.forEach(q => { q.seismic_budget_difficulty = this.seismic_budget_difficulty; }); }
    },
    seismic_lack_of_personnel() {
      if(this.form.questions) { this.form.questions.forEach(q => { q.seismic_lack_of_personnel = this.seismic_lack_of_personnel; }); }
    },
    seismic_other() {
      if(this.form.questions) { this.form.questions.forEach(q => { q.seismic_other = this.seismic_other; }); }
    },
    seismic_other_specify() {
      if(this.form.questions) { this.form.questions.forEach(q => { q.seismic_other_specify = this.seismic_other_specify; }); }
    },

    selectedStructures() {
      //v-on:keyup the value of rcc total if the totalAmountv-on:keyups
      this.form.questions = [];
      this.selectedStructures.forEach(str => {
        
        this.form.questions.push({
          category: this.selectedCategory,
          structure: str,
          //sheet 2, 02 Des. criteria, work, main: '',
          design_criteria: '',
          design_year: '',
          design_submit: '',
          design_format: '',
          design_filename: '',
          design_file: '',
          design_url: '',
          design_remarks: '',
          //construction guidelines informati: '',
          cons_present: '',
          cons_title: '',
          cons_year: '',
          cons_submit: '',
          cons_format: '',
          cons_filename: '',
          cons_file: '',
          cons_url: '',
          cons_remarks: '',
          //maintenance guidelines informati: '',
          main_present: '',
          main_title: '',
          main_year: '',
          main_submit: '',
          main_format: '',
          main_filename: '',
          main_file: '',
          main_url: '',
          main_remarks: '',
          //end of sheet: '',
          //sheet 3; 03 Seismic reinforceme: '',
          //: '',
          seismic_plan_present: '',
          seismic_plan_name: '',
          seismic_plan_submit: '',
          seismic_plan_format: '',
          seismic_plan_filename: '',
          seismic_plan_file: '',
          seismic_plan_url: '',
          seismic_plan_remarks: '',
          //table: '',
          seismic_project_present: '',
          seismic_project_title: '',
          seismic_project_criteria: '',
          seismic_project_budget: '',
          //table: '',
          seismic_plan2_present: '',
          seismic_plan2_area: '',
          seismic_plan2_time: '',
          seismic_plan2_budget: '',
          seismic_tech_difficulty: '',
          seismic_budget_difficulty: '',
          seismic_lack_of_personnel: '',
          seismic_other: '',
          seismic_other_specify: '',
          //table: '',
          seismic_guidelines_present: '',
          seismic_guidelines_title: '',
          seismic_guidelines_year: '',
          seismic_guidelines_submit: '',
          seismic_guidelines_format: '',
          seismic_guidelines_filename: '',
          seismic_guidelines_file: '',
          seismic_guidelines_url: '',
          seismic_guidelines_remarks: '',
          //Sheet 4a: 04 Data about structure(Buil: '',
          building_importance_present: '',
          building_importance_level: '',
          building_importance_title: '',
          building_importance_submit: '',
          building_importance_format: '',
          building_importance_filename: '',
          building_importance_file: '',
          building_importance_url: '',
          building_importance_remarks: '',
          //table: '',
          building_priority_present: '',
          building_priority_title: '',
          building_priority_submit: '',
          building_priority_format: '',
          building_priority_filename: '',
          building_priority_file: '',
          building_priority_url: '',
          building_priority_remarks: '',
          //table: '',्
          building_gis_present: '',
          building_gis_preparer: '',
          building_gis_submit: '',
          building_gis_format: '',
          building_gis_filename: '',
          building_gis_file: '',
          building_gis_url: '',
          building_gis_remarks: '',
          //table: '',
          building_register_present: '',
          building_register_title: '',
          building_register_number: '',
          building_register_submit: '',
          building_register_format: '',
          building_register_filename: '',
          building_register_file: '',
          building_register_url: '',
          building_register_remarks: '',
          //table: '',
          building_info_present: '',
          building_info_title: '',
          building_info_submit: '',
          building_info_format: '',
          building_info_filename: '',
          building_info_file: '',
          building_info_url: '',
          building_info_remarks: '',
          //sheet 4b; 04 Data about structure(Infr: '',
          infra_importance_present: '',
          infra_importance_level: '',
          infra_importance_title: '',
          infra_importance_submit: '',
          infra_importance_format: '',
          infra_importance_filename: '',
          infra_importance_file: '',
          infra_importance_url: '',
          infra_importance_remarks: '',
          //table: '',
          infra_priority_present: '',
          infra_priority_title: '',
          infra_priority_submit: '',
          infra_priority_format: '',
          infra_priority_filename: '',
          infra_priority_file: '',
          infra_priority_url: '',
          infra_priority_remarks: '',
          //table: '',
          infra_gis_present: '',
          infra_gis_preparer: '',
          infra_gis_submit: '',
          infra_gis_format: '',
          infra_gis_filename: '',
          infra_gis_file: '',
          infra_gis_url: '',
          infra_gis_remarks: '',
          //table: '',
          infra_register_present: '',
          infra_register_title: '',
          infra_register_number: '',
          infra_register_submit: '',
          infra_register_format: '',
          infra_register_filename: '',
          infra_register_file: '',
          infra_register_url: '',
          infra_register_remarks: '',
          //table: '',
          infra_info_present: '',
          infra_info_title: '',
          infra_info_submit: '',
          infra_info_format: '',
          infra_info_filename: '',
          infra_info_file: '',
          infra_info_url: '',
          infra_info_remarks: '',
        })
        console.log(this.form.questions);
      });
    }
  },
  mounted() {
    this.add_new_evac();
    this.add_new_budget();
    this.add_new_donor_project();
    this.add_new_donor_infra_project();

  },
  methods: {
    save() {
        // console.log(this.form.org_chart_doc_file);
        console.log(this.form);
        const url = `/api/registration/${this.$route.params.regId}/rcc`;
        VueAxios.post(
          url,
          this.rcc,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`
            }
          },
          {
            timeout: 10000
          }
        )
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              this.rcc = response.data.data;
              this.successfulMessage = "Successful.";
            }
          })
          .catch(error => {
            console.log(`api error:${error}`);
          });
    },
    add_new_item() {
      // `this.items.push({})` does not work
      // instead each attribute must be provided for reactivity:
      this.items.push({
        first_name: '',
        last_name: '',
      })
      console.log(this.items);
    },
    add_new_evac() {
      // `this.items.push({})` does not work
      // instead each attribute must be provided for reactivity:
      this.form.evacuations.push({
        evac_category:'Evacuation_facility',
        evac_structure:'',
        evac_data_present:'',
        evac_data_preparer:'',
        evac_data_format :'',
        evac_data_filename : '',
        evac_data_file : '',
        evac_data_url : '',
        evac_data_remarks: '',
      })
      console.log(this.items);
    },
    add_new_budget() {
      this.form.budgets.push({
        budget_category: this.selectedCategory,
        budget_present:'',
        budget_title:'',
        budget_preparer:'',
        budget_submit:'',
        budget_format:'',
        budget_filename:'',
        budget_file:'',
        budget_url:'',
        budget_remarks:'',
      })
      console.log(this.items);
    },
    add_new_donor_project() {
      this.form.donorProjects.push({
        donor_project_title:'',
        donor_project_period:'',
        donor_project_name:'',
        donor_project_url:'',
      })
    },
     add_new_donor_infra_project() {
      this.form.donorInfraProjects.push({
        donor_infra_project_title:'',
        donor_infra_project_period:'',
        donor_infra_project_name:'',
        donor_infra_project_url:'',
      })
    },
    
    
    check: function(e) {
      console.log(this.selectedStructures,e)
    },
    onFileChange(e, variable) {
      console.log(variable);
      const self = this;
      // self.formData.append('remarks', this.document.remarks);
      const files = e.target.files || e.dataTransfer.files;
      console.log(files[0].size);
      if (files.length > 0 && files[0].size < 1024 * 1024 * 5) {
        for (let i = 0; i < files.length; i += 1) {
          self.formData.append("document_data", files[i], files[i].name);
        }
      } else {
          document.getElementById("file-upload").value = "";
          this.snackbar.color = "error";
          this.snackbar.show = true;
          this.successfulMessage = "Please select file less than 5 Mb!!";
        
      }
    },
  }
};
</script>
<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}
.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}
.row,
.col {
  height: 58px;
}
.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}
</style>
