<template>
  <div
    class="container pa-4 text-center text-no-wrap rounded-xl"
    style="border-style: ridge; margin-bottom: 20px"
  >
    <div>
      <h3 class="blue--text font-weight-bold text-center display-1">डिजाइनरको विवरण</h3>
      <hr />
    </div>
    <v-form>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <label for="">{{ $t("designer.affiliationType") }} <span> *</span></label>
          <v-radio-group v-model="designer.is_consulting_firm" :mandatory="true">
            <v-row>
              <v-col
                ><v-radio class="radio-btn" label="Consulting Firm" v-bind:value="1"></v-radio
              ></v-col>
              <v-col
                ><v-radio class="radio-btn" label="Freelancer" v-bind:value="0"></v-radio> </v-col
            ></v-row>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="designer.is_consulting_firm">
          <label for="">{{ $t("designer.affiliateConsultancy") }}</label>
          <v-select
            v-model="designer.affiliated_consultancy_id"
            :label="$t('designer.affiliateConsultancy')"
            :items="consultancy"
            item-value="id"
            item-text="name"
            :key="id"
            outlined
            filled
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <label for="">{{ $t("designer.consultancyRegNo") }}</label>
          <v-text-field
            v-model="designer.registration_number"
            :label="$t('designer.consultancyRegNo')"
            :rules="[$valid.isRequired, $valid.isNepaliAlphabet]"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <label for="">{{ $t("designer.designerType") }} <span>*</span></label>
          <v-select
            v-model="designer.designer_type_id"
            :label="$t('designer.designerType')"
            :items="designerTypes"
            item-value="id"
            item-text="name_en"
            :key="id"
            outlined
            filled
          ></v-select>
        </v-col>
        <!-- <v-col cols="12" sm="6" md="6" hidden>
          <v-text-field
            v-model="designer.affiliated_consultancy_name"
            label="आवद्ध कन्सल्टेन्सी"
            outlined
          ></v-text-field>
        </v-col> -->
        <v-col cols="12" sm="6" md="6">
          <label for="">{{ $t("designer.designerCouncilNo") }} <span>*</span> </label>
          <v-text-field
            v-model="designer.designer_council_number"
            :label="$t('designer.designerCouncilNo')"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <label for="">{{ $t("designer.designerName") }} <span>*</span> </label>
          <v-text-field
            v-model="designer.designer_name"
            :rules="[$valid.isRequired, $valid.isNepaliAlphabet]"
            :label="$t('designer.designerName')"
            outlined
            filled
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <label for="">{{ $t("designer.designerEmail") }} <span>*</span> </label>
          <v-text-field
            v-model="designer.email"
            label="Designer Email"
            :rules="[$valid.isRequired, $valid.isEmail]"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <label for="">{{ $t("designer.designerPhone") }} <span>*</span></label>
          <v-text-field
            v-model="designer.phone_no"
            :label="$t('designer.designerPhone')"
            :rules="[$valid.isRequired, $valid.isPhone]"
            outlined
            filled
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import validator from "@/form-validator/index";
import api from "@/api/api";

export default {
  data() {
    return {
      designer: {
        id: 0,
        reg_id: this.registrationId,
        is_consulting_firm: "",
        registration_number: "",
        affiliated_consultancy: "",
        affiliated_consultancy_id: "",
        affiliated_consultancy_name: "",
        designer_type_id: "",
        designer_name: "",
        designer_council_number: "",
        phone_no: "",
        email: "",
      },
      consultancy: [],
      valid: {
        nepaliName: (v) => validator.isNepaliAlphabet(v) || "Please Type in nepali ma k",
      },
    };
  },
  computed: {},
  mounted() {
    this.getConsultancyList();
  },
  methods: {
    async getConsultancyList() {
      try {
        let response = await api.dropdowns.consultancyList();
        this.consultancy = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
span {
  color: red;
}

.radio-btn {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
}
</style>
