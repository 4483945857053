var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"nibedanBibaran elevation-12 my-2"},[_c('v-expansion-panels',{attrs:{"value":_vm.opened}},_vm._l((1),function(item,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(" निवेदन विवरणहरु ")])],1)],1),_c('v-expansion-panel-content',[_c('v-simple-table',{staticClass:"sober elevation-2"},[_c('thead',[(_vm.regConstructionId==8)?_c('tr',{},[_c('th',{staticClass:"text-left"},[_vm._v("कित्ताकाट भएको छ कि छैन")]),_c('th',{staticClass:"text-left"}),_c('th',{staticClass:"text-left"},[_c('v-switch',{attrs:{"label":"छ","color":"primary","value":"primary","hide-details":""},model:{value:(_vm.ex11),callback:function ($$v) {_vm.ex11=$$v},expression:"ex11"}})],1)]):_vm._e(),_c('tr',{},[_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Actions")]),_c('th',{staticClass:"text-left"},[_vm._v("Status")])])]),_c('tbody',_vm._l((_vm.nibedanBibarans),function(item){return _c('tr',{key:item.name,class:item.status == 'Completed' ? 'green lighten-5' : 'red lighten-5'},[_c('td',{},[_vm._v(_vm._s(item.name))]),_c('td',{},[_c('router-link',{attrs:{"to":{
                  name: 'tabbed',
                  params: {
                    id: item.id,
                    regId: _vm.regId,
                    sn: item.sn,
                    isPostAuthorized: _vm.isAddEditAuthorized,
                    regConstructionId: _vm.regConstructionId,
                  },
                }}},[_c('v-btn',{staticClass:"blue elevation-0 white--text",attrs:{"small":""}},[_vm._v(_vm._s(item.nameEng == "contractor" ? _vm.contractorActionButtonText : _vm.actionButtonText))])],1)],1),_c('td',[_vm._v(_vm._s(item.status))])])}),0)])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }