<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></loading>
    <v-row>
      <v-col sm="12" md="12" lg="12">
        <v-card class="mx-0 my-3" max-width="1200">
          <v-card-text>
            <div>
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>
                  {{ docTitle }}
                </v-toolbar-title>
              </v-toolbar>
            </div>
            <div class="text--primary">
              <v-form v-if="isPostAuthorized" ref="form" class="mx-4 my-4" v-model="valid">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-file-input
                      v-model="document.document_data"
                      :rules="fileSizeValidate()"
                      label="Select Document File.."
                      filled
                      prepend-icon="mdi-file"
                    ></v-file-input>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field v-model="document.remarks" placeholder="Remarks" outlined></v-text-field>
                  </v-col>
                </v-row>

                <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="3000">
                  {{ successfulMessage }}
                </v-snackbar>
              </v-form>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn primary v-on:click="save(false)" small v-if="isPostAuthorized" class="primary" :disabled="!valid">Save</v-btn>
            <v-btn
              primary
              v-on:click="save(true)"
              v-if="isPostAuthorized && show && !isLastElement"
              class="primary"
              small
              :disabled="!valid"
              >Save &amp; Next</v-btn
            >
            <v-btn v-if="!isLastElement && show" small color="primary" @click="nextStep(1)"> Skip </v-btn>
            <v-btn
              v-if="isLastElement"
              class="blue-grey lighten-5 mt-1"
              :to="{
                name: 'application_tab',
                params: { id: this.$route.params.regId },
              }"
            >
              Back to Main
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col sm="12" md="12" offset-md="2" lg="12" offset-lg="0">
        <v-card class="mx-0 my-5" max-width="1200">
          <template slot="progress">
            <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
          </template>
          <v-card-title>Upload Document List</v-card-title>
          <v-card-text>
            <v-simple-table height="100%">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Document</th>
                    <th class="text-left" v-if="isPostAuthorized">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="document in documentList" :key="document.id"> 
                    <td>
                      <template v-if="!IsCompressedFile(document.document_data)">
                        <pdf-dialog :src="basePath + document.document_data" displayText="Full Screen"></pdf-dialog>
                        <h6 class="my-3">{{ document.remarks }}</h6>
                        <div>
                          <inline-doc :src="basePath + document.document_data"></inline-doc>
                        </div>
                        <br />
                      </template>
                      <template v-else>
                        <strong class="text-h5">FileType: Compressed File</strong> <br />
                        <strong>Download</strong>:<a :href="basePath + document.document_data">
                          <u>Click here</u>
                        </a>
                        <br />
                        <strong>Remarks</strong>: {{ document.remarks }}
                      </template>
                    </td>

                    <td v-if="isPostAuthorized">
                      <v-btn small class="ma-1" color="primary" v-on:click="edit(document.id)">Edit</v-btn>
                      <custom-dialog :click-dialog="deleterecord" :regId="document.id" click-type="delete"> Delete </custom-dialog>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DocumentHistory from "../../history/DocumentHistory.vue";
export default {
  name: "DocumentUploadListComponent",
  components: {
    // DocumentHistory,
  },
  props: {
    registrationId: {
      type: [String, Number],
    },
    id: {
      type: [String, Number],
    },
    reg: {
      type: Object,
    },
    isPostAuthorized: {
      type: Boolean,
    },
    show: {
      type: Boolean,
      default: true,
    },
    nextStep: {
      type: Function,
    },
    docTitle: {
      type: String,
    },
    apiUrl: {
      type: String,
    },
    fileSize: {
      type: Number,
      default: 5,
    },
    isLastElement: {
      type: Boolean,
    },
    isFirstElement: {
      type: Boolean,
    },
  },
  data() {
    return {
      valid: false,
      isLoading: false,
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      formData: new FormData(),
      document: {
        created_by_user_id: 0,
        created_date: null,
        document_master_id: 0,
        document_data: "",
        edited_by_user_id: null,
        edited_date: null,
        id: 0,
        reg_id: 0,
        remarks: this.docTitle,
      },
      basePath: process.env.VUE_APP_BASE_URL,
      documentList: [],
      fileSizeValidate() {
        return [(v) => !v || v.size < this.fileSize * 1000000 || `File should be less than ${this.fileSize} MB`];
      },
      historyData: {
        apiUrl: this.apiUrl + `/history`,
      },
    };
  },
  mounted() {
    const url = this.apiUrl;
    this.isLoading = true;
    this.$axios
      .get(url)
      .then((response) => {
        this.documentList = response.data.data;
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
        this.successfulMessage = error.response.data.message;
      });
  },
  methods: {
    buildFormData(formData, data, parentKey) {
      if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
      } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
      }
    },
    jsonToFormData(data) {
      this.buildFormData(this.formData, data);
      return this.formData;
    },
    save(isNext) {
      this.isLoading = true;
      const self = this;
      this.formData = this.jsonToFormData(this.document);
      if (this.document.id === 0) {
        // const urlSave = `api/registration/${this.$route.params.regId}/documents/document`;
        const urlSave = this.apiUrl;
        this.$axios
          .post(urlSave, self.formData)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.isLoading = false;
              this.snackbar.show = true;
              this.snackbar.color = "success";
              this.successfulMessage = "Successfully Updated.";
              this.uploadedDocument = this.basePath + response.data.data.document_data;
              this.documentList.push({
                document_data: response.data.data.document_data,
                remarks: response.data.data.remarks,
                id: response.data.data.id,
              });
              this.document.document_data = null;
              if (isNext) {
                this.nextStep(1);
              }
              console.log(`remarks: ${this.document.remarks}`);
            }
          })
          .catch(() => {
            this.isLoading = false;
            this.snackbar.color = "error";
            this.snackbar.show = true;
            this.successfulMessage = "Error!!";
            this.$refs.form.reset();
          });
      } else {
        self.formData.append("_method", "PUT");
        const urlEdit = `${this.apiUrl}/${this.document.id}`;
        this.$axios
          .post(urlEdit, self.formData)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.isLoading = false;
              this.snackbar.show = true;
              this.successfulMessage = "Successfuly Updated.";
              this.uploadedDocument = this.basePath + response.data.data.document_data;
              this.$refs.form.reset();
              var docIndex = this.documentList
                .map(function (e) {
                  return e.id;
                })
                .indexOf(this.document.id);
              this.documentList[docIndex] = response.data.data;
              if (isNext) {
                this.nextStep(1);
              }
            }
          })
          .catch(() => {
            this.isLoading = false;
            this.snackbar.color = "error";
            this.snackbar.show = true;
            this.successfulMessage = "Error!!";
            this.$refs.form.reset();
          });
      }
    },
    edit(id) {
      this.document = this.documentList.filter((c) => c.id === id).pop();
      this.uploadedDocument = this.basePath + this.document.document_data;
    },
    deleterecord(id) {
      //   const deleteurl = `api/registration/${this.$route.params.regId}/documents/document/${id}`;
      const deleteurl = `${this.apiUrl}/${id}`;
      this.$axios
        .delete(deleteurl)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
            const i = this.documentList.map((item) => item.id).indexOf(id); // find index of your object
            this.documentList.splice(i, 1);
          }
          // console.log(response);
        })
        .catch(() => {
          // console.log(error);
        });
    },
    IsCompressedFile(document) {
      if (document.slice(-3) == "zip" || document.slice(-3) == "rar") {
        console.log("Zip Rar files");
        return true;
      } else {
        console.log("Image pdf file");
        return false;
      }
    },
  },
};
</script>

<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

label span {
  color: red;
}

.w-16 {
  max-height: 4rem;
}
</style>
