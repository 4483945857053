export const engNumConvert = {
  created() {
    this.engNumToNep();
    this.nepNumToEng();
  },
  methods: {
    engNumToNep(engNum) {
      const englishNum = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      const nepaliNum = ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"];
      var result = engNum;
      // var regex;

      if (engNum == "" || engNum == null) {
        return "";
      } else {
        for (var i = 0; i < engNum.length + 1; i++) {
          // regex = new RegExp(engNum, "g");
          // result = result.replace(regex, nepaliNum[i]);
          if (englishNum.includes(engNum[i])) {
            result = result.replace(engNum[i], nepaliNum[engNum[i]]);
          } else {
            continue;
          }
        }
      }
      return result;
    },
    nepNumToEng(nepNum) {
      const englishNum = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      const nepaliNum = ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"];
      var result = nepNum;
      // var regex;

      if (nepNum == "" || nepNum == null) {
        return "";
      } else {
        for (var i = 0; i < 10; i++) {
          // regex = new RegExp(engNum, "g");
          // result = result.replace(regex, nepaliNum[i]);
          // if (nepaliNum.includes(nepNum[i])) {
          for (var j = 0; j < nepNum.length; j++) {
            result = result.replace(nepaliNum[i], i);
          }
          // } else {
          // continue;
          // }
        }
      }
      return result;
    }
  }
};

// function engNumToNep(engNum) {
//   const englishNum = ["0","1","2","3","4","5","6","7","8","9"];
//   const nepaliNum = ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"];
//   var result = engNum;
//   // var regex;

//   if (engNum == "") {
//     return null;
//   } else {
//     for (var i = 0; i < engNum.length + 1; i++) {
//       // regex = new RegExp(engNum, "g");
//       // result = result.replace(regex, nepaliNum[i]);
//       if (englishNum.includes(engNum[i])) {
//         result = result.replace(engNum[i], nepaliNum[engNum[i]]);
//       } else {
//         continue;
//       }
//     }
//   }
//   return result;
// }

// console.log(engNumToNep("12-31"));
