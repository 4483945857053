<template>
  <b-container fluid class="mt-4">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <v-row>
      <v-col cols="2" class="ma-auto">
        <h5>Receipt Name</h5>
      </v-col>
      <template v-if="receiptEdit">
        <v-col cols="3">
          <v-text-field v-model="revenueInfo.meta.name" hide-details="true" class="ma-0 pa-0">
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn v-if="isAddEditAuthorized" color="primary" @click="changeReceiptName">Change Receipt Name</v-btn>
        </v-col>
      </template>
      <template v-else>
        <v-col cols="3">
          {{ revenueInfo.meta.name }}
        </v-col>
        <v-col cols="2">
          <v-btn v-if="isAddEditAuthorized" color="primary" @click="receiptEdit = true">Click to Change</v-btn>
        </v-col>
      </template>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <!-- <v-btn class="error" @click="deleteReceipt(revenueInfo.meta.receipt_id)" small>Delete this receipt</v-btn> -->
        <custom-dialog v-if="true" :click-dialog="deleteReceipt" :regId="revenueInfo.meta.receipt_id.toString()" click-type="delete">Delete this Receipt</custom-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <h5>Description</h5>
      </v-col>
      <v-col>
        {{ revenueInfo.meta.description }}
      </v-col>
    </v-row>

    <v-row>
      <v-simple-table class="sober" v-if="$store.getters.registrationData.construction_type_id == 4">
        <thead>
          <tr class="registrationtable">
            <th rowspan="2" class="registrationtable">
              <h5>Wall</h5>
            </th>
            <th rowspan="2" class="registrationtable">
              <h5>Running Length (in Ft.)</h5>
            </th>
            <th colspan="2" class="registrationtable">
              <h5>Municipality Fees</h5>
            </th>
            <th rowspan="2" class="registrationtable">
              <h5>Remarks</h5>
            </th>
            <th rowspan="2" class="registrationtable">
              <h5>Actions</h5>
            </th>
          </tr>
          <tr class="registrationtable">
            <th class="registrationtable">
              <h5>Rate</h5>
            </th>
            <th class="registrationtable">
              <h5>Amount</h5>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="registrationtable">
            <td class="registrationtable"> पर्खाल </td>
            <td class="registrationtable">
              <v-text-field v-model="compoundWallRevenue.length" type="number" hide-details="auto" solo class="my-2" @input="calculateAmount"></v-text-field>
            </td>
            <td class="registrationtable">
              <v-text-field v-model="compoundWallRevenue.revenue_rate" type="number" hide-details="auto" solo class="ma-0 pa-0" @input="calculateAmount"></v-text-field>
            </td>
            <td class="registrationtable">
              <v-text-field v-model="compoundWallRevenue.revenue_amount" hide-details="auto" solo class="ma-0 pa-0"></v-text-field>
            </td>
            <td class="registrationtable">
              <v-text-field v-model="compoundWallRevenue.revenue_remarks" hide-details="auto" solo class="ma-0 pa-0"></v-text-field>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-row>
    <v-row>
      <br>
    </v-row>
    <v-row>
      <v-simple-table class="sober">
        <thead>
          <tr class="registrationtable">
            <th rowspan="2" class="registrationtable" v-if="$store.getters.registrationData.construction_type_id == 11">
              <h5>Block</h5>
            </th>
            <th rowspan="2" class="registrationtable">
              <h5>Floor</h5>
            </th>
            <th v-if="hasHistory" rowspan="2" class="registrationtable">
              <h5>Previous Area</h5>
            </th>
            <th rowspan="2" class="registrationtable">
              <h5>Area</h5>
            </th>
            <th colspan="2" class="registrationtable">
              <h5>Municipality Fees</h5>
            </th>
            <th rowspan="2" class="registrationtable">
              <h5>Remarks</h5>
            </th>
            <th rowspan="2" class="registrationtable">
              <h5>Actions</h5>
            </th>
          </tr>
          <tr class="registrationtable">
            <th class="registrationtable">
              <h5>Rate</h5>
            </th>
            <th class="registrationtable">
              <h5>Amount</h5>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="registrationtable" v-for="(floor, i) in revenueInfo.floorRevenue" :key="i">
            <td class="registrationtable" v-if="$store.getters.registrationData.construction_type_id == 11">Block {{
      floor.block
    }}</td>
            <td class="registrationtable">{{ floor.floor_name }}</td>
            <td class="registrationtable" v-if="hasHistory">
              <v-text-field v-model="floor.prev_area" type="number" hide-details="auto" solo class="my-2" @input="calculateAmount"></v-text-field>
            </td>
            <td class="registrationtable">
              <v-text-field v-model="floor.area" type="number" hide-details="auto" solo class="my-2" @input="calculateAmount"></v-text-field>
            </td>
            <td class="registrationtable">
              <v-text-field v-model="floor.revenue_rate" type="number" hide-details="auto" solo class="ma-0 pa-0" @input="calculateAmount"></v-text-field>
            </td>
            <td class="registrationtable">
              <v-text-field v-model="floor.revenue_amount" hide-details="auto" solo class="ma-0 pa-0"></v-text-field>
            </td>
            <td class="registrationtable">
              <v-text-field v-model="floor.revenue_remarks" hide-details="auto" solo class="ma-0 pa-0"></v-text-field>
            </td>
          </tr>
          <tr class="registrationtable">
            <td class="registrationtable" :colspan="hasHistory || $store.getters.registrationData.construction_type_id == 11 ? 4 : 3">
              <b>Sub Total</b>
            </td>
            <td class="registrationtable">
              <v-text-field :value="subTotal" hide-details="auto" solo class="my-2"></v-text-field>
            </td>
            <td class="registrationtable">
              <v-text-field solo hide-details="auto" class="ma-0 pa-0"></v-text-field>
            </td>
          </tr>

          <tr class="registrationtable" v-for="(field, index) in revenueInfo.otherRevenue" :key="'A' + index">
            <template v-if="field.var_name != 'grand_total'">
              <td class="registrationtable" :colspan="hasHistory ? 4 : 3">
                {{ field.name }}
              </td>
              <td class="registrationtable">
                <v-text-field v-model="field.amount" hide-details="auto" solo class="my-2" @input="calculateGrandTotal">
                </v-text-field>
              </td>
              <td>
                <v-text-field v-model="field.remarks" hide-details="auto" solo></v-text-field>
              </td>
              <td>
                <v-btn icon color="red" @click="deleteOtherRevenue(index, field.id)">
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </td>
            </template>
          </tr>
          <tr class="registrationtable">
            <td class="registrationtable" :colspan="hasHistory || $store.getters.registrationData.construction_type_id == 11 ? 4 : 3">
              <b>कुल जम्मा</b>
            </td>
            <td class="registrationtable">
              <v-text-field hide-details="auto" solo class="my-2" :value="grandTotal"></v-text-field>
            </td>
            <td class="registrationtable">
              <v-text-field solo hide-details="auto" class="ma-0 pa-0"></v-text-field>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-row>
    <v-row v-if="isAddEditAuthorized">
      <v-col cols="6">
        <v-select v-model="selectedOtherRevenue" :items="otherRevenueFormFields" label="Select" item-text="name" item-value="id"></v-select>
      </v-col>
      <v-col cols="4">
        <v-btn color="primary" class="mt-4" @click="addNewOtherRevenue">+ Add</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-btn color="primary" v-on:click="save" class="ma-3">Save</v-btn>
      <div class="mt-3">
        <pdf-dialog :src="basePath + printRevenueUrl" :isSmall="false" displayText="Print Revenue" :key="pdfDialogKey">
        </pdf-dialog>
      </div>
    </v-row>
    <!-- Make save and print revenue later -->
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="3000">
      {{ successfulMessage }}
    </v-snackbar>
  </b-container>
</template>

<script>
export default {
  name: "Revenue",
  props: {
    revenueInfo: {
      type: Object
    },
    otherRevenueFormFields: {
      type: Array
    },
    isAddEditAuthorized: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isLoading: false,
      userRoleId: localStorage.getItem("auth_user_role_id"),
      basePath: process.env.VUE_APP_BASE_URL,
      // printRevenue: `api/registration/${this.$route.params.regId}/${this.revenueInfo.meta.receipt_id}/revenue-receipt`,
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      floorRevenueSurface: [],
      floorRevenueBase: [],
      application: {},
      historyFloorRevenue: [],
      selectedOtherRevenue: null,
      receiptEdit: false,
      pdfDialogKey: 0,
      compoundWallRevenue: {
        'length': 0,
        'revenue_rate': null,
        'revenue_amount': null,
        'revenue_remarks': null
      }

    };
  },
  created() {
    this.getApplicationData();
    this.getFloorRevenueHistory();
  },
  watch: {
  },
  computed: {
    printRevenueUrl() {
      return `api/registration/${this.$route.params.regId}/${this.revenueInfo.meta.receipt_id}/revenue-receipt`;
    },
    hasHistory: function () {
      if (this.historyFloorRevenue.length || this.$store.getters.registrationData.construction_type_id == 5) {
        return true;
      } else {
        return false;
      }
    },
    subTotal: function () {
      let tempSubTotal = 0;
      if (this.revenueInfo.floorRevenue) {
        this.revenueInfo.floorRevenue.forEach((element) => {
          tempSubTotal =
            parseFloat(
              element.revenue_amount == "" || element.revenue_amount == null
                ? 0
                : element.revenue_amount
            ) + tempSubTotal;
        });
      }
      //compound wall chha bhane wall ko amount ni add garnu paro
      if (this.$store.getters.registrationData.construction_type_id == 4) {
        tempSubTotal += parseFloat(this.compoundWallRevenue.revenue_amount == "" || this.compoundWallRevenue.revenue_amount == null ? 0 : this.compoundWallRevenue.revenue_amount);
      }
      return tempSubTotal.toFixed(2);
    },
    grandTotal: function () {
      let grandTotal = 0;
      grandTotal =
        parseFloat(
          this.subTotal == "" || this.subTotal == null ? 0 : this.subTotal
        ) + grandTotal;
      let tempOtherRevenue = [];
      if (this.revenueInfo.otherRevenue) {
        tempOtherRevenue = this.revenueInfo.otherRevenue.filter(
          (e) => e.var_name != "grand_total"
        );
      }
      tempOtherRevenue.forEach((e) => {
        e.revenue_weight = e.revenue_weight ? e.revenue_weight : 1;
        grandTotal =
          grandTotal +
          parseFloat(
            e.amount == "" || e.amount == null ? 0 : e.amount * e.revenue_weight
          );
      });
      return grandTotal.toFixed(2);
    }
  },
  mounted() {
  },
  methods: {
    save() {
      // Emit the single revenue data to revenue tabs
      // Emit is not needed for now, prop is changing on parent level
      // So, passing data is not necessary
      // this.$emit("saveRevenue");
      // console.log("save from child component");


      // Let's save from here instead
      console.log("save");
      this.isLoading = true;
      if (this.revenueInfo.floorRevenue[0].receipt_id === 0 ||
        this.revenueInfo.floorRevenue[0].receipt_id === null ||
        this.revenueInfo.floorRevenue[0].receipt_id === undefined
      ) {
        console.log("store");
        const url = `/api/registration/${this.$route.params.regId}/revenue-receipt`;
        this.revenueInfo.compoundWallRevenue = this.compoundWallRevenue;
        this.$axios.post(url, this.revenueInfo).then(response => {
          if (response.status == 200 || response.status == 201) {
            this.revenueInfo = response.data.data[0];
            this.pdfDialogKey += 1;
            // this.$forceUpdate();
            this.isLoading = false;
            this.snackbar.color = 'green';
            this.snackbar.show = true;
            this.successfulMessage = 'Saved successfully';
          }
        }).catch(error => {
          this.isLoading = false;
          console.log(`api error: ${error}`)
        })
      }
      else {
        console.log("update");
        const updateUrl = `/api/registration/${this.$route.params.regId}/revenue-receipt/${this.revenueInfo.meta.receipt_id}`;
        this.$axios.put(updateUrl, this.revenueInfo).then(response => {
          if (response.status == 200 || response.status == 201) {
            this.revenueInfo = response.data.data[0];
            this.pdfDialogKey += 1;
            this.isLoading = false;
            this.snackbar.color = 'green';
            this.snackbar.show = true;
            this.successfulMessage = 'Updated successfully';
          }
        }).catch(error => {
          this.isLoading = false;
          console.log(`api error: ${error}`)
        })
      }



    },
    calculateAmount() {
      this.revenueInfo.floorRevenue.forEach((element) => {
        if (!this.hasHistory) {
          element.revenue_amount = (
            parseFloat(
              element.area == "" || element.area == null ? 0 : element.area
            ) *
            parseFloat(
              element.revenue_rate == "" || element.revenue_rate == null
                ? 0
                : element.revenue_rate
            )
          ).toFixed(2);
        } else {
          element.revenue_amount = parseFloat(
            ((element.area == "" && element.prev_area == "") ||
              (element.area == null && element.prev_area == null)
              ? 0
              : element.area - element.prev_area) *
            parseFloat(
              element.revenue_rate == "" || element.revenue_rate == null
                ? 0
                : element.revenue_rate
            )
          ).toFixed(2);
        }
      });

      //compound wall chha bhane wall ko amount ni add garnu paro
      if (this.$store.getters.registrationData.construction_type_id == 4) {
        this.compoundWallRevenue.revenue_amount = parseFloat(
          ((this.compoundWallRevenue.length == "" || this.compoundWallRevenue.length == null)
            ? 0
            : this.compoundWallRevenue.length) *
          parseFloat(
            this.compoundWallRevenue.revenue_rate == "" || this.compoundWallRevenue.revenue_rate == null
              ? 0
              : this.compoundWallRevenue.revenue_rate
          )
        ).toFixed(2);
      }
      this.calculateGrandTotal();
    },
    calculateGrandTotal() {
      let gIndex = this.revenueInfo.otherRevenue.findIndex(
        (e) => e.var_name === "grand_total"
      );
      if (gIndex == -1) {
        this.revenueInfo.otherRevenue.push({
          id: 0,
          receipt_id: this.revenueInfo.meta.receipt_id,
          amount: 0,
          remarks: "",
          var_name: "grand_total",
          name: "कुल जम्मा",
          revenue_weight: 1,
        });
        let newGIndex = this.revenueInfo.otherRevenue.findIndex(
          (e) => e.var_name === "grand_total"
        );
        this.revenueInfo.otherRevenue[newGIndex].amount = this.grandTotal;
      }
      else {
        this.revenueInfo.otherRevenue[gIndex].amount = this.grandTotal;
      }

      //compound wall chha bhane wall ko amount ni add garnu paro
      if (this.$store.getters.registrationData.construction_type_id == 4) {
        this.revenueInfo.otherRevenue[gIndex].amount = this.grandTotal + parseFloat(
          this.compoundWallRevenue.revenue_amount == "" || this.compoundWallRevenue.revenue_amount == null
            ? 0
            : this.compoundWallRevenue.revenue_amount
        );
      }
    },
    getApplicationData() {
      const dataUrl = `api/registration/${this.$route.params.regId}/application`;
      this.$axios
        .get(dataUrl)
        .then((response) => {
          this.application = response.data.data;
          this.compoundWallRevenue.length = this.application.building_length_ft;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getApplicationIdList() {
      const apiUrl = `api/registration/${this.$route.params.regId}/applicationIdList`;
      this.$axios
        .get(apiUrl)
        .then((response) => {
          this.applicationIdList = response.data.data;
        })
        .catch((error) => console.log(error));
    },
    getFloorRevenueHistory() {
      const apiUrl = `api/registration/${this.$route.params.regId}/floor-info-revenue/history`;
      this.$axios
        .get(apiUrl)
        .then((response) => {
          if (response.status == 200) {
            this.historyFloorRevenue = response.data.data[0].floorRevenue;
          }
        })
        .catch((error) => console.log(error));
    },
    addNewOtherRevenue() {
      // console.log("add function start");
      if (!this.selectedOtherRevenue || this.selectedOtherRevenue == null) {
        this.snackbar.color = 'error';
        this.snackbar.show = true;
        this.successfulMessage = 'Select an option first';
      }
      else {
        // console.log("add function 2nd");
        let selectedId = this.selectedOtherRevenue;
        let selectedObject = this.otherRevenueFormFields.find(x => x.id == selectedId)
        this.revenueInfo.otherRevenue.push({
          id: 0,
          receipt_id: this.revenueInfo.meta.receipt_id,
          amount: 0,
          remarks: "",
          var_name: selectedObject.var_name,
          name: selectedObject.name,
          revenue_weight: selectedObject.revenue_weight,
        });
        // console.log("add fn finish");
      }

    },
    deleteOtherRevenue(index, id) {
      if (id == 0) {
        this.isLoading = true;
        this.revenueInfo.otherRevenue.splice(index, 1);
        this.isLoading = false;
      }
      else {
        this.isLoading = true;
        const deleteUrl = `api/registration/${this.$route.params.regId}/other-revenue/${id}`;
        this.$axios.delete(deleteUrl).then(response => {
          this.revenueInfo.otherRevenue.splice(index, 1);
          this.isLoading = false;
          this.snackbar.color = 'green';
          this.snackbar.show = true;
          this.successfulMessage = 'Deleted successfully';
        }).catch(error => {
          console.log(`Delete other revenue error: ${error}`);
        })
      }
    },
    changeReceiptName() {
      this.receiptEdit = false;
    },
    deleteReceipt(receiptId) {
      this.isLoading = true;
      if (receiptId != 0) {
        const url = `/api/registration/${this.$route.params.regId}/revenue-receipt/${receiptId}`;
        this.$axios.delete(url).then(response => {
          this.revenueInfo = {};    // Yo code line milauna parne xa, tab item nai close hudaina yesto tara delete hunxa
          this.$emit("deletereceipt");
          this.isLoading = false;
          this.snackbar.color = 'green';
          this.snackbar.show = true;
          this.successfulMessage = 'Deleted successfully';
        }).catch(error => {
          this.isLoading = false;
          console.log(`Error on delete receipt: ${error}`);
        })
      }
      else {
        this.$emit("deletereceipt");
        this.isLoading = false;
        this.snackbar.color = 'green';
        this.snackbar.show = true;
        this.successfulMessage = 'Deleted successfully';
      }

    },
  },
};
</script>

<style scoped>
.registrationtable td,
.registrationtable th {
  border: 1px solid #ddd;
  padding: 8px;
  width: 1000px;
}
</style>
