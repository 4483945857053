<template>
  <v-dialog v-model="show" max-width="900px">
    <v-form ref="dpcForm" v-model="valid">
      <loading :active.sync="loading" :can-cancel="false" :is-full-page="false"></loading>
      <v-card>
        <v-card-title>
          <span class="text-h5">डि.पी.सी. फारम</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="text-h6">
              <v-col>विवरण</v-col>
              <v-col>मापदण्ड/नक्सा बमोजिम</v-col>
              <v-col>फिल्ड बमोजिम</v-col>
              <v-col>कैफियत</v-col>
              <v-col>कार्य</v-col>
            </v-row>
            <div v-for="(floorItem, floorIndex) in floorDetail" :key="floorIndex" class="border mb-2 pa-2">
              <v-row>
                <v-col>
                  <span class="text-h6 font-weight-bold">{{ floorItem.floor_name }}</span>
                </v-col>
                <v-col></v-col>
                <v-col></v-col>
                <v-col></v-col>
                <v-col>
                  <v-btn v-if="isPostAuthorized" class="primary" fab small @click="addDpcRow(floorItem.floor, floorItem.application_id, floorItem.block)">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row v-for="(item, index) in dpc" :key="index">
                <template v-if="item.floor == floorItem.floor">
                  <v-col>
                    <v-text-field v-model="item.title" dense hide-details="auto" :disabled="!isPostAuthorized" :rules="[v => !!v || 'Item is required']"></v-text-field>
                  </v-col>
                  <v-col><v-text-field v-model="item.standard_value" dense hide-details="auto" :rules="[v => !!v || 'Item is required']" required
                      :disabled="!isPostAuthorized"></v-text-field></v-col>
                  <v-col><v-text-field v-model="item.measured_value" dense hide-details="auto" required :disabled="!isPostAuthorized"
                      :rules="[v => !!v || 'Item is required']"></v-text-field></v-col>
                  <v-col><v-text-field v-model="item.remarks" dense hide-details="auto" required :disabled="!isPostAuthorized"></v-text-field></v-col>
                  <v-col>
                    <v-btn v-if="isPostAuthorized" icon color="red" @click="deleteDpcRow(index, item.id)">
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </v-col>
                </template>
              </v-row>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="isPostAuthorized" color="primary" @click="save" :disabled="!valid">Save</v-btn>
          <v-btn @click.stop="show = false">Close</v-btn>
        </v-card-actions>
      </v-card>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="3000">
        {{ snackbar.message }}
      </v-snackbar>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "DpcSupervisionForm",
  props: {
    value: Boolean,
    // isPostAuthorized: {
    //   type: Boolean,
    //   default: false
    // }
  },
  data() {
    return {
      opened: 0,
      floorDetail: {},
      loading: true,
      dpcFormRow: {
        id: 0,
        floor: "",
        title: "",
        standard_value: "",
        measured_value: "",
        remarks: ""
      },
      dpc: [],
      valid: false,
      snackbar: {
        show: false,
        color: "success",
        message: ""
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    regId() {
      return this.$route.params.id;
    },
    isPostAuthorized() {
      return this.$store.getters.forwardRevertActionAuthData;
    }
  },
  created() {
    this.getFloorDetails().then(this.getDpc())
    // this.getDpc();
  },
  mounted() {

  },
  methods: {
    async getFloorDetails() {
      try {
        // if (this.value) {
        let response = await this.$api.registration.floorDetailsIndex(this.regId);
        this.floorDetail = response.data.data;
        this.loading = false;
        // }

      }
      catch (error) {
        console.log(error);
      }
    },
    async getDpc() {
      // this.dpc = [
      //   {
      //     id: 1,
      //     floor: 0,
      //     title: "someman",
      //     standard_value: "",
      //     measured_value: "",
      //     remarks: ""
      //   },
      //   {
      //     id: 2,
      //     floor: 0,
      //     title: "nice",
      //     standard_value: "",
      //     measured_value: "",
      //     remarks: ""
      //   },
      //   {
      //     id: 3,
      //     floor: 1,
      //     title: "nice",
      //     standard_value: "12",
      //     measured_value: "",
      //     remarks: ""
      //   },
      // ];
      try {
        let response = await this.$api.registration.getFloorDetailExtra(this.regId);
        let dpcTemp = response.data.data;
        this.dpc = dpcTemp;
        if (!dpcTemp.length) {
          // let floorDetailTemp = this.floorDetail;
          console.log("floor length zero");
          Object.values(this.floorDetail).forEach(element => {
            this.addDpcRow(element.floor, element.application_id, element.block);
          });
        }

      } catch (error) {
        console.log(error);
      }

      // If length zero add empty row in each floor
    },
    addDpcRow(floor, app_id, blockVal) {
      this.dpc.push(
        {
          id: 0,
          application_id: app_id,
          block: blockVal,
          floor: floor,
          title: "",
          standard_value: "",
          measured_value: "",
          remarks: ""
        }
      );
    },
    deleteDpcRow(index, id) {
      if (id == 0) {
        this.loading = true;
        this.dpc.splice(index, 1);
        this.loading = false;
      }
      else {
        this.loading = true;
        this.$api.registration.deleteFloorDetailExtraSingle(this.regId, id).then(response => {
          this.dpc.splice(index, 1);
          this.loading = false;
          this.snackbar.show = true;
          this.snackbar.message = "Data row deleted successfully";
        }).catch(error => {
          console.log("DPC single row delete error: " + error)
        })
      }
    },
    async save() {
      let floorDetailExtra = {
        floor_detail_extra: this.dpc
      }
      try {
        // if the row is empty splice it here

        this.loading = true;


        let response = await this.$api.registration.saveFloorDetailExtra(this.regId, floorDetailExtra);
        this.dpc = response.data.data;
        this.loading = false;
        this.snackbar.show = true;
        this.snackbar.message = "Data saved successfully";
        // console.log("Data saved successfully");
      }
      catch (error) {
        console.log(error);
        this.loading = false;
        this.snackbar.color = "error";
        this.snackbar.show = true;
        this.snackbar.message = error;
      }
    }

  },
};
</script>