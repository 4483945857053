<template>
  <b-container>
    <div>
      <v-btn class="primary back px-3 py-3" :to="{ name: 'home' }">Back</v-btn>
    </div>
    <br>
    <v-card class="mb-8">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title> Application Status </v-toolbar-title>
      </v-toolbar>
      <v-simple-table class="sober">
        <template v-slot:default>
          <thead>
            <tr class="registrationtable">
              <th
                class="text-left registrationtable"
                v-for="p in applicationHeader"
                :key="p"
              >
                {{ p }}
              </th>
              <th class="text-left registrationtable">Issued</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="registrationtable"
              v-for="applicationStatusList in applicationStatus"
              :key="applicationStatusList.id"
            >
              <td
                class="registrationtable"
                v-for="key in applicationHeader"
                :key="key"
              >
                {{ applicationStatusList[key] }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <!-- Revert Log Div -->
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title> Reverted Files </v-toolbar-title>
    </v-toolbar>
    <div style="font-size: 0.875rem">
      <b-table
        striped
        hover
        :items="revertItems"
        :fields="revertFields"
        :busy="isLoading"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template>
          <col :key="remarks" :style="{ width: '20%' }" />
        </template>
        <template #cell(reg_id)="row">
          <v-btn
            class="primary"
            small
            :to="{ name: 'application_tab', params: { id: row.item.reg_id } }"
            >View File</v-btn
          >
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
    </div>
    <!-- Revert Log Div -->
  </b-container>
</template>

<script>
export default {
  name: "Component1",
  props: {
    nextStep: {
      type: Function,
    },
  },
  data() {
    return {
      applicationStatus: [],
      applicationHeader: [],
      revertItems: [],
      revertFields: [
        { key: "reg_houseowner_name", label: "घरधनी", sortable: true },
        { key: "reg_phase", label: "स्थिति", sortable: true },
        { key: "reg_created_by", label: "फारम बुझाउने", sortable: true },
        { key: "date_np", label: "Revert Date", sortable: true },
        {
          key: "remarks",
          label: "Revert Remarks",
          thStyle: { width: "30%" },
        },
        { key: "reg_id", label: "कार्य" },
      ],
      // Pagination Purpose
      perPage: 10,
      currentPage: 1,
      isLoading: false, // for loading comp
    };
  },
  computed: {
    rows() {
      return this.revertItems.length;
    },
  },
  mounted() {
    this.getAppplicationStatus();
    this.getRevertedFileLog();
  },
  methods: {
    getAppplicationStatus() {
      const url = "api/report/application-status";
      this.$axios
        .get(url)
        .then((response) => {
          this.applicationStatus = response.data.data;
          this.applicationHeader = Object.keys(this.applicationStatus[0]);
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    getRevertedFileLog() {
      const revertInfoUrl = "api/consultant-reverted-log";
      this.isLoading = true;
      this.$axios
        .get(revertInfoUrl)
        .then((response) => {
          this.revertItems = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(`revert info api: ${error}`);
        });
    },
  },
};
</script>
<style scoped>
.td_revert_remarks {
  width: 50px;
}
</style>
