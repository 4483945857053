<template>
  <v-simple-table>
    <thead>
      <tr>
        <th>File Id</th>
        <th>Houseowner</th>
        <th>Message</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>12311</td>
        <td>राम थापा</td>
        <td>Edit access dinu hola</td>
        <td>
            <v-btn class="primary ma-1">Allow</v-btn>
            <v-btn class="error ma-1">Reject</v-btn>
        </td>
      </tr>
    </tbody>
    <tbody>
      <tr></tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: "HandleFileEditRequest",
  data() {
    return {};
  },
  methods: {},
};
</script>