<template>
  <div>
    <v-expansion-panels :value="opened">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title> नोटिस तथा सर्टिफिकेटहरु </v-toolbar-title>
          </v-toolbar>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table class="sober elevation-2">
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="70%">आंशिक सम्पन्न</td>
                <td>
                  <!-- <v-btn small class="primary">Edit Details</v-btn> -->
                  <v-dialog v-model="dialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small class="primary" v-bind="attrs" v-on="on">Edit Details</v-btn>
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-toolbar color="primary" dark>
                          <v-col>आंशिक सम्पन्न</v-col>
                          <v-col class="text-right">
                            <v-btn icon dark @click="dialog.value = false">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-col>
                        </v-toolbar>
                        <v-card-text>
                          <v-switch v-model="isPartialComplete" color="primary" label="आंशिक सम्पन्न गरेको" :disabled="floorDetail.length < 1"></v-switch>
                          <template v-if="isPartialComplete">
                            Partial Completion up to <br>
                            <!-- <v-checkbox v-model="selectedStoreys" v-for="(i, index) in floorDetail" :key="index" :label="i.floor_name" :value="i.floor"></v-checkbox> -->
                            <v-select :items="floorDetail" v-model="completedStoreys" item-text="floor_name" item-value="floor"></v-select>
                            <custom-dialog click-type="save" :click-dialog="savePartialCompletionDetail" :reg-id="$store.getters.registrationData.id">Save</custom-dialog>
                          </template>
                        </v-card-text>
                      </v-card>
                    </template>
                  </v-dialog>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "AdditionalForms",
  data() {
    return {
      opened: 0,
      dialog: false,
      isPartialComplete: false,
      floorDetail: [],
      completedStoreys: null,
      floorNames: [],
      snackbar: {
        show: false,
        color: "success",
        message: ""
      }
    };
  },
  created() {
    this.getFloorDetails();
    this.getPartialCompletionDetail();
  },
  methods: {
    async getFloorDetails() {
      try {
        const regId = this.$store.getters.registrationData.id;
        let response = await this.$api.registration.floorDetailsIndex(regId);
        const tempFloorDetails = response.data.data;
        this.floorDetail = tempFloorDetails.slice(0, -1)
        // const floorsLength = tempFloorDetails.length;
        // this.floors = [...Array(floorsLength - 1).keys()];
      }
      catch (error) {
        console.log(error);
      }
    },
    savePartialCompletionDetail() {
      this.dialog = false;
      const regId = this.$store.getters.registrationData.id;
      // console.log("checkboxed " + this.selectedStoreys);
      const url = `api/registration/${regId}/aamshik-sampanna`;
      this.$axios.post(url, { floor: this.completedStoreys }).then(response => {
        this.snackbar.message = "Updated successfully";
        this.snackbar.show = true;
        this.isPartialComplete = response.data.data.is_aamshik_sampanna;
        this.completedStoreys = response.data.data.aamshik_sampanna_floors;
      }).catch(error => { console.log(error); })
    },
    getPartialCompletionDetail() {
      const regId = this.$store.getters.registrationData.id;
      const url = `api/registration/${regId}/aamshik-sampanna`;
      this.$axios.get(url).then(response => {
        this.isPartialComplete = response.data.data.is_aamshik_sampanna;
        this.completedStoreys = response.data.data.aamshik_sampanna_floors;
      }).catch(error => { console.log(error) });
    },
  }
}
</script>