<template>
  <div class="my-2">
    <div v-if="!isDataLoaded">
      <v-expansion-panels :value="opened">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title> विवरणहरु </v-toolbar-title>
            </v-toolbar>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-skeleton-loader type="list-item, list-item">
            </v-skeleton-loader>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div v-else>
      <v-expansion-panels :value="opened">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title> विवरणहरु </v-toolbar-title>
            </v-toolbar>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table class="sober elevation-1">
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Actions</th>
                  <th class="text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr :class="completionStatus == 'Completed' ? 'green lighten-5' : 'red lighten-5'">
                  <td>डि.पी.सी. विवरण</td>
                  <td>
                    <v-btn color="primary elevation-0" small @click.stop="showDpcForm = true">
                      {{ actionButtonText }}
                    </v-btn>
                    <DpcSupervisionForm v-model="showDpcForm" />
                  </td>
                  <td>
                    {{ this.completionStatus }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>



  </div>
</template>
<script>
import DpcSupervisionForm from "./DpcSupervisionForm";

export default {
  name: "PlinthLevelForms",
  props: {
    isAddEditAuthorized: {
      type: Boolean,
      default: true
    }
  },
  components: {
    DpcSupervisionForm
  },
  data() {
    return {
      opened: 0,
      isDataLoaded: false,
      showDpcForm: false,
      completionStatus: ""
    };
  },
  computed: {
    actionButtonText() {
      if (this.$store.getters.forwardRevertActionAuthData) {
        return "Add/Edit";
      }
      return "View";
    },
  },
  mounted(){
    this.getCompletionStatus();
  },
  methods: {
    // Put this status also into the vuex with mutations later may be or may be not
    async getCompletionStatus() {
      const url = `api/registration/${this.$route.params.id}/status`;
      let response = await this.$axios.get(url);
      this.completionStatus = response.data.data.floor_detail_extra;
      this.isDataLoaded = true;
      // console.log()
    },
  }
};
</script>