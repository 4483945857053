export const YEAR_DATES = [
    { year: 2000, value: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31] },
    { year: 2001, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2002, value: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30] },
    { year: 2003, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31] },
    { year: 2004, value: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31] },
    { year: 2005, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2006, value: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30] },
    { year: 2007, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31] },
    { year: 2007, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31] },
    { year: 2008, value: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31] },
    { year: 2009, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2010, value: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30] },
    { year: 2011, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31] },
    { year: 2012, value: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30] },
    { year: 2013, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2014, value: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30] },
    { year: 2015, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31] },
    { year: 2016, value: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30] },
    { year: 2017, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2018, value: [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30] },
    { year: 2019, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31] },
    { year: 2020, value: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2021, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2022, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30] },
    { year: 2023, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31] },
    { year: 2024, value: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2025, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2026, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31] },
    { year: 2027, value: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31] },
    { year: 2028, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2029, value: [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30] },
    { year: 2030, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31] },
    { year: 2031, value: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31] },
    { year: 2032, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2033, value: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30] },
    { year: 2034, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31] },
    { year: 2035, value: [30, 32, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31] },
    { year: 2036, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2037, value: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30] },
    { year: 2038, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31] },
    { year: 2039, value: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30] },
    { year: 2040, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2041, value: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30] },
    { year: 2042, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31] },
    { year: 2043, value: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30] },
    { year: 2044, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2045, value: [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30] },
    { year: 2046, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31] },
    { year: 2047, value: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2048, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2049, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30] },
    { year: 2050, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31] },
    { year: 2051, value: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2052, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2053, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30] },
    { year: 2054, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31] },
    { year: 2055, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2056, value: [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30] },
    { year: 2057, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31] },
    { year: 2058, value: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31] },
    { year: 2059, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2060, value: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30] },
    { year: 2061, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31] },
    { year: 2062, value: [30, 32, 31, 32, 31, 31, 29, 30, 29, 30, 29, 31] },
    { year: 2063, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2064, value: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30] },
    { year: 2065, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31] },
    { year: 2066, value: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31] },
    { year: 2067, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2068, value: [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30] },
    { year: 2069, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31] },
    { year: 2070, value: [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30] },
    { year: 2071, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2072, value: [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30] },
    { year: 2073, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31] },
    { year: 2074, value: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2075, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2076, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30] },
    { year: 2077, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31] },
    { year: 2078, value: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2079, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30] },
    { year: 2080, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30] },
    { year: 2081, value: [31, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30] },
    { year: 2082, value: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30] },
    { year: 2083, value: [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30] },
    { year: 2084, value: [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30] },
    { year: 2085, value: [31, 32, 31, 32, 30, 31, 30, 30, 29, 30, 30, 30] },
    { year: 2086, value: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30] },
    { year: 2087, value: [31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30] },
    { year: 2088, value: [30, 31, 32, 32, 30, 31, 30, 30, 29, 30, 30, 30] },
    { year: 2089, value: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30] },
    { year: 2090, value: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30] },
    { year: 2091, value: [31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30] },
    { year: 2092, value: [30, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30] },
    { year: 2093, value: [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30] },
    { year: 2094, value: [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30] },
    { year: 2095, value: [31, 31, 32, 31, 31, 31, 30, 29, 30, 30, 30, 30] },
    { year: 2096, value: [30, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30] },
    { year: 2097, value: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30] },
    { year: 2098, value: [31, 31, 32, 31, 31, 31, 29, 30, 29, 30, 29, 31] },
    { year: 2099, value: [31, 31, 32, 31, 31, 31, 30, 29, 29, 30, 30, 30] },
];

export const ENGLISH_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const NEPALI_WEEK = ["आइत", "सोम", "मंगल", "बुध", "बिहि", "शुक्र", "शनि"];

export const ENGLISH_NEPALI_MONTH = [
    "Baisakh",
    "Jestha",
    "Asar",
    "Shrawan",
    "Bhadra",
    "Aswin",
    "Kartik",
    "Mangsir",
    "Poush",
    "Magh",
    "Falgun",
    "Chaitra",
];

export const NEPALI_MONTH = [
    "बैशाख",
    "जेठ",
    "असार",
    "श्रावण",
    "भाद्र",
    "आश्विन",
    "कार्तिक",
    "मंसिर",
    "पौष",
    "माघ",
    "फाल्गुण",
    "चैत्र",
]