<template>
  <div class="">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <div class="pa-4 white rounded-lg my-4">
      <h3 class="text-center"><strong>Users And Desk</strong></h3>
      <span
        class="
          blue-grey--text
          text--darken-3
          font-weight-thin
          d-block
          text-center
        "
        >User and Desk Management</span
      >
    </div>
    <div class="w-96 white rounded-lg">
      <v-col>
        <h4>Desks Status Management</h4>
        <!-- Alert for layman -->
        <v-alert border="bottom" colored-border type="warning">
          Desk which are disabled here are disabled from every task of the
          system.
        </v-alert>
        <v-simple-table>
          <thead>
            <tr>
              <th>Desk Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in userRole" :key="item.id">
              <td>{{ item.role_name }}</td>
              <td>
                <v-switch
                  v-model="item.is_enabled"
                  inset
                  dense
                  hide-details="auto"
                  @change="changeUserRoleStatus(item.role_id, item.is_enabled)"
                >
                </v-switch>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </div>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="3000">
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "AdminUserAndDesk",
  data() {
    return {
      isLoading: false,
      userRole: [],
      snackbar: {
        show: false,
        color: "success",
        message: "",
      },
    };
  },
  created() {
    this.getUserRole();
  },
  methods: {
    async getUserRole() {
      this.isLoading = true;
      try {
        let response = await api.dropdowns.userRoleList();
        this.userRole = response.data.data;
        const consultancyIndex = this.userRole.findIndex(
          (item) => item.role_id == 8
        );
        console.log("consultancy index " + consultancyIndex);
        this.userRole.splice(consultancyIndex, 1);
        this.isLoading = false;
      } catch (error) {
        console.log("User Role Fetch Error: " + error);
        this.isLoading = false;
      }
    },
    changeUserRoleStatus(id, isEnabled) {
      this.isLoading = true;
      let url = ``;
      if (isEnabled) {
        url = `api/user-role/${id}/enable`;
      } else {
        url = `api/user-role/${id}/disable`;
      }
      this.$axios
        .post(url, {})
        .then((response) => {
          this.userRole = response.data.data;
          this.isLoading = false;
          this.snackbar.show = true;
          this.snackbar.message = "Successfully Updated";
        })
        .catch((error) => {
          console.log("User Role status change error " + error);
          this.getUserRole();
          this.isLoading = false;
          this.snackbar.show = true;
          this.snackbar.color = "error";
          this.snackbar.message = "Error, Try Again";
        });
    },
  },
};
</script>

<style>
</style>