<template>
  <div class="ma-4 pa-2">
    <div>
      <v-data-table :headers="headers" :items="documentMaster" :search="search">
        <template v-slot:body.prepend>
          <tr>
            <td>
              <v-text-field v-model="keywordSearch" label="Search"></v-text-field>
            </td>
            <td>
              <v-select class="filter-select" v-model="phaseSearch" label="Select" clearable :items="phaseList" item-value="phase_id" item-text="phase_name"></v-select>
            </td>
            <td></td>
          </tr>
        </template>
        <template v-slot:item.options="{ item }">
          <v-row>
            <v-col cols="2">
              <v-btn icon color="brown" x-small><v-icon>mdi-eye</v-icon></v-btn>
            </v-col>
            <v-col cols="2"><v-btn icon color="blue-grey" @click="editItem(item)" x-small><v-icon>mdi-pencil</v-icon></v-btn>
            </v-col>
            <v-col cols="2"><v-btn icon color="red" x-small><v-icon>mdi-trash-can</v-icon></v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.native_phase_id="{ item }">
          {{
            phaseList.find((x) => x.phase_id === item.native_phase_id)
              .phase_name
          }}
        </template>
      </v-data-table>
      <!-- Edit Dialog -->
      <v-dialog v-model="editDialog" max-width="800px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ editedItem.title_short }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="editedItem.document_name" label="Document Name" solo></v-text-field>
                </v-col>
                <v-col>
                  <v-select class="filter-select" v-model="editedItem.native_phase_id" label="Native Phase" :items="phaseList" item-value="phase_id" item-text="phase_name"
                    hint="Phase where the file originally belongs" solo></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea v-model="editedItem.title" rows="1" label="Title" solo></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea v-model="editedItem.title_short" label="Short Title" rows="1" solo></v-textarea>
                </v-col>
              </v-row>
              <h4>File Placement</h4>
              <v-row v-for="(i, index) in editedItem.phase" :key="i" class="mt-4">
                <v-col cols="4">
                  <v-select class="filter-select" v-model="i.phase_id" label="Phase" :items="phaseList" item-value="phase_id" item-text="phase_name"
                    hint="Phase where the file is placed" solo></v-select>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="i.document_weight" label="Document Weight"></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-switch v-model="i.status" label="Status" color="secondary" hide-details></v-switch>
                </v-col>
                <v-col cols="2">
                  <v-btn class="error mt-4" @click="deleteForm(index, i.id)">Delete</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn class="primary" v-on:click="addForm">+ Add Another</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="editDialog = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- <div id="phaseDocumentMaster" class="mt-4">
      <v-row>
        <v-col cols="4">
          <span>Document Master</span>
        </v-col>
        <v-col cols="4">
          <span>Phase</span>
        </v-col>
        <v-col cols="4">
          <span>Status</span>
        </v-col>
      </v-row>
      <v-row v-for="item in phaseDocumentMaster" :key="item">
        <v-col cols="4">
          {{ item.document_master.document_name }}
        </v-col>
      </v-row>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      documentMaster: [],
      // phaseDocumentMaster: [],
      phaseList: [],

      keywordSearch: "",
      phaseSearch: "",
      titleSearch: "",
      search: "",

      // Edit
      editDialog: false,
      editIndex: 0,
      editedItem: {
        id: 0,
        document_name: "",
        title: "",
        title_short: "",
        native_phase_id: 1,
        phase: [
          {
            id: 0,
            document_master_id: 0,
            phase_id: 1,
            document_weight: "",
            status: 0,
          },
        ],
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Keyword",
          value: "document_name",
          filter: (value) => {
            if (!this.keywordSearch) return true;
            return value.includes(this.keywordSearch);
          },
        },
        {
          text: "Native Phase",
          // value: nativePhase,
          value: "native_phase_id",
          filter: (value) => {
            if (!this.phaseSearch) return true;
            return value == this.phaseSearch;
          },
        },
        {
          text: "Title Short",
          value: "title_short",
        },
        {
          text: "Options",
          value: "options",
          sortable: false,
        },
      ];
    },
    // nativePhase() {
    //   // return this.phaseList.find((x) => x.phase_id === item.native_phase_id);
    //   // return this.documentMaster.map(x => {x.native_phase_id === this.phaseList.})
    // },
  },
  created() {
    this.getDocumentMaster();
    this.getPhaseDocumentMaster();
    this.getPhaseList();
  },
  methods: {
    getDocumentMaster() {
      const documentMasterUrl = "api/document-master";
      this.$axios
        .get(documentMasterUrl)
        .then((response) => {
          this.documentMaster = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getPhaseDocumentMaster() {
      const phaseDocumentMasterUrl = "api/phase-document-master";
      this.$axios
        .get(phaseDocumentMasterUrl)
        .then((response) => {
          this.phaseDocumentMaster = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getPhaseList() {
      let phaseTemp = this.$store.getters.getPhaseList;
      try {

        if (!phaseTemp.length) {
          await this.$store.dispatch("fetchPhaseList")
        }
        this.phaseList = this.$store.getters.getPhaseList;
      } catch (error) {
        console.log(error.response.data.message);
      }

    },
    editItem(item) {
      this.editDialog = true;
      this.editIndex = item.id;
      this.editedItem = Object.assign({}, item); // Assign new id to the item and fill it
      console.log(`edit index id: ${item.id}`);
    },
    addForm() {
      this.editedItem.phase.push({
        id: 0,
        document_master_id: 0,
        phase_id: 1,
        document_weight: "",
        status: 0,
      });
    },
    deleteForm(index, id) {
      this.editedItem.phase.splice(index, 1);
      // Delete for backend code here
    },
    save() {
      const saveUrl = ``
    },
  },
};
</script>

<style></style>