<template>
  <b-container fluid>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></loading>
    <v-row>
      <v-col cols="auto">
        <v-btn class="primary" :to="{ name: 'home' }">Back</v-btn>
      </v-col>
      <v-col cols="auto">
        <v-dialog v-model="dialog" max-width="600px" v-if="user_role_id == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on"> Add New File </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">New File</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field label="Title*" hide-details="auto" :rules="[required('Title')]" v-model="document.title"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-radio-group v-model="document.document_type" required :mandatory="true">
                        <v-radio label="Reference" v-bind:value="1"></v-radio>
                        <v-radio label="Notice" v-bind:value="2"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6" v-if="document.document_type == 2">
                      <v-menu v-model="expireDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="document.expire_date" label="Expire Date *" prepend-icon="event" readonly :rules="[required('Expire Date')]" v-bind="attrs"
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="document.expire_date" no-title @input="expireDate = false"></v-date-picker>
                      </v-menu>
                      <!-- <v-menu ref="menu" v-model="expireDate" :close-on-content-click="false" :return-value.sync="document.expire_date" transition="scale-transition" offset-y
                        min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="document.expire_date" label="Expire Date *" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="document.expire_date" no-title scrollable>
                        </v-date-picker>
                      </v-menu> -->
                    </v-col>
                    <v-col cols="12">
                      <v-file-input label="File Input" :rules="[required('File Input')]" v-model="document.file_data"></v-file-input>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="uploadDocument()" :disabled="!valid" class="primary ml-4 mb-4 text-white"> Upload </v-btn>
              <v-btn @click="dialog = false" class="error ml-4 mb-4 text-white"> Cancel </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-tabs v-model="tab" left icons-and-text class="py-3">
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#reference">
        Reference
        <v-icon>mdi-file-document</v-icon>
      </v-tab>

      <v-tab href="#notice">
        Notice
        <v-icon>mdi-information-outline</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item :key="1" value="reference">
        <v-expansion-panels>
          <v-expansion-panel v-for="(item, i) in documentList" :key="i">
            <v-expansion-panel-header>
              <v-row>
                <v-col cols="auto">
                  {{ item.title }}
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" v-if="user_role_id == 1">
                  <custom-dialog :click-dialog="deleteFile" :regId="item.id" click-type="delete">
                    Delete
                  </custom-dialog>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <inline-doc :src="basePath + item.file_data"></inline-doc>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>
      <v-tab-item :key="2" value="notice">
        <v-expansion-panels>
          <div class="about-notice" align="left">
            About Notice: The latest uploaded notice will be displayed as homepage flash notice.
          </div>
          <v-expansion-panel v-for="(item, i) in noticeList" :key="i">
            <v-expansion-panel-header>
              <v-row>
                <v-col cols="auto">
                  {{ item.title }}
                </v-col>
                <v-col>
                  Created on {{ item.created_at }}
                </v-col>
                <v-col>
                  Expires on {{ item.expire_date }}
                  <span class="red-text">{{ item.expire_date == null ? "No expire date, so this notice can't be shown" : "" }}</span>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" v-if="user_role_id == 1">
                  <custom-dialog :click-dialog="deleteFile" :regId="item.id" click-type="delete">
                    Delete
                  </custom-dialog>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <inline-doc :src="basePath + item.file_data"></inline-doc>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>
    </v-tabs-items>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="3000">
      {{ successfulMessage }}
    </v-snackbar>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      required(propertyType) {
        return (v) => !!v || `${propertyType} is required`;
      },
      tab: "reference",
      user_role_id: localStorage.getItem("auth_user_role_id"),
      dialog: false,
      valid: false,
      isLoading: false,
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      formData: new FormData(),
      document: {
        id: 0,
        title: "",
        document_type: "",
        file_data: [],
        expire_date: "",
      },
      documentList: [],
      noticeList: [],
      documentTypes: "",
      basePath: process.env.VUE_APP_BASE_URL,
    };
  },

  mounted() {
    this.getReferenceFiles();
    this.getNoticeFiles();
  },

  methods: {
    getReferenceFiles() {
      this.isLoading = true;
      const refUrl = `api/reference-files`;
      this.$axios.get(refUrl).then((response) => {
        this.documentList = response.data.data;
        this.isLoading = false;
      }).catch(error => {
        console.log(error);
        this.isLoading = false;
      });
    },
    getNoticeFiles() {
      this.isLoading = true;
      const refUrl = `api/notice-file-list`;
      this.$axios.get(refUrl).then((response) => {
        const noticeList = response.data.data;
        noticeList.sort(({ created_at: a }, { created_at: b }) => a < b ? 1 : a > b ? -1 : 0);
        this.noticeList = noticeList;
        this.isLoading = false;
      }).catch(error => {
        console.log(error);
        this.isLoading = false;
      });
    },

    buildFormData(formData, data, parentKey) {
      if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
      } else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
      }
    },

    jsonToFormData(data) {
      this.buildFormData(this.formData, data);
      return this.formData;
    },

    uploadDocument() {
      const refUrl = `api/reference-files`;
      this.formData = this.jsonToFormData(this.document);
      this.$axios.post(refUrl, this.formData).then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.dialog = false;
          this.snackbar.show = true;
          this.successfulMessage = response.data.message;
          this.getReferenceFiles();
          this.getNoticeFiles();
          this.$refs.form.reset();
        }
      });
    },
    deleteFile(id) {
      const deleteurl = `api/reference-files/${id}`;
      this.$axios
        .delete(deleteurl)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.dialog = false;
            this.snackbar.show = true;
            this.successfulMessage = response.data.message;
            this.getReferenceFiles();
            this.getNoticeFiles();
          }
        })
        .catch(() => { });
    },
  },
};
</script>

<style scoped>
span.red-text {
  color: red;
}

.about-notice {
  padding-bottom: 2rem;
  font-style: italic;
}
</style>
