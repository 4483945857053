<style scoped>
table {
  line-height: 0px;
  text-align: center;
  height: 20px;
}
</style>

<template>
  <div>
    <table>
      <tr>
        <td>
          <v-checkbox
            color="success"
            :value="c"
            v-on:change="$emit('change', $event.target.value)"
            hide-details
          ></v-checkbox>
        </td>
        <!-- <td>
          <v-checkbox
            color="red darken-3"
            value="nc"
            v-on:change="$emit('change', $event.target.value)"
            hide-details
          ></v-checkbox>
        </td>
        <td>
          <v-checkbox
            color="black"
            value="na"
            v-on:change="$emit('change', $event.target.value)"
            hide-details
          ></v-checkbox>
        </td> -->
      </tr>
      <tr>
        <td><strong>C</strong></td>
        <td><strong>NC</strong></td>
        <td><strong>NA</strong></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    
  },
  data() {
    return {
      cBox: ""
    };
  },
  methods: {
    // passData(){
    //   this.$emit('getData', this.cBox)
    // }
  }
};
</script>
