<template>
  <div class="elevation-2">
    <v-toolbar dense dark flat color="primary">
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0">
          <v-toolbar-title class="pt-2">{{ regData.houseowner_name }}<h1 style="font-size:10px;">{{ regData.construction_type }}</h1></v-toolbar-title>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-simple-table>
      <tbody>
        <tr>
          <td>
            आर्थिक बर्ष: <b> {{ engNumToNep(regData.fiscal_year) }} </b>
          </td>
          <td>
            सम्पर्क नं: <b>{{ engNumToNep(regData.contact_no) }}</b>
          </td>
          <td>
            घरधनीको नाम : <b>{{ regData.houseowner_name_np }}</b>
          </td>
        </tr>
        <tr>
          <td>
            सडक न : <b>{{ engNumToNep(regData.street_no) }}</b>
          </td>
          <!-- </tr>
        <tr> -->
          <td>
            करदाता संकेत न:
            <b>{{ engNumToNep(regData.houseowner_tax_no) }}</b>
          </td>
          <td>
            जग्गा कित्ता न: <b>{{ engNumToNep(regData.land_plot_no) }}</b>
          </td>
        </tr>
        <tr>
          <td>
            आन्तरिक संकेत न:
            <b>{{ engNumToNep(regData.internal_code) }}</b>
          </td>
          <td>
            वडा नं: <b>{{ engNumToNep(regData.ward_no ? regData.ward_no.toString() : "-") }}</b>
          </td>
          <!-- </tr>
        <tr> -->
          <td>
            फारमको दर्ता मिति:
            <b>{{
            regData ? engNumToNep(regData.registration_date_np) : ""
          }}</b>
          </td>
        </tr>
        <tr>
          <td v-if="this.$store.getters.nibedanBibaranAuthData">
            <edit-new-registration-dialog></edit-new-registration-dialog>
          </td>
          <td>
            <v-dialog v-model="mapDialog" max-width="700" height="400px">

              <template v-slot:activator="{ on, attrs }">
                <v-btn small v-bind="attrs" v-on="on" color="primary" dark class="ma-2 white--text">View in Map
                  <v-icon right dark> mdi-map-legend </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5 primary white--text">
                  House in Map
                </v-card-title>

                <v-card-text>
                  <div>
                    Coordinates: (
                    {{ regData.latitude }}
                    {{ regData.longitude }}
                    )
                  </div>
                  <div>
                    <Map :inputLatitude="regData.latitude" :inputLongitude="regData.longitude" :disableMarker="true"></Map>
                  </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="mapDialog = false">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import { engNumConvert } from "../../mixins/numericToUnicode.js";
import EditNewRegistrationDialog from '../../newregistration/components/EditNewRegistrationDialog.vue';
import Map from '@/modules/oldregistration/map/Map.vue'
export default {
  name: "RegistrationInfo",
  mixins: [engNumConvert],
  components: {
    EditNewRegistrationDialog,
    Map
  },
  data() {
    return {
      regData: [],
      mapDialog: false,
    };
  },
  created() {
    this.getRegDataFromStore();
  },
  methods: {
    getRegDataFromStore() {
      this.regData = this.$store.getters.registrationData;
    }
  }

};
</script>