import Vue from "vue";
import JQuery from "jquery";
import VueHtmlToPaper from "vue-html-to-paper";
import VueGoogleCharts from "vue-google-charts";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueSession from "vue-session";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueAxios from "@/plugins/axios"; /*eslint-disable-line*/
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import InlineDocument from "./modules/applicationlevel/subComponents/InlineDocument";
import CustomDialog from "./modules/applicationlevel/subComponents/CustomDialog";
import PdfDialog from "./modules/applicationlevel/subComponents/PdfDialog";
import DocumentUploadListComponent from "./modules/applicationlevel/subComponents/DocumentUploadListComponent";
import ComplianceCheckbox from "./modules/applicationlevel/subComponents/ComplianceCheckbox";
import i18n from "../i18n";

// Vuex Store
import store from "@/store";

// import api from "@/api/api";
import FormValidator from "@/form-validator";

//This is for popup notification
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


Vue.use(VueSession);

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

// Vue.use(VueApexCharts);
Vue.use(VueGoogleCharts);
// Vue.use(VNepaliDatePicker);

// Vue.http.headers.common.Authorization = 'Basic YXBpOnBhc3N3b3Jk';// axios.defaults.headers.common.Authorization = 'Basic YXBpOnBhc3N3b3Jk';

// Register the loading component globally
Vue.component("loading", Loading);
Vue.component("inline-doc", InlineDocument);
Vue.component("custom-dialog", CustomDialog);
Vue.component("pdf-dialog", PdfDialog);
Vue.component("doc-upload", DocumentUploadListComponent);
Vue.component("check-set", ComplianceCheckbox);

// Vue.component('apexchart', VueApexCharts);

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css"
  ]
};

Vue.use(VueHtmlToPaper, options);

// or, using the defaults with no stylesheet
Vue.use(VueHtmlToPaper);
window.$ = JQuery;
window.jQuery = JQuery;

Vue.use(VueAxios);
Vue.use(FormValidator);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  i18n,
  // api,
  render: h => h(App)
}).$mount("#app");

// Vue.prototype.$validk = {
//   nice: "nice",
// };

//Regex list
Vue.prototype.$regexList = {
  engName: '^[a-zA-Z \. ]+$',
  nepName: '^[\u0900-\u0966 \u0970-\u097F \.]+$',
  phone: '^\+?(977|९७७)? ?[0-9०-९]{9,15}$',
  email: '^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$',
  citizenshipNo: '^[0-9०-९-/ ]+$',
  nepEngNo: '^[0-9०-९ ]+$',
  nepDate: '^२[०-९]{3}[-/][०१]?[०-९][-/][०-३]?[०-९]$',
  area: '^[0-9०-९]*[.]?[0-9०-९]+?$',  //'^[0-9०-९]+.?([0-9०-९]+)?$'
  length: '^[0-9०-९ ]*$',
  nepAddress: '^[\u0900-\u0966 \u0970-\u097F \.-]+$',

};

Vue.prototype.$defaultTheme = {
  light: {
    primary: "#0277BD", // Blue Lighten 3 #90CAF9 light-blue darken-3 #0277BD
    secondary: "#b0bec5",
    accent: "#8c9eff",
    error: "#b71c1c",
    sober: "#eff1f1",
    info: "#FFB74D", //Orange Lighten 2
    active: "#079af2",
  },
  dark: {
    primary: "#0277BD", // Blue Lighten 3 #90CAF9 light-blue darken-3 #0277BD
    secondary: "#b0bec5",
    accent: "#8c9eff",
    error: "#b71c1c",
    sober: "#eff1f1",
    info: "#FFB74D" //Orange Lighten 2
  }
}

Vue.prototype.$consultantTheme = {
  dark: {
    primary: "#21CFF3",
    accent: "#FF4081",
    secondary: "#21dc79",
    success: "#86af3f",
    info: "#f34fc6",
    warning: "#FB8C00",
    error: "#FF5252",
    sober: "#eff1f1"
  },
  light: {
    // primary: "#22daff",
    primary: "#69a56c",
    accent: "#ff6b99",
    secondary: "#26ff8c",
    success: "#a5d64c",
    info: "#ff53d0",
    warning: "#ff8e00",
    error: "#ff5252",
    sober: "#eff1f1",
    active: "#aef5b1"
    // color: #69a56c
  }
}