<template>
  <b-overlay spinner-variant="primary" :show="isLoading" rounded="lg">
    <div class="row white rounded-lg">
      <div class="col-9">
        <div class="mt-4">
          <v-card>
            <v-toolbar color="light-blue darken-2" dark>
              <!-- <v-toolbar dense dark flat color="primary"> -->
              <v-toolbar-title>Authority Transfer (अख्तियारी)</v-toolbar-title>
            </v-toolbar>
          </v-card>
          <div v-for="i in authoritiesData" :key="i" class="py-2">
            <AuthorityTransferCard
              :userList="transferFromUserList"
              :authority="i"
              @revoke="revokeAuthority($event)"
              @assign="assignList($event)"
            />
          </div>
          <v-btn class="ma-2" outlined color="blue" @click="addNewTransfer">
            Add New<v-icon right dark> mdi-plus </v-icon>
          </v-btn>
          <div>
            <v-snackbar
              v-model="snackbar.show"
              :bottom="y === 'bottom'"
              :color="snackbar.color"
              :left="x === 'left'"
              :multi-line="mode === 'multi-line'"
              :right="x === 'right'"
              :timeout="3000"
              :top="y === 'top'"
              :vertical="mode === 'vertical'"
            >
              {{ successfulMessage }}
            </v-snackbar>
          </div>
        </div>
      </div>
      <div class="col-3 mt-4 pl-0">
        <v-card>
          <!-- <v-toolbar dense dark flat color="primary"> -->
          <v-toolbar color="light-blue darken-2" dark>
            <v-toolbar-title>Authority Transfer Log</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-dialog v-model="dialog" max-width="1000px" transition="dialog-top-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark x-small v-bind="attrs" v-on="on"> View all Logs </v-btn>
              </template>
              <v-card class="elevation-2 my-2">
                <v-toolbar color="primary" dark flat>
                  <v-toolbar-title> Authority Transfer Log </v-toolbar-title>
                </v-toolbar>
                <v-simple-table class="sober elevation-2">
                  <thead>
                    <tr class="">
                      <th class="text-left">Transfer From</th>
                      <th class="text-left">Transfer To</th>
                      <th class="text-left">Start Date</th>
                      <th class="text-left">End Date</th>
                      <th class="text-left">Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="log in all_authority_transfer_log" :key="log.id" class="">
                      <td>{{ log.transfer_from_user['username'] }}</td>
                      <td>{{ log.transfer_to_user['username'] }}</td>
                      <td>{{ log.start_date }}</td>
                      <td>{{ log.end_date }}</td>
                      <td>{{ log.remarks }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import api from "@/api/api";
import AuthorityTransferCard from "./authority-transfer/AuthorityTransferCard.vue";
export default {
  name: "AdminAuthorityTransfer",
  components: {
    AuthorityTransferCard,
  },
  data() {
    return {
      isLoading: true,
      authorityAssignedList: [],
      transferFromUserList: [],
      assignedUserList: [],
      authoritiesData: [],
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      all_authority_transfer_log: "",
    };
  },
  mounted() {
    this.getAuthorityAssignedList();
    this.getOfficialUserList();
    // this.getAssignedUserList();
    this.getTransferFromList();
    this.getAuthorityLogList();
  },
  methods: {
    async getAuthorityAssignedList() {
      try {
        let response = await api.adminAuthority.authorityAssignedList();
        this.authoritiesData = response.data;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getOfficialUserList() {
      try {
        let response = await api.dropdowns.officialUserList();
        this.userList = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    addNewTransfer() {
      this.authoritiesData.push({
        isNew: 1,
        transfer_from: {
          id: 0,
        },
        transfer_to: [
          {
            id: 1,
          },
        ],
        remarks: '',
      });
    },
    async getAssignedUserList() {
      try {
        let response = await api.adminAuthority.authorityAssignedList();
        this.assignedUserList = response.data.data;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    // Tranfer from list
    async getTransferFromList() {
      try {
        let response = await api.adminAuthority.assignAuthorityTransferFrom();
        this.transferFromUserList = response.data.data;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    revokeAuthority($userId) {
      let revokedUser = this.authoritiesData.find((x) => x.transfer_from.id === $userId);
      let index = this.authoritiesData.indexOf(revokedUser);
      this.authoritiesData.splice(index, 1);
      // alert(`parent user id: ${$userId}`)
      this.snackbar.show = true;
      this.successfulMessage = "Revoked Successfully!";
      this.getAuthorityLogList();
    },
    assignList() {
      this.getAuthorityAssignedList();
      this.getAuthorityLogList();
    },
    getAuthorityLogList() {
      const regLogUrl = `api/authority-transfer-log`;
      this.$axios.get(regLogUrl).then((response) => {
        this.all_authority_transfer_log = response.data.data;
      });
    },
  },
};
</script>
