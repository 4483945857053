<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-form ref="form" class="mx-4" v-model="valid">
          <v-row class="pa-0--child">
            <v-col cols="5">
              <label> भवनको प्रयोजन<span>*</span></label>
            </v-col>

            <v-col cols="7">
              <!-- <v-text-field v-model="application.building_use_id" label=""      ></v-text-field> -->

              <v-select v-model="application.building_use_id" :rules="[required('Building Use')]" :items="buildingUse" item-value="id" item-text="building_use_type" :key="id"
                dense></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <label> भवन ऐन अनुसार भवनको वर्गिकरण<span>*</span></label>
            </v-col>

            <v-col cols="7">
              <!-- <v-text-field v-model="application.building_category_id" label=""      ></v-text-field>
                                 -->
              <v-select required v-model="application.building_category_id" :rules="[required('Building Category')]" :items="buildingCategory" item-value="id"
                item-text="building_category_type" :key="id" dense></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <label> भवनको संरचना प्रणाली<span>*</span></label>
            </v-col>

            <v-col cols="7">
              <v-select required v-model="application.building_structure_id" :rules="[required('Building Structure')]" :items="buildingStructure" item-value="id"
                item-text="building_structure_type" :key="id" dense></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <label> भवनको लम्बाइ<span>*</span></label>
            </v-col>

            <v-col cols="4">
              <v-text-field required v-model="application.building_length_ft" :rules="requiredFormat('Length (ft)', '^(?:[0-9]+|[०-९]+)$')" label="feet" dense></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field required v-model="application.building_length_in" :rules="requiredFormat('Length (in)', '^(?:[0-9]+|[०-९]+)$')" label="inch" dense></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <label> भवनको चौडाई<span>*</span></label>
            </v-col>

            <v-col cols="4">
              <v-text-field required v-model="application.building_breadth_ft" :rules="requiredFormat('Breadth (ft)', '^(?:[0-9]+|[०-९]+)$')" label="feet" dense></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field required v-model="application.building_breadth_in" :rules="requiredFormat('Breadth(in)', '^(?:[0-9]+|[०-९]+)$')" label="inch" dense></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="5">
              <label> भवनको तला<span>*</span></label>
            </v-col>
            <v-col cols="7">
              <v-text-field v-model="application.building_storey" :rules="requiredFormat('Storey', '^(?:[0-9]+|[०-९]+)$')" label="भवनको तला" dense></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <label> भुइँ तलाको क्षेत्रफल<span>*</span></label>
            </v-col>
            <!-- <v-text-field v-model="application.reg_id" :value="applicationrole.id" type="hidden" ></v-text-field> -->
            <v-col cols="7">
              <v-text-field required v-model="application.building_plinth_area" :rules="requiredFormat('Plinth Area', `${this.$regexList.area}`)" label="Plinth Area (sq.ft)"
                dense></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <label> कुल भवन क्षेत्रफल<span>*</span></label>
            </v-col>
            <!-- <v-text-field v-model="application.reg_id" :value="applicationrole.id" type="hidden" ></v-text-field> -->
            <v-col cols="7">
              <v-text-field required v-model="application.building_max_area" :rules="requiredFormat('Total Area', `${this.$regexList.area}`)" label="Total Area (sq.ft)"
                dense></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <label> भवनको कुल उचाइ<span>*</span></label>
            </v-col>

            <v-col cols="4">
              <v-text-field required v-model="application.building_height_ft" :rules="requiredFormat('Height (ft)', `${this.$regexList.length}`)" label="feet" dense></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field required v-model="application.building_height_in" :rules="requiredFormat('Height (in)', `${this.$regexList.length}`)" label="inch" dense></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <label> कुल राजस्व</label>
            </v-col>
            <v-col cols="7">
              <v-text-field required v-model="application.total_revenue" label="Total Revenue" dense></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn primary v-on:click="save" v-if="isPostAuthorized" class="primary mt-2 ml-n2" :disabled="!valid">Save</v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-snackbar v-model="snackbar.show" :bottom="y === 'bottom'" :color="snackbar.color" :left="x === 'left'" :multi-line="mode === 'multi-line'" :right="x === 'right'"
          :timeout="3000" :top="y === 'top'" :vertical="mode === 'vertical'">
          {{ successfulMessage }}
        </v-snackbar>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "oldregghardhaniform",
  props: {
    registrationId: {
      type: [String, Number]
    },
    id: {
      type: [String, Number]
    },
    isPostAuthorized: {
      type: Boolean
    }
  },
  data() {
    return {
      required(propertyType) {
        return v => !!v || `${propertyType} is required`;
      },
      requiredFormat(propertyType, regexexp) {
        var regex = new RegExp(regexexp, "g");
        return [
          v => !!v || `${propertyType} is required`,
          v => regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`
        ];
      },
      valid: false,
      user_role_id: localStorage.getItem("auth_user_role_id"),
      application: {
        id: 0,
        application_date: "2020-04-22",
        application_date_np: "",
        building_category_id: "",
        building_use_id: "",
        building_structure_id: "",
        building_length_in: "",
        building_breadth_in: "",
        building_height_in: "",
        building_length_ft: "",
        building_breadth_ft: "",
        building_height_ft: "",
        building_storey: "",
        building_max_area: "",
        building_plinth_area: "",
        total_revenue: ""
      },
      buildingUse: [],
      buildingCategory: [],
      buildingStructure: [],
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success"
      }
    };
  },
  computed: {
    // required() {
    //   return this.required();
    // }
  },
  created() {
    this.buildingUseCall();
    this.buildingCategoryCall();
    this.buildingStructureCall();
    this.applicationDetailCall();
  },
  methods: {
    applicationDetailCall() {
      const url = `api/registration/${this.$route.params.id}/application`;
      this.$axios
        .get(url)
        .then(response => {
          // debugger;
          this.application = response.data.data;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
    },
    buildingUseCall() {
      const buildingUseUrl = "api/building-use";
      this.$axios
        .get(buildingUseUrl)
        .then(response => {
          this.buildingUse = response.data;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
    },
    buildingCategoryCall() {
      const buildingCategoryUrl = "api/building-category";
      this.$axios
        .get(buildingCategoryUrl)
        .then(response => {
          this.buildingCategory = response.data;
          // console.log(this.buildingCategory);
          // debugger;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
    },
    buildingStructureCall() {
      const buildingStructureUrl = "api/building-structure";
      this.$axios
        .get(buildingStructureUrl, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then(response => {
          this.buildingStructure = response.data;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
    },
    save() {
      this.isFormSubmitting = false;
      if (
        this.application.id === null ||
        this.application.id === 0 ||
        this.application.id === undefined
      ) {
        const url = `/api/registration/${this.$route.params.id}/application`;
        this.$axios
          .post(url, this.application)
          .then(response => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.isFormSubmitting = true;
              this.application = response.data.data;
              this.snackbar.show = true;
              this.successfulMessage = "Building information added successfully";
            }
          })
          .catch(error => {
            // alert(2);
            console.log(`api error:${error}`);
          });
      } else {
        const eurl = `/api/registration/${this.$route.params.id}/application/${this.application.id}`;
        this.$axios
          .put(eurl, this.application)
          .then(response => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.isFormSubmitting = true;
              this.snackbar.show = true;
              this.successfulMessage = "Building information updated successfully";
            }
            // else {
            //     this.$router.push({
            //         name: 'tabbed',
            //     });
            // }
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    edit(id) {
      // debugger;
      //    const url = `api/registration/${this.$route.params.id}/application/${this.$route.params.id}`;
      const url = `api/registration/${this.$route.params.id}/application/${id}`;
      this.$axios.get(url).then(response => {
        // debugger;
        this.application = response.data.data;
        console.log(response.data.data);
      });
    },
    deleterecord(id) {
      const deleteurl = `api/registration/${this.$route.params.id}/application/${id}`;
      this.$axios
        .delete(deleteurl, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then(response => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
          }
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
.col {
  padding: 0 15px;
}

.row:nth-child(even) {
  /* background-clip: content-box; */
  background-color: #eceff1;
}

label span {
  color: red;
}
</style>
