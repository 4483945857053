<template>
  <div>
    <vue-editor v-model="content"></vue-editor>
    {{ content }}
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      content:  `
            <!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="stylesheet" href="{{public_path('css/book-style.css')}}" />
    <title>Document</title>
</head>

<body>
nice 


</body>

</html>
            `,
    };
  },
  computed: {
    // content() {
    //   return;
    // },
  },
};
</script>

<style>
</style>