<template>
  <div class="vld-parent">
    <v-card class="sober">
      <v-card class="sober d-flex flex-column md-4 my-4">
        <v-form ref="form" class="mx-4" v-model="valid">
          <v-text-field 
            v-model="newChat.message" 
            :rules="[required('')]"
            required>
          </v-text-field>
          <v-btn 
            class="primary" 
            v-on:click="sendMessage" 
            v-on:keydown.enter="sendMessage"
            :disabled="!valid"
          > Send Message 
          </v-btn>
        </v-form>
      
      </v-card>
    </v-card>
    <v-card>
      <v-simple-table v-for="m in messages" :key="m.value">
        <td>
          <tr>
            {{m.value}}
          </tr>
        </td>
      </v-simple-table>
    </v-card>
    <v-alert dismissable type="success" value="true" v-if="alertShow">
        {{alertMessage}}
    </v-alert>
    <v-snackbar
          v-model="show"
          :left="x === 'left'"
          :multi-line="mode === 'multi-line'"
          :right="x === 'right'"
          :timeout="3000"
          :vertical="mode === 'vertical'"
        >
          Message Sent!
      </v-snackbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      valid: false,
      required(propertyType) {
        return v => !!v || `${propertyType}`;
      },
      show: false,
      alertShow: false,
      alertMessage: "",
      newChat: {
        message: ""
      },
      messages: [
        
      ]
    }
  },
  created() {
    window.Echo.channel('testchannel')
    .listen('Chat', (e) => {
      console.log(e.message);
      this.alertMessage = e.message;
      this.alertShow = true;
      // console.log("new message");
      this.messages.push({
        value: e.message
      }
      );
    });
  },
  methods: {
    sendMessage() {
      let url = 'api/chat'
      this.$axios.post(
          url,
          this.newChat,
        )
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              this.show = true;
            }
          })
          .catch(error => {
            console.log(`api error:${error}`);
          });
    }
  }
}
</script>
