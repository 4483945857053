import { VueAxios } from '@/plugins/axios';
import api from '@/api/api';

const state = {
    documentMaster: [],
    noticeMaster: [],
};
const getters = {
    getDocumentMaster: state => state.documentMaster,
};
const actions = {
    async fetchDocumentMaster({ commit }, constructionTypeId, phaseId) {
        const response = await api.documents.phaseDocumentMaster(constructionTypeId, phaseId);
        commit('setDocumentMaster', { 'constructionType': constructionTypeId, 'phase': phaseId, 'data': response.data.data });
    },
};
const mutations = {
    setDocumentMaster: (state, documentMaster) => (state.documentMaster = documentMaster),
};

export default {
    state,
    getters,
    actions,
    mutations
};