<template>
  <div>
    <div v-for="i in floorBase" :key="i.floor">
      <SingleFloor :floorData="i" />
    </div>
    <div v-for="i in floorSurface" :key="i.floor">
      <SingleFloor :floorData="i" />
    </div>
    <!-- <v-text-field @change="sendFloorData"></v-text-field> -->
  </div>
</template>

<script>
import api from "@/api/api";
import _, { floor } from "lodash"; // If needed
import SingleFloor from "./SingleFloor.vue";

export default {
  name: "FloorInfo",
  props: {
    registrationId: [String, Number],
    surfaceLength: Number,
    baseLength: Number,
    // newFloorData: Array,
  },
  components: {
    SingleFloor,
  },
  data() {
    return {
      floorInfo: [],
      floorNames: [],
    };
  },
  // beforeCreate() {},
  created() {
    this.getFloorNames();
    this.getFloorInfo();
  },
  computed: {
    floorSurface() {
      let floorSurfaceTemp = _.filter(this.floorInfo, function (o) {
        return o.floor >= 0;
      });
      if (this.surfaceLength && this.surfaceLength < floorSurfaceTemp.length) {
        floorSurfaceTemp = _.slice(floorSurfaceTemp, 0, this.surfaceLength);
      } else if (this.surfaceLength > floorSurfaceTemp.length) {
        for (let i = floorSurfaceTemp.length; i < this.surfaceLength; i++) {
          let floorName = _.filter(this.floorNames, {
            floor_id: i.toString(),
          });
          if (floorName.length) {
            floorSurfaceTemp.push(this.newFloorRow(i));
          }
        }
      }
      return floorSurfaceTemp;
    },
    floorBase() {
      let floorBaseTemp = [];
      floorBaseTemp = _.filter(this.floorInfo, function (o) {
        return o.floor < 0;
      });
      if (this.baseLength && this.baseLength < floorBaseTemp.length) {
        floorBaseTemp = _.slice(floorBaseTemp, 0, this.baseLength);
      } else if (this.baseLength > floorBaseTemp.length) {
        for (let i = floorBaseTemp.length; i < this.baseLength; i++) {
          let floorName = _.filter(this.floorNames, {
            floor_id: i.toString(),
          });
          if (floorName.length) {
            floorBaseTemp.push(this.newFloorRow(-i - 1));
          }
        }
      }
      return floorBaseTemp;
    },
    newFloorInfo() {
      let newFloorInfo = _.concat(this.floorBase, this.floorSurface);
      // this.newFloorData = [];
      // this.newFloorData = newFloorInfo;
      // this.$emit("floorData", newFloorInfo);
      // this.$root.$emit("floorData");

      return newFloorInfo;
    },
  },
  mounted() {
    // this.sendFloorData();
  },
  methods: {
    async getFloorInfo() {
      try {
        let responseFloorInfo = await api.houseownerInfo.floorInfoRevenue(
          this.registrationId
        );
        this.floorInfo = responseFloorInfo.data.data[0].floorRevenue;
      } catch (error) {
        console.log(error);
      }
    },

    async getFloorNames() {
      try {
        let responseFloorNames = await api.dropdowns.floorNames();
        this.floorNames = responseFloorNames.data.data;
      } catch (error) {
        console.log(`floor-master-api-error; ${error}`);
      }
    },

    newFloorRow(index) {
      let floorName = _.filter(this.floorNames, {
        floor_id: index.toString(),
      });
      if (floorName.length) {
        return {
          id: 0,
          floor: index,
          floor_name: floorName[0].name,
          prev_area: "",
          area: "",
          height_ft: "",
          height_in: "",
        };
      }
    },

    sendFloorData() {
      this.$emit("floorData", this.newFloorInfo);
    },
  },
};
</script>

<style>
</style>