<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></loading>
    <v-card class="mb-12" height="auto">
      <v-app id="inspire">
        <div>
          <v-btn class="primary ml-4" :to="{ name: 'home' }">Back</v-btn>
        </div>
        <b-container fluid class="my-4">
          <v-form>
            <v-row>
              <v-col cols="1"> Municipality name (English) </v-col>
              <v-col cols="2">
                <v-text-field v-model="municipality.name" label="" height="10" solo></v-text-field>
              </v-col>
              <v-col cols="1"> Municipality name (Nepali) </v-col>
              <v-col cols="2">
                <v-text-field v-model="municipality.name_np" label="" height="10" solo></v-text-field>
              </v-col>
              <v-col cols="1"> Municipality Address </v-col>
              <v-col cols="2">
                <v-text-field v-model="municipality.address" label="" height="10" solo></v-text-field>
              </v-col>
              <v-col cols="1"> Municipality Type </v-col>
              <v-col cols="2">
                <v-text-field v-model="municipality.municipality_type" label="" height="10" solo></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1"> Municipality Office Type </v-col>
              <v-col cols="2">
                <v-text-field v-model="municipality.municipality_office_type" label="" height="10" solo></v-text-field>
              </v-col>
              <v-col cols="1"> Province </v-col>
              <v-col cols="2">
                <v-text-field v-model="municipality.province" label="" height="10" solo></v-text-field>
              </v-col>
              <v-col cols="1"> Municipality Short Name </v-col>
              <v-col cols="2">
                <v-text-field v-model="municipality.short_name" label="" height="10" solo></v-text-field>
              </v-col>
              <v-col md="1"> Fiscal Year </v-col>
              <v-col md="2">
                <v-select v-model="municipality.fiscal_year_id" :items="fiscalyear" item-value="id" item-text="f_year" :item-key="fiscalyear" solo></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn v-if="userRoleId == 1" primary v-on:click="save" class="primary mx-4 my-4">Save</v-btn>
              </v-col>
            </v-row>
            <br />
            <br />
          </v-form>
          <v-snackbar v-model="snackbar.show" :color="snackbar.color">
            {{ successfulMessage }}
          </v-snackbar>

          <v-row>
            <v-col>
              <bps-user-data-table></bps-user-data-table>
            </v-col>
          </v-row>
        </b-container>
      </v-app>
    </v-card>
  </div>
</template>
<script>
import BpsUserDataTable from "../home/components/BpsUserDataTable.vue";

import { VueAxios } from "@/plugins/axios";

export default {
  name: "Application",
  components: {
    BpsUserDataTable,
  },

  data() {
    return {
      userRoleId: localStorage.getItem("auth_user_role_id"),
      fiscalyear: [],
      // countData: ['a','2','3','4'],
      municipality: {
        id: 0,
        // reg_id: 0,
        name: "",
        name_np: "",
        short_name: "",
        no_of_wards: "",
        latitude: "",
        longitude: "",
        province: "",
        address: "",
        municipality_type: "",
        area_metric_system: "",
        area_mf_system: "",
        short_name: "",
      },
      consultancy: [],
      districts: [],
      newconsultancy: {
        id: 0,
        reg_id: this.registrationId,
        is_consulting_firm: "",
        registration_number: "",
        name: "",
        location: "",
        district_id: "",
        registration_date_np: "",
        registration_date: "2020-04-04",
      },
      // municipalityList: [],
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success"
      },
      isLoading: false,
    };
  },
  mounted() {
    this.getFiscalyear();
    this.getMunicipality();
    this.getDate();
  },
  methods: {
    getDate() {
      const { dateField } = this.$refs;
      if (dateField) {
        $(dateField.$el).nepaliDatePicker({
          dateFormat: "%y-%m-%d",
          closeOnDateSelect: true,
        });
        $(dateField.$el).on("dateSelect", (event) => {
          this.newconsultancy.registration_date_np = event.datePickerData.formattedDate;
        });
      }
    },
    async getFiscalyear() {

      let fiscalYearListTemp = this.$store.getters.getFiscalYearList;
      fiscalYearListTemp.length || await this.$store.dispatch("fetchFiscalYearList");
      this.fiscalyear = this.$store.getters.getFiscalYearList;
    },
    // save() {
    //   this.isLoading = true;
    //   if (
    //     this.municipality.id === null ||
    //     this.municipality.id === 0 ||
    //     this.municipality.id === undefined
    //   ) {
    //     const url = "/api/municipality";
    //     this.$axios.post(url, this.municipality).then(response => {
    //       // debugger;
    //       if (response.status === 200 || response.status === 201) {
    //         this.isLoading = false;
    //         this.municipality = response.data.data;
    //         this.snackbar.show = true;
    //         this.successfulMessage = "Successful.";
    //       }
    //     })
    //       .catch(error => {
    //         this.isLoading = false;
    //         console.log(`api error:${error}`);
    //       });
    //   } else {
    //     this.$store.dispatch("saveMunicipality", this.municipality).then(response => {
    //       if (response.status === 200 || response.status === 201) {
    //         this.isLoading = false;
    //         this.snackbar.show = true;
    //         this.successfulMessage = "Data Updated successfully";
    //       }
    //     }).catch(error => {
    //       this.isLoading = false;
    //       console.log(error);
    //     });
    //   }
    // },
    async save() {
      this.isLoading = true;
      await this.$store.dispatch("saveMunicipality", this.municipality);
      this.isLoading = false;
    },
    async getMunicipality() {
      let munTemp = this.$store.getters.getMunicipality;
      // console.log("chhirnu agi" + munTemp);
      try {
        if (!Object.keys(munTemp).length) {
          // console.log("yeah chiryo");
          await this.$store.dispatch("fetchMunicipality")
        }
        this.municipality = this.$store.getters.getMunicipality;
      } catch (error) {
        console.log("Error while retrieving municipality");
        console.log(error);
      }
    },
    edit(id) {
      // debugger;
      //    const url = `api/municipality/${this.$route.params.id}`;
      const url = `api/municipality/${id}`;
      VueAxios.get(url).then((response) => {
        // debugger;
        this.municipality = response.data.data;
        console.log(response.data.data);
      });
    },
    deleterecord(id) {
      const deleteurl = `api/municipality/${id}`;
      VueAxios.delete(deleteurl)
        .then((response) => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
          }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  clearAllData() {
    this.newconsultancy.id = 0;
    this.newconsultancy.reg_id = this.registrationId;
    this.newconsultancy.is_consulting_firm = "";
    this.newconsultancy.registration_number = "";
    this.newconsultancy.name = "";
    this.newconsultancy.location = "";
    this.newconsultancy.district_id = "";
    this.newconsultancy.registration_date_np = "";
    this.newconsultancy.registration_date = "2043-3-3";
  },
  saveConsultancy() {
    //   debugger;
    if (
      this.newconsultancy.id === "null" ||
      this.newconsultancy.id === 0 ||
      this.newconsultancy.id === undefined
    ) {
      const saveUrl = "/api/consultancy";
      VueAxios.post(saveUrl, this.newconsultancy)
        .then((response) => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.consultancy.push(response.data.data);
            this.snackbar.show = true;
            this.successfulMessage = "Successful.";
            this.clearAllData();
          }
        })
        .catch((error) => {
          // alert(2);
          console.log(`api error:${error}`);
        });
    } else {
      const eurl = `/api/consultancy/${this.newconsultancy.id}`;
      VueAxios.put(
        eurl,
        this.newconsultancy,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
          _method: "patch",
        },
        {}
      )
        .then((response) => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data updated successfully";
            this.clearAllData();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  edit(id) {
    //    const url = `api/registration/${this.$route.params.regId}/consultancy/${this.$route.params.id}`;
    const url = `api/consultancy/${id}`;
    VueAxios.get(url).then((response) => {
      // debugger;
      this.newconsultancy = response.data.data;
      console.log(response.data.data);
    });
  },
  deleterecord(id) {
    const deleteurl = `api/registration/${this.$route.params.regId}/consultancy/${id}`;
    VueAxios.delete(deleteurl)
      .then((response) => {
        // debugger;
        if (response.status === 200 || response.status === 201) {
          this.snackbar.show = true;
          this.successfulMessage = "Data deleted successfully";
          const i = this.consultancy.map((item) => item.id).indexOf(id); // find index of your object
          this.consultancy.splice(i, 1);
        }
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field,
.v-text-field,
.v-text-field--enclosed,
.v-input__slot {
  padding: 0;
}

.v-text-field__details {
  margin: 0;
  padding: 0;
}
</style>
