<template>
  <div class="my-4" v-if="apiData.length > 0">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title> History </v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col cols="2" v-for="document in apiData" :key="document.id">
        <v-row class="ma-4">
          <pdf-dialog
            :src="basePath + document.document_data"
            displayText="Full Screen"
          ></pdf-dialog>
        </v-row>
        <v-row class="ma-4">
          <inline-doc
            :src="basePath + document.document_data"
          ></inline-doc>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "DocumentHistory",
  props: {
    historyData: {
      type: [Object, Array],
    },
    sameAsOldData: {
      type: Boolean,
    },
  },
  data() {
    return {
      basePath: process.env.VUE_APP_BASE_URL,
      apiData: [],
    };
  },
  watch: {
    sameAsOldData() {
      if (this.sameAsOldData == true) {
        this.getOldData();
      }
    },
  },
  mounted() {
    this.getApiData();
  },
  methods: {
    getApiData() {
      const historyUrl = this.historyData.apiUrl;
      if (historyUrl != null) {
        this.$axios
          .get(historyUrl)
          .then((response) => {
            this.apiData = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getOldData() {
      if (this.sameAsOldData == true) {
        this.$emit("getOldData", this.apiData);
      }
    },
  },
};
</script>

<style scoped>
.width-sm {
  width: 10%;
}
</style>