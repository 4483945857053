export default axios => ({
    authorityAssignedList() {
        return axios.get("api/assign-authority/list");
    },

    assignAuthorityStatus() {
        return axios.get("api/assign-authority/status");
    },

    assignAuthorityTransferFrom() {
        return axios.get("api/assign-authority/transfer-from");
    },

    assignAuthorityTransferTo(userId) {
        return axios.get(`api/assign-authority/${userId}/transfer-to`);
    },

    postAssignAuthority(payload) {
        return axios.post(`api/assign-authority`, payload);
    },

    postRevokeAuthority(userId, payload) {
        return axios.post(`api/revoke-authority/${userId}`, payload);
    }

});