<template>
  <div>
    <v-dialog v-model="dialog" width="1200" persistent>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ noticeData.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="isSeen">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <inline-doc :src="basePath + noticeData.file_data"></inline-doc>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="isSeen"> Mark as Read </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="two-button-row">
      <v-col cols="auto">
        <v-btn
          v-if="user_role_id != 8"
          large
          depressed=""
          class="secondary border-secondary coltext--text right subtitle-1"
          :to="{ name: 'consultantfiles' }"
        >
          कन्सल्टेन्टबाट दर्ता भएका निवेदनहरु
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn
          v-if="user_role_id != 8"
          large
          depressed=""
          class="secondary border-secondary coltext--text right subtitle-1 white--text"
          :to="{ name: 'old_registration_dashboard' }"
        >
          पुरानो दर्ताहरु
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn large depressed="" class="primary lighten-3 border-primary right subtitle-1 coltext--text" :to="{ name: 'new_registration' }">
          <v-icon left>mdi-plus</v-icon>
          नयाँ दर्ता
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <registration-data-table :isOld="false"></registration-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RegistrationDataTable from "../home/components/RegistrationDataTable.vue";

export default {
  name: "Home",
  components: {
    RegistrationDataTable,
  },
  data() {
    return {
      basePath: process.env.VUE_APP_BASE_URL,
      dialog: false,
      user_role_id: localStorage.getItem("auth_user_role_id"),
      noticeData: "",
      noticeDataId: "",
      noticeFile: "",
      showDialog: "",
    };
  },

  created() {
    this.getNoticeData();
  },

  methods: {
    check() {
      const isSeen = localStorage.getItem("isSeen");
      const noticeFileId = localStorage.getItem("noticeFileId");

      if (isSeen != true && noticeFileId != this.noticeData.id) {
        this.dialog = true;
      }
      else {
        this.dialog = false;
      }
    },

    getNoticeData() {
      const refUrl = `api/notice-file`;
      this.$axios
        .get(refUrl)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.noticeData = response.data.data; // Notice File Data

            this.noticeFile = this.basePath + this.noticeData.file_data;
          }
        })
        .then(() => {
          this.check();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    isSeen() {
      this.dialog = false;
      localStorage.setItem("isSeen", true);
      localStorage.setItem("noticeFileId", this.noticeData.id);
    },
  },
};
</script>

<style scoped>
.v-btn.v-btn--has-bg.border-secondary {
  border: 1px solid var(--v-secondary-darken1) !important;
}

.v-btn.v-btn--has-bg.border-primary {
  border: 1px solid var(--v-primary-base) !important;
}

.v-btn.v-btn--has-bg.border-accent {
  border: 1px solid var(--v-accent-base) !important;
}

</style>
