<template>
  <div>
    <nav>
      <!-- Start of app toolbar -->
      <v-app-bar class="background" dark elevation="3">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"> </v-app-bar-nav-icon>
        <v-toolbar-title class="headline rounded-xl d-flex align-center">
          <router-link :to="{ name: 'home' }">
            <img :src="require('@/assets/images/logo-dark-2.png')" width="80px" class="nav-top-logo mb-8" />
          </router-link>
          <router-link :to="{ name: 'home' }" class="black--text font-weight-medium font-heading"> {{ munName }} GharNaksha </router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-toolbar-items class="hidden-sm-and-down" v-for="item in items" :key="item.title" link>
          <v-btn color="primary" :to="{ name: item.link }" exact>{{ item.title }}</v-btn>
        </v-toolbar-items> -->
        <v-toolbar-items class="hidden-xs-only" elevation="0">
          <v-btn
            text
            v-for="item in items"
            :key="item.title"
            :to="{ name: item.link }"
            class="background black--text text--darken1 text-subtitle-2 font-weight-medium"
            elevation="0"
          >
            <v-icon left dark>{{ item.icon }}</v-icon>
            {{ item.title }}
          </v-btn>
        </v-toolbar-items>
        <template>
          <div class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar class="mx-4 avatar-background" size="40" dark v-on="on" v-bind="attrs">
                  <v-badge :content="unreadMessageNo" :value="unreadMessageNo" overlap>
                    <v-icon color="coltext">mdi-bell-outline</v-icon></v-badge
                  >
                </v-avatar>
              </template>
              <!-- Here notificstion -->
              <notification></notification>
            </v-menu>
          </div>
        </template>
        <div class="coltext--text">
          <div class="my-4 userdesk-border" v-if="userRoleId == '1'">
            Admin
            <p style="font-size: 10pt" v-if="check_ward != null">Ward - {{ user.ward_no }}</p>
          </div>
          <div class="userdesk-border" v-if="userRoleId == '2'">
            Registration
            <p style="font-size: 10pt" v-if="check_ward != null">Ward - {{ user.ward_no }}</p>
          </div>
          <div class="userdesk-border" v-if="userRoleId == '3'">Amin</div>
          <div class="userdesk-border" v-if="userRoleId == '4'">Technical</div>
          <div class="userdesk-border" v-if="userRoleId == '5'">SeniorTechnical</div>
          <div class="userdesk-border" v-if="userRoleId == '6'">Executive</div>
          <div class="userdesk-border" v-if="userRoleId == '8'">Consultancy</div>

          <!-- <div>{{ userRoleName }}</div> -->
        </div>
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-y transition="slide-y-transition">
          <template v-slot:activator="{ on }">
            <v-btn icon>
              <v-avatar class="mx-4 secondary coltext--text" size="36" dark v-on="on">
                <!-- PB -->
                {{ usernameInitials }}
              </v-avatar>
            </v-btn>
          </template>

          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-avatar class="secondary" size="40">
                  {{ usernameInitials }}
                </v-list-item-avatar>

                <v-list-item-content style="max-width: 400px; font-weight: 500; font-size: 3pt">
                  <v-list-item-title>{{ userFullName }}</v-list-item-title>
                  <v-list-item-subtitle>Username: {{ user.username }}</v-list-item-subtitle>
                  <v-list-item-subtitle>Email: {{ user.email }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="check_ward != null">Ward No: {{ user.ward_no }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="user.substitute_authority_status != null">Assigned Desk:
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="user.substitute_authority_status != null">{{
                    user.substitute_authority_status
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list>
              <v-list-item-group>
                <v-list-item v-if="userRoleId != 8" :to="{ name: 'technician' }">
                  <v-list-item-icon>
                    <v-icon>mdi-account-group-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Technicians</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="userRoleId == 8" :to="{ name: 'consultancy_profile' }">
                  <v-list-item-icon>
                    <v-icon>mdi-account-group-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Your Profile</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="userRoleId == 1" :to="{ name: 'admin_main' }">
                  <v-list-item-icon>
                    <v-icon>mdi-view-dashboard</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Admin Dashboard</v-list-item-title>
                </v-list-item>

                <!-- <v-dialog v-model="authorityDialog" width="500" v-if="userRoleId != 8">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-icon>
                        <v-icon>mdi-account-arrow-right</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Give Authority</v-list-item-title>
                        <v-list-item-subtitle>
                          {{
                            isAuthorityTransfer
                              ? "Authority given to " + selectedAuthorityUser
                              : "Another authority not assigned"
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <div>
                    <v-card>
                      <v-card-title>
                        <v-col cols="auto">Authority Transfer</v-col>
                        <v-spacer></v-spacer>
                        <v-col>
                          <v-switch
                            v-model="isAuthorityTransfer"
                            :label="isAuthorityTransfer ? 'ON' : 'OFF'"
                            hide-details
                          >
                          </v-switch>
                        </v-col>
                      </v-card-title>
                      <div v-if="isAuthorityTransfer">
                        <v-card-text>
                          <v-row>
                            <v-col
                              cols="auto"
                              class="d-flex flex-column justify-center"
                              >Name:</v-col
                            >
                            <v-col cols="auto">
                              <v-select
                                v-model="selectedAuthority"
                                :items="userList"
                                :item-text="
                                  (item) =>
                                    item.first_name + ' ' + item.last_name
                                "
                                item-value="id"
                              ></v-select>
                            </v-col>
                            <v-col class="d-flex flex-column justify-center">
                              <v-btn class="primary" @click="changeAuthority"
                                >Submit</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </div>
                      <v-snackbar
                        v-model="snackbar.show"
                        :bottom="y === 'bottom'"
                        :color="snackbar.color"
                        :left="x === 'left'"
                        :multi-line="mode === 'multi-line'"
                        :right="x === 'right'"
                        :timeout="3000"
                        :top="y === 'top'"
                        :vertical="mode === 'vertical'"
                      >
                        {{ successfulMessage }}
                      </v-snackbar>
                    </v-card>
                  </div>
                </v-dialog> -->

                <v-list-item :to="{ name: 'resetpassword' }">
                  <v-list-item-icon>
                    <v-icon>mdi-lock-reset</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Change Password</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout" class="error--text">
                  <v-list-item-icon>
                    <v-icon color="error">mdi-logout-variant</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Sign Out</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>
      </v-app-bar>
      <v-snackbar v-model="internetNotConnected" color="error" timeout="10000000000">
        {{ networkStatus }}
      </v-snackbar>

      <!-- End of app toolbar -->

      <!-- Start of mobile side menu -->
      <v-navigation-drawer app v-model="drawer" left class="primary accent-4" dark temporary>
        <template #prepend>
          <v-list-item>
            <v-icon color="primary" @click="drawer = !drawer">mdi-menu</v-icon>
          </v-list-item>
        </template>
        <v-flex>
          <v-flex class="justify-start">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"> </v-app-bar-nav-icon>
            <img class="mx-9 my-3" :src="require('@/assets/images/logo-light-2.png')" width="180px" />
          </v-flex>
          <v-list-item-title class="white--text mx-9"> GharNaksha </v-list-item-title>
          <h6 class="white--text mx-9">{{ munName }}</h6>
          <!-- <v-list-item-subtitle>
            {{ munName }}
          </v-list-item-subtitle> -->
        </v-flex>
        <v-divider color="white"></v-divider>
        <v-list>
          <v-list-item v-for="item in items" :key="item.title" :to="{ name: item.link }">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <template v-slot:append>
          <div class="pa-2">
            <v-btn class="error" block v-on:click="logout">Logout</v-btn>
          </div>
        </template>
      </v-navigation-drawer>

      <!-- End of mobile side menu -->
    </nav>
    <!-- End of Navigation -->
  </div>
</template>

<script>
import Notification from "./navbar/components/Notification.vue";
import api from "@/api/api";
export default {
  components: {
    Notification,
  },
  data() {
    return {
      check_ward: "",
      userRoleId: localStorage.getItem("auth_user_role_id"),
      itemsOld: [
        { title: "Dashboard", icon: "dashboard", link: "home" },
        { title: "Report", icon: "pie_chart", link: "report" },
        { title: "Analyse", icon: "pie_chart", link: "resultanalysis" },
        {
          title: "Application Status",
          icon: "folder",
          link: "applicationstatuspage",
        },
        { title: "Municipality", icon: "folder", link: "municipalitydetail" },
        { title: "Consultancy", icon: "folder", link: "consultancy" },
      ],
      user: [],
      municipality: [],
      messages: [
        {
          registration: {
            reg_id: 0,
            houseowner_name_np: "",
          },
          message: "",
          isRead: false,
          created_by: {
            username: "",
          },
        },
      ],
      munName: "",
      usernameInitials: "",
      userFullName: "",
      username: "",
      drawer: false,
      networkStatus: "Unable to connect to the internet!",
      // snackbar: true,
      internetNotConnected: false,
      timeout: -1,
      showMessage: false,
      showProfile: false,
      unreadMessageNo: 0,
      notificationMore: "",
      menu: "",
      authorityDialog: false,
      isAuthorityTransfer: false,
      userList: [
        {
          id: 1,
          name: "Nice Name",
        },
        {
          id: 2,
          name: "Nice Name Two",
        },
      ],
      selectedAuthority: "",

      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      userRole: [],
      userRoleName: "",
    };
  },
  computed: {
    items() {
      if (this.userRoleId == 8) {
        return [
          { title: "Dashboard", icon: "dashboard", link: "home" },
          {
            title: "Notice & Information",
            icon: "mdi-download",
            link: "referencefiles",
          },
        ];
      } else {
        return [
          {
            title: "Dashboard",
            icon: "mdi-view-dashboard-variant",
            link: "home",
          },
          { title: "Report", icon: "mdi-chart-arc", link: "report" },
          {
            title: "Analyse",
            icon: "mdi-home-analytics",
            link: "resultanalysis",
          },
          {
            title: "Application Status",
            icon: "mdi-list-status",
            link: "applicationstatuspage",
          },
          { title: "Downloads", icon: "mdi-download", link: "referencefiles" },
          {
            title: "Municipality",
            icon: "mdi-domain",
            link: "municipalitydetail",
          },
          {
            title: "Consultancy",
            icon: "mdi-account-group",
            link: "consultancy",
          },
        ];
      }
    },
    selectedAuthorityUser() {
      if (this.selectedAuthority) {
        let user = this.userList.find((x) => x.id == this.selectedAuthority);
        let userFullName = user.first_name + " " + user.last_name;
        return userFullName;
      }
      return "None";
    },
  },
  watch: {
    isAuthorityTransfer() {
      if (!this.isAuthorityTransfer) {
        var payload = {
          authority_transfer_status: false,
        };
        api.dropdowns.saveAssignAuthority(payload).then((response) => {
          this.isAuthorityTransfer = response.data.data.authority_transfer_status;
          this.snackbar.show = true;
          this.successfulMessage = response.data.message;
        });
      }
    },
  },

  created() {
    // this.getUserRole();
  },

  mounted() {
    //to check if the user has gone offline
    this.getMunicipality();

    window.addEventListener("offline", this.updateOfflineStatus);
    window.addEventListener("online", this.updateOnlineStatus);
    const currentuser_url = "api/profile";
    this.$axios
      .get(currentuser_url)
      .then((response) => {
        // debugger;
        if (response.status === 200) {
          this.user = response.data;
          this.usernameInitials = `${this.user.first_name.charAt(0).toUpperCase() + this.user.last_name.charAt(0).toUpperCase()}`;
          this.userFullName = `${this.user.first_name} ${this.user.last_name}`;
          this.userRoleId = this.user.user_role_id;
          this.check_ward = this.user.ward_no;
        }
      })
      .catch((error) => {
        console.log("Navbar error");
        console.error(error);
        if (error.status !== 500) {
          window.localStorage.clear();
          this.$router.push("/login");
        }
      });

    // const messages_url = "api/messages";
    // this.$axios
    //   .get(messages_url)
    //   .then((response) => {
    //     // debugger;
    //     if (response.status === 200) {
    //       this.messages = response.data.data;
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("Navbar error");
    //     console.error(error);
    //   });




  },
  methods: {
    logout() {
      window.localStorage.clear();
      this.$router.push("/login");
    },
    updateOnlineStatus() {
      console.log("Online aayo computer!!!");
      this.internetNotConnected = false;
    },
    updateOfflineStatus() {
      console.log("Oh no!! Off line gayo computer!!!");
      this.internetNotConnected = true;
    },
    refreshCurrentPage() {
      this.$root.$emit("home_search"); // Method 1
    },
    changeAuthority() {
      // console.log("nice change authrity");
      var payload = {
        authority_transfer_status: this.isAuthorityTransfer,
        user_id: this.selectedAuthority,
      };
      api.dropdowns
        .saveAssignAuthority(payload)
        .then((response) => {
          this.isAuthorityTransfer = response.data.data.authority_transfer_status;
          this.selectedAuthority = response.data.data.user_id;
          this.snackbar.show = true;
          this.successfulMessage = response.data.message;
        })
        .catch((error) => {
          this.snackbar.show = true;
          this.snackbar.color = red;
          this.successfulMessage = error.response.data.message;
        });
    },
    async getUserRole() {
      // this.isLoading = true;
      try {
        let response = await api.dropdowns.userRoleList();
        this.userRole = response.data.data;
        let roleName = "";
        if (parseInt(this.userRoleId == 1)) {
          roleName = "Admin Desk";
        } else {
          roleName = this.userRole.filter((item) => item.role_id === parseInt(this.userRoleId))[0].role_name;
        }
        // Do for admin here
        if (parseInt(this.userRoleId == 1)) {
          roleName = "Admin Desk";
        }
        // console.log("role name " + roleName);
        this.userRoleName = roleName;
        // this.isLoading = false;
      } catch (error) {
        console.log("User Role Fetch Error: " + error);
        // this.isLoading = false;
      }
    },
    async getMunicipality() {
      let munTemp = this.$store.getters.getMunicipality;
      try {
        if (!Object.keys(munTemp).length) {
          await this.$store.dispatch("fetchMunicipality")
        }
        this.municipality = this.$store.getters.getMunicipality;
        this.munName = this.$store.getters.getMunicipality.name.split(" ")[0];
      } catch (error) {
        console.log("Navbar error while retrieving municipality");
        console.log(error);
      }
    }
  },
};
</script>

<style scoped>
.navigation {
  position: relative;
  height: 15vh !important;
  width: 100vw;
}

.logout {
  width: 120px;
}

.reset-btn {
  width: 25px !important;
  height: 30px !important;
  margin-top: 10px !important;
  font-size: 12px !important;
}

.nav-top-logo {
  margin-bottom: 20px;
}

.container {
  display: flex;
}

.v-application .v-btn--active {
  border-bottom: 3px solid var(--v-primary-base) !important;
  color: var(--v-primary-darken1) !important;
}

.v-btn:hover {
  background-color: var(--v-primary-lighten5) !important;
}

.theme--dark.v-btn--active:hover::before,
.theme--dark.v-btn--active::before {
  background-color: var(--v-primary-lighten1) !important;
}

.mdi-menu {
  color: var(--v-primary-base) !important;
}

.userdesk-border {
  border: 2px solid var(--v-primary-base);
  border-radius: 40px;
  padding: 5px 5px;
  box-shadow: 1px 1px 1px var(--v-primary-base);
}

.avatar-background {
  background-color: var(--v-primary-lighten2) !important;
}
</style>
