import { VueAxios } from '@/plugins/axios';

const state = {
    nibedanBibaranAuth: JSON.parse(localStorage.getItem('nibedan_bibaran_auth_data')),
    nibedanDocumentsAuth: JSON.parse(localStorage.getItem('nibedan_documents_auth_data')),
    documentsAuth: JSON.parse(localStorage.getItem('documents_auth_data')), // New
    fifteenDayDocumentsAuth: JSON.parse(localStorage.getItem('fifteen_day_documents_auth_data')),
    plinthDocumentsAuth: JSON.parse(localStorage.getItem('plinth_documents_auth_data')),
    superstructureDocumentsAuth: JSON.parse(localStorage.getItem('superstructure_documents_auth_data')),
    completionDocumentsAuth: JSON.parse(localStorage.getItem('completion_documents_auth_data')),
    forwardRevertActionAuth: JSON.parse(localStorage.getItem('forward_revert_action_auth_data')),
    rememberMe: false,
};

// Generalise the below fucntion

const getters = {
    nibedanBibaranAuthData: state => state.nibedanBibaranAuth,
    nibedanDocumentsAuthData: state => state.nibedanDocumentsAuth,

    documentsAuthData: state => state.documentsAuth, //New

    fifteenDayDocumentsAuthData: state => state.fifteenDayDocumentsAuth,
    plinthDocumentsAuthData: state => state.plinthDocumentsAuth,
    superstructureDocumentsAuthData: state => state.superstructureDocumentsAuth,
    completionDocumentsAuthData: state => state.completionDocumentsAuth,
    forwardRevertActionAuthData: state => state.forwardRevertActionAuth,
    rememberMeData: state => state.rememberMe,
};
const actions = {
    async getNibedanBibaranAuth({ commit }, registrationId) {
        const response = await VueAxios.get(`api/registration/${registrationId}/nibedan-bibaran/is-authorized`);
        commit('setNibedanBibaranAuth', response.data.data);
        localStorage.setItem('nibedan_bibaran_auth_data', JSON.stringify(response.data.data));
    },
    // New common one for all
    async getDocumentsAuth({ commit }, param) {
        let url = null;
        const registrationId = param.regId;
        const phase = param.phase;
        // const phase = phaseId.toString();
        switch (phase) {
            case '1':
                url = `api/registration/${registrationId}/nibedan-documents/is-authorized`;
                break;
            case '2':
                url = `api/registration/${registrationId}/fifteen-day-documents/is-authorized`;
                break;
            case '3':
                url = `api/registration/${registrationId}/plinth-documents/is-authorized`;
                break;
            case '4':
                url = `api/registration/${registrationId}/superstructure-documents/is-authorized`;
                break;
            case '5':
                url = `api/registration/${registrationId}/completion-documents/is-authorized`;
                break;
            default:
                url = `api/registration/${registrationId}/nibedan-documents-nice/is-authorized`; //change wrong
        }
        const response = await VueAxios.get(url);
        commit('setDocumentsAuth', response.data.data);
        localStorage.setItem('documents_auth_data', JSON.stringify(response.data.data));
        // console.log(`nibedan document: nice${JSON.parse(localStorage.getItem('documents_auth_data'))}`);

    },

    async getNibedanDocumentsAuth({ commit }, registrationId) {
        const response = await VueAxios.get(`api/registration/${registrationId}/nibedan-documents/is-authorized`);
        commit('setNibedanDocumentsAuth', response.data.data);
        localStorage.setItem('nibedan_documents_auth_data', JSON.stringify(response.data.data));
        // console.log(`nibedan document: nice${JSON.parse(localStorage.getItem('nibedan_documents_auth_data'))}`);

    },
    async getFifteenDayDocumentsAuth({ commit }, registrationId) {
        const response = await VueAxios.get(`api/registration/${registrationId}/fifteen-day-documents/is-authorized`);
        commit('setFifteenDayDocumentsAuth', response.data.data);
        localStorage.setItem('fifteen_day_documents_auth_data', JSON.stringify(response.data.data));
    },
    async getPlinthDocumentsAuth({ commit }, registrationId) {
        const response = await VueAxios.get(`api/registration/${registrationId}/plinth-documents/is-authorized`);
        commit('setPlinthDocumentsAuth', response.data.data);
        localStorage.setItem('plinth_documents_auth_data', JSON.stringify(response.data.data));
    },
    async getSuperstructureDocumentsAuth({ commit }, registrationId) {
        const response = await VueAxios.get(`api/registration/${registrationId}/superstructure-documents/is-authorized`);
        commit('setSuperstructureDocumentsAuth', response.data.data);
        localStorage.setItem('superstructure_documents_auth_data', JSON.stringify(response.data.data));
    },
    async getCompletionDocumentsAuth({ commit }, registrationId) {
        const response = await VueAxios.get(`api/registration/${registrationId}/completion-documents/is-authorized`);
        commit('setCompletionDocumentsAuth', response.data.data);
        localStorage.setItem('completion_documents_auth_data', JSON.stringify(response.data.data));
    },
    async getForwardRevertActionAuth({ commit }, registrationId) {
        const response = await VueAxios.get(`api/registration/${registrationId}/forward-revert-action/is-authorized`);
        commit('setForwardRevertActionAuth', response.data.data);
        localStorage.setItem('forward_revert_action_auth_data', JSON.stringify(response.data.data));
    }
};
const mutations = {
    setNibedanBibaranAuth: (state, payload) => (state.nibedanBibaranAuth = payload),
    setNibedanDocumentsAuth: (state, payload) => (state.nibedanDocumentsAuth = payload),
    setDocumentsAuth: (state, payload) => (state.documentsAuth = payload), //New
    setFifteenDayDocumentsAuth: (state, payload) => (state.fifteenDayDocumentsAuth = payload),
    setPlinthDocumentsAuth: (state, payload) => (state.plinthDocumentsAuth = payload),
    setSuperstructureDocumentsAuth: (state, payload) => (state.superstructureDocumentsAuth = payload),
    setCompletionDocumentsAuth: (state, payload) => (state.completionDocumentsAuth = payload),
    setForwardRevertActionAuth: (state, payload) => (state.forwardRevertActionAuth = payload),
    setRememberMe: (state, payload) => (state.rememberMe = payload),
};


export default {
    state,
    getters,
    actions,
    mutations
};