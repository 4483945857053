export default axios => ({
    registration(regId) {
        return axios.get(`api/registration/${regId}`);
    },
    updateRegistration(regId, payload) {
        return axios.put(`api/registration/${regId}`, payload);
    },
    floorDetailsIndex(regId) {
        return axios.get(`api/registration/${regId}/floor-detail`);
    },

    // Floor Details Extra
    // Get
    getFloorDetailExtra(regId) {
        return axios.get(`api/registration/${regId}/floor-detail-extra`);
    },
    // Save
    saveFloorDetailExtra(regId, payload) {
        return axios.post(`api/registration/${regId}/floor-detail-extra`, payload);
    },
    // Delete single extra detail row
    deleteFloorDetailExtraSingle(regId, rowId) {
        return axios.delete(`api/registration/${regId}/floor-detail-extra/${rowId}`);
    }
});