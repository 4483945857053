<template>
  <v-card>
    <v-toolbar dense dark flat color="primary">
      <v-toolbar-title>Application Status</v-toolbar-title>
    </v-toolbar>
    <v-card-text v-if="!isAppStatusLoaded">
      <v-skeleton-loader type="paragraph, sentences">
      </v-skeleton-loader>
    </v-card-text>
    <v-card-text v-else>
      <strong>{{ log }}</strong>
      <br />
      <strong> Phase: </strong> {{ regData.phase }}
      <br />
      <v-dialog v-model="dialog" max-width="800px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark x-small v-bind="attrs" v-on="on">
            View all Logs
          </v-btn>
        </template>
        <v-card class="elevation-2 my-2">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>
              {{ regData.houseowner_name }}'s Application Log
            </v-toolbar-title>
          </v-toolbar>
          <v-simple-table class="sober elevation-2">
            <thead>
              <tr class="">
                <th class="text-left">Date</th>
                <th class="text-left">Phase</th>
                <th class="text-left">Remarks</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(log, index) in all_logs" :key="index" class="">
                <td class="">{{ log.date_np }}</td>
                <td>{{ log.reg_phase }}</td>
                <td>{{ log.remarks }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ApplicationStatusCard",
  data() {
    return {
      all_logs: "",
      log: "",
      dialog: false,
      isAppStatusLoaded: false,
      regData: []
    };
  },
  created() {
    this.getRegDataFromStore();
  },
  mounted() {
    this.getLogData();
    this.$root.$on("refreshLogs", () => {
      this.getLogData();
    })
  },
  methods: {
    getLogData() {
      const regLogUrl = `api/registration/${this.$route.params.id}/log`;
      this.$axios.get(regLogUrl).then((response) => {
        this.all_logs = response.data.data;
        this.log = response.data.data[0].remarks;
        this.isAppStatusLoaded = true;
      });
    },
    getRegDataFromStore() {
      this.regData = this.$store.getters.registrationData;
    }
  }
};
</script>

