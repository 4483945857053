import ax from "axios";
import axiosRetry from "axios-retry";

const httpAxios = ax.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 60000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': `Bearer ${localStorage.getItem("token")}`
  }
});
axiosRetry(httpAxios, {
  retries: 3,
  // retry on Network Error & 5xx responses
  retryCondition: axiosRetry.isRetryableError
}); // retry on 500 error from server. file_put_contents error appearing occasionally


export const VueAxios = httpAxios;

export default {
  install(Vue) {
    Vue.prototype.$axios = httpAxios;
  }
};

/* eslint-enable */
