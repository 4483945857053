<template>
    <div>
        <v-toolbar color="primary" dark flat>
            <v-toolbar-title> Actions </v-toolbar-title>
        </v-toolbar>
        <v-expansion-panels :value="openConsultancyTransferPanel">
            <v-expansion-panel v-if="this.$store.getters.forwardRevertActionAuthData">
                <v-expansion-panel-header>
                    Transfer File To Another Desk
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <registration-action v-if="this.$store.getters.forwardRevertActionAuthData" :regPhaseId="this.regData.phase_id" :regDeskId="this.regData.desk_user_role_id"
                        :regCreatedByConsultancy="this.regData.is_created_by_consultancy"></registration-action>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="this.regData.desk_user_role_id == 8 && false"> <!-- add edit authorization false-->
                <v-expansion-panel-header>Request for Edit/Change</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <request-file-edit></request-file-edit>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="this.user_role_id == 2">
                <v-expansion-panel-header>Consultancy Transfer</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <consultancy-transfer @closePanelAndSnack="closeConsultancyTransferPanel"></consultancy-transfer>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-snackbar v-model="snackbar.show" :color="snackbar.color" class="snack-z-top">
            {{ snackbar.message }}
        </v-snackbar>
    </div>
</template>

<script>
import RegistrationAction from "@/modules/applicationlevel/subComponents/RegistrationAction.vue";
import RequestFileEdit from "./RequestFileEdit.vue";
import ConsultancyTransfer from "./ConsultancyTransfer.vue";

export default {
    name: "FileActionList",
    components: {
        RegistrationAction,
        RequestFileEdit,
        ConsultancyTransfer
    },
    data() {
        return {
            regData: {},
            user_role_id: localStorage.getItem("auth_user_role_id"),
            openConsultancyTransferPanel: [],
            snackbar: {
                show: false,
                color: "success",
                message: ""
            }
        };
    },
    mounted() {
        this.regData = this.$store.getters.registrationData;
        // console.log("desk id " + this.regData.desk_user_role_id);
    },
    methods: {
        closeConsultancyTransferPanel(event) {
            this.openConsultancyTransferPanel = [];
            this.snackbar.show = true;
            this.snackbar.message = event;
        }
    }

};
</script>