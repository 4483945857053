<template>
  <div>
    <v-container class="border">
      <!-- Byelaws standard part One Start -->
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
      <v-form v-model="byelawsFormValid" class="px-4 py-4">
        <v-row>
          <v-col>
            <span class="text-h5 text-decoration-underline"> मापदण्ड सम्बन्धी राय</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="font-weight-bold"> सडकको नाम </v-col>
          <v-col cols="4" v-if="roadList.length">
            <v-select :items="roadList" solo :item-text="roadNameStartEnd" item-value="id" v-model="selectedRoad"></v-select>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="aminComment.road_name" label="Name of the Road" solo> </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4"></v-col>
          <v-col cols="2"><span class="font-weight-bold">मापदण्ड अनुसार</span></v-col>
          <v-col cols="2"><span class="font-weight-bold">नक्सा अनुसार</span></v-col>
          <v-col cols="4"><span class="font-weight-bold">कैफियत</span></v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="m-auto font-weight-bold">बाटाेको सडक अधिकार क्षेत्र (ROW) <span class="required-star">*</span></v-col>
          <v-col cols="2"
            ><v-text-field v-model="aminComment.road_right_area" :label="unitLabel" type="number" suffix="meter" solo> </v-text-field
          ></v-col>
          <v-col cols="2">
            <v-text-field v-model="aminComment.map_road_right_area" :rules="validateROW" type="number" suffix="meter" solo> </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-textarea v-model="aminComment.road_right_area_remarks" solo auto-grow rows="1" label="remarks"></v-textarea>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-col cols="4" class="m-auto font-weight-bold">सेट ब्याक <span class="required-star">*</span></v-col>
          <v-col cols="2"
            ><v-text-field v-model="aminComment.guideline_setback" :label="unitLabel" type="number" suffix="ft" solo> </v-text-field
          ></v-col>
          <v-col cols="2"
            ><v-text-field v-model="aminComment.map_setback" :rules="validateSetback" :label="unitLabel" suffix="ft" type="number" solo>
            </v-text-field
          ></v-col>
          <v-col cols="4">
            <v-textarea v-model="aminComment.guideline_setback_remarks" solo auto-grow rows="1" label="remarks"></v-textarea>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-col cols="4" class="m-auto font-weight-bold">जि.सि.आर (%)<span class="required-star">*</span></v-col>
          <v-col cols="2"><v-text-field v-model="aminComment.guideline_gcr" label="" suffix="%" type="number" solo> </v-text-field></v-col>
          <v-col cols="2"
            ><v-text-field v-model="aminComment.map_gcr" :rules="validateGCR" label="" suffix="%" type="number" solo> </v-text-field
          ></v-col>
          <v-col cols="4">
            <v-textarea v-model="aminComment.guideline_gcr_remarks" solo auto-grow rows="1" label="remarks"></v-textarea>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-col cols="4" class="m-auto font-weight-bold">Floor Area Ratio (FAR)<span class="required-star">*</span></v-col>
          <v-col cols="2"><v-text-field v-model="aminComment.floor_area_ratio" label="" type="number" solo> </v-text-field></v-col>
          <v-col cols="2"
            ><v-text-field v-model="aminComment.map_floor_area_ratio" :rules="validateFAR" label="" type="number" solo> </v-text-field
          ></v-col>
          <v-col cols="4">
            <v-textarea v-model="aminComment.floor_area_ratio_remarks" solo auto-grow rows="1" label="remarks"></v-textarea>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-col cols="4" class="m-auto font-weight-bold">हाई टेन्सन लाईनदेखिको दुरी (If Applicable)</v-col>
          <v-col cols="2"
            ><v-text-field v-model="aminComment.electric_line_distance" suffix="meter" type="number" solo> </v-text-field
          ></v-col>
          <v-col cols="2"
            ><v-text-field v-model="aminComment.map_electric_line_distance" suffix="meter" type="number" solo> </v-text-field
          ></v-col>
          <v-col cols="4">
            <v-textarea v-model="aminComment.electric_line_distance_remarks" solo auto-grow rows="1" label="remarks"></v-textarea>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-col cols="4" class="m-auto font-weight-bold">नदी किनारदेखिको दुरी (If Applicable)</v-col>
          <v-col cols="2"><v-text-field v-model="aminComment.river_distance" type="number" suffix="meter" solo> </v-text-field></v-col>
          <v-col cols="2"><v-text-field v-model="aminComment.map_river_distance" type="number" suffix="meter" solo> </v-text-field></v-col>
          <v-col cols="4">
            <v-textarea v-model="aminComment.river_distance_remarks" solo auto-grow rows="1" label="remarks"></v-textarea>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-col cols="4" class="m-auto"> <span class="font-weight-bold">अमिनको राय</span></v-col>
          <v-col cols="8">
            <v-textarea
              solo
              v-if="this.userRoleId == 3"
              :readonly="this.userRoleId != 3"
              v-model="aminComment.documents_remarks"
              auto-grow
              rows="6"
            ></v-textarea>
            <span v-else> {{ aminComment.documents_remarks }}</span>
          </v-col>
        </v-row>
        <v-row class="pl-2 pb-2">
          <v-btn v-if="isAddEditAuthorized" v-on:click="saveAminComment" :disabled="!byelawsFormValid" color="primary" class="px-6"
            >Save</v-btn
          >
        </v-row>
        <v-snackbar v-model="snackbar.show" :color="snackbar.color">
          {{ successfulMessage }}
        </v-snackbar>
      </v-form>
      <!-- Byelaws standard part One End -->
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "ByelawsStandardsForm",
  props: {
    isAddEditAuthorized: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validateGCR: [(v) => parseFloat(v) <= parseFloat(this.aminComment.guideline_gcr) || "GCR greater than standard value"],
      validateROW: [(v) => parseFloat(v) >= parseFloat(this.aminComment.road_right_area) || "ROW smaller than standard value"],
      validateSetback: [(v) => parseFloat(v) >= parseFloat(this.aminComment.guideline_setback) || "Setback greater than standard value"],
      validateFAR: [(v) => parseFloat(v) <= parseFloat(this.aminComment.floor_area_ratio) || "FAR greater than standard value"],
      validateElectricLine: [
        (v) => parseFloat(v) >= parseFloat(this.aminComment.electric_line_distance) || "Electric line distance smaller than standard value",
      ],
      validateRiverDistance: [
        (v) => parseFloat(v) >= parseFloat(this.aminComment.river_distance) || "River distance smaller than standard value",
      ],
      userRoleId: localStorage.getItem("auth_user_role_id"),
      byelawsFormValid: false,
      aminComment: {
        id: 0,
        is_documents_submitted: true,
        documents_remarks: "",
        land_length_unit_type_id: "0",
        land_east_length_unit_type_id: "",
        land_east_length: "",
        land_east_sub_length: "",
        land_west_length_unit_type_id: "",
        land_west_length: "",
        land_west_sub_length: "",
        land_north_length_unit_type_id: "",
        land_north_length: "",
        land_north_sub_length: "",
        land_south_length_unit_type_id: "",
        land_south_length: "",
        land_south_sub_length: "",

        road_right_area: "",
        map_road_right_area: "",
        road_right_sub_area: "",
        guideline_setback: "",
        guideline_sub_setback: "",
        map_setback: "",
        map_sub_setback: "",
        guideline_gcr: "",
        map_gcr: "",
        electric_line_distance: "",
        map_electric_line_distance: "",
        river_distance: "",
        map_river_distance: "",
        floor_area_ratio: "",
        map_floor_area_ratio: "",
        nbc_guideline_remarks: "",

        road_right_area_remarks: "",
        // map_road_right_area_remarks: "",
        guideline_setback_remarks: "",
        // map_setback_remarks: "",
        guideline_gcr_remarks: "",
        // map_gcr_remarks: "",
        electric_line_distance_remarks: "",
        river_distance_remarks: "",
        floor_area_ratio_remarks: "",

        road_name: "",
      },
      roadList: [],
      unitLabel: "",
      selectedRoad: "",
      byelawsStandard: {},
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      isLoading: false,
    };
  },
  watch: {
    selectedRoad: function (id) {
      for (let i = 0; i < this.roadList.length; i++) {
        if (this.roadList[i].id == this.selectedRoad) {
          this.aminComment.road_right_area = this.roadList[i].road_row_master.row_min;
          this.aminComment.road_name = this.roadList[i].road_name;
        }
      }
    },
  },
  created() {
    this.getByelawsStandard();
    this.getRoadList();
  },
  mounted() {
    this.loadAminComment();
  },
  methods: {
    roadNameStartEnd: (item) => item.road_name + " : " + item.road_start + "देखि " + item.road_end + "सम्म",
    getRoadList() {
      const roadUrl = `api/${this.$route.params.regId}/roads`;
      this.$axios
        .get(roadUrl)
        .then((response) => {
          this.roadList = response.data.data;
          // if roadList isEmpty
        })
        .catch((error) => console.log(error));
    },
    getByelawsStandard() {
      const byelawsUrl = `api/${this.$route.params.regId}/byelaws-standard`;
      this.$axios
        .get(byelawsUrl)
        .then((response) => {
          this.byelawsStandard = response.data.data[0];
          if (!_.isEmpty(this.byelawsStandard)) {
            this.aminComment.guideline_gcr = this.byelawsStandard.gcr_max;
            this.aminComment.floor_area_ratio = this.byelawsStandard.far_max;
          } else {
            console.log("byelaws standard empty");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadAminComment() {
      const url = `api/registration/${this.$route.params.regId}/amin-comment`;
      this.$axios
        .get(url)
        .then((response) => {
          this.aminComment = response.data.data;
          //   this.edit(this.aminComment.id); // Why ???
          this.isLoading = false; //stop the loading spinner
        })
        .catch((error) => {
          console.log(`api error:${error}`);
          this.isLoading = false; //stop the loading spinner
        });
    },
    saveAminComment() {
      this.isLoading = true;
      if (this.aminComment.id === null || this.aminComment.id === 0 || this.aminComment.id === undefined) {
        const url = `/api/registration/${this.$route.params.regId}/amin-comment`;
        this.$axios
          .post(url, this.aminComment)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.isLoading = false;
              this.aminComment = response.data.data;
              this.snackbar.show = true;
              this.successfulMessage = "Successful.";
            }
          })
          .catch((error) => {
            console.log(`api error:${error}`);
          });
      } else {
        const eurl = `/api/registration/${this.$route.params.regId}/amin-comment/${this.aminComment.id}`;
        this.$axios
          .put(eurl, this.aminComment)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.isLoading = false;
              this.snackbar.show = true;
              this.successfulMessage = "Data Updated successfully";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
.required-star {
  color: red !important;
}
</style>