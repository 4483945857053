const regexList = {
    engName: {
        pattern: '^[a-zA-Z \.]+$',
        response: 'This field must only contain english characters',
    },
    nepName: {
        pattern: '^[\u0900-\u0966 \u0970-\u097F \.]+$',
        response: 'This field must only contain nepali unicode characters',
    },
    phone: {
        pattern: /^\+?(977|९७७)? ?[0-9०-९]{9,15}$/,
        // Pattern front and back has / and its fking working
        response: 'Not a valid nepali phone number',
    },
    email: {
        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        response: 'Not a valid email',
    },
    citizenshipNo: {
        pattern: '^[0-9०-९-/ ]+$',
        response: 'Not a valid citizenship number',
    },
    nepEngNo: {
        pattern: '^[0-9०-९ ]+$',
        response: 'Only numbers',
    },
    nepDate: {
        pattern: '^२[०-९]{3}[-/][०१]?[०-९][-/][०-३]?[०-९]$',
        response: 'Not a valid nepali date',
    },
    area: {
        pattern: '^[0-9०-९]*[.]?[0-9०-९]+?$',
        response: 'Input not valid area',
    },
    length: {
        pattern: '^[0-9०-९ ]*$',
        response: 'Input not valid length',
    },
    nepAddress: {
        pattern: '^[\u0900-\u0966 \u0970-\u097F \.-]+$',
        response: 'Not a valid nepali address',
    },
    engNumber: {
        pattern: '^[0-9]*$',
        response: 'Please Type Nepali number',
    },
    nepNumber: {
        pattern: '^[०-९]*$',
        response: 'Please Type English Number',
    }
}

export default regexList;