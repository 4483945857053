<style scoped>
.m-auto {
  margin: auto;
}
.myn-1 {
  margin: -1rem 0;
}
.subtitle {
  font-size: 20px;
  padding-left: 0.75rem;
  text-decoration: underline;
}

.align-right {
  text-align: right;
}
</style>

<template>
  <v-container>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <v-card class="mb-12">
      <v-row
        ><v-col cols="1">
          <v-btn
            class="primary ma-1 d-flex justify-center"
            :to="{
              name: 'application_tab',
              params: { id: this.$route.params.regId },
            }"
            >Back</v-btn
          ></v-col
        >
        <v-spacer></v-spacer
      ></v-row>

      <v-toolbar color="primary" dark flat>
        <v-toolbar-title> अमिनको राय </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="5"> आवश्यक कागजातहरु नपुग भएको भए सो को विवरण </v-col>
            <v-col cols="7">
              <v-textarea v-model="aminComment.documents_remarks" label="" solo>
              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <span class="subtitle">
              <strong> अमिनको राय/ प्रतिबेदन </strong></span
            >
          </v-row>
          <v-row>
            <v-col cols="2" class="m-auto"> जग्गाको लम्बाई: </v-col>
            <!-- <v-col cols="2" class="m-auto">
              <v-select
                v-model="aminComment.land_length_unit_type_id"
                :items="unitType"
                label="Unit"
                item-value="id"
                item-text="unit_type"
                :key="id"
                dense
              ></v-select>
            </v-col> -->
            <v-col>
              <v-row>
                <v-col cols="2" class="m-auto align-right">पूर्व</v-col>
                <v-col cols="2"
                  ><v-text-field
                    v-model="aminComment.land_east_length"
                    :label="unitLabel"
                    solo
                  >
                  </v-text-field
                ></v-col>
                <v-col cols="2"
                  ><v-text-field
                    v-model="aminComment.land_east_sub_length"
                    v-if="(aminComment.land_length_unit_type_id = '1')"
                    label="inch"
                    solo
                  >
                  </v-text-field
                ></v-col>
                <v-col cols="2" class="m-auto align-right">पश्चिम</v-col>
                <v-col cols="2"
                  ><v-text-field
                    v-model="aminComment.land_west_length"
                    :label="unitLabel"
                    solo
                  >
                  </v-text-field
                ></v-col>
                <v-col cols="2"
                  ><v-text-field
                    v-model="aminComment.land_west_sub_length"
                    label="inch"
                    solo
                  >
                  </v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="m-auto align-right">उत्तर</v-col>
                <v-col cols="2"
                  ><v-text-field
                    v-model="land_north_length"
                    :label="unitLabel"
                    solo
                  >
                  </v-text-field
                ></v-col>
                <v-col cols="2"
                  ><v-text-field
                    v-model="land_north_sub_length"
                    label="inch"
                    solo
                  >
                  </v-text-field
                ></v-col>
                <v-col cols="2" class="m-auto align-right">दक्षिण</v-col>
                <v-col cols="2"
                  ><v-text-field
                    v-model="land_south_length"
                    :label="unitLabel"
                    solo
                  >
                  </v-text-field
                ></v-col>
                <v-col cols="2"
                  ><v-text-field
                    v-model="land_south_sub_length"
                    label="inch"
                    solo
                  >
                  </v-text-field
                ></v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=""></v-col>
          </v-row>
          <v-row>
            <span class="subtitle">
              <strong> मापदण्ड सम्बन्धी राय </strong></span
            >
          </v-row>
          <!-- <v-row>
            <v-col cols="2">
              <v-select
                :items="unitType"
                label="Unit"
                item-value="id"
                item-text="unit_type"
                :key="id"
                dense
              ></v-select>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="5" class="m-auto"
              >मापदण्ड अनुसार बाटाेको सडक अधिकार क्षेत्र</v-col
            >
            <v-col cols="2"
              ><v-text-field
                v-model="aminComment.road_right_area"
                :label="unitLabel"
                solo
              >
              </v-text-field
            ></v-col>
            <v-col cols="2"
              ><v-text-field
                v-model="aminComment.road_right_sub_area"
                label="inch"
                solo
              >
              </v-text-field
            ></v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row>
            <v-col cols="5" class="m-auto">मापदण्ड अनुसार सेट ब्याक</v-col>
            <v-col cols="2"
              ><v-text-field
                v-model="aminComment.guideline_setback"
                :label="unitLabel"
                solo
              >
              </v-text-field
            ></v-col>
            <v-col cols="2"
              ><v-text-field
                v-model="aminComment.guideline_sub_setback"
                label="inch"
                solo
              >
              </v-text-field
            ></v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row>
            <v-col cols="5" class="m-auto">नक्सा अनुसार सेट ब्याक</v-col>
            <v-col cols="2"
              ><v-text-field
                v-model="aminComment.map_setback"
                :label="unitLabel"
                solo
              >
              </v-text-field
            ></v-col>
            <v-col cols="2"
              ><v-text-field
                v-model="aminComment.map_sub_setback"
                label="inch"
                solo
              >
              </v-text-field
            ></v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row>
            <v-col cols="5" class="m-auto">मापदण्ड अनुसार जि.सि.आर (%)</v-col>
            <v-col cols="4"
              ><v-text-field v-model="aminComment.guideline_gcr" label="" solo>
              </v-text-field
            ></v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row>
            <v-col cols="5" class="m-auto">नक्सा अनुसार जि.सि.आर (%)</v-col>
            <v-col cols="4"
              ><v-text-field v-model="aminComment.map_gcr" label="" solo>
              </v-text-field
            ></v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row>
            <v-col cols="5" class="m-auto">बिद्य‍‌ुत लाईन</v-col>
            <v-col cols="4"
              ><v-text-field
                v-model="aminComment.electric_line_distance"
                label=""
                solo
              >
              </v-text-field
            ></v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row>
            <v-col cols="5" class="m-auto">नदी किनार</v-col>
            <v-col cols="4"
              ><v-text-field v-model="aminComment.river_distance" label="" solo>
              </v-text-field
            ></v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row>
            <v-col cols="5" class="m-auto">Floor Area Ratio (FAR)</v-col>
            <v-col cols="4"
              ><v-text-field
                v-model="aminComment.floor_area_ratio"
                label=""
                solo
              >
              </v-text-field
            ></v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
        <v-container>
          <v-row>
            <span class="subtitle">
              <strong> राष्‍ट्रिय भवन संहिता सम्बन्धी राय </strong></span
            >
          </v-row>
          <v-row>
            <v-col cols="5">
              <v-row>
                <v-col>
                  नक्सामा उल्लेखित सम्पूर्ण विवरण राष्‍ट्रिय भवन संहिता बमाेजिम
                  छ ।
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-radio-group v-model="radios" mandatory>
                    <v-radio label="छ" value="1"></v-radio>
                    <v-radio label="छैन" value="0"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="7">
              <v-textarea
                v-model="aminComment.nbc_guideline_remarks"
                label=""
                solo
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-btn primary v-on:click="save" class="primary">Save</v-btn>
        </v-container>
      </v-form>
      <v-snackbar
        v-model="snackbar.show"
        :bottom="y === 'bottom'"
        :color="snackbar.color"
        :left="x === 'left'"
        :multi-line="mode === 'multi-line'"
        :right="x === 'right'"
        :timeout="3000"
        :top="y === 'top'"
        :vertical="mode === 'vertical'"
      >
        {{ successfulMessage }}
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "AminComment",
  props: {
    // nextStep: {
    //   type: Function
    // },
    // registrationId: {
    //   type: [String, Number]
    // },
    // id: {
    //   type: [String, Number]
    // },
    isPostAuthorized: {
      type: Boolean,
    },
  },
  data() {
    return {
      unitLabel: "feet",
      isFormSubmitting: true,
      date: "",
      isValid: true,
      isLoading: true,
      userRoleId: localStorage.getItem("auth_user_role_id"),
      unitType: [
        { id: 1, unit_type_short: "ft", unit_type: "Feet" },
        { id: 2, unit_type_short: "m", unit_type: "Meter" },
      ],
      aminComment: {
        id: 0,
        is_documents_submitted: true,
        documents_remarks: "",
        land_length_unit_type_id: "0",
        land_east_length_unit_type_id: "",
        land_east_length: "",
        land_east_sub_length: "",
        land_west_length_unit_type_id: "",
        land_west_length: "",
        land_west_sub_length: "",
        land_north_length_unit_type_id: "",
        land_north_length: "",
        land_north_sub_length: "",
        land_south_length_unit_type_id: "",
        land_south_length: "",
        land_south_sub_length: "",

        road_right_area: "",
        road_right_sub_area: "",
        guideline_setback: "",
        guideline_sub_setback: "",
        map_setback: "",
        map_sub_setback: "",
        guideline_gcr: "",
        map_gcr: "",
        electric_line_distance: "",
        river_distance: "",
        floor_area_ratio: "",
        nbc_guideline_remarks: "",

        road_right_area_remarks: "",
        map_right_area_remarks: "",
        guideline_setback_remarks: "",
        map_setback_remarks: "",
        guideline_gcr_remarks: "",
        map_gcr_remarks: "",
        electric_distance_remarks: "",
        river_distance_remarks: "",
        floor_area_ratio_remarks: "",
      },
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
    };
  },
  mounted() {
    // this.unitTypeCall();
    const url = `api/registration/${this.$route.params.regId}/amin-comment`;
    this.$axios
      .get(url)
      .then((response) => {
        // debugger;
        this.aminComment = response.data.data;
        this.edit(this.aminComment[0].id);
        this.isLoading = false; //stop the loading spinner
        // this.newaminComment.clear();
      })
      .catch((error) => {
        console.log(`api error:${error}`);
        this.isLoading = false; //stop the loading spinner
      });
  },
  computed: {
    // changeUnitLabel(){
    // }
  },
  methods: {
    // unitTypeCall() {
    //   const unitTypeUrl = "api/building-category";
    //   this.$axios
    //     .get(unitTypeUrl)
    //     .then(response => {
    //       this.unitType = response.data;
    //       // console.log(this.buildingCategory);
    //       // debugger;
    //     })
    //     .catch(error => {
    //       console.log(`api error:${error}`);
    //     });
    // },
    save() {
      this.isFormSubmitting = false;
      if (
        this.aminComment.id === null ||
        this.aminComment.id === 0 ||
        this.aminComment.id === undefined
      ) {
        const url = `/api/registration/${this.$route.params.regId}/amin-comment`;
        this.$axios
          .post(url, this.aminComment)
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.isFormSubmitting = true;
              this.aminComment = response.data.data;
              this.snackbar.show = true;
              this.successfulMessage = "Successful.";
            }
          })
          .catch((error) => {
            // alert(2);
            console.log(`api error:${error}`);
          });
      } else {
        const eurl = `/api/registration/${this.$route.params.regId}/amin-comment/${this.aminComment.id}`;
        this.$axios
          .put(eurl, this.aminComment)
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.isFormSubmitting = true;
              this.snackbar.show = true;
              this.successfulMessage = "Data Updated successfully";
            }
            // else {
            //     this.$router.push({
            //         name: 'tabbed',
            //     });
            // }
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>
