<style scoped>
.registrationtable td,
.registrationtable th {
  border: 1px solid #ddd;
  padding: 8px;
}

.document td,
.document th {
  padding: 8px;
}

.technicaltable td,
.technicaltable th {
  border: 1px;
  padding: 8px;
}

.forward_button {
  background-color: #008cba;
}

.row {
  height: 100% !important;
}
</style>
<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <NibedanBibaran class="order-1" :regId="this.$route.params.id" :isAddEditAuthorized="isAddEditAuthorized"
          :regConstructionId="this.constructionTypeId" />
        <PrabhidhikBibaran v-if="true" class="order-2" :regId="this.$route.params.id"
          :isAddEditAuthorized="isAddEditAuthorized" />
        <!-- New -->
        <NoticeList class="order-3" phaseId="1" :regId="this.$route.params.id" />


      </v-col>
      <v-col cols="12" md="6">
        <!-- <v-card class="documents registrationDocuments elevation-2 my-2"> -->
        <UploadDocumentList phaseId="1" :submitStatus="submitStatus" />
        <RegistrationAction v-if="this.$store.getters.forwardRevertActionAuthData" :regPhaseId="this.results.phase_id"
          :regDeskId="this.results.desk_user_role_id"
          :regCreatedByConsultancy="this.results.is_created_by_consultancy" />
        <!-- </v-card> -->
      </v-col>
    </v-row>
    <!--  -->
  </div>
</template>
<script>
import NibedanBibaran from "../subComponents/NibedanBibaran.vue";
import PrabhidhikBibaran from "../subComponents/PrabhidhikBibaran.vue";
import RegistrationAction from "../subComponents/RegistrationAction.vue";
import { engNumConvert } from "../../mixins/numericToUnicode.js";
//   import Application from '../../../modules/tabbedElements/components/Application.vue';
import api from "@/api/api";

import NoticeList from "./NoticeList.vue";
import UploadDocumentList from "./UploadDocumentList.vue";

export default {
  name: "Application",
  mixins: [engNumConvert],
  props: {
    nextStep: {
      type: Function,
    },
    id: {
      type: [String, Number],
    },
    currentApplicationPhase: {
      type: [String, Number],
    },
    currentApplicationDesk: {
      type: [String, Number],
    },
    submitStatus: {
      type: Object,
    },
  },
  components: {
    NibedanBibaran,
    PrabhidhikBibaran,
    RegistrationAction,
    NoticeList,
    UploadDocumentList
  },
  data() {
    return {
      opened: 0,
      user_role_id: localStorage.getItem("auth_user_role_id"),
      results: [],
      isAddEditAuthorized: false,
      isDocumentAddEditAuthorized: false,
      documents: [],
      constructionTypeId: null,
      phaseId: null,
    };
  },
  mounted() {
  },
  created() {
    this.getNibedanAuthorization();
  },

  methods: {
    getNibedanAuthorization() {
      this.$store
        .dispatch("getNibedanBibaranAuth", parseInt(this.$route.params.id))
        .then(() => {
          this.isAddEditAuthorized = this.$store.getters.nibedanBibaranAuthData;
        });
    },
  },
};
</script>
<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}

.registrationLevel {
  height: 100% !important;
}
</style>
