<template>
  <div class="bg-image">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <div class="login-card">
      <div class="login-box">
        <h3>Log In</h3>
        <v-form>
          <v-text-field v-model="username" name="username" label="Username" prepend-icon="mdi-account" v-on:keydown.enter="authenticate">
          </v-text-field>
          <v-text-field v-model="password" :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'" name="password" label="Password" :type="showpassword ? 'text' : 'password'"
            prepend-icon="mdi-lock" v-on:keydown.enter="authenticate" @click:append="showpassword = !showpassword">
          </v-text-field>
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0">
              <v-checkbox v-model="rememberMe" label="Remember me" class=""></v-checkbox>
            </v-col>
            <v-col class="my-auto mr-n16">
              <router-link :to="{ name: 'forgot_password' }" class="">Forgot Password?</router-link>
            </v-col>
          </v-row>
        </v-form>
        <v-row align="center" justify="center">
          <v-col><v-btn color="primary" v-on:click="authenticate" class="float-left">Login</v-btn></v-col>
        </v-row>
        <v-row>
          <v-col class="ma-0 pa-0">
            <router-link color="error" dark x-small :to="{ name: 'houseownerstatus' }" class="ghardhani-link mx-4">
              घरधनीको स्थिति हेर्नुहोस्
            </router-link>
          </v-col>
          <v-col class="ma-0 pa-0">
            <!-- <router-link
              color="error"
              dark
              x-small
              :to="{ name: 'consultancy_profile' }"
              class=""
            >
              Sign Up for Consultancy
            </router-link> -->
          </v-col>
        </v-row>
        <br />
        <div class="error-alert mx-auto mt-10 ml-n8" v-if="message">
          <v-alert type="error" border="left">{{ message }}</v-alert>
        </div>
      </div>
      <div class="info-box light-blue--text text--lighten-5">
        <img :src="require('@/assets/images/logo-light.png')" width="150px" class="logo-image" />
        <h2 class="font-heading">Welcome to GharNaksha</h2>
        <h3 class="font-heading text-center">{{ munName }} Municipality</h3>
      </div>
    </div>
    <!-- <div class="error-alert mx-auto" v-if="message">
      <v-alert type="error" border="left">{{ message }}</v-alert>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "Login",
  components: {},
  data() {
    return {
      showpassword: false,
      username: "",
      password: "",
      token: "",
      message: "",
      isLoading: false,
      networkStatus: "Unable to connect to the internet!",
      internetNotConnected: false,
      munName: "",
      rememberMe: true,
    };
  },
  watch: {
    rememberMe: function (newValue) {
      this.$store.commit("setRememberMe", newValue);
      console.log(`remember me: ${newValue}`);
      // console.log("nice nice nice");
    },
  },
  created() {
    this.getMunicipalityDetail();
  },
  methods: {
    // getMunicipalityDetail() {
    //   const apiUrl = "api/no-login/municipality";
    //   this.$axios
    //     .get(apiUrl)
    //     .then((response) => {
    //       this.munName = response.data.data[0].name.split(" ")[0];
    //     })
    //     .catch((error) => {
    //       console.log(error); // Find the error data not this haii bruh;
    //     });
    // },
    async getMunicipalityDetail() {
      let munTemp = this.$store.getters.getMunicipality;
      try {

        if (!Object.keys(munTemp).length) {
          await this.$store.dispatch("fetchMunicipality")
        }
        // this.municipality = this.$store.getters.getMunicipality;
        this.munName = this.$store.getters.getMunicipality.name.split(" ")[0];
      } catch (error) {
        console.log("Error while retrieving municipality");
        console.log(error);
      }
    },
    authenticate() {
      this.isLoading = true;
      const url = "api/login";
      this.$axios
        .post(url, {
          username: this.username,
          password: this.password,
          remember: true,
        })
        .then((response) => {
          this.isLoading = false;
          if (response.status === 200) {
            this.token = response.data.access_token;
            window.localStorage.clear();
            window.localStorage.setItem("auth_user_role_id", response.data.data.user_role_id);
            window.localStorage.setItem("token", response.data.access_token);
            this.$axios.defaults.headers = {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            };
            this.getUserProfile();
            this.$router.push({ name: "home" });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.message = error.response.data.message;
        });
    },
    getUserProfile() {
      const apiUrl = "api/profile";
      this.$axios
        .get(apiUrl)
        .then((response) => {
          if (response.status === 200) {
            this.user = response.data;
            window.localStorage.setItem("user", this.user);
            this.message = response.message;
          } else if (response.status === 401) {
            this.isLoading = false;
            this.message = $axios.interceptor;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.bg-color {
  background-color: #eef5f9b0;
}

.bg-image {
  background-image: url("~@/assets/images/hexagon_bg.jpg");
  background-position: center;
  max-width: 100%;
  height: 100vh;
  object-fit: cover;
  /* filter: blur(1px); */
}

.login-card {
  height: 400px;
  width: 800px;
  margin: 40px auto auto auto;
  display: flex;
  background-color: aliceblue;
}

.info-box {
  /* background-color: #1dbba4; */
  background-color: #1f465c;
  height: inherit;
  width: 400px;
  padding: 0 40px 40px 30px;
}

.login-box {
  height: inherit;
  width: 400px;
  padding: 40px 25px 40px 40px;
}

.logo-image {
  display: block;
  margin: 0 auto;
}

.ghardhani-link {
  margin: 20px auto auto 580px;
  font-size: 15px;
  text-decoration: underline !important;
}

.error-alert {
  width: 400px;
  margin-left: 275px;
}
</style>
