<template>
    <div>
        <v-textarea solo v-model="request.message" label="Type your request message here and registration will change it"></v-textarea>
        
        <v-btn onclick="sendRequest" class="primary">Apply for Request</v-btn>
    </div>
</template>
<script>
export default {
    name: "RequestFileEdit",
    data() {
        return {
            request: {
                message: ""
            }
        };
    },
    methods: {
        sendRequest() {
            const requestUrl = "";

        }
    }
};
</script>