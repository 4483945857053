<style scoped>
.login-link {
  font-size: 15px;
  text-decoration: underline !important;
}
</style>

<template>
  <v-container style="max-width: 600px">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="false"
    ></loading>
    <v-alert color="blue-grey" dark v-model="showAlert">{{
      alertMessage
    }}</v-alert>
    <v-card v-if="!otpSuccess" class="mx-auto my-12" max-width="600">
      <v-card-title>Forgot Password</v-card-title>
      <v-card-subtitle
        >Please enter the associated email address below so that we can send One
        Time Password (OTP) to your mail</v-card-subtitle
      >
      <v-card-text>
        <v-form>
          <v-text-field label="email" v-model="otpEmail" @keyup.enter="sendOTPEmail()"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn class="primary" @click="sendOTPEmail()">Send OTP</v-btn>
        <router-link
          color="error"
          dark
          x-small
          :to="{ name: 'login' }"
          class="login-link mr-0 ml-auto"
        >
          Go to login
        </router-link>
      </v-card-actions>
    </v-card>
    <v-card v-if="otpSuccess">
      <v-card-title>Change Password</v-card-title>
      <v-card-subtitle></v-card-subtitle>
      <v-card-text>
        <v-form v-model="valid">
          <v-text-field
            label="Email"
            type="text"
            autocomplete="new-password"
            v-model="newPasswordData.email"
          ></v-text-field>
          <v-text-field
            label="One Time Password"
            hint="Enter the code you received in your email"
            persistent-hint
            v-model="newPasswordData.otp"
          ></v-text-field>
          <v-text-field
            v-model="newPasswordData.new_password"
            label="Password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            autocomplete="new-password"
          ></v-text-field>
          <v-text-field
            v-model="rePassword"
            label="Confirm new password"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, passwordConfirmationRule]"
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn class="primary" @click="changePassword()">Change Password</v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{ successfulMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "forgotpassword",
  data() {
    return {
      isLoading: false,
      showAlert: false,
      alertMessage: "",
      otpSuccess: false,
      show1: false,
      show2: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      otpEmail: "",
      newPasswordData: {
        email: "",
        otp: "",
        new_password: "",
      },
      rePassword: "",
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.successfulMessage,
        color: "",
      },
    };
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.newPasswordData.new_password === this.rePassword ||
        "Password must match";
    },
  },
  methods: {
    sendOTPEmail() {
      const eUrl = `api/forgot-password-otp`;
      this.isLoading = true;
      this.$axios
        .post(eUrl, { email: this.otpEmail })
        .then((response) => {
          if (response.status == 200 || response.status == 201) {
            this.successfulMessage = response.data.message;
            this.isLoading = false;
            this.snackbar.color = "success";
            this.snackbar.show = true;
            this.otpSuccess = true;
            this.newPasswordData.email = this.otpEmail;
            this.showAlert = true;
            this.alertMessage =
              "Please check mail in spam folder if it's not in your inbox";
          } else {
            this.isLoading = false;
            this.successfulMessage = response.data.message;
            this.snackbar.color = "error";
            this.snackbar.show = true;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.successfulMessage = error.response.data.message;
          this.snackbar.show = true;
          this.snackbar.color = "error";
          console.log(`api error:${error}`);
        });
    },

    changePassword() {
      const cUrl = `api/forgot-password-reset`;
      this.isLoading = true;
      this.$axios
        .post(cUrl, this.newPasswordData)
        .then((response) => {
          if (response.status == 200 || response.status == 201) {
            this.successfulMessage = response.data.message;
            this.isLoading = false;
            this.snackbar.color = "success";
            this.snackbar.show = true;
            this.alertMessage =
              "Password reset completed. You may login now with new password.";
            this.otpSuccess = false;
            // this.showAlert = false;
            // hold maa rakhne for 5 seconds
            // this.$router.push({ name: "login" });
          } else {
            this.successfulMessage = response.data.message;
            this.isLoading = false;
            this.snackbar.color = "error";
            this.snackbar.show = true;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.successfulMessage = error.response.data.message;
          this.snackbar.show = true;
          this.snackbar.color = "error";
          console.log(`api error:${error}`);
        });
    },
  },
};
</script>