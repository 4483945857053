<template>
  <v-card class="prabhidhikBibaran elevation-12 my-2">
    <v-expansion-panels :value="opened">
      <v-expansion-panel v-for="(item, i) in 1" :key="i">
        <v-expansion-panel-header>
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title> प्राविधिक विवरणहरु </v-toolbar-title>
          </v-toolbar>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table class="sober elevation-2">
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Actions</th>
                <th class="text-left">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in prabhidhiBibarans"
                :key="item.name"
                :class="item.status == 'Completed' ? 'green lighten-5' : 'red lighten-5'"
              >
                <td class="">{{ item.name }}</td>
                <td class="">
                  <router-link
                    :to="{
                      name: item.nameEng,
                      params: { regId: regId },
                    }"
                  >
                    <v-btn small class="blue elevation-0 white--text"> {{ actionButtonText }}</v-btn></router-link
                  >
                </td>
                <td>{{ item.status }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
export default {
  name: "PrabhidhikBibaran",
  props: {
    regId: {
      type: [Number, String],
    },
    isAddEditAuthorized: {
      type: Boolean,
    },
    isRCC: {
      type: Boolean,
    },
  },
  computed: {
    actionButtonText() {
      if (this.isAddEditAuthorized) {
        return "Add/Edit";
      }
      return "View";
    },
  },
  data() {
    return {
      opened: 0,
      user_role_id: localStorage.getItem("auth_user_role_id"),
      isRccValid: 1,
      // submitStatus: {},
      registrationStatus: [],
      contractorFormAddEdit: false,
      reg: {
        construction_type_id: 1,
      },
      prabhidhiBibarans: [
        {
          id: 1,
          name: "Byelaws",
          nameEng: "byelaws",
          action: "Add/Edit",
          status: "",
          sn: 1,
        },
        {
          id: 2,
          name: "RCC Checklist",
          nameEng: "rcc",
          action: "Add/Edit",
          status: "",
          sn: 2,
        },
        {
          id: 3,
          name: "Revenue",
          nameEng: "revenue",
          action: "Add/Edit",
          status: "",
          sn: 3,
        },
      ],
    };
  },
  created() {
    this.getChecklistType();
    // this.getSubmitStatus();
  },
  mounted() {
    const statusUrl = `api/registration/${this.regId}/status`;
    const temp = this.prabhidhiBibarans;

    this.$axios
      .get(statusUrl)
      .then((response) => {
        this.registrationStatus = response.data.data;

        temp.forEach((item) => {
          this.prabhidhiBibarans[item.sn - 1].status = this.registrationStatus[item.nameEng];
        });
      })
      .catch((error) => {
        console.log(`api error:${error}`);
        temp.forEach((item) => {
          this.prabhidhiBibarans[item.sn - 1].status = "Not Completed";
        });
      });
  },
  methods: {
    getChecklistType() {
      this.$store.dispatch("getApplication", parseInt(this.regId)).then(() => {
        const applicationData = this.$store.getters.applicationData;
        const building_structure_id = applicationData.building_structure_id;
        const building_category_id = applicationData.building_category_id;
        if (building_structure_id == 1 && building_category_id == 3) {
          this.isRccValid = 1;
        } else if (building_structure_id != 1) {
          this.isRccValid = 2;
        } else {
          this.isRccValid = 0;
        }
      });
    },
    // Add this function in parent component instead later sarkar
    // getSubmitStatus() {
    //   const url = `api/registration/${this.$route.params.id}/status`;
    //   this.$axios
    //     .get(url)
    //     .then((response) => {
    //       this.submitStatus = response.data.data;
    //     })
    //     .catch((error) => {
    //       console.log(`submit api error: ${error}`);
    //     });
    // },
  },
};
</script>

<style scoped>
.white--text {
  color: white;
}
</style>
