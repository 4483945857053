<template>
  <v-dialog v-model="dialog" max-width="100%">
    <template v-slot:activator="{ on, attrs }">
      <a v-bind="attrs" v-on="on">
        <v-btn :small="isSmall" class="primary elevation-0 white--text">{{ displayText }}</v-btn>
      </a>
      <!-- <v-btn v-bind="attrs" v-on="on" style="width:40px;">{{ displayText }}</v-btn> -->
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-col>Document</v-col>
          <v-col class="text-right">
            <v-btn icon dark @click="dialog.value = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-toolbar>
        <v-card-text>
          <inline-doc :src="srcUrl"></inline-doc>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: {
    src: {
      type: String
    },
    displayText: {
      type: String,
      default: "Print"
    },
    isSmall: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    srcUrl(){
      // this.srcUrl = '';
      // this.emptySrcUrl();
      let tempUrl = '';
      tempUrl = this.src;
      return tempUrl;
    }
  },
  methods: {
    // emptySrcUrl(){
    //   this.srcUrl = '';
    // }
    closeAndRefresh(){
      this.dialog = false;
      this.srcUrl = '';
      // this.srcUrl = this.src;
    } 
    // refreshIframe(){
    //   const tempUrl = this.src;
    //   this.src = '';
    //   this.src = tempUrl;
    // }
  }
};
</script>

<style scoped>
.white--text {
  color: white;
}
</style>
