<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-row no-gutters>
        <v-col cols="12" sm="6" md="11">
          <v-card class="primary pa-2" outlined>
            <v-toolbar-title>Welcome Back, {{ userFullName }}</v-toolbar-title>
          </v-card>
        </v-col>
        <v-col cols="6" md="1">
          <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on }">
              <v-btn icon>
                <div v-if="userRoleId == '1'">Admin</div>
                <v-avatar class="mx-4 avatar-color" dark v-on="on">
                  {{ usernameInitials }}
                </v-avatar>
              </v-btn>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-avatar class="avatar-color text-white" size="40">
                    {{ usernameInitials }}
                  </v-list-item-avatar>

                  <v-list-item-content style="max-width: 400px; font-weight: 500; font-size: 3pt">
                    <v-list-item-title>{{ userFullName }}</v-list-item-title>
                    <v-list-item-subtitle>Username: {{ user.username }}</v-list-item-subtitle>
                    <v-list-item-subtitle>Email: {{ user.email }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="check_ward != null">Ward No: {{ user.ward_no }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="user.substitute_authority_status != null">Assigned Desk:
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="user.substitute_authority_status != null">{{
                      user.substitute_authority_status
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-list>
                <v-list-item-group>
                  <v-list-item @click="logout">
                    <v-list-item-icon>
                      <v-icon>mdi-logout-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Sign Out</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-navigation-drawer app>
      <aside class="side-nav white">
        <v-card>
          <v-list>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item>
                <router-link :to="{ name: 'home' }">
                  <img :src="require('@/assets/images/logo-light-2.png')" width="55px" /> GharNaksha
                </router-link>
              </v-list-item>
              <v-list-item v-for="(item, i) in adminLinks" :key="i" :class="item.textColor + '--text'" :to="{ name: item.link }">
                <v-list-item-icon>
                  <v-icon v-text="item.icon" :class="item.textColor + '--text'"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </aside>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="primary--text text--accent-4">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Authority Transfer</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer color="primary lighten-1" padless app>
      <v-row justify="center" no-gutters>
        <v-col class="primary py-4 text-center white--text" cols="12">
          {{ new Date().getFullYear() }} — <strong>GharNaksha Nepal</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      usernameInitials: "",
      items: [
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
        },
      ],
      drawer: false,
      adminLinks: [
        {
          title: "Home",
          link: "admin_main",
          icon: "mdi-account",
        },
        {
          title: "Users and Desk",
          link: "admin_user_and_desk",
          icon: "mdi-account",
        },
        {
          title: "Authority Transfer",
          link: "authority_transfer",
          icon: "mdi-account-arrow-right",
        },
        // { title: "Checklist", link: "admin_checklist" },
        // { title: "Document Master", link: "admin_document_master" },
        // { title: "Forward Revert Matrix", link: "admin_frmatrix" },
        // { title: "Site Configuration", link: "admin_site_configuration" },
        // { title: "Notice List", link: "notice_list" },
        {
          title: "Ward Assignment",
          link: "admin_ward_assignment",
          icon: "mdi-flag",
        },
        {
          title: "Registration Files",
          link: "admin_file_action",
          icon: "mdi-file"
        },
        {
          title: "GharNaksha Home",
          link: "home",
          icon: "mdi-home",
        },
        {
          title: "Role Transfer",
          link: "home",
          icon: "mdi-transit-transfer",
        },
        // {
        //   title: "Log Out",
        //   link: "home",
        //   textColor: "red",
        //   icon: "mdi-logout",
        // },
      ],
    };
  },

  mounted() {
    const currentuser_url = "api/profile";
    this.$axios
      .get(currentuser_url)
      .then((response) => {
        // debugger;
        if (response.status === 200) {
          this.user = response.data;
          this.usernameInitials = `${this.user.first_name.charAt(0).toUpperCase() +
            this.user.last_name.charAt(0).toUpperCase()
            }`;
          this.userFullName = `${this.user.first_name} ${this.user.last_name}`;
          this.userRoleId = this.user.user_role_id;
          this.check_ward = this.user.ward_no;
        }
      })
      .catch((error) => {
        console.log("Navbar error");
        console.error(error);
        if (error.status !== 500) {
          window.localStorage.clear();
          this.$router.push("/login");
        }
      });
  },
};
</script>

<style scoped>
.avatar-color {
  background-color: #00d955 !important;
}

.top-nav {
  /* background-color: red; */
  height: 4rem;
}

.side-nav {
  /* display: flex; */
  /* flex-direction: column; */
  /* background-color: white; */
  height: 100vh;
  /* Change */
  width: 16rem;
}

.flex-container {
  display: flex;
  flex-direction: row;
}

.main-content {
  padding: 4rem;
  min-width: 50%;
}
</style>
