import Vue from "vue";
import Router from "vue-router";

import userrouter from "./userrouter"
import adminrouter from "./adminrouter"

Vue.use(Router)

var allRoutes = []
allRoutes = allRoutes.concat(adminrouter, userrouter)

const routes = allRoutes

export default new Router({
  routes,
  // mode: 'history'
})