import Vue from "vue";
import "@mdi/font/css/materialdesignicons.css";
import Vuetify from "vuetify/lib";
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi" // default - only for display purposes
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        // primary: "#0277BD",
        primary: {
          base: "#0277BD",
          darken1: "#00589b",
          lighten1: "#4EA0D1",
          lighten2: "#b3e6fc",
          lighten3: "#e1f5fe",
        },
        secondary: {
          base: "#e6e6e6",
          darken1: "#666666",
        },
        tertiary: "#C57133",
        accent: {
          base: "#0BBD9E",
          darken1: "#A89C40",
          lighten1: "#0FFCD5"
        },
        background: "#FFFFFF",
        surface: "#F5F5F5",
        error: "#B00020",
        success: "#4CAF50",
        warning: "#FFC107",
        sober: "#ffffff",
        coltext: "#212121",
        info: "#FFB74D",
      },
      dark: {
        // primary: "#3f51b5",
        primary: "#0277BD", // Blue Lighten 3 #90CAF9 light-blue darken-3 #0277BD
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#b71c1c",
        // sober: "#E0E0E0",
        sober: "#eff1f1",
        info: "#FFB74D" //Orange Lighten 2
        // color: #eff1f1
      }
    }
  }
});


// primary: colors.blue.darken2,
//         secondary: "#b0bec5",
//         accent: "#704000",
//         error: "#b71c1c",
//         sober: "#ffffff",
//         info: "#FFB74D" //Orange Lighten 2
//         // color: #eff1f1