<template>
  <b-container fluid>
    <!-- <v-card class="mb-12" color="grey lighten-1" height="auto"> -->
    <!-- <v-app id="inspire"> -->
    <!-- <div> -->
    <v-btn class="primary" :to="{ name: 'home' }">Back</v-btn>
    <!-- </div> -->
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></loading>

    <v-dialog v-model="dialog" persistent max-width="800px" v-if="userRoleId == 1">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" class="ma-4" dark v-bind="attrs" v-on="on" @click="clearAllData()">
          Create New Technician
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">New Technician Form</span>
        </v-card-title>
        <v-card-text>
          <v-form v-if="userRoleId == 1" ref="form" v-model="valid" style="margin-bottom: 1rem;">
            <b-container>
              <v-row align="center">
                <!-- <v-col cols="6">
                  <v-radio-group v-model="newTechnician.technician_master_id" :mandatory="true">
                    <v-radio label="Contractor" v-bind:value="1"></v-radio>
                    <v-radio label="Electrician" v-bind:value="2"></v-radio>
                  </v-radio-group>
                </v-col> -->
                <v-col cols="2">Type<span class="required-star">*</span></v-col>
                <v-col cols="4">
                  <v-select
                    v-model="newTechnician.technician_master_id"
                    :items="technicianList"
                    item-text="title_en"
                    item-value="id"
                    hide-details="auto"
                    solo
                  ></v-select>
                </v-col>
                <v-col cols="2"> Name<span class="required-star">*</span> </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newTechnician.name"
                    hide-details="auto"
                    :rules="[required('Name')]"
                    label=""
                    required
                    height="10"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="2"> Phone<span class="required-star">*</span> </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newTechnician.phone"
                    label=""
                    :rules="[required('Name')]"
                    class="custom-text-field"
                    hide-details="auto"
                    ref="dateField"
                    required
                    solo
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  License Number
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newTechnician.license_no"
                    label=""
                    height="10"
                    hide-details="auto"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="2">
                  Address
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newTechnician.address"
                    hide-details="auto"
                    solo
                  ></v-text-field>
                </v-col>
                <v-col cols="2">Registration Date</v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="newTechnician.registration_date"
                    hide-details="auto"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
            </b-container>
          </v-form>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            v-if="userRoleId == 1"
            primary
            v-on:click="saveTechnician"
            :disabled="!valid"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <br /> -->
    <v-snackbar
      v-model="snackbar.show"
      :bottom="y === 'bottom'"
      :color="snackbar.color"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="3000"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      {{ successfulMessage }}
    </v-snackbar>
    <!-- <v-toolbar color="primary mt-2 pa-0" dark dense flat>
        <v-toolbar-title>
          List
        </v-toolbar-title>
      </v-toolbar> -->
    <div>
      <v-simple-table class="sober" width="100%">
        <template v-slot:default>
          <thead>
            <tr class="registrationtable">
              <th class="text-left registrationtable">
                S. No.
              </th>
              <th class="text-left registrationtable">
                Name
              </th>
              <th class="text-left registrationtable">
                License No
              </th>
              <th class="text-left registrationtable">Location</th>
              <th class="text-left registrationtable">Phone No</th>
              <th class="text-left registrationtable" v-if="userRoleId == 1">Action</th>
            </tr>
          </thead>
          <tbody>
            <!-- Search Function for technician pending -->
            <!-- <tr>
              <td></td>
              <td>
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="searchKeys.name"
                  @keyup="searchTechnician"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="searchKeys.registration_number"
                  @keyup="searchTechnician"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="searchKeys.location"
                  @keyup="searchTechnician"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="searchKeys.registration_date_np"
                  @keyup="searchTechnician"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="searchKeys.phone_no"
                  @keyup="searchTechnician"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="searchKeys.email"
                  @keyup="searchTechnician"
                ></v-text-field>
              </td>
            </tr> -->
            <tr
              class="registrationtable"
              v-for="technicianList in technician"
              :key="technicianList.id"
            >
              <td class="registrationtable">{{ technicianList.sno }}</td>
              <td class="registrationtable">{{ technicianList.name }}</td>
              <td class="registrationtable">
                {{ technicianList.license_no }}
              </td>
              <td class="registrationtable">{{ technicianList.address }}</td>
              <td class="registrationtable">{{ technicianList.phone }}</td>
              <td v-if="userRoleId == 1">
                <v-btn class="primary" small v-on:click="editTechnician(technicianList.id)"
                  >Edit</v-btn
                >
                &nbsp;
                <!-- <v-btn
                    small
                    class="primary"
                    v-on:click="deleterecordTechnician(technicianList.id)"
                    >Delete</v-btn
                  > -->
                <custom-dialog
                  :click-dialog="deleterecordTechnician"
                  :regId="technicianList.id"
                  click-type="delete"
                  >Delete</custom-dialog
                >
                <br />
                <br />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <!-- </v-app> -->
    <!-- </v-card> -->
  </b-container>
</template>
<script>
import { VueAxios } from "@/plugins/axios";
// import * as nepaliDatePicker from "../../../node_modules/nepali-date-picker/dist/jquery.nepaliDatePicker.min.js"; /*eslint-disable-line*/
// import "../../../node_modules/nepali-date-picker/dist/nepaliDatePicker.min.css";

export default {
  name: "technician",

  data() {
    return {
      required(propertyType) {
        return v => !!v || `${propertyType}`;
      },
      isLoading: false,
      userRoleId: localStorage.getItem("auth_user_role_id"),
      valid: false,
      date: "",
      technician: [],
      //   districts: [],
      //   newTechnician: {
      //     id: 0,
      //     reg_id: this.registrationId,
      //     is_consulting_firm: "",
      //     registration_number: "",
      //     name: "",
      //     location: "",
      //     district_id: "",
      //     registration_date_np: "",
      //     registration_date: "",
      //     phone_no: "",
      //     email: "",
      //     person_name: "",
      //     person_nec_no: ""
      //   },
      newTechnician: {
        id: 0,
        // technician_master_id: { id: 1, title_en: "Contractor" },
        technician_master_id: 1,
        name: "",
        phone: "",
        license_no: "",
        address: "",
        registration_date: ""
      },
      //   searchKeys: {
      //     name: "",
      //     registration_number: "",
      //     location: "",
      //     registration_date_np: "",
      //     phone_no: "",
      //     email: ""
      //   },
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success"
      },
      technicianList: [],
      dialog: false
    };
  },
  created() {
    this.getTechnicianMaster();
  },
  mounted() {
    this.getTechnician();
  },
  methods: {
    getTechnicianMaster() {
      const techUrl = `api/technician-master`;
      this.$axios
        .get(techUrl)
        .then(response => {
          this.technicianList = response.data.data;
        })
        .catch(error => {
          console.log(`technician list retrieve error: ${error}`);
        });
    },
    getTechnician() {
      const TechnicianListUrl = "api/technician";
      this.isLoading = true;
      VueAxios.get(TechnicianListUrl)
        .then(response => {
          // debugger;
          this.technician = response.data.data;
          this.technician = this.technician.map((d, index) => ({ ...d, sno: index + 1 }));
          // this.newTechnician.clear();
          this.isLoading = false;
        })
        .catch(error => {
          console.log(`api error:${error}`);
          this.isLoading = false;
        });
    },
    clearAllData() {
      this.$refs.form.reset();
    },
    saveTechnician() {
      this.isLoading = true;
      //   debugger;
      if (
        this.newTechnician.id === "null" ||
        this.newTechnician.id === 0 ||
        this.newTechnician.id === undefined
      ) {
        const saveUrl = "/api/technician";
        VueAxios.post(saveUrl, this.newTechnician)
          .then(response => {
            this.isLoading = false;
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.technician.push(response.data.data);
              this.snackbar.show = true;
              this.successfulMessage = "Successful.";
              this.$refs.form.reset();
              this.dialog = false;
            }
          })
          .catch(error => {
            // alert(2);
            console.log(`api error:${error}`);
          });
      } else {
        const eurl = `/api/technician/${this.newTechnician.id}`;
        VueAxios.put(
          eurl,
          this.newTechnician,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`
            },
            _method: "patch"
          },
          {}
        )
          .then(response => {
            this.isLoading = false;
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.successfulMessage = "Data updated successfully";
              this.$refs.form.reset();
              this.newTechnician.id = 0; // reset the edit id
              this.dialog = false;
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    editTechnician(id) {
      //   debugger;
      //    const url = `api/registration/${this.$route.params.regId}/technician/${this.$route.params.id}`;
      const url = `api/technician/${id}`;
      VueAxios.get(url).then(response => {
        // debugger;
        this.newTechnician = response.data.data;
        this.dialog = true;
        console.log(response.data.data);
      });
    },
    deleterecordTechnician(id) {
      const deleteurl = `api/technician/${id}`;
      this.isLoading = true;
      VueAxios.delete(deleteurl)
        .then(response => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.isLoading = false;
            this.successfulMessage = "Data deleted successfully";
            const i = this.technician.map(item => item.id).indexOf(id); // find index of your object
            this.technician.splice(i, 1);
          }
          console.log(response);
        })
        .catch(error => {
          this.isLoading = false;
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
.required-star {
  color: red !important;
}
</style>
