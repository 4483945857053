<template>
  <div>
    <v-card class="mb-12" height="auto">
      <v-app id="inspire">
        <v-form>
          <v-container height="auto">
            <v-row height="auto">
              <v-col md="6">
                <v-card>
                  <center>
                    <h3>निवेदन विवरणहरु</h3>
                  </center>
                  <br />
                  <v-simple-table class="sober">
                    <template v-slot:default>
                      <thead>
                        <tr class="registrationtable">
                          <th class="text-left registrationtable">Name</th>
                          <th class="text-left registrationtable">Actions</th>
                          <th class="text-left registrationtable">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in desserts" :key="item.name" class="registrationtable">
                          <td class="registrationtable">{{ item.name }}</td>
                          <td class="registrationtable">
                            <router-link to="/tabbed">{{ item.action }} </router-link>
                          </td>
                          <td>{{ item.status }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-app>
    </v-card>
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "ApplicationTab",
  data() {
    return {
      results: []
    };
  },
  mounted() {
    // console.log(window.localStorage.getItem("token"));

    this.getRegistrationData();
  },
  methods: {
    async getRegistrationData() {
      let regDataTemp = this.$store.getters.registrationData;
      try {
        if (!Object.keys(regDataTemp).length || regDataTemp.id != this.$route.params.id) {
          await this.$store.dispatch("getRegistration", this.$route.params.id)
        }
        this.results = this.$store.getters.registrationData;
      }
      catch (error) {
        console.log(error);
      }
    },
  },

};
</script>
