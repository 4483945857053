<style scoped>
.registrationtable td,
.registrationtable th {
  border: 1px solid #ddd;
  padding: 8px;
  width: 1000px;
}
.revenue td,
.revenue th {
  width: 500px;
}
.document td,
.document th {
  padding: 8px;
}
.technicaltable td,
.technicaltable th {
  border: 1px;
  padding: 8px;
}

div.options > label {
  display: block;
  margin: 0 0 0 -10px;
  padding: 0 0 20px 0;
  height: 20px;
  width: 150px;
}

div.options > label > img {
  display: inline-block;
  padding: 0px;
  height: 30px;
  width: 30px;
  background: none;
}

div.options > label > input:checked + img {
  background: url(http://cdn1.iconfinder.com/data/icons/onebit/PNG/onebit_34.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30px 30px;
}

.symno-col {
  width: 1% !important;
}

.attr-col {
  width: 55% !important;
}

.remarks-col {
  width: 13% !important;
}

input {
  margin-left: 60px !important;
}
</style>
<template>
  <div>
    <v-card class="mb-12" height="auto">
      <v-app id="inspire">
        <v-form class="sober">
          <v-container height="auto">
            <v-row height="auto">
              <v-col md="12">
                <v-btn
                  class="primary ma-1"
                  :to="{
                    name: 'application_tab',
                    params: { id: this.$route.params.regId },
                  }"
                  >Back</v-btn
                >
                <v-card>
                  <center>
                    <p>
                      <strong> भवन निर्माण अनुमति कार्यको नक्सा र प्रतिवेदन जाँच गर्ने चेकलिस्ट </strong>
                    </p>
                    <p>“ग” वर्गका पिलरवाला भवनहरुको नक्सा जाँच गर्ने चेकलिस्ट</p>
                  </center>
                  <br />
                  <v-simple-table class="registrationtable buildingcode sober">
                    <template v-slot:default>
                      <thead>
                        <tr class="registrationtable">
                          <th class="text-left registrationtable symno-col" colspan="1">S.N</th>
                          <th class="text-left registrationtable attr-col">Attributes</th>
                          <th class="text-left registrationtable remarks-col">Specific Conditions</th>
                          <th class="text-left registrationtable">Check</th>
                          <th class="text-left registrationtable">Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">1</td>
                          <td class="registrationtable" rowspan="2">
                            <strong>Overall Dimension Ratio: </strong> <br />
                            a. Overall length of the building shall not exceed 3 times width of the building. <br />
                            b. Overall height of the building shall not exceed 3 times width of the building.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.overall_dim_ratio_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.overall_dim_ratio_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item21">
                                <input type="radio" value="0" v-model="rcc.overall_dim_ratio_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>

                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">2</td>
                          <td class="registrationtable" rowspan="2">
                            <strong>No. of Storey:</strong><br />
                            The maximum height of the structure is 11 m or 3 storeys, whichever is less, from the level of lateral restraint. Within
                            an 11 m height, there may be an additional storey of smaller plan area. The area of this shall not exceed 25 % of the area
                            of a typical floor.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.wings_length_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.wings_length_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item21">
                                <input type="radio" value="0" v-model="rcc.wings_length_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">3</td>
                          <td class="registrationtable" rowspan="2">
                            <strong>Column Layout:</strong> <br />
                            All columns shall be in grid line
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.setbacks_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.setbacks_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.setbacks_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">4</td>
                          <td class="registrationtable" rowspan="2">
                            <strong>Span :</strong> Column to column span must be less than or equal to 4.5m and greater than or equal to 2.1m. Total
                            outer dimension of the building must be less than or equal to 25m both side
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.redundancy_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.redundancy_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.redundancy_compliance" />
                                <img />
                              </label>
                            </div>
                            <!-- <v-checkbox
                              v-bind:value="true"
                              v-model="rcc.redundancy_compliance"
                              class="ma-0 pa-0"
                            ></v-checkbox> -->
                          </td>
                        </tr>

                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">5</td>
                          <td class="registrationtable" rowspan="2">
                            <strong>Redundancy:</strong> Number of bays in both directions shall not be more than 6 and less than 2.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.beam_continuity_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.beam_continuity_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.beam_continuity_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">6</td>
                          <td class="registrationtable" rowspan="2"><strong>Soft Storey:</strong> Buildings shall not have a soft storey.</td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.vertical_discontinuity_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.vertical_discontinuity_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.vertical_discontinuity_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>

                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">7</td>
                          <td class="registrationtable" rowspan="2"> <strong>Beam Discontinuity:  </strong> There shall not be discontinuity in beams in any frame. <br></td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.column_layout_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.column_layout_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.column_layout_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>

                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">8</td>
                          <td class="registrationtable" rowspan="2">
                            <strong>Vertical Discontinuity:</strong> All vertical elements in the lateral force resisting system shall be continuous
                            from the roof to foundation.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.cantilever_projection_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.cantilever_projection_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.cantilever_projection_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">9</td>
                          <td class="registrationtable" rowspan="2">
                            <strong>Short Column Effect: </strong> Continue the column stirrups as specified as special confining reinforcements, if
                            column stands adjacent to a window or such opening to take care of the short-column effect. 25mm gap between landing beam
                            and column shall be given.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.short_column_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.short_column_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.short_column_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">10</td>
                          <td class="registrationtable" rowspan="2">
                            <strong>Construction Materials:</strong><br />
                            <strong>Concrete: </strong> The concrete to be used in footings, columns, beams and slabs, etc., shall have a minimum
                            crushing strength of 20 N/mm² (Nominal mix, 1:1.5:3) at 28 days for a 150 mm cube. <br />
                            <strong>Mortar:</strong> Minimum 1:4 cement-sand mortar for half-brick thick wall and 1:6 cement-sand mortar for one-brick
                            thick. <br />
                            <strong>Steel:</strong> High-strength deformed bars with fy = 415 N/mm² or high strength deformed steel bars, produced by
                            the thermo mechanical treatment process, of grades Fe 500, having elongation more than 14.5 percent shall be used for the
                            reinforcement. <br />
                            <strong>Bricks: </strong> Minimum crushing strength 3.5 N/mm2 <br />
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.torsion_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.torsion_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.torsion_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">11</td>
                          <td class="registrationtable" rowspan="2">
                            <strong> Size of column: </strong> The minimum dimension of the member shall not be less 300 mm. <br />
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.adjacent_building_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.adjacent_building_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.adjacent_building_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">12</td>
                          <td class="registrationtable" rowspan="2">
                            <strong>12 Min. no. of bars in column: </strong> There shall be at least 8 numbers of bars in a column.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.soft_storey_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.soft_storey_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.soft_storey_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>

                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">13</td>
                          <td class="registrationtable" rowspan="2">
                            <strong>13 Column bar splices: </strong> <br> a. Lap splices shall be located only in the central half of the member length with sufficient
                            development length (60 x dia.) as per grade of concrete. <br> b. Not more than 50% of the bars should be spliced at a section.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.beam_size_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.beam_size_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.beam_size_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">14</td>
                          <td class="registrationtable" rowspan="2">
                            <strong>Stirrups in Column: </strong>The minimum diameter of the stirrups shall be 8mm for fy415 and 7mm for fy500 (TMT).
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.strong_column_weak_beam_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.strong_column_weak_beam_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.strong_column_weak_beam_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">15</td>
                          <td class="registrationtable" rowspan="2">
                            <strong>Column Stirrup spacing: </strong> <br>
                          a.	Provide stirrups at the spacing of 100 mm c/c at the ends of columns for 600 mm length as the special confining reinforcement. <br>
                          b.	At remaining portion keep spacing as 150 mm. <br>
                          c.	At the lapping region provide 100 mm c/c spacing <br>
                          d.	Continue the column stirrups as specified as special confining reinforcements, if column stands adjacent to a window or such opening to take care of the short-column effect.

                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.column_shear_stress_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.column_shear_stress_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.column_shear_stress_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">16</td>
                          <td class="registrationtable" rowspan="2"> <strong> Beam Size: </strong> <br>
                            a.	Width of the beam shall be 230 or 250 mm according to size of brick available <br>
                            b.	Depth : 355 mm overall depth including slab.
</td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.concrete_grade_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.concrete_grade_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.concrete_grade_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">17</td>
                          <td class="registrationtable" rowspan="2">
                            <strong>Beam Column Joint:</strong> At an exterior joint, top and bottom longitudinal bars of beams shall be provided with horizontal length beyond the inner face of the column, equal to horizontal development length (ldh) terminating in a standard hook of length 12db.The hook shall be located within the confined core of a column or of a boundary element with hook bent into the joint.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.column_size_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.column_size_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.column_size_compliance" />
                                <img />
                              </label>
                            </div>
                            <!-- <v-checkbox
                              v-bind:value="true"
                              v-model="rcc.column_size_compliance"
                              class="ma-0 pa-0"
                            ></v-checkbox> -->
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">18</td>
                          <td class="registrationtable" rowspan="2">
                            <strong>Beam Bar Splices: </strong><br>
                              a.	The lap length shall not be less than the 60 times diameter of longitudinal bar. <br> 
                              b.	Lap splices shall not be located (a) within a beam column joint, (b) within a distance of 2 times effective depth of beam from beam column joint face, and (d) within a quarter length of the member where flexural yielding may occur under the effect of earthquake forces.  <br>
                              c.	Not more than 50% of the bars shall be spliced at one section

                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.min_column_bars_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.min_column_bars_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.min_column_bars_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <!-- <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">19</td>
                          <td class="registrationtable" rowspan="2">
                            Stirrups in Column: The minimum diameter of the stirrups shall be 8 mm for fy500 (TMT).
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.column_stirrups_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.column_stirrups_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.column_stirrups_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr> -->
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">19</td>
                          <td class="registrationtable" rowspan="2">
                            <strong>	Beam Stirrup spacing: </strong> <br>
                              a.	The spacing of stirrups over a length of 2 times the depth of beam (d) at either end of a beam shall not exceed 100mm. <br>
                              b.	The spacing of stirrups at the region of lapping shall not exceed 100 mm <br>
                              c.	The spacing of stirrups at remaining portion shall not exceed 150 mm							

                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.column_bar_splices_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.column_bar_splices_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.column_bar_splices_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">20</td>
                          <td class="registrationtable" rowspan="2">
                            <strong>	Joint reinforcement: </strong> Beam­ column joints shall have stirrup spaced at 100mm			
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.column_stirrups_spacing_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.column_stirrups_spacing_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.column_stirrups_spacing_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">21</td>
                          <td class="registrationtable" rowspan="2">
                           <strong>Stirrup: </strong> The stirrups shall be anchored into the member cores 75mm with hooks of 135 degree
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.beam_column_joint_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.beam_column_joint_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.beam_column_joint_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">22</td>
                          <td class="registrationtable" rowspan="2">
                            <strong>Slab: </strong> Maximum panel area should be less than 13.5sq.m. Minimum thickness of slab is 125mm and 8mm bars @ 150mm c/c bothways.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.beam_bar_splices_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.beam_bar_splices_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.beam_bar_splices_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">23</td>
                          <td class="registrationtable" rowspan="2">
                           <strong> Staircase intersection: </strong> Provision of extra tension bar at intersections. The bars at intersections shall be up to development length past the intersections. 
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.beam_stirrups_spacing_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.beam_stirrups_spacing_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.beam_stirrups_spacing_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">24</td>
                          <td class="registrationtable" rowspan="2">
                           <strong> Pad Foundation: </strong> <br>
                              a.	Size of pad foundation depends on type of soil condition and location of column. <br>
                              b.	The foundation shall be at a uniform level.

                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.joint_reinforcement_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.joint_reinforcement_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.joint_reinforcement_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">25</td>
                          <td class="registrationtable" rowspan="2">
                           <strong> Foundation Beam: </strong> When the foundation lies in soft soil foundation beam sized 9”x9” shall be provided. 4­12mm dia. Longitudinal bars shall be provided with 8mm stirrups @ 6”c­c only in the case when foundation lies in soft soil.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.stirrup_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.stirrup_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.stirrup_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">26</td>
                          <td class="registrationtable" rowspan="2">
                            <strong>Cover of Concrete: </strong>
                              Foundation=50 mm, Column=40 mm, Beam=25 mm, Slab=15 mm

                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.wall_connection_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="">
                            <v-text-field v-model="rcc.wall_connection_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.wall_connection_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <!-- <tr class="registrationtable">
                          <td colspan="5" class="registrationtable">
                            <strong>BUILDING BYELAWS</strong>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">28</td>
                          <td class="registrationtable" rowspan="2">Front width</td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.front_width_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.front_width_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.front_width_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">29</td>
                          <td class="registrationtable" rowspan="2">Floor Area Ratio(FAR)</td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.floor_area_ratio_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.floor_area_ratio_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.floor_area_ratio_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">30</td>
                          <td class="registrationtable" rowspan="2">Front Setback</td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.front_setback_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.front_setback_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.front_setback_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">31</td>
                          <td class="registrationtable" rowspan="2">Ground Coverage Ratio(GCR)</td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.ground_coverage_ratio_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field v-model="rcc.ground_coverage_ratio_remarks" solo class="ma-0 pa-0"></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.ground_coverage_ratio_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr> -->
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-simple-table class="sober">
                    <thead>
                      <tr>
                        <th class="text-left">
                          <strong>Observations</strong>
                        </th>
                        <th class=""><strong>Recommendation</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong><v-text-field v-model="rcc.observation" solo class="ma-0 pa-0"></v-text-field></strong>
                        </td>
                        <td>
                          <strong><v-text-field v-model="rcc.recommendation" solo class="ma-0 pa-0"></v-text-field></strong>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <br />
                </v-card>
                <v-snackbar
                  v-model="snackbar.show"
                  :bottom="y === 'bottom'"
                  :color="snackbar.color"
                  :left="x === 'left'"
                  :multi-line="mode === 'multi-line'"
                  :right="x === 'right'"
                  :timeout="3000"
                  :top="y === 'top'"
                  :vertical="mode === 'vertical'"
                >
                  {{ successfulMessage }}
                </v-snackbar>
                <v-btn v-if="userRoleId == 4 || userRoleId == 8" primary v-on:click="save" class="primary ma-3">Submit</v-btn>
                <v-btn
                  class="primary ma-1"
                  :to="{
                    name: 'application_tab',
                    params: { id: this.$route.params.regId },
                  }"
                  >Back</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-app>
    </v-card>
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "Rcc",
  props: {
    nextStep: {
      type: Function,
    },
    isPostAuthorized: {
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: false,
      userRoleId: localStorage.getItem("auth_user_role_id"),
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      rcc: {
        id: 0,
        regId: this.$route.params.regId,
        overall_dim_ratio_compliance: "",
        overall_dim_ratio_remarks: "",
        wings_length_compliance: "",
        wings_length_remarks: "",
        setbacks_compliance: "",
        setbacks_remarks: "",
        // one missing field here in prototype
        column_layout_compliance: "",
        column_layout_remarks: "",
        beam_continuity_compliance: "",
        beam_continuity_remarks: "",
        vertical_discontinuity_compliance: "",
        vertical_discontinuity_remarks: "",
        cantilever_projection_compliance: "",
        cantilever_projection_remarks: "",
        short_column_compliance: "",
        short_column_remarks: "",
        torsion_compliance: "",
        torsion_remarks: "",
        adjacent_building_compliance: "",
        adjacent_building_remarks: "",
        soft_storey_compliance: "",
        soft_storey_remarks: "",
        beam_size_compliance: "",
        beam_size_remarks: "",
        strong_column_weak_beam_compliance: "",
        strong_column_weak_beam_remarks: "",
        column_shear_stress_compliance: "",
        column_shear_stress_remarks: "",
        concrete_grade_compliance: "",
        concrete_grade_remarks: "",
        column_size_compliance: "",
        column_size_remarks: "",
        min_column_bars_compliance: "",
        min_column_bars_remarks: "",
        column_stirrups_compliance: "",
        column_stirrups_remarks: "",
        column_bar_splices_compliance: "",
        column_bar_splices_remarks: "",
        column_stirrups_spacing_compliance: "",
        column_stirrups_spacing_remarks: "",
        beam_column_joint_compliance: "",
        beam_column_joint_remarks: "",
        beam_bar_splices_compliance: "",
        beam_bar_splices_remarks: "",
        beam_stirrups_spacing_compliance: "",
        beam_stirrups_spacing_remarks: "",
        joint_reinforcement_compliance: "",
        joint_reinforcement_remarks: "",
        stirrup_compliance: "",
        stirrup_remarks: "",
        wall_connection_compliance: "",
        wall_connection_remarks: "",
        // missing in prototype
        front_width_compliance: "",
        front_width_remarks: "",
        floor_area_ratio_compliance: "",
        floor_area_ratio_remarks: "",
        front_setback_compliance: "",
        front_setback_remarks: "",
        ground_coverage_ratio_compliance: "",
        ground_coverage_ratio_remarks: "",
        // Revenue Section
        basement1_plinth_area: "",
        basement1_rate: "",
        basement1_amount: 0,
        basement1_remarks: "",
        basement2_plinth_area: "",
        basement2_rate: "",
        basement2_amount: 0,
        basement2_remarks: "",
        basement3_plinth_area: "",
        basement3_rate: "",
        basement3_amount: 0,
        basement3_remarks: "",
        first_plinth_area: "",
        first_rate: "",
        first_amount: 0,
        first_remarks: "",
        second_plinth_area: "",
        second_rate: "",
        second_amount: 0,
        second_remarks: "",
        third_plinth_area: "",
        third_rate: "",
        third_amount: 0,
        third_remarks: "",
        fourth_plinth_area: "",
        fourth_rate: "",
        fourth_amount: 0,
        fourth_remarks: "",
        fifth_plinth_area: "",
        fifth_rate: "",
        fifth_amount: 0,
        fifth_remarks: "",
        sixth_plinth_area: "",
        sixth_rate: "",
        sixth_amount: 0,
        sixth_remarks: "",
        seventh_plinth_area: "",
        seventh_rate: "",
        seventh_amount: 0,
        seventh_remarks: "",
        layout_charge: 0,
        design_charge: 0,
        form_charge: 0,
        application_registration: 0,
        others: 0,
        total: 0,
        observation: "",
        recommendation: "",
      },
    };
  },
  computed: {
    subTotal: function () {
      return (
        parseFloat(this.rcc.basement1_amount == "" || this.rcc.basement1_amount == null ? 0 : this.rcc.basement1_amount) +
        parseFloat(this.rcc.basement2_amount == "" || this.rcc.basement2_amount == null ? 0 : this.rcc.basement2_amount) +
        parseFloat(this.rcc.basement3_amount == "" || this.rcc.basement3_amount == null ? 0 : this.rcc.basement3_amount) +
        parseFloat(this.rcc.first_amount == "" || this.rcc.first_amount == null ? 0 : this.rcc.first_amount) +
        parseFloat(this.rcc.second_amount == "" || this.rcc.second_amount == null ? 0 : this.rcc.second_amount) +
        parseFloat(this.rcc.third_amount == "" || this.rcc.third_amount == null ? 0 : this.rcc.third_amount) +
        parseFloat(this.rcc.fourth_amount == "" || this.rcc.fourth_amount == null ? 0 : this.rcc.fourth_amount) +
        parseFloat(this.rcc.fifth_amount == "" || this.rcc.fifth_amount == null ? 0 : this.rcc.fifth_amount) +
        parseFloat(this.rcc.sixth_amount == "" || this.rcc.sixth_amount == null ? 0 : this.rcc.sixth_amount) +
        parseFloat(this.rcc.seventh_amount == "" || this.rcc.seventh_amount == null ? 0 : this.rcc.seventh_amount)
      );
    },
    totalAmount: function () {
      // convert the string to numbers first with the parseFloat function
      return (
        parseFloat(this.rcc.basement1_amount == "" || this.rcc.basement1_amount == null ? 0 : this.rcc.basement1_amount) +
        parseFloat(this.rcc.basement2_amount == "" || this.rcc.basement2_amount == null ? 0 : this.rcc.basement2_amount) +
        parseFloat(this.rcc.basement3_amount == "" || this.rcc.basement3_amount == null ? 0 : this.rcc.basement3_amount) +
        parseFloat(this.rcc.first_amount == "" || this.rcc.first_amount == null ? 0 : this.rcc.first_amount) +
        parseFloat(this.rcc.second_amount == "" || this.rcc.second_amount == null ? 0 : this.rcc.second_amount) +
        parseFloat(this.rcc.third_amount == "" || this.rcc.third_amount == null ? 0 : this.rcc.third_amount) +
        parseFloat(this.rcc.fourth_amount == "" || this.rcc.fourth_amount == null ? 0 : this.rcc.fourth_amount) +
        parseFloat(this.rcc.fifth_amount == "" || this.rcc.fifth_amount == null ? 0 : this.rcc.fifth_amount) +
        parseFloat(this.rcc.sixth_amount == "" || this.rcc.sixth_amount == null ? 0 : this.rcc.sixth_amount) +
        parseFloat(this.rcc.seventh_amount == "" || this.rcc.seventh_amount == null ? 0 : this.rcc.seventh_amount) +
        parseFloat(this.rcc.layout_charge == "" || this.rcc.layout_charge == null ? 0 : this.rcc.layout_charge) +
        parseFloat(this.rcc.design_charge == "" || this.rcc.design_charge == null ? 0 : this.rcc.design_charge) +
        parseFloat(this.rcc.form_charge == "" || this.rcc.form_charge == null ? 0 : this.rcc.form_charge) +
        parseFloat(this.rcc.application_registration == "" || this.rcc.application_registration == null ? 0 : this.rcc.application_registration) +
        parseFloat(this.rcc.others == "" || this.rcc.others == null ? 0 : this.rcc.others)
      );
    },
  },
  watch: {
    totalAmount() {
      //v-on:keyup the value of rcc total if the totalAmountv-on:keyups
      this.rcc.total = this.totalAmount;
    },
  },
  mounted() {
    console.log("test parseFloat");
    console.log(
      parseFloat(this.rcc.basement1_amount) +
        parseFloat(this.rcc.basement2_amount) +
        parseFloat(this.rcc.basement3_amount) +
        parseFloat(this.rcc.first_amount) +
        parseFloat(this.rcc.second_amount) +
        parseFloat(this.rcc.third_amount) +
        parseFloat(this.rcc.fourth_amount) +
        parseFloat(this.rcc.fifth_amount) +
        parseFloat(this.rcc.sixth_amount) +
        parseFloat(this.rcc.seventh_amount) +
        parseFloat(this.rcc.layout_charge) +
        parseFloat(this.rcc.design_charge) +
        parseFloat(this.rcc.form_charge) +
        parseFloat(this.rcc.application_registration) +
        parseFloat(this.rcc.others)
    );
    console.log(parseFloat(20));
    console.log(parseFloat("30.12212"));
    const url = `api/registration/${this.$route.params.regId}/rcc`;
    VueAxios.get(
      url,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
      {}
    )
      .then((response) => {
        this.rcc = response.data.data;
      })
      .catch((error) => {
        console.log(`api error:${error}`);
      });
  },
  methods: {
    updateBasement1Amount(event) {
      this.rcc.basement1_amount = parseFloat(this.rcc.basement1_plinth_area) * parseFloat(this.rcc.basement1_rate);
    },
    updateBasement2Amount(event) {
      this.rcc.basement2_amount = parseFloat(this.rcc.basement2_plinth_area) * parseFloat(this.rcc.basement2_rate);
    },
    updateBasement3Amount(event) {
      this.rcc.basement3_amount = parseFloat(this.rcc.basement3_plinth_area) * parseFloat(this.rcc.basement3_rate);
    },

    updateAmount1(event) {
      this.rcc.first_amount = parseFloat(this.rcc.first_plinth_area) * parseFloat(this.rcc.first_rate);
    },
    updateAmount2(event) {
      this.rcc.second_amount = parseFloat(this.rcc.second_plinth_area) * parseFloat(this.rcc.second_rate);
    },
    updateAmount3(event) {
      this.rcc.third_amount = parseFloat(this.rcc.third_plinth_area) * parseFloat(this.rcc.third_rate);
    },
    updateAmount4(event) {
      this.rcc.fourth_amount = parseFloat(this.rcc.fourth_plinth_area) * parseFloat(this.rcc.fourth_rate);
    },
    updateAmount5(event) {
      this.rcc.fifth_amount = parseFloat(this.rcc.fifth_plinth_area) * parseFloat(this.rcc.fifth_rate);
    },
    updateAmount6(event) {
      this.rcc.sixth_amount = parseFloat(this.rcc.sixth_plinth_area) * parseFloat(this.rcc.sixth_rate);
    },
    updateAmount7(event) {
      this.rcc.seventh_amount = parseFloat(this.rcc.seventh_plinth_area) * parseFloat(this.rcc.seventh_rate);
    },
    save() {
      // console.log(this.$route.params.regId);
      if (this.rcc.id === null || this.rcc.id === 0 || this.rcc.id === undefined) {
        const url = `/api/registration/${this.$route.params.regId}/rcc`;
        VueAxios.post(
          url,
          this.rcc,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          },
          {
            timeout: 10000,
          }
        )
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.rcc = response.data.data;
              this.snackbar.show = true;
              this.successfulMessage = "Successful.";
            }
          })
          .catch((error) => {
            console.log(`api error:${error}`);
          });
      } else {
        const eurl = `/api/registration/${this.$route.params.regId}/rcc/${this.rcc.id}`;
        VueAxios.put(
          eurl,
          this.rcc,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
            _method: "patch",
          },
          {}
        )
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.successfulMessage = "Data Updated successfully";
            }
            // else {
            //     this.$router.push({
            //         name: 'tabbed',
            //     });
            // }
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    edit(id) {
      //    const url = `api/registration/${this.$route.params.regId}/rcc/${this.$route.params.id}`;
      const url = `api/registration/${this.$route.params.regId}/rcc/${id}`;
      VueAxios.get(
        url,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
        {
          timeout: 100000,
        }
      ).then((response) => {
        this.rcc = response.data.data;
        console.log(response.data.data);
      });
    },
    deleterecord(id) {
      const deleteurl = `api/registration/${this.$route.params.regId}/rcc/${id}`;
      VueAxios.delete(
        deleteurl,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
        {
          timeout: 100000,
        }
      )
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
          }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}
.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}
.row,
.col {
  height: 58px;
}
.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}
</style>
