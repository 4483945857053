<template>
    <div>
        <handle-file-edit-request></handle-file-edit-request>
    </div>
</template>
<script>
import HandleFileEditRequest from "@/modules/admin/file-action/HandleFileEditRequest.vue";

export default {
    name: "AdminFileAction",
    components: {
        HandleFileEditRequest
    },
    data() {
        return {};
    },
    methods: {}
};
</script>