import { VueAxios } from '@/plugins/axios';
import api from '@/api/api';
// import _ from 'lodash'; 

const state = {
    registration: JSON.parse(localStorage.getItem('registration_data')),
    application: JSON.parse(localStorage.getItem('application_data')),
};

// Make the session storage clearable

const getters = {
    registrationData: state => state.registration,
    applicationData: state => state.application,
};
const actions = {
    async getRegistration({ commit }, registrationId) {
        // const response = await VueAxios.get(`api/registration/${registrationId}`)
        const response = await api.registration.registration(registrationId);
        commit('setRegistration', response.data.data);
        // Set the state into local storage
        localStorage.setItem('registration_data', JSON.stringify(response.data.data));
    },
    async updateRegistration({ commit, getters }, payload) {
        const response = await api.registration.updateRegistration(getters.registrationData.id, payload);
        commit('setRegistration', response.data.data);
        localStorage.setItem('registration_data', JSON.stringify(response.data.data));
    },
    async getApplication({ commit }, registrationId) {
        const response = await VueAxios.get(`api/registration/${registrationId}/application`)
        commit('setApplication', response.data.data);
        // Set the state into local storage
        localStorage.setItem('application_data', JSON.stringify(response.data.data));
    },
    async updateRegistrationLocal({ commit }, registrationData) {
        commit('setRegistration', registrationData);
    },
};
const mutations = {
    setRegistration: (state, payload) => (state.registration = payload),
    setApplication: (state, payload) => (state.application = payload),
    // initialiseStore()
};


export default {
    state,
    getters,
    actions,
    mutations
};