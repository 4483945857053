export default axios => ({
    // List of applicant Type
    applicantType() {
        return axios.get("api/applicant-type");
    },
    buildingCategory() {
        return axios.get("api/building-category");
    },
    buildingStructure() {
        return axios.get("api/building-structure");
    },
    buildingUse() {
        return axios.get("api/building-use");
    },
    constructionType() {
        return axios.get("api/construction-type");
    },
    // List of consultancy
    consultancyList() {
        return axios.get("api/consultancy");
    },
    // All district list
    districts() {
        return axios.get("api/districts");
    },
    // Fiscal Years
    fiscalYearList() {
        return axios.get("api/fiscal-year");
    },
    // तलाको नाम मात्र हो यार
    floorNames() {
        return axios.get("api/floor-names");
    },
    // Remove this from dropdown later
    getAssignAuthority() {
        return axios.get("api/assign-authority/status");
    },
    // List of Landowner Type
    landownerType() {
        return axios.get("api/landowner-type");
    },
    // भू-उपयोग क्षेत्र
    landUseArea() {
        return axios.get("api/land-use-area");
    },
    // That one row from municipality table
    municipality() {
        return axios.get("api/municipality");
    },
    postMunicipality(payload) {
        return axios.post("api/municipality", payload);
    },
    putMunicipality(id, payload) {
        return axios.put(`api/municipality/${id}`, payload);
    },
    // Municipality Map 
    map(){
        return axios.get("api/map");
    },
    // Api call that do not require authentication
    municipalityNoLogin() {
        return axios.get("api/no-login/municipality");
    },
    officialUserList() {
        return axios.get("api/user/municipal");
    },
    phaseList() {
        return axios.get("api/phase-list");
    },
    saveAssignAuthority(payload) {
        return axios.post("api/assign-authority", payload);
    },
    userRoleList() {
        return axios.get("api/user-role")
    },

    // Site configuration for user 
    getSiteConfiguration() {
        return axios.get("api/site-configuration");
    }

});