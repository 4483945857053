<template>
  <div>
    <v-toolbar class="background">
      <v-row>
        <v-col class="d-flex align-center">
          <router-link :to="{ name: 'home' }">
            <img :src="require('@/assets/images/logo-dark-2.png')" width="70px" class="mb-8" />
          </router-link>
          <router-link :to="{ name: 'home' }" class="textcol--text text-h6"> {{ munName || "Localization" }} GharNaksha </router-link>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end flex-row align-center">
          <v-btn icon class="mr-4">
            <v-icon>mdi-bell</v-icon>
          </v-btn>
          <v-avatar color="indigo" size="36" class="">
            <span class="white--text text-h5">PS</span>
          </v-avatar>
        </v-col>
      </v-row>
    </v-toolbar>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>