<template>
  <div id="transferNewAuthority">
    <div id="editSection" class="border border-rounded pa-4" v-if="authority.isNew == 1">
      <v-row>
        <v-col cols="4">
          <h4>Transfer From</h4>
          <v-select
            v-model="authority.transfer_from.id"
            :items="userList"
            outlined
            :item-text="(item) => item.first_name + ' ' + item.last_name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="4">
          <h4>Transfer To</h4>
          <div v-for="assignee in authority.transfer_to" :key="assignee">
            <v-select
              v-model="assignee.id"
              :items="transferTo"
              outlined
              :item-text="(item) => item.first_name + ' ' + item.last_name"
              item-value="id"
            ></v-select>
          </div>
          <v-btn class="primary" fab small @click="addNewTransferee">
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="4">
          <h4>Remarks</h4>
          <v-textarea v-model="authority.remarks" label="Remarks" outlined rows="1" row-height="20"></v-textarea>
        </v-col>
      </v-row>
      <!-- <v-btn class="primary" @click="transferAuthority">Transfer Authority</v-btn> -->
      <custom-dialog click-type="transferAuthority" :click-dialog="transferAuthority"
        >Transfer Authority</custom-dialog
      >
    </div>
    <div id="viewSection" class="border border-rounded pa-4" v-else>
      <v-row>
        <v-col cols="6">
          <h5>Transferred From</h5>
          <p>
            <v-chip close close-icon="mdi mdi-account-minus" color="red lighten-1" dark>
              {{ `${authority.transfer_from.first_name} ${authority.transfer_from.last_name}` }}
            </v-chip>
          </p>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6">
          <h5>Transferred To</h5>
          <div v-for="i in authority.transfer_to" :key="i.id">
            <p>
              <v-chip close close-icon="mdi mdi-account-check" color="green darken-1" dark>
                {{ `${i.first_name} ${i.last_name}` }}
              </v-chip>
            </p>
          </div>
        </v-col>
        <v-col cols="12">
          <custom-dialog class="my-1" click-type="revokedAuthority" :click-dialog="revokedAuthority"
            >Revoke Authority</custom-dialog
          ></v-col
        >
      </v-row>
    </div>
    <div>
      <v-snackbar
        v-model="snackbar.show"
        :bottom="y === 'bottom'"
        :color="snackbar.color"
        :left="x === 'left'"
        :multi-line="mode === 'multi-line'"
        :right="x === 'right'"
        :timeout="3000"
        :top="y === 'top'"
        :vertical="mode === 'vertical'"
      >
        {{ successfulMessage }}
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "AuthorityTransferCard",
  props: {
    userList: Array,
    transferTo: Array,
    authority: Object,
  },
  data() {
    return {
      transfereeCount: 1,
      writeStatus: 1,
      transfer_to: [],
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
    };
  },
  watch: {
    async transferFromId() {
      // alert(`woohoo! ${this.userFullName(this.authority.transfer_from.id)}`);
      let response = await api.adminAuthority.assignAuthorityTransferTo(
        this.authority.transfer_from.id
      );
      this.transferTo = response.data.data;
      this.snackbar.show = false;
      this.successfulMessage = response.data.message;
    },
  },
  computed: {
    transferFromId: function () {
      return this.authority.transfer_from.id;
    },
  },
  methods: {
    addNewTransferee() {
      this.authority.transfer_to.push({ id: 0 });
    },
    userFullName(userId) {
      let user = this.userList.find((x) => x.id == userId);
      let fullName = user.first_name + " " + user.last_name;
      return fullName;
    },
    async transferAuthority() {
      this.writeStatus = 0;
      // console.log(`ho ho ho ${this.authority.transfer_from.id}`);
      let response = await api.adminAuthority.postAssignAuthority(this.authority);
      this.snackbar.show = true;
      this.successfulMessage = response.data.message;

      // this.authority.isNew = 0;
      this.$emit("assign", this.authority);
    },
    async revokedAuthority() {
      this.writeStatus = 1;
      let response = await api.adminAuthority.postRevokeAuthority(this.authority.transfer_from.id);
      // this.snackbar.show = true;
      // this.successfulMessage = response.data.message;
      this.$emit("revoke", this.authority.transfer_from.id);
    },
  },
};
</script>
