<template>
  <div>
    <v-row>
      <v-col cols="2">
        <v-text-field
          readonly
          v-model="floorData.floor_name"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          label="Previous Area"
          type="number"
          v-model="floorData.prev_area"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          label="Area"
          type="number"
          v-model="floorData.area"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          label="Height (ft)"
          type="number"
          v-model="floorData.height_ft"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          label="Height (in)"
          type="number"
          v-model="floorData.height_in"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SingleFloor",
  props: {
    floorData: Object,
  },
};
</script>

<style>
</style>