var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isDataLoaded)?_c('div',[_c('v-expansion-panels',{attrs:{"value":_vm.opened}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(" कागजातहरु ")])],1)],1),_c('v-expansion-panel-content',[_c('v-skeleton-loader',_vm._b({attrs:{"type":"list-item, list-item, list-item, list-item, list-item, list-item, list-item, list-item, list-item"}},'v-skeleton-loader',_vm.attrs,false))],1)],1)],1)],1):_c('div',[_c('v-expansion-panels',{attrs:{"value":_vm.opened}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(" कागजातहरु ")])],1)],1),_c('v-expansion-panel-content',[_c('v-simple-table',{staticClass:"sober elevation-2"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.documents),function(item){return _c('tr',{key:item.name,staticClass:"registrationtable",class:_vm.submitStatus[item.value] == 'Completed' ? 'green lighten-5' : 'red lighten-5'},[_c('td',{class:item.typography == 'bold' ? 'font-weight-bold' : ''},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_c('span',[_c('router-link',{attrs:{"to":{
                        name: 'documentupload',
                        params: {
                          id: item.id,
                          regId: _vm.$store.getters.registrationData.id,
                          sn: item.sn,
                          isPostAuthorized: _vm.isDocumentAddEditAuthorized,
                          phaseId: _vm.phaseId,
                        },
                      }}},[_c('v-btn',{staticClass:"blue elevation-0 white--text",attrs:{"small":""}},[_vm._v(_vm._s(_vm.isDocumentAddEditAuthorized || _vm.uploadLevel(item.nativePhaseId) ? "Upload" : "View")+" ")])],1)],1)])])}),0)])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }