const state = {
    userDeskFileOnly: JSON.parse(localStorage.getItem('home_user_file_only')) ? JSON.parse(localStorage.getItem('home_user_file_only')) : false,
    // () => {
    //     let localUserDeskFileOnly = JSON.parse(localStorage.getItem('home_user_file_only'));
    //     return localUserDeskFileOnly.length > 0 ? localUserDeskFileOnly : false;
    //     return false;
    // },
};
const getters = {
    getUserDeskFileOnly: state => state.userDeskFileOnly,
};
const actions = {};
const mutations = {
    changeUserDeskFileOnly: (state, payload) => {
        state.userDeskFileOnly = payload;
        localStorage.setItem("home_user_file_only", payload);
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};