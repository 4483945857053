import api from '@/api/api';

const state = {
    siteConfigurations: JSON.parse(localStorage.getItem('site_configurations')),
    // siteConfigurations: {},
    // localConfigurations: JSON.parse(localStorage.getItem('local_configurations')),
};
const getters = {
    siteConfigurationsList: state => state.siteConfigurations,
    // localConfigurationsList: state => state.localConfigurations,
};
const actions = {
    async getSiteConfigurations({ commit }) {
        const response = await api.dropdowns.getSiteConfiguration();
        commit('setSiteConfigurations', response.data.data);
        // localStorage.setItem('site_configurations', JSON.stringify(response.data.data));
    }
};
const mutations = {
    setSiteConfigurations: (state, payload) => (state.siteConfigurations = payload),
};

export default {
    state,
    getters,
    actions,
    mutations
};