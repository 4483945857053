import { VueAxios } from '@/plugins/axios';


// This form keeping in the store may be bad idea after all
// Let's keep a pause on the forms.js for now

const state = {
    searchKeys: {},
}

const getters = {
    searchKeysAll: state => state.searchKeys,
}
const actions = {
    updateSearchKeys({ commit }, payload) {
        commit('setSearchKeysObject', payload)
    }
}
const mutations = {
    setSearchKeysObject: (state, searchKeys) => (state.searchKeys = searchKeys),
}

export default {
    state,
    getters,
    actions,
    mutations
}