<template>
  <div>
    One is rendering
    <v-file-input
      v-model="newData.photo"
      label="Select File..."
    ></v-file-input>
    <v-text-field v-model="newData.nice"></v-text-field>
    <v-btn @click="testCall">Second Btn</v-btn>
  </div>
</template>

<script>
export default {
  name: "ComponentOne",
  data() {
    return {
      newData: {
        nice: "",
        photo: "",
      },
      // formInfo: new FormData(),
    };
  },
  computed: {
    formInfo() {
      let formData = new FormData();
      formData.append("nice", this.newData.nice);
      formData.append("photo", this.newData.photo);
      // formData.append("_method", "POST");
      return formData;
    },
  },
  methods: {
    testCall() {
      // this.formInfo.append("nice", this.newData.nice);
      // this.formInfo.append("photo", this.newData.photo);
      // this.formInfo.append("_method", "POST");
      this.newData = this.formInfo;

      this.$axios.post("dds", this.newData);
    },
  },
};
</script>

<style>
</style>