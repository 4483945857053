<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></loading>
    <template v-if="!isImage">
      <div v-for="pdf in pdfs" :key="pdf">
        <iframe :src="pdf" title="description" width="100%" height="800px" id="my_iframe"></iframe>
      </div>
    </template>
    <template v-else>
      <viewer :images="images">
        <img :class="thumbnail ? 'thumbnail-preview' : 'full-screen-preview'" v-for="content in images" :key="content" :src="content" />
      </viewer>
    </template>
  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";

import VueViewer from "v-viewer";
import Vue from "vue";

Vue.use(VueViewer);

export default {
  props: {
    src: {
      type: String,
    },
    thumbnail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: undefined,
      isLoading: false,
      images: [],
      pdfs: [],
    };
  },
  created() {
    this.getImageArray();
    this.getPdfArray();
    this.getPdfContent();
  },
  computed: {
    isImage() {
      if (this.src.split(".").pop() === "jpg" || this.src.split(".").pop() === "jpeg" || this.src.split(".").pop() === "png") {
        return true;
      }
      return false;
    },
  },
  methods: {
    getPdfContent() {
      let url = this.pdfs[0];
      this.isLoading = true;
      return this.$axios
        .get(url, {
          responseType: "blob",
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: response.data.type });
          const objectUrl = URL.createObjectURL(blob);
          this.content = objectUrl;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    getImageArray() {
      if (this.src.split(".").pop() === "jpg" || this.src.split(".").pop() === "jpeg" || this.src.split(".").pop() === "png") {
        this.images.push(this.src);
        console.log(`images[]: ${images}`);
      }
    },
    getPdfArray() {
      if ((this.src.split(".").pop() !== "jpg" && this.src.split(".").pop() !== "jpeg") || this.src.split(".").pop() !== "png") {
        this.pdfs.push(this.src);
      }
    },
  },
};
</script>

<style scoped>
.thumbnail-preview {
  max-height: 8rem;
}

.full-screen-preview {
  width: 100%;
}
</style>