<template>
  <b-container fluid>
    <b-overlay :show="busy" rounded="lg" opacity="0.6" @hidden="onHidden">
      <b-alert
        :show="dismissCountDownSuccess"
        dismissible
        variant="success"
        @dismissed="dismissCountDownSuccess = 0"
        @dismiss-count-down="countDownChanged"
      >
        {{ message }}
      </b-alert>

      <b-alert
        :show="dismissCountDownError"
        dismissible
        variant="danger"
        @dismissed="dismissCountDownError = 0"
        @dismiss-count-down="countDownChanged"
      >
        {{ message }}
      </b-alert>

      <b-modal
        ref="modal-center"
        id="modal-center"
        size="lg"
        centered
        title="Add New Municipality User"
        hide-footer
      >
        <b-form @submit.stop.prevent="onSubmit">
          <b-row>
            <b-col>
              <b-form-group id="example-input-group-1" label="Username" label-for="example-input-1">
                <b-form-input
                  id="example-input-1"
                  name="example-input-1"
                  v-model="$v.bpsUser.username.$model"
                  :state="validateState('username')"
                  type="text"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                  >This is a required field must be at least 3 characters without any
                  space</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="example-input-group-1"
                label="First Name"
                label-for="example-input-1"
              >
                <b-form-input
                  id="example-input-1"
                  name="example-input-1"
                  v-model="$v.bpsUser.first_name.$model"
                  :state="validateState('first_name')"
                  type="text"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                  >This is a required field must be in english character</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                id="example-input-group-1"
                label="Last Name"
                label-for="example-input-1"
              >
                <b-form-input
                  id="example-input-1"
                  name="example-input-1"
                  v-model="$v.bpsUser.last_name.$model"
                  :state="validateState('last_name')"
                  type="text"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                  >This is a required field must be in english characters
                </b-form-invalid-feedback>
              </b-form-group> </b-col
            ><b-col>
              <b-form-group id="example-input-group-1" label="Email" label-for="example-input-1">
                <b-form-input
                  id="example-input-1"
                  name="example-input-1"
                  type="email"
                  v-model="$v.bpsUser.email.$model"
                  :state="validateState('email')"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                  >Given data is in an invalid format
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                id="example-input-group-1"
                label="Designation"
                label-for="example-input-1"
              >
                <b-form-input
                  id="example-input-1"
                  name="example-input-1"
                  type="text"
                  v-model="$v.bpsUser.designation.$model"
                  :state="validateState('designation')"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                  >This is a required field must be in english characters</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="fieldset-1"
                description="Enter your password."
                label="Enter your password"
                label-for="input-1"
              >
                <b-form-input
                  id="example-input-1"
                  name="example-input-1"
                  type="password"
                  v-model="$v.bpsUser.password.$model"
                  :state="validateState('password')"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group id="input-group-3" label="User Roles *" label-for="input-3">
                <b-form-select size="sm" v-model="$v.bpsUser.user_role_id.$model">
                  <b-form-select-option
                    v-for="userRole in userRoles"
                    :key="userRole.id"
                    :value="userRole.id"
                    size="sm"
                    >{{ userRole.role_name }}</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group id="input-group-3" label="User Office Type *" label-for="input-3">
                <b-form-select
                  size="sm"
                  v-on:change="getUserOfficeType"
                  v-model="$v.bpsUser.user_office_type_id.$model"
                >
                  <b-form-select-option
                    v-for="officeType in officeTypes"
                    :key="officeType.id"
                    :value="officeType.id"
                    size="sm"
                    >{{ officeType.name }}</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Ward:" label-for="input-3" v-if="showWard">
                <b-form-select id="input-3" size="sm" v-model="$v.bpsUser.ward_no.$model" required>
                  <b-form-select-option
                    v-for="munward in munwards"
                    :key="munward"
                    :value="munward"
                    >{{ munward }}</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col> </b-col>
          </b-row>
          <b-button type="submit" variant="success">Submit</b-button>
          <b-button variant="danger" class="ml-2" @click="resetForm()">Reset</b-button>
        </b-form>
      </b-modal>

      <div>
        <!-- Tabs with card integration -->
        <b-card no-body>
          <b-tabs v-model="tabIndex" small card>
            <b-tab title="Active Users" active v-on:click="getBpsUserList()">
              <v-toolbar color="primary" dark flat>
                <v-spacer />
                <v-toolbar-title> Municipality Users ( ACTIVED Users ) </v-toolbar-title>
                <v-spacer />
                <b-button pill variant="info" v-b-modal.modal-center v-if="isAdmin === 1">
                  <!-- Yo chai customize gareko natra garyo bhane default ma auchha ok ra cancel modal footer ma -->
                  <!-- <b-button pill variant="info" v-b-modal.modal-center.modal-prevent-closing> -->
                  <b-icon-plus></b-icon-plus> Add New User</b-button
                >
              </v-toolbar>

              <div>
                <b-table
                  striped
                  hover
                  :items="bpsUserItem"
                  :fields="bpsUserFields"
                  :busy="isLoading"
                  :per-page="perPage"
                  :current-page="currentPage"
                >
                  <template #cell(name)="data">
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle">{{ data.value }}</b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>
                  <template>
                    <col :key="remarks" :style="{ width: '20%' }" />
                  </template>
                  <template #cell(id)="row">
                    <v-btn
                      class="primary"
                      small
                      v-b-modal.modal-prevent-closing-edit
                      v-on:click="editBpsActiveUser(row.item.id)"
                      v-if="isAdmin === 1"
                      >Edit</v-btn
                    >
                    <v-btn
                      class="error mx-3"
                      small
                      v-on:click="deleteBpsUser(row.item.id)"
                      v-if="isAdmin === 1"
                      >Delete</v-btn
                    >

                    <v-btn
                      class="mx-3"
                      :class="row.item.is_active == 1 ? 'warning' : 'success'"
                      small
                      v-if="isAdmin === 1"
                      v-on:click="checkActivateStatus(row.item)"
                      >{{ row.item.is_active ? "Deactivate" : "Activate" }}</v-btn
                    >
                  </template>
                </b-table>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </b-tab>
            <b-tab title="Deactive Users" v-on:click="getBpsDeactivateUserList()">
              <v-toolbar color="primary" dark flat>
                <v-spacer />
                <v-toolbar-title> Municipality Users ( DEACTIVATED Users ) </v-toolbar-title>
                <v-spacer />
              </v-toolbar>

              <div>
                <b-table
                  striped
                  hover
                  :items="bpsDeactivateUserItem"
                  :fields="bpsDeactivateUserFields"
                  :busy="isLoading"
                  :per-page="perPage"
                  :current-page="currentPage"
                >
                  <template #cell(name)="data">
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle">{{ data.value }}</b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>
                  <template>
                    <col :key="remarks" :style="{ width: '20%' }" />
                  </template>
                  <template #cell(id)="row">
                    <v-btn
                      class="primary"
                      small
                      v-b-modal.modal-prevent-closing-edit
                      v-on:click="editBpsDeactiveUser(row.item.id)"
                      v-if="isAdmin === 1"
                      >Edit</v-btn
                    >
                    <v-btn
                      class="error mx-3"
                      small
                      v-on:click="deleteBpsUser(row.item.id)"
                      v-if="isAdmin === 1"
                      >Delete</v-btn
                    >

                    <v-btn
                      class="mx-3"
                      :class="row.item.is_active ? 'warning' : 'success'"
                      small
                      v-if="isAdmin === 1"
                      v-on:click="checkActivateStatus(row.item)"
                      >{{ !row.item.is_active ? "Activate" : "Deactivate" }}</v-btn
                    >
                  </template>
                </b-table>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>

        <!-- Control buttons-->
        <div class="text-center">
          <b-button-group class="mt-2">
            <b-button variant="outline-primary" @click="tabIndex--">Previous</b-button>
            <b-button variant="outline-primary" @click="tabIndex++">Next</b-button>
          </b-button-group>

          <!-- <div class="text-muted">Current Tab: {{ tabIndex }}</div> -->
        </div>
      </div>

      <!-- Yo chai delete ko lagi modal ho hai -->
      <b-modal id="modalDelete" ref="modalDelete" title="Delete User" @ok="handleOkDelete">
        <form ref="form">
          <p class="my-4">Are you sure to delete this user?</p>
        </form>
      </b-modal>

      <!-- Yo chai deactivate ko lagi modal ho hai -->
      <b-modal
        id="modalActivate"
        ref="modalActivate"
        title="Deactivate User"
        @ok="handleOkDeactivate"
      >
        <form ref="form">
          <p class="my-4">Are you sure to {{ isActive ? "deactivate" : "activate" }} this user?</p>
        </form>
      </b-modal>

      <!-- Yo chai edit ko lagi modal ho hai -->
      <b-modal
        id="modal-prevent-closing-edit"
        ref="modal"
        title="Edit User"
        @ok="handleOk"
        :bpsUserId="bpsUserId"
      >
        <form ref="form">
          <b-form-group
            label="Username"
            label-for="username-input"
            invalid-feedback="Name is required"
          >
            <b-form-input id="username-input" v-model="bpsUser.username" readonly></b-form-input>
          </b-form-group>
          <b-form-group label="Email" label-for="email-input" invalid-feedback="Name is required">
            <b-form-input id="email-input" v-model="bpsUser.email" readonly></b-form-input>
          </b-form-group>
          <b-form-group label="Designation" label-for="designation-input">
            <b-form-input
              id="designation-input"
              v-model="bpsUser.designation"
              readonly
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="First Name"
            label-for="first-name-input"
            invalid-feedback="Name is required"
          >
            <b-form-input
              id="first-name-input"
              v-model="bpsUser.first_name"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Last Name"
            label-for="last-name-input"
            invalid-feedback="Name is required"
          >
            <b-form-input id="last-name-input" v-model="bpsUser.last_name" required></b-form-input>
          </b-form-group>
          <b-form-group
            label="Office Type"
            label-for="office-type-input"
            invalid-feedback="Office Type is required"
          >
            <b-form-select
              size="sm"
              v-on:change="getUserOfficeType"
              v-model="bpsUser.user_office_type_id"
            >
              <b-form-select-option
                v-for="officeType in officeTypes"
                :key="officeType.id"
                :value="officeType.id"
                size="sm"
                >{{ officeType.name }}</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
          <b-form-group label="Ward:" label-for="input-3" v-if="showWard">
            <b-form-select id="input-3" size="sm" v-model="bpsUser.ward_no" required>
              <b-form-select-option v-for="munward in munwards" :key="munward" :value="munward">{{
                munward
              }}</b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group label="Desk:" label-for="input-3">
            <b-form-select size="sm" v-model="bpsUser.user_role_id">
              <b-form-select-option
                v-for="userRole in userRoles"
                :key="userRole.id"
                :value="userRole.id"
                size="sm"
                >{{ userRole.role_name }}</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </form>
      </b-modal>

      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="secondary"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="secondary"></b-spinner>
          <!-- We add an SR only text for screen readers -->
          <span class="sr-only">Please wait...</span>
        </div>
      </template>
    </b-overlay>
  </b-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  alpha,
  email,
  alphaNum,
  regex,
  helpers,
} from "vuelidate/lib/validators";

const alphaWithSpace = helpers.regex(/^[a-zA-Z\s]/);

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [validationMixin],
  name: "BpsUserComponent",
  props: {
    nextStep: {
      type: Function,
    },
    bpsUserIdDel: {
      type: Number,
    },
  },
  data: () => ({
    tabIndex: 1,
    showWard: false,
    isActive: false,
    user_office_type_id: "",
    munwards: "",
    ward_no: null,
    officeTypes: [],
    userRoles: [],
    bpsUser: {
      username: "",
      first_name: "",
      last_name: "",
      email: "",
      designation: "",
      password: "",
      user_office_type_id: "",
      ward_no: "",
      user_role_id: "",
    },
    bpsUserItem: [],
    bpsDeactivateUserItem: [],
    bpsUserFields: [
      {
        key: "username",
        label: "Username",
        sortable: true,
      },
      { key: "email", label: "Email", sortable: true },
      {
        key: "designation",
        label: "Designation",
        sortable: true,
      },
      {
        key: "first_name",
        label: "First Name",
        sortable: true,
      },
      {
        key: "last_name",
        label: "Last Name",
        sortable: true,
      },
      {
        key: "user_office_type_id",
        label: "Office Type",
        sortable: true,
        formatter: (value) => {
          if (value === 0) {
            return "Municipality";
          } else if (value === 1) {
            return "Municipality";
          } else if (value === 2) {
            return "Ward";
          }
        },
      },
      {
        key: "ward_no",
        label: "Ward No",
        sortable: true,
        formatter: (value) => {
          if (Number.isNaN(parseFloat(value))) {
            return "N/A";
          } else {
            return value;
          }
        },
      },
      {
        key: "user_role_id",
        label: "User Role",
        sortable: true,
        formatter: (value) => {
          if (value === 0) {
            return "NA";
          } else if (value === 1) {
            return "Admin";
          } else if (value === 2) {
            return "Registration Desk";
          } else if (value === 3) {
            return "Overseer Desk";
          } else if (value === 4) {
            return "Technical Desk";
          } else if (value === 6) {
            return "Elecutive Desk";
          } else if (value === 7) {
            return "Elected Offcials Desk";
          } else if (value === 8) {
            return "Consultancy Desk";
          } else if (value === 9) {
            return "Structure Engineer Desk";
          }
        },
      },
      {
        key: "id",
        label: "Action",
      },
    ],
    bpsDeactivateUserFields: [
      {
        key: "username",
        label: "Username",
        sortable: true,
      },
      { key: "email", label: "Email", sortable: true },
      {
        key: "designation",
        label: "Designation",
        sortable: true,
      },
      {
        key: "first_name",
        label: "First Name",
        sortable: true,
      },
      {
        key: "last_name",
        label: "Last Name",
        sortable: true,
      },
      {
        key: "user_office_type_id",
        label: "Office Type",
        sortable: true,
        formatter: (value) => {
          if (value === 0) {
            return "Municipality";
          } else if (value === 1) {
            return "Municipality";
          } else if (value === 2) {
            return "Ward";
          }
        },
      },
      {
        key: "ward_no",
        label: "Ward No",
        sortable: true,
        formatter: (value) => {
          if (Number.isNaN(parseFloat(value))) {
            return "N/A";
          } else {
            return value;
          }
        },
      },
      {
        key: "user_role_id",
        label: "User Role",
        sortable: true,
        formatter: (value) => {
          if (value === 0) {
            return "NA";
          } else if (value === 1) {
            return "Admin";
          } else if (value === 2) {
            return "Registration Desk";
          } else if (value === 3) {
            return "Overseer Desk";
          } else if (value === 4) {
            return "Technical Desk";
          } else if (value === 6) {
            return "Elecutive Desk";
          } else if (value === 7) {
            return "Elected Offcials Desk";
          } else if (value === 8) {
            return "Consultancy Desk";
          } else if (value === 9) {
            return "Structure Engineer Desk";
          }
        },
      },
      {
        key: "id",
        label: "Action",
      },
    ],
    message: "",
    bpsUserId: 0,
    bpsUserIdDel: "",
    perPage: 10,
    currentPage: 1,
    isLoading: false,
    dismissSecs: 4,
    dismissCountDownSuccess: 0,
    dismissCountDownError: 0,
    countDownChanged: 0,
    value: "Some value",
    busy: false,
    timeout: null,
    isAdmin: "",
    remarks: "",
    resetModal: null,
  }),

  computed: {
    rows() {
      return this.bpsUserItem.length;
    },
  },
  mounted() {
    this.getBpsActiveUserList();
    this.getBpsDeactivateUserList();
    this.getTotalWard();
    this.getOfficeTypes();
    this.isAuthorize();
    this.getUserRoles();
    this.editBpsActiveUser(id);
    this.editBpsDeactiveUser(id);
  },
  beforeDestroy() {
    this.checkActivate();
    this.clearTimeout();
  },

  validations: {
    bpsUser: {
      username: {
        required,
        minLength: minLength(3),
        alphaNum,
      },

      first_name: {
        required,
        alphaNum,
      },

      last_name: {
        required,
        alphaNum,
      },

      email: {
        required,
        email,
      },

      designation: {
        required,
      },

      password: {
        required,
      },

      user_office_type_id: {
        required,
      },

      ward_no: {},

      user_role_id: {
        required,
      },
    },
  },

  methods: {
    validateState(modelName) {
      const { $dirty, $error } = this.$v.bpsUser[modelName];
      return $dirty ? !$error : null;
    },

    resetForm() {
      this.bpsUser = {
        username: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    clearTimeout() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
    },

    setTimeout(callback) {
      this.clearTimeout();
      this.timeout = setTimeout(() => {
        this.clearTimeout();
        callback();
      }, 1000);
    },

    onHidden() {
      this.$refs.button.focus();
    },

    hideModal() {
      this.$refs["modal-center"].hide();
    },

    onSubmit() {
      this.$v.bpsUser.$touch();
      if (this.$v.bpsUser.$anyError) {
        return;
      }
      const url = `api/register`;
      this.$axios
        .post(url, this.bpsUser)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.$refs["modal-center"].hide();
            this.busy = true;
            this.setTimeout(() => {
              this.busy = false;
              this.message = response.data.message;
              this.dismissCountDownSuccess = this.dismissSecs;
            });
          }
        })
        .catch((error) => {
          this.$refs["modal-center"].hide();
          this.busy = true;
          this.setTimeout(() => {
            this.busy = false;
            this.message = error.response.data.message;
            this.dismissCountDownError = this.dismissSecs;
          });
        });
    },

    handleOk() {
      const url = `/api/user/${this.bpsUserId}`;
      this.$axios
        .put(url, this.bpsUser)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.busy = true;
            this.setTimeout(() => {
              this.busy = false;
            });
            this.message = response.data.message;
            this.dismissCountDownSuccess = this.dismissSecs;
            this.getBpsActiveUserList();
            this.getBpsDeactivateUserList();
          }
        })
        .catch((error) => {
          this.busy = true;
          this.setTimeout(() => {
            this.busy = false;
          });
          this.message = error.response.data.message;
          this.dismissCountDownError = this.dismissSecs;
          this.getBpsDeactivateUserList();
          this.getBpsActiveUserList();
        });
    },

    editBpsActiveUser(id) {
      const bps_user_id = id;
      const url = `api/user/${bps_user_id}`;
      this.$axios
        .get(url)
        .then((response) => {
          this.bpsUser = response.data.data;
          // console.log(`apitest:${response.data.data['user_office_type_id']}`);
          // console.log(`apitest:${response.data.data["id"]}`);
          this.bpsUserId = response.data.data["id"];
        })
        .catch((error) => {
          console.log(`api error:${error}`);
          this.isLoading = false;
        });
    },

    editBpsDeactiveUser(id) {
      const bps_user_id = id;
      const url = `api/user/${bps_user_id}`;
      this.$axios
        .get(url)
        .then((response) => {
          this.bpsUser = response.data.data;
          // console.log(`apitest:${response.data.data['user_office_type_id']}`);

          this.bpsUserId = response.data.data["id"];
        })
        .catch((error) => {
          console.log(`api error:${error}`);
          this.isLoading = false;
        });
    },

    deleteBpsUser(id) {
      this.bpsUserIdDel = id;
      this.$bvModal.show("modalDelete");
    },

    checkActivateStatus(item) {
      this.bpsUserId = item.id;
      if (!item.is_active) {
        this.isActive = false;
      } else {
        this.isActive = true;
      }
      this.$bvModal.show("modalActivate");
    },

    handleOkDelete() {
      const url = `/api/user/${this.bpsUserIdDel}`;
      this.$axios
        .delete(url)
        .then((response) => {
          if (response.status === 200) {
            this.busy = true;
            this.setTimeout(() => {
              this.busy = false;
            });
            this.message = response.data.message;
            this.dismissCountDownSuccess = this.dismissSecs;
            this.getBpsActiveUserList();
            this.getBpsDeactivateUserList();
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.message = error.response.data.message;
          this.dismissCountDownError = this.dismissSecs;
          this.getBpsActiveUserList();
          this.getBpsDeactivateUserList();
        });
    },

    handleOkDeactivate() {
      let url = "";
      if (this.isActive == true) {
        url = `api/user/${this.bpsUserId}/deactivate`;
      } else {
        url = `api/user/${this.bpsUserId}/activate`;
      }
      this.$axios
        .put(url, this.bpsUser)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.busy = true;
            this.setTimeout(() => {
              this.busy = false;
            });
            this.message = response.data.message;
            this.dismissCountDownSuccess = this.dismissSecs;
            this.getBpsActiveUserList();
            this.getBpsDeactivateUserList();
          }
        })
        .catch((error) => {
          this.busy = true;
          this.setTimeout(() => {
            this.busy = false;
          });
          this.message = error.response.data.message;
          this.dismissCountDownError = this.dismissSecs;
          this.getBpsActiveUserList();
        });
    },

    getBpsActiveUserList() {
      const bpsUserUrl = "api/municipality-user-list";
      this.isLoading = true;
      this.$axios
        .get(bpsUserUrl)
        .then((response) => {
          this.bpsUserItem = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(`revert info api: ${error}`);
        });
    },

    getBpsDeactivateUserList() {
      const bpsUserUrl = "api/municipality-deactivate-user-list";
      this.isLoading = true;
      this.$axios
        .get(bpsUserUrl)
        .then((response) => {
          this.bpsDeactivateUserItem = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(`revert info api: ${error}`);
        });
    },

    getTotalWard() {
      const url = `api/muncipality-wards`;
      this.$axios
        .get(url)
        .then((response) => {
          this.munwards = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getOfficeTypes() {
      const url = `api/office-types`;
      this.$axios
        .get(url)
        .then((response) => {
          this.officeTypes = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getUserRoles() {
      const url = `api/user-roles`;
      this.$axios
        .get(url)
        .then((response) => {
          this.userRoles = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getUserOfficeType() {
      if (this.bpsUser.user_office_type_id === 1) {
        this.showWard = false;
        this.bpsUser.ward_no = "";
      } else {
        this.showWard = true;
      }
    },

    isAuthorize() {
      const url = `api/profile`;
      this.$axios
        .get(url)
        .then((response) => {
          this.isAdmin = response.data.user_role_id;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
