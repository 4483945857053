<template>
  <v-app>
    <router-view> </router-view>
  </v-app>
</template>
<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

@font-face {
  font-family: "Roboto Condensed";
  src: url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
  font-weight: 400;
}


@font-face {
  font-family: "Mukta Regular";
  /* src: local("Mukta Regular"), url("./assets/fonts/Mukta/Mukta-Regular.woff2"); */
  src: url("./assets/fonts/Mukta/Mukta-Regular.ttf") format('truetype');
  font-weight: 400;
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1DC0-1DFF, U+A8E0-A8FB;
}

/* Below commented code works, but incomplete UI testing */
/* .v-application {
  font-family: "Mukta Regular", "Noto Sans", sans-serif !important;
} */

/* .v-application .title {
  font-family: "Mukta Regular", "Roboto Condensed", sans-serif !important;
} */

.font-heading {
  font-family: "Mukta Regular", "Roboto Condensed", sans-serif !important;
}

.layout.wrap {
  background: #eee;
}

.layout.wrap a {
  text-decoration: none;
}

.logoutdiv {
  background: #337ab7;
  width: fit-content;
  padding: 2px 35px;
  border-radius: 0px 0px 20px 20px;
  margin-top: -24px;
  color: #fff;
}

.md2 {
  padding: 10px 22px;
  border-right: 1px solid #ddd;
}

.content {
  border: 1px solid #ddd;
  padding: 20px;
}

.v-stepper__step--active {
  background-color: #048bdb;
}

.v-stepper__step--active > div {
  color: white !important;
  font-weight: 600;
}

.v-stepper__step--active:hover > div {
  color: black !important;
  font-weight: 600;
}

.v-stepper__content {
  padding: 0px !important;
  /* Set some breathing gap for all the forms in stepper content */
}

a {
  text-decoration: none !important;
}

.left-logo {
  text-align: right !important;
  float: right;
  align-self: flex-end;
  margin-left: -96px;
}

.v-label {
  font-size: 11pt !important;
}

.v-select__selections {
  line-height: 23px !important;
}

/* .text-start{
  font-size: 1rem !important;
} */
</style>
<script>
export default {
  name: "App",
};
</script>
